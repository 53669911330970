import { Component } from '@angular/core';

@Component({
  selector: 'app-keywords',
  standalone: true,
  templateUrl: './keywords.component.html'
})
export class KeywordsComponent {

}
