<ngx-file-drop [accept]="extensionAllowed"
               (onFileDrop)="fileDropped($event)"
               (onFileOver)="fileOver($event)"
               (onFileLeave)="fileLeave($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <span class="title">{{title}}</span>
        <button mat-raised-button mat-primary
                type="button"
                aria-label="select a file"
                class="add-file-button mat-raised-button"
                (click)="openFileSelector()"
                aria-label="add file">
            <span> {{btnLabel}}</span>
        </button>
        <span class="description">{{description}}</span>
        <span class="error" *ngIf="errorMsg!==''">{{errorMsg}}</span>
    </ng-template>
</ngx-file-drop>

