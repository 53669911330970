<div id="maintenance" fxLayout="column" fusePerfectScrollbar>

  <div id="maintenance-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="maintenance-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

      <div class="logo">
        <img *ngIf="!brandLogoSvg" src="assets/{{ brand }}/images/logos/brand-square.png" alt="">
          <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square" ></mat-icon>
      </div>

      <div class="title">Fermé pour maintenance!</div>

      <div class="subtitle">
        Nous sommes désolé pour cet inconvénient <br> Merci de revenir un peu plus tard.
      </div>

    </div>

  </div>

</div>