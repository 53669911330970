<video *ngIf="video?.uri" [controls]="controls"
       (contextmenu)="onContextMenu($event)" #myVideo
       [attr.controlsList]="controlsList"
       crossorigin="anonymous"
       [muted]="muted">
    <source [src]="source" [type]="filemime">
    <track *ngFor="let subtitle of (subtitles ?? [])"
           [label]="subtitle.label | translate"
           [src]="subtitle.url"
           [srclang]="subtitle.lang"
           kind="subtitles">
    <ng-content></ng-content>
    {{ 'generic.video_support' | translate }}
</video>
