<div class="followed-tabs page-layout simple fullwidth"
     fxFlex
     fxLayout="column">

    <!-- The root-filter component handles his own visibility (depending on brand, route, etc.) -->
    <app-root-filter></app-root-filter>

    <nav class="followed-tabs__nav"
         mat-tab-nav-bar
         [tabPanel]="tabPanel">
        <button *ngFor="let tab of matTabs"
                class="followed-tabs__nav-button"
                mat-tab-link
                [active]="isActive(tab)"
                [routerLink]="getLink(tab)">
            {{ tab | translate }}
        </button>
    </nav>

    <mat-tab-nav-panel #tabPanel
                       class="followed-tabs__panel">
        <router-outlet></router-outlet>
    </mat-tab-nav-panel>
    
</div>