<div fxLayout="column" class="page-layout simple" fxFlex>

<div class="header p-16" *ngIf="!page?.get('hideLabel') && displayTitle && !isCustomBanner">
    <h2>{{ page?.get('label') }}</h2>
</div>

<div class="content">
    <div [innerHTML]="page?.get('body')" [ngClass]="{'custom-page': isCustomBanner}" (click)="clickOnPage($event)"></div>

    <app-dynamic *ngFor="let component of page?.get('components')"
                 [room]="component.module"
                 [subject]="component.component"
                 [params]="composeParams(component)"
    ></app-dynamic>

    <iframe *ngIf="iframeUrl" allow="fullscreen" [src]="iframeUrl" style="width:100%; height: 100%;"></iframe>

    <mat-accordion class="p-24" *ngIf="page?.get('pages').length" multi>
        <div *ngFor="let subPage of page.get('pages')">
            <h3 *ngIf="subPage.chapter">{{ subPage.label }}</h3>
            <h4 *ngIf="subPage.chapter && subPage.body" [innerHTML]="subPage.body"></h4>
            <mat-expansion-panel *ngIf="!subPage.chapter" class="mb-24 border-radius-8">
                <mat-expansion-panel-header class="border-radius-8">
                    <mat-panel-title fxLayoutAlign="center">
                        {{subPage.label}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="subPage.body">
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>
</div>
</div>