<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>
    <button *ngIf="embedded"
            class="close"
            (click)="close()"
            [attr.aria-label]="'generic.close' | translate"
            [matTooltip]="'generic.close' | translate">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
    <div class="content inner-scroll">
        <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="mindmap-list"></app-banner-info>
        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <app-search-filters (launchSearch)="refreshNotes($event).subscribe()" [countEntities]="paginator?.count"
                                    [customFieldName]="[{field: 'metadatas_title', value: 'generic.title'}]"
                                    [fields]="filters"
                                    [overrideEndpointFilterName]="[
                                        {originalFilterName: 'lessons', targetFilterName: 'associated_node'}
                                    ]"
                ></app-search-filters>
            </mat-toolbar-row>
        </mat-toolbar>

        <div *ngIf="isLoadingDone; then listBlock; else loadingBlock"></div>

        <ng-template #listBlock>
            <div class="mindmap-card-list" >
                <div class="content">
                    <div class="fake-card">
                        <div (click)="createNote($event)" *ngIf="isLoadingDone"
                             role="button"
                             (keyup.enter)="createNote($event)"
                             tabindex="0">
                            <mat-card class="mat-card">
                                <mat-card-content class="mat-card-content">
                                    <mat-icon svgIcon="add"></mat-icon>
                                    <h3>{{'mindmap.add_mindmap' | translate}}</h3>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div *ngFor="let mindmap of mindmaps">
                        <app-mindmap-card (actionTriggered)="onActionTriggered($event)"
                                          (hasChanged)="onMindmapChanged(mindmap)"
                                          [embedded]="embedded"
                                          [forceAssociationOnSave]="forceAssociationOnSave"
                                          [mindmapBasicSearchEntity]="mindmap"
                                          class="card-split-media-info"></app-mindmap-card>
                    </div>
                </div>
            </div>
            <mat-paginator (page)="onPaginateChange($event)" [length]="paginator.count"
                           [pageIndex]="paginator.page -1"
                           [pageSize]="mindmapListRange"></mat-paginator>
        </ng-template>

        <ng-template #loadingBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>
</div>
