<mat-accordion>
    <mat-expansion-panel *ngFor="let selection of selections">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{selection.method.attributes.name}}
            </mat-panel-title>
            <mat-panel-description>
                {{selection.chapters.length}} {{ "generic.chapters" | translate}}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div *ngFor="let chapter of selection.chapters; let last = last">
            <p>{{chapter.attributes.label}}</p>
            <mat-divider *ngIf="last === false"></mat-divider>
        </div>

        <mat-action-row>
            <button mat-button (click)="remove(selection)">
                <mat-icon svgIcon="delete"></mat-icon>
            </button>
        </mat-action-row>

    </mat-expansion-panel>

</mat-accordion>