<div class="custom-block">
    <h2 *ngIf="block?.title">{{ block.title }}</h2>
    <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel *ngFor="let question of block['questions']">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ question['question'] }}
                    <mat-icon svgIcon="chevron"></mat-icon>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <p *ngIf="question['answer']" [innerHTML]="question['answer']" appLinkHandler (click)="blockHandler.onLinkClick($event)"></p>

            <ng-container *ngIf="question['questions']">
                <mat-expansion-panel *ngFor="let subquestion of question['questions']">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{subquestion.title}}
                            <mat-icon svgIcon="chevron"></mat-icon>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p *ngIf="subquestion['answer']" [innerHTML]="subquestion['answer']" appLinkHandler (click)="blockHandler.onLinkClick($event)"></p>

                    <ng-container *ngIf="subquestion['questions']">
                        <mat-expansion-panel *ngFor="let subsubquestion of subquestion['questions']">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{subsubquestion.title}}
                                    <mat-icon svgIcon="chevron"></mat-icon>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p *ngIf="subsubquestion['answer']" [innerHTML]="subsubquestion['answer']" appLinkHandler (click)="blockHandler.onLinkClick($event)"></p>
                        </mat-expansion-panel>
                    </ng-container>

                </mat-expansion-panel>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>