<!--for lock all control during loading mat-chip list and mat select must be managed manualy-->
<fieldset class="groups-form-dialog disabled-border-fieldset-when-loading"
          [disabled]="loading">
    <div class="groups-form-dialog__container dialog-content-wrapper">

        <div *ngIf="isModal"
             class="groups-form-dialog__header mb-0"
             mat-dialog-title>
            <h2>
                <span translate>{{dialogTitle}}</span>
                <span *ngIf="entity.name" class="entity-name">{{entity.name}}</span>
            </h2>
            <button class="close"
                    (click)="dialogRef.close()"
                    aria-label="Close dialog">
                <mat-icon svgIcon="cross"></mat-icon>
            </button>
        </div>

        <div class="groups-form-dialog__content m-0"
             mat-dialog-content>

            <form class="groups-form-dialog__form"
                  [formGroup]="entityForm"
                  name="entityForm"
                  novalidate>

                <!-- Color Field -->
                <ng-container *ngIf="getFieldByLabel('color')">
                    <button class="groups-form-dialog__color-button button-color-select"
                            id="group-form-control-color"
                            mat-button
                            [matMenuTriggerFor]="groupsSelectedColor">
                        <span class="groups-form-dialog__color-button-label">{{ 'groups-management.chose_color' | translate }}</span>
                        <div class="pastille"
                             [style.background-color]="selectedColor">
                        </div>
                    </button>
                    <mat-menu #groupsSelectedColor="matMenu"
                              class="groups-form-dialog__color-menu">
                        <mat-radio-group fxLayoutGap="5px"
                                         formControlName="color">
                            <mat-radio-button *ngFor="let color of colors"
                                              class="pastille"
                                              [style.background-color]="color"
                                              [ngClass]="{'selected': colorSelected(color)}"
                                              value="{{color}}"
                                              (change)="colorChange($event)">
                            </mat-radio-button>
                        </mat-radio-group>
                    </mat-menu>
                </ng-container>


                <!-- Nickname Field -->
                <div class=""
                     fxLayout="row"
                     fxLayoutAlign="start start"
                     *ngIf="getFieldByLabel('nickname')">
                    <mat-form-field fxFlex   floatLabel="auto">
                        <mat-label>{{ 'groups-management.choose_nickname' | translate }}</mat-label>
                        <input
                            [formControl]="entityForm.get('nickname')"
                            [matAutocomplete]="auto"
                            name="nickname"
                            id="group-form-control-nickname"
                            formControlName="nickname"
                            matInput
                            [required]="isRequired('nickname')">
                        <mat-error *ngIf="entityFormErrors?.nickname?.nameInvalid">
                            {{ 'groups-management.learner_nickname_invalid' | translate }}
                        </mat-error>
                        <mat-error *ngIf="entityFormErrors?.nickname?.taken">
                            {{ 'account-management.nickname_taken' | translate }}
                        </mat-error>
                        <mat-error *ngIf="entityFormErrors?.nickname?.nonexistent">
                            {{ 'account-management.nickname_nonexistent' | translate }}
                        </mat-error>

                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnNickname">
                            <mat-option *ngFor="let learner of filteredLearnersOptions | async" [value]="learner">
                                {{learner}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- Email Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('email')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.your_email' | translate }}</mat-label>
                        <input
                            matInput
                            formControlName="email"
                            required>
                    </mat-form-field>
                </div>
                <!-- age Fields is store in same than birthday it's one or other not both-->
                <div class="" fxLayout="row" fxLayoutAlign="start start"
                     *ngIf="getFieldByLabel('age') && !getFieldByLabel('birthMonth')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.your_age' | translate }}</mat-label>
                        <input
                            type="number"
                            min="0"
                            matInput
                            formControlName="age"
                            [required]="isRequired('age')">
                    </mat-form-field>
                </div>

                <!-- Birthday Fields -->
                <div class="" fxLayout="row" fxLayoutAlign="start start" *ngIf="getFieldByLabel('birthMonth')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.birthmonth' | translate }}</mat-label>
                        <mat-select [disabled]="loading"
                                    name="birthMonth"
                                    formControlName="birthMonth"
                                    [required]="isRequired('birthMonth')">
                            <mat-option *ngFor="let month of months" [value]="month">{{  month  }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.birthyear' | translate }}</mat-label>
                        <mat-select [disabled]="loading"
                                    name="birthYear"
                                    formControlName="birthYear"
                                    [required]="isRequired('birthYear')">
                            <mat-option *ngFor="let year of years" [value]="year">{{  year  }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!--educational level used for learner sometimes store data in level in back : !!cannot be used if level field is used!!-->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('educationalLevel')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.chose_educationnal_level' | translate }}</mat-label>
                        <mat-select
                            name="educationalLevel"
                            formControlName="educationalLevel"
                            [required]="isRequired('educationalLevel')">
                            <mat-option
                                *ngFor="let level of selects?.educationalLevels()"
                                [value]="level?.id">  {{level.get('label')}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Gender Field -->
                <div class="" fxLayout="row" fxLayoutAlign="start start" *ngIf="getFieldByLabel('gender')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.gender' | translate }}</mat-label>
                        <mat-select [disabled]="loading"
                                    name="gender"
                                    formControlName="gender"
                                    [required]="isRequired('gender')">
                            <mat-option *ngFor="let gender of genders" [value]="gender">{{  gender  }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- dyspraxic disorder Field -->
                <div class="" fxLayout="row" fxLayoutAlign="start start" *ngIf="getFieldByLabel('dyspraxic')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.dyspraxic' | translate }}</mat-label>
                        <mat-select [disabled]="loading"
                                    name="dyspraxic"
                                    formControlName="dyspraxic">
                            <mat-option *ngFor="let dyspraxicTrouble of dyspraxicTroubleList"
                                        [value]="dyspraxicTrouble">
                                {{  dyspraxicTrouble  }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- other disorder Field -->
                <div class="" fxLayout="row" fxLayoutAlign="start start" *ngIf="getFieldByLabel('disorder')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.disorder' | translate }}</mat-label>
                        <mat-select [disabled]="loading"
                                    name="disorder"
                                    formControlName="disorder" multiple>
                            <mat-option *ngFor="let trouble of otherTroubleList"
                                        [value]="trouble">
                                {{  trouble  }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Type Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('type')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.assign_type' | translate }}</mat-label><mat-select
                            [disabled]="loading"
                            name="type"
                            formControlName="type">
                            <mat-option
                                *ngFor="let type of selects.types()"
                                [value]="type">{{  selectType(type) | translate  }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Groupname Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('groupname')">
                    <mat-form-field fxFlex="100" floatLabel="auto">
                        <mat-label>{{ 'groups-management.chose_class_name' | translate }}</mat-label><input matInput type="text"

                               formControlName="groupname"
                               [required]="isRequired('groupname')">
                        <mat-error *ngIf="entityFormErrors?.groupname?.required">
                            {{ 'generic.field.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="entityFormErrors?.groupname?.alreadyExist">
                            {{ 'groups-management.name_already_used' | translate }}
                        </mat-error>
                    </mat-form-field>

                </div>

                <!-- Workgroupname Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('workgroupname')">
                    <mat-form-field fxFlex floatLabel="auto">
                        <mat-label>{{ 'groups-management.chose_group_name' | translate }}</mat-label>
                        <input
                            name="workgroupname"
                            formControlName="workgroupname"
                            matInput
                            [required]="isRequired('workgroupname')">
                        <mat-error *ngIf="entityFormErrors?.workgroupname?.required">
                            {{ 'generic.field.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="entityFormErrors?.workgroupname?.alreadyExist">
                            {{ 'groups-management.name_already_used' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- PostalCode & Title fields -->
                <div fxLayout="row"
                     fxLayoutAlign="start start"
                     fxLayoutGap="20px"
                     *ngIf="getFieldByLabel('postalCode') || getFieldByLabel('title')">
                    <!-- Postal code -->
                    <mat-form-field *ngIf="getFieldByLabel('postalCode')" fxFlex="20">
                        <mat-label>{{ 'generic.postal-code' | translate }}</mat-label><input type="text"

                               matInput
                               [formControl]="myCodePostalControl"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete"
                                          (optionSelected)="applyFilters($event, 'postalCode')">
                            <mat-option *ngFor="let option of filteredPostalCodes | async" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <!-- title institution for new add -->
                    <mat-form-field *ngIf="getFieldByLabel('title')" fxFlex>
                        <mat-label>{{ 'generic.name' | translate }}</mat-label>
                        <input [formControl]="institutionTitleFormControl" matInput >
                    </mat-form-field>
                </div>
                <div *ngIf="hasLaunchSearchButton()"
                     fxLayout="row"
                     fxLayoutAlign="start start"
                     class="mb-12">
                    <button
                        mat-raised-button
                        class="save-button mat-accent"
                        (click)="launchSearch(true)">{{'generic.search' | translate}}</button>

                </div>


                <div class=" h-400" *ngIf="getFieldByLabel('institution')">

                    <table mat-table #table [dataSource]="dataSource"
                           [@animateStagger]="{value:'50'}">
                        <ng-container matColumnDef="field">
                            <th mat-header-cell
                                *matHeaderCellDef>{{ 'groups-management.select-institution' | translate }}</th>
                            <td mat-cell *matCellDef="let entity">
                                <p class="text-truncate">{{entity.field}}</p>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
                            class="entity"
                            (click)="selectedInstitution(entity)"
                            [ngClass]="{'selected': institutionSelected.id === entity.id}"
                            matRipple
                            [@animate]="{value:'*',params:{y:'100%'}}">
                        </tr>
                    </table>
                </div>


                <!-- Password Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('password') && hasPasswordField()  && !isJoiningExistingLearner">
                    <mat-form-field fxFlex floatLabel="auto">
                        <mat-label>{{ 'groups-management.chose_password' | translate }}</mat-label>
                        <input
                            name="password"
                            formControlName="password"

                            matInput
                            type="new-password"
                            [required]="isRequired('password')"
                            *ngIf="action === 'new'">
                        <input
                            name="password"
                            formControlName="password"
                            matInput
                            type="new-password"
                            *ngIf="action !== 'new'">
                    </mat-form-field>
                </div>
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('passwordConfirm') && hasPasswordField()">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'account-management.password-confirm' | translate }}</mat-label>
                        <input matInput type="password"
                               formControlName="passwordConfirm" required>
                        <mat-error *ngIf="entityFormErrors?.passwordConfirm?.required">
                            {{ 'account-management.password-confirm.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="entityFormErrors?.passwordConfirm?.passwordsNotMatch">
                            {{ 'account-management.password-confirm.not-identical' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="!hasPasswordField()">
                    {{ 'group-management.sso_user_no_edition' | translate }}
                </div>

                <!-- Groups field-->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="space-around center"
                    *ngIf="getFieldByLabel('groups') && canAttachToGroup()">

                    <mat-form-field fxFlex="100"  floatLabel="auto">
                        <mat-label>{{ 'groups-management.assign_to_class' | translate }}</mat-label>
                        <mat-chip-grid #chipList>
                            <mat-chip-row
                                *ngFor="let chip  of chips"
                                [removable]="removable"
                                (removed)="remove(chip, 'groups')">
                                {{chip}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip-row>

                            <input
                                #groupsChipInput
                                [matAutocompleteDisabled]="(groupNameClassroomSelected!== null && groupNameClassroomSelected!==undefined && groupNameClassroomSelected !=='')"
                                [formControl]="chipsCtrl"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                [ngClass]="(groupNameClassroomSelected !== null && groupNameClassroomSelected !== undefined && groupNameClassroomSelected !== '')?'chip-read-only':''"
                            />
                            <mat-error *ngIf="entityFormErrors?.groups?.required">
                                {{ 'generic.field.required' | translate }}
                            </mat-error>
                        </mat-chip-grid>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, 'groups')">
                            <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
                                {{ chip }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <div class=""
                     fxLayout="row"
                     fxLayoutAlign="space-around center"
                     *ngIf="getFieldByLabel('workgroups') && canAttachToWorkgroup()">
                    <mat-form-field fxFlex="100" floatLabel="auto">
                        <mat-label>{{ 'filter.workgroup' | translate }}</mat-label>
                        <mat-chip-grid [disabled]="loading" #workgroupChipList>
                            <mat-chip-row
                                *ngFor="let workgroupChip  of workgroupChips"
                                [removable]="workgroupRemovable"
                                (removed)="remove(workgroupChip, 'workgroup')">
                                {{workgroupChip}}
                                <mat-icon matChipRemove *ngIf="workgroupRemovable">cancel</mat-icon>
                            </mat-chip-row>
                            <input
                                #workgroupChipInput
                                [matAutocompleteDisabled]="(groupNameSelected!== null && groupNameSelected!==undefined && groupNameSelected !=='')"
                                [formControl]="workgroupCtrl"
                                [matAutocomplete]="workgroup"
                                [matChipInputFor]="workgroupChipList"
                                [matChipInputSeparatorKeyCodes]="workgroupSeparatorKeysCodes"
                                [matChipInputAddOnBlur]="workgroupAddOnBlur"
                                [ngClass]="(groupNameSelected !== null && groupNameSelected !== undefined && groupNameSelected !== '') ?'chip-read-only':''"/>

                        </mat-chip-grid>
                        <mat-autocomplete #workgroup="matAutocomplete"
                                          (optionSelected)="selected($event, 'workgroups')">
                            <mat-option *ngFor="let workgroupChip of workgroupFilteredChips | async"
                                        [value]="workgroupChip">
                                {{ workgroupChip }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>

                <!-- Checkbox Column -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="space-around center"
                    *ngIf="getFieldByLabel('parentalConsent')">
                    <mat-checkbox formControlName="parentalConsent">
                        {{'generic.parental_consent' | translate}}
                    </mat-checkbox>
                </div>

                <!-- Level Field -->
                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('level')">
                    <mat-form-field fxFlex floatLabel="auto">
                        <mat-label>{{ 'groups-management.chose_level' | translate }}</mat-label>
                        <mat-select
                            [disabled]="loading"
                            name="level" [required]="isRequired('level')"
                            formControlName="level" *ngIf="settings.levelsWidget == 'select'">
                            <mat-option
                                *ngFor="let level of selects?.levels()"
                                [value]="level.get('name')">  {{level.get('name')}} </mat-option>
                        </mat-select>
                        <input
                            name="level"
                            formControlName="level"
                            placeholder="{{ 'groups-management.chose_level' | translate }}"
                            matInput *ngIf="settings.levelsWidget != 'select'">
                    </mat-form-field>
                </div>

                <div
                    class=""
                    fxLayout="row"
                    fxLayoutAlign="start start"
                    *ngIf="getFieldByLabel('schoolyear_term')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.chose_school.year' | translate }}</mat-label><mat-select [disabled]="loading"
                                    name="schoolyear_term"
                                    placeholder="{{ 'groups-management.chose_school.year' | translate }}"
                                    formControlName="schoolyear_term">
                            <mat-option
                                *ngFor="let shoolYear of selects?.schoolYears()"
                                [value]="shoolYear.id.toString()">  {{ shoolYear.get('name') + '-' + (+shoolYear.get('name') + 1)}} </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- institutions Field edit or add class-->
                <div class=""
                     fxLayout="row"
                     fxLayoutAlign="start start"
                     *ngIf="getFieldByLabel('parent')">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'groups-management.target_institution' | translate }}</mat-label><mat-select [disabled]="loading"
                                    name="parent"
                                    placeholder="{{ 'groups-management.target_institution' | translate }}"
                                    formControlName="parent">
                            <mat-option
                                *ngFor="let institution of institutions"
                                [value]="institution">  {{institution.field}}  </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="infobox"
                     *ngIf="getFieldByLabel('info')">
                    <mat-icon svgIcon="info" class="info-icon"></mat-icon>
                    <span>{{ 'groups-management.add_learner_info' | translate }}</span>
                </div>
            </form>

        </div>

        <div *ngIf="getFieldByLabel('institution')"
             fxFlex="100"
             fxLayoutAlign="center start">
            <mat-paginator
                [length]="countEntities"
                [pageIndex]="pageIndex"
                [pageSize]="pageRange"
                (page)="onPaginateChange($event)">
            </mat-paginator>
        </div>

        <div class="groups-form-dialog__actions"
             mat-dialog-actions>
            <button class="groups-form-dialog__save-button save-button mat-accent"
                    mat-raised-button
                    [attr.aria-label]="'generic.save' | translate"
                    [matTooltip]="'generic.save' | translate"
                    [disabled]="entityForm.invalid"
                    (click)="close(entityForm)">
                <span *ngIf="isModal">{{ 'generic.validate' | translate }}</span>
                <!--for on line editing we not use text but icon-->
                <mat-icon *ngIf="!isModal" svgIcon="groups-form-add"></mat-icon>
            </button>
        </div>
        <!--for cancel action of editing or adding when we edit in a row context-->
        <div *ngIf="!isModal && (originEntity.data.action === 'edit' || originEntity.data.action === 'new')"
             class="groups-form-dialog__actions"
             mat-dialog-actions>
            <button class="groups-form-dialog__close-button save-button mat-accent"
                    mat-raised-button
                    [attr.aria-label]="'generic.close' | translate"
                    [matTooltip]="'generic.close' | translate"
                    [disabled]="loading"
                    (click)="cancelEditingRow()">
                <!--for on line editing we not use text but icon-->
                <mat-icon svgIcon="cross"></mat-icon>
            </button>
        </div>
    </div>
</fieldset>