<mat-card class="activities-card mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content #iframeViewport>
        <!--we use a container because iframe must not exist without having the good size ref he must take-->
        <ng-container #iframeView>
            <!--style is put here because without this that can't work-->
            <iframe *ngIf="isSizeInitDone" (load)="onIframeLoad()" style="width:100%; height: 100%;"
                    allow="microphone; fullscreen"
                    [src]="iframeUrl()" allowfullscreen="true" id="taskIframe"
                    [title]="'demo'"></iframe>
        </ng-container>
    </mat-card-content>
    <mat-card-footer>
    </mat-card-footer>
</mat-card>