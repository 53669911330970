<div class="page-layout simple tabbed inner-scroll" fxFlex>
    <div class="content inner-scroll" fxFlex fxLayout="column">
        <mat-card>
            <ng-template #loading>
                <mat-spinner></mat-spinner>
            </ng-template>

            <ng-container *ngIf="isLoading === false else loading">
                <mat-card-header>
                    <mat-card-title>{{'unlink_parent.title' | translate}}</mat-card-title>
                </mat-card-header>

                <ng-template #noParent>
                    <mat-card-content>
                        <p>{{'unlink_parent.no_parent' | translate}}</p>
                    </mat-card-content>
                </ng-template>

                <ng-container *ngIf="!!trainerLabel else noParent">
                    <mat-card-content>
                        <p>{{getCustomTranslationWithTrainerLabel('unlink_parent.body') | async}}</p>
                    </mat-card-content>
                    <mat-card-actions>
                        <button (click)="onUnlinkClick($event)"
                                color="warn"
                                mat-button>{{'unlink_parent.validate' | translate}}</button>
                    </mat-card-actions>
                </ng-container>
            </ng-container>
        </mat-card>
    </div>
</div>
