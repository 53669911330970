<div *ngIf="!dateSelected && !ruleSelected">

    <h2>{{ 'assignment.assign_its_all_right' | translate }}</h2>

    <p>{{ 'assignment.assign_its_all_right_you_can_assign' | translate }}</p>

    <div class="choice-main-container">
        <div class="choice-container">
            <h3>{{ 'assignment.assign_add_begin_date_title' | translate }}</h3>
            <p>{{ 'assignment.assign_add_begin_date_explain' | translate }}</p>
            <button (click)="goToDate()">
                {{ 'assignment.assign_begin_date' | translate }}
            </button>
        </div>
        <div class="choice-container">
            <h3>{{ 'assignment.assign_add_end_date_title' | translate }}</h3>
            <p>{{ 'assignment.assign_add_end_date_explain' | translate }}</p>
            <button (click)="goToDate()">
                {{ 'assignment.assign_end_date' | translate }}
            </button>
        </div>
        <div class="choice-container">
            <h3>{{ 'assignment.assign_add_rules_title' | translate }}</h3>
            <p>{{ 'assignment.assign_add_rules_explain' | translate }}</p>
            <button (click)="goToRules()">
                {{ 'assignment.assign_add_rules_button' | translate }}
            </button>
        </div>
    </div>

</div>

<app-assignment-manage-dates *ngIf="dateSelected"></app-assignment-manage-dates>

<app-assignment-add-rules-validation *ngIf="ruleSelected"></app-assignment-add-rules-validation>