<div class="dialog-resume-action-menu">

    <button class="dialog-resume-action-menu__button"
            (click)="!isPlayingOriginalAudio ? play() : stop()">
        <span class="dialog-resume-action-menu__button-label">{{ (!isPlayingOriginalAudio ? 'language_lab.listen_to_original_version' : 'language_lab.stop') | translate }}</span>
        <mat-icon class="dialog-resume-action-menu__button-icon"
                  [svgIcon]="!isPlayingOriginalAudio ? 'sound_off' : 'stop'">
        </mat-icon>
    </button>

    <button *ngIf="recordForSelectedElementExist"
            class="dialog-resume-action-menu__button"
            (click)="!isPlayingUserAudio ? playUserVersion() : stop()">
        <span class="dialog-resume-action-menu__button-label">{{ (!isPlayingUserAudio ? 'language_lab.listen_to_user_version' : 'language_lab.stop') | translate }}</span>
        <mat-icon class="dialog-resume-action-menu__button-icon"
                  [svgIcon]="!isPlayingUserAudio ? 'headphones' : 'stop'">
        </mat-icon>
    </button>

    <button class="dialog-resume-action-menu__button"
            (click)="record()">
        <span class="dialog-resume-action-menu__button-label">{{ 'language_lab.record_again' | translate }}</span>
        <mat-icon class="dialog-resume-action-menu__button-icon"
                  svgIcon="keyboard_voice">
        </mat-icon>
    </button>

</div>