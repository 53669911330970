<!--to show toaster only if content is loaded-->
<ng-container *ngIf="!isToaster || (isToaster && page)">
    <div mat-dialog-title>
        <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
        <h2>{{ page?.get('label') | loadTemplateData$ | async }}</h2>
        <button *ngIf="!isToaster"
                class="close"
                (click)="closeModal()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <app-basic-page [page]="page" [title]="false" (action)="closeModal()"></app-basic-page>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="hasActions()">
        <button *ngIf="page?.get('document')" (click)="downloadDocument()" mat-raised-button class="mat-accent">
            {{ 'generic.download_document' | translate }}
            <mat-spinner *ngIf="downloading" class="ml-8"></mat-spinner>
        </button>
        <button *ngIf="labelConfirmDialog?.length > 0"  (click)="closeModal()" mat-raised-button class="mat-accent mr-16" >{{labelConfirmDialog}}</button>
        <ng-container *ngFor="let block of blocks">
            <app-button-block *ngIf="block.type === 'button'" [blockData]="block" (action)="closeModal($event)"></app-button-block>
            <app-field-block *ngIf="block.type === 'field'" [blockData]="block" (action)="nextOrCloseModal(block)" ></app-field-block>
            <app-button-block *ngIf="hasNext(block)" [blockData]="nextFieldBlock" (action)="nextOrCloseModal(block)" class="right"></app-button-block>
            <app-collections-block *ngIf="block.type === 'collections'" [blockData]="block" (action)="closeModal($event)" ></app-collections-block>
        </ng-container>
    </mat-dialog-actions>
</ng-container>