<div mat-dialog-title>
    <h2 [ngClass]="{'assignment' : isAssignment}" >{{ resource.get('metadatas').title }}</h2>
    <button class="close"
            (click)="close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <mat-progress-bar mode="determinate" [value]="pourcent()"></mat-progress-bar>
    <span class="progress-number">{{progressBarValue?.value}} / {{progressBarValue?.max}}</span>
</mat-dialog-content>

<mat-dialog-actions>
    <app-launcher-button  *ngFor="let step of steps, let i = index" [attr.data-e2e]="'launch_lesson_button' + i" (launchQuestionSet)="launch($event)" [step]="step" [lessonParent]="resource"
                         [index]="i" [assignment]= "assignment" appAddClassInRegardOfTextHeight></app-launcher-button>
</mat-dialog-actions>

