<div class="m-24">
    <mat-card class="card-simple">
        <mat-card-header class="mat-accent-bg">
            <mat-card-title>
                {{ 'account-management.profile.licensing.title' | translate }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <licensing-content [uid]="id"></licensing-content>
        </mat-card-content>
        <mat-card-actions fxLayoutAlign="center center">
            <licensing-method-add *ngIf="!authenticateService.isSSO()"></licensing-method-add>
            <div fxLayoutAlign="center center" fxFlex  *ngIf="authenticateService.isSSO()">
                <button fxLayoutAlign="left center" class="mr-24" (click)="syncSSOLicenses()">
                    <mat-icon svgIcon="rafraichir"></mat-icon>
                    <span>&nbsp;{{'licensing.sync_sso' | translate}}</span>
                </button>
                <button  fxLayoutAlign="left center"><a href="{{ 'licensing.redirect_sso_url' | translate }}" target="_blank">{{'licensing.redirect_sso' | translate}}</a></button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
