<div *ngIf="!noModalContext" mat-dialog-title>
    <h2>{{dataInjected.title | translate}}</h2>
    <button class="close"
            (click)="close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content fusePerfectScrollbar>
    <ng-container *ngFor="let row of dataInjected.list; let index = index">
        <button [attr.data-e2e]="'button_list_item_' + index" [disabled]="isBtnDisabled(row)" mat-raised-button (click)="selectedItem(row)">
            {{row.label | translate}}
        </button>
    </ng-container>
</mat-dialog-content>