<div mat-dialog-title>
    <h2>{{ 'feedback-dialog.title' | translate }}</h2>
    <button (click)="selfDialogRef.close()"
            aria-label="close dialog"
            class="close"
            >
        <mat-icon svgIcon="cross"></mat-icon>


    </button>
</div>

<mat-dialog-content>

    <form [formGroup]="form" mat-dialog-content>
        <div>
            <p>{{ 'feedback-dialog.ranking' | translate }}</p>
            <div class="rating">
                <input
                    formControlName="grade"
                    id="grade-5"
                    name="grade"
                    type="radio"
                    value="5"
                />
                <label for="grade-5"></label>
                <input
                    formControlName="grade"
                    id="grade-4"
                    name="grade"
                    type="radio"
                    value="4"
                />
                <label for="grade-4"></label>
                <input
                    formControlName="grade"
                    id="grade-3"
                    name="grade"
                    type="radio"
                    value="3"
                />
                <label for="grade-3"></label>
                <input
                    formControlName="grade"
                    id="grade-2"
                    name="grade"
                    type="radio"
                    value="2"
                />
                <label for="grade-2"></label>
                <input
                    formControlName="grade"
                    id="grade-1"
                    name="grade"
                    type="radio"
                    value="1"
                />
                <label for="grade-1"></label>
            </div>
        </div>
        <div>
            <p>{{ 'feedback-dialog.recommandation' | translate }}</p>
            <div class="slider-container">
                <mat-slider min="1"
                            max="10"
                            aria-label="unit(s)"
                            formControlName="recommendation">
                    <input matSliderStartThumb>
                </mat-slider>
                <ol>
                    <li>1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li>6</li>
                    <li>7</li>
                    <li>8</li>
                    <li>9</li>
                    <li>10</li>
                </ol>
            </div>
        </div>
        <div>
            <fieldset>
                <legend>
                    {{ 'feedback-dialog.before_activities' | translate }}
                </legend>
                <div>
                    <input formControlName="before" id="before-1" name="before" type="radio" value="1"/>
                    <label for="before-1">{{ 'feedback-dialog.labels_0' | translate }}</label>
                </div>
                <div>
                    <input formControlName="before" id="before-2" name="before" type="radio" value="2"/>
                    <label for="before-2">{{ 'feedback-dialog.labels_1' | translate }}</label>
                </div>
                <div>
                    <input formControlName="before" id="before-3" name="before" type="radio" value="3"/>
                    <label for="before-3">{{ 'feedback-dialog.labels_2' | translate }}</label>
                </div>
                <div>
                    <input formControlName="before" id="before-4" name="before" type="radio" value="4"/>
                    <label for="before-4">{{ 'feedback-dialog.labels_3' | translate }}</label>
                </div>
                <div>
                    <input formControlName="before" id="before-5" name="before" type="radio" value="5"/>
                    <label for="before-5">{{ 'feedback-dialog.labels_4' | translate }}</label>
                </div>
            </fieldset>
        </div>
        <div>
            <fieldset>
                <legend>
                    {{ 'feedback-dialog.after_activities' | translate }}
                </legend>
                <div>
                    <input type="radio" id="after-1" formControlName="after" name="after" value="1" />
                    <label for="after-1">{{ 'feedback-dialog.labels_0' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="after-2" formControlName="after" name="after" value="2" />
                    <label for="after-2">{{ 'feedback-dialog.labels_1' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="after-3" formControlName="after" name="after" value="3" />
                    <label for="after-3">{{ 'feedback-dialog.labels_2' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="after-4" formControlName="after" name="after" value="4" />
                    <label for="after-4">{{ 'feedback-dialog.labels_3' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="after-5" formControlName="after" name="after" value="5" />
                    <label for="after-5">{{ 'feedback-dialog.labels_4' | translate }}</label>
                </div>
            </fieldset>
        </div>
        <div>
            <fieldset>
                <legend>
                    {{ 'feedback-dialog.genre_activities' | translate }}
                </legend>
                <div>
                    <input type="radio" id="boy" formControlName="genre" name="genre" value="boy" />
                    <label for="boy">{{ 'feedback-dialog.genre.boy' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="girl" formControlName="genre" name="genre" value="girl" />
                    <label for="girl">{{ 'feedback-dialog.genre.girl' | translate }}</label>
                </div>
                <div>
                    <input type="radio" id="unknow" formControlName="genre" name="genre" value="unknow" />
                    <label for="unknow">{{ 'feedback-dialog.genre.unknow' | translate }}</label>
                </div>
            </fieldset>
        </div>
        <div>
            <mat-form-field>
                <mat-label>
                    {{'feedback-dialog.feedback' | translate}}
                </mat-label>
                <textarea formControlName="feedback" matInput rows="2" maxlength="280"></textarea>
                <mat-hint *ngIf="form.value.feedback?.length >= 280">{{'feedback-dialog.max_characters' | translate}}</mat-hint>
            </mat-form-field>
        </div>
    </form>
    <div mat-dialog-actions>
        <button  class="submit-button accent" (click)="onSubmit()" mat-button>{{ 'feedback-dialog.submit' | translate }}</button>
    </div>


</mat-dialog-content>