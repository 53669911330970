<mat-card [attr.id]="type===''?'':'type-is-word-or-lettersSelecting'"
          [ngClass]="{'two-columns-layout': isTwoColumns, 'listen-content': type === 'listen' }"
          class="activities-card inner-scroll mat-elevation-z0 qcuContainer">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                (isReading)="ttsReadingEvent($event)"
                [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmcu' | translate):''"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                [language]="language"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [wordingAudio]="wordingAudio"
                [wording]="wording"
            ></app-consignes>
            <h2 *ngIf="showSubInstruction"
                [ngClass]="isTTSReading?.value ? 'read-in-progress':'read-not-in-progress'">{{ 'activities.sub_instruction_qmcu' | translate }}</h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes
            *ngIf="(isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio))  || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))) && type !== 'listen'"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            [language]="language"
            [instructionAudio]="instructionAudio"
            [instruction]="instruction"
            [ngClass]="{'consigneImg': isInstructionImg()}"
            questionTypeName="{{ 'activities.questionTypeName.app-qcu' | translate }}"
        >
        </app-consignes>

        <ng-container *ngFor="let f of referenceActivityGranule.flashcards">
            <app-flashcard [class.button-image]="f.image"
                           [class.is-audio]="f.audio?.uri"
                           [flashCard]="f">
            </app-flashcard>
        </ng-container>

        <div *ngIf="displaySaveBtn && !answerContainImg && !areAnswersContainFlashcard() && !areAnswersContainAudio()"
             [attr.id]="type===''?'container-for-answers-available':'container-for-answers-available-text-version'"
             [ngClass]="columnClass" class="available-answers"
        >
            <div *ngFor="let option of availableAnswers">
                <button
                    (click)="validate(option)"
                    [class]="getStateAnswer(option)"
                    [disabled]="isDisabledOptions"
                    class="btn-option">
                    <span
                        [appDayRelativeNameToString]="{language_key: language}"
                        [appTimeSlotToString]="{language_key: language}"
                        [innerHTML]="option.answer | stringToHtml"
                    ></span>
                </button>
            </div>
        </div>
        <ng-container
            *ngIf="!displaySaveBtn && !answerContainImg && !areAnswersContainFlashcard() && !areAnswersContainAudio()">
            <mat-radio-group
                [(ngModel)]="valueChecked"
                [ngClass]="{showFinalAnswers: testAnswer || displaySolution || answerSaved, answersChecked: testAnswer}">
                <mat-radio-button (change)="validate(option)"
                                  *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select'"
                                  [disableRipple]="true"
                                  [disabled]="isDisabledOptions"
                                  [ngClass]="optionState(option)" [value]="option.answer"
                                  appAddClassInRegardOfTextHeight>
                    <span
                        [appDayRelativeNameToString]="{language_key: language}"
                        [appTimeSlotToString]="{language_key: language}"
                        [innerHTML]="option.answer | stringToHtml | translate"
                    ></span>
                </mat-radio-button>
            </mat-radio-group>
        </ng-container>

        <ng-container *ngIf="answerContainImg && !areAnswersContainFlashcard()">
            <div [attr.id]="type===''?'container-for-answers-available':'container-for-answers-available-text-version'"
                 [ngClass]="columnClass" class="available-answers">
                <div (click)="validate(option)"
                     *ngFor="let option of availableAnswers let index = index;"
                     [ngClass]="optionState(option)" class="button-image">
                    <!-- TODO add alt text to the image, set in the back -->
                    <img [attr.id]="'imgToZoom' + uuid + index.toString()" [src]="option.image"/>
                    <div *ngIf="isImageFullscreenButtonEnabled()" class="action-buttons">
                        <app-zoomable [uuid]="uuid" [index]="index"></app-zoomable>
                    </div>
                </div>
            </div>
        </ng-container>


        <ng-container *ngIf="!answerContainImg && areAnswersContainFlashcard()">
            <div [attr.id]="type===''?'container-for-answers-available':'container-for-answers-available-text-version'"
                 [ngClass]="columnClass" class="available-answers">
                <ng-container *ngFor="let option of availableAnswers let index = index;">
                    <ng-container *ngIf="option.flashcards">
                        <app-flashcard *ngFor="let flashcard of option.flashcards"
                                       [class.button-image]="flashcard.image"
                                       [class.is-audio]="flashcard.audio?.uri"
                                       [ngClass]="optionState(option)"
                                       [flashCard]="flashcard"
                                       (click)="validate(option)">
                        </app-flashcard>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="areAnswersContainAudio()">
            <div [attr.id]="type===''?'container-for-answers-available':'container-for-answers-available-text-version'"
                 [ngClass]="columnClass" class="available-answers">
                <button *ngFor="let option of availableAnswers let index = index;"
                     class="qcu__audio-button"
                     [ngClass]="optionState(option)"
                     (click)="playAudioAndValidate(option.audio.id, option)">
                    <mat-icon [svgIcon]="'qcm-qcu__audio-' + (index + 1)"></mat-icon>
                    <audio *ngIf="option.audio"
                           [src]="option.audio.uri"
                           [id]="option.audio.id">
                    </audio>
                </button>
            </div>
        </ng-container>

    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>

        <div *ngIf="isPercentileDisplayAllowed"
             class="gradePercentil">{{ 'generic.score' | translate }}{{ showPercentile }}
        </div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button (click)="onAction(button)"
                        *ngIf="button.display"
                        [disabled]="button.disable">
                    <span>{{ button.title | translate }}</span>
                    <mat-icon *ngIf="button.svgIcon"
                              [svgIcon]="button.svgIcon">
                    </mat-icon>
                </button>
            </ng-container>
        </ng-container>

    </mat-card-footer>

</mat-card>

<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>