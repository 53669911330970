<!-- SIDENAV CONTENT -->
<div class="content" fusePerfectScrollbar>
    <ng-container *ngIf="selected">
        <div class="header">
            <div class="title">{{ selected?.title }}</div>
        </div>

        <div
            class="file-details"
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { delay: '200ms' } }"
        >
            <div
                class="preview file-icon"
                fxLayout="row"
                fxLayoutAlign="center center"
            >
                <div
                    *ngIf="
            selected?.format === 'media' ||
            selected?.format === 'audio' ||
            selected?.format === 'video' ||
            selected?.format === 'document' ||
            selected?.format === 'image'
          "
                >
                    <img
                        oncontextmenu="return false"
                        *ngIf="selected?.referenceEntity.get('type') === 'image'"
                        (click)="corpusService.openRessource(selected)"
                        src="{{ selected?.referenceEntity.get('uri') }}"
                        alt="{{ selected?.title }}"
                    />

                    <div
                        *ngIf="selected?.ressourceEntity.get('reference').type === 'video'"
                        (click)="corpusService.openRessource(selected)"
                        [innerHTML]="currentVid"
                    ></div>

                    <mat-icon
                        *ngIf="
              selected?.referenceEntity.get('type') === 'audio' ||
              selected?.referenceEntity.get('type') === 'document'
            "
                        svgIcon="{{ selected?.format }}"
                        (click)="corpusService.openRessource(selected)"
                    ></mat-icon>
                </div>

                <div *ngIf="selected?.format === 'url'">
                    <mat-icon
                        *ngIf="
              selected?.ressourceType === 'link' ||
              selected?.ressourceType === 'url' ||
              selected?.ressourceType === 'video' ||
              selected?.videoShortImage != 'noThumb'
            "
                        svgIcon="{{ selected?.format }}"
                    ></mat-icon>
                    <a
                        (click)="corpusService.openRessource(selected)"
                        title="{{ selected?.ressourceEntity.get('reference').url }}"
                        *ngIf="selected.ressourceType != 'video'"
                        rel="nofollow noreferrer noopener"
                        target="_blank"
                    >
                        {{ selected?.ressourceEntity.get("reference").url }}
                    </a>
                </div>

                <div *ngIf="selected?.format === 'videoUrl'">
                    <img
                        oncontextmenu="return false"
                        *ngIf="selected?.ressourceType === 'video' ||
              selected?.videoShortImage != 'noThumb'"
                        (click)="corpusService.openRessource(selected)"
                        src="{{ videoShortImage }}"
                        alt=""
                    />
                    <a
                        (click)="corpusService.openRessource(selected)"
                        title="{{ selected?.ressourceEntity.get('reference').url }}"
                        *ngIf="selected.ressourceType != 'video'"
                        rel="nofollow noreferrer noopener"
                        target="_blank"
                    >
                        {{ selected?.ressourceEntity.get("reference").url }}
                    </a>
                </div>

                <div *ngIf="selected?.format === 'text'">
                    <div>{{ selected?.ressourceEntity.get("reference").text }}</div>
                </div>
            </div>
            <!-- / preview-->
            <!-- boutons -->
            <div class="file-details__actions">
                <ng-container
                    *ngIf="selected
                    && selected.hasOwnProperty('ressourceEntity')"
                >
                    <button
                        mat-mini-fab
                        (click)="corpusService.openRessource(selected)"
                        [attr.aria-label]="'generic.read' | translate"
                        [matTooltip]="'generic.read' | translate">
                        <mat-icon svgIcon="play"></mat-icon>
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="
                    displayField('files') &&
                    selected?.metadatasEntity &&
                    selected?.metadatasEntity.get('files')?.length > 0 &&
                    authService.accessLevel !== 'learner'
                    "
                >
                    <button
                        *ngFor="let file of selected?.metadatasEntity.get('files')"
                        mat-mini-fab
                        (click)="corpusService.openFile(file)"
                        [attr.aria-label]="'generic.download_document' | translate"
                        [matTooltip]="'generic.download_document' | translate">
                        <mat-icon svgIcon="pdf"></mat-icon>
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="selected
                    && selected.hasOwnProperty('ressourceEntity')
                    "
                >
                <button
                    *ngIf="canShare(selected)"
                    mat-mini-fab
                    (click)="openShareToClassesDialog(selected)"
                    [attr.aria-label]="'generic.share' | translate"
                    [matTooltip]="'generic.share' | translate">
                    <mat-icon>share</mat-icon>
                </button>
                </ng-container>
            </div>
            <!-- <div class="offline-switch">
                      <mat-slide-toggle ([ngModel])="selected?.offline" labelPosition="before">Available Offline</mat-slide-toggle>
                  </div> -->

            <dl>
                <ng-container
                    *ngIf="
            displayField('learning-time') &&
            selected?.metadatasEntity &&
            selected?.duration?.length > 0
          "
                >
                    <dt class="learning-time">
                        {{ "generic.learning_time" | translate }}
                    </dt>
                    <dd>{{ selected?.duration }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('concepts') &&
            selected?.metadatasEntity &&
            selected?.metadatasEntity.get('concepts')?.length
          "
                >
                    <dt class="concepts">{{ "generic.concepts" | translate }}</dt>
                    <dd class="span-divider">
                        <span *ngFor="let concept of concepts">{{ concept?.label }}</span>
                    </dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('chapters') &&
            selected?.metadatasEntity &&
            selected?.metadatasEntity.get('chapters')?.length
          "
                >
                    <dt class="chapters">{{ "generic.chapters" | translate }}</dt>
                    <dd class="span-divider">
                        <span *ngFor="let chapter of chapters">{{ chapter.label }}</span>
                    </dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('levels') &&
            selected?.educationalLevelString &&
            selected?.educationalLevelString !== ''
          "
                >
                    <dt class="levels">{{ "generic.level" | translate }}</dt>
                    <dd>{{ selected?.educationalLevelString }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('target_age') &&
            selected?.educationalLevelString &&
            selected?.educationalLevelString !== ''
          "
                >
                    <dt class="target_age">{{ "activities.target_age" | translate }}</dt>
                    <dd>{{ selected?.educationalLevelString }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('difficulty') &&
            selected?.difficultyString &&
            selected?.difficultyString !== ''
          "
                >
                    <dt class="difficulty">{{ "generic.difficulty" | translate }}</dt>
                    <dd>{{ selected?.difficultyString }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('skills') &&
            selected?.skillsString &&
            selected?.skillsString !== ''
          "
                >
                    <dt class="skills">{{ "generic.skills" | translate }}</dt>
                    <dd>{{ selected?.skillsString }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('created') &&
            selected?.metadatasEntity &&
            !haveCustomDate &&
            selected?.metadatasEntity.get('created')?.length
          "
                >
                    <dt class="created">{{ "generic.createdDate" | translate }}</dt>
                    <dd>
                        {{
                        corpusService.localeDate(selected?.metadatasEntity.get("created"))
                        }}
                    </dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('changed') &&
            selected?.metadatasEntity &&
            !haveCustomDate &&
            selected?.metadatasEntity.get('changed')?.length
          "
                >
                    <dt class="changed">{{ "generic.changedDate" | translate }}</dt>
                    <dd>
                        {{
                        corpusService.localeDate(selected?.metadatasEntity.get("changed"))
                        }}
                    </dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('scolomDate') &&
            selected?.metadatasEntity &&
            haveCustomDate &&
            selected?.metadatasEntity.get('scolomDate')?.length
          "
                >
                    <dt class="scolomDate">{{ "generic.date" | translate }}</dt>
                    <dd>{{ selected?.metadatasEntity.get("scolomDate") }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('source-author') && getDataSelected('source-author')
          "
                >
                    <dt class="auteur">{{ "generic.source_author" | translate }}</dt>
                    <dd>{{ getDataSelected("source-author") }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('source') &&
            selected?.metadatasEntity &&
            selected?.metadatasEntity.get('source')
          "
                >
                    <dt class="source">{{ "generic.source" | translate }}</dt>
                    <dd>{{ selected?.metadatasEntity.get("source") }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="displayField('format') && selected?.format?.length > 0"
                >
                    <dt class="format">{{ "generic.type" | translate }}</dt>
                    <dd *ngIf="selected?.format != 'media'">{{ 'generic.' + selected?.format | translate }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('description') &&
            selected?.metadatasEntity &&
            selected?.metadatasEntity.get('description')?.length > 0
          "
                >
                    <ng-container>
                        <dt class="description">{{ "generic.description" | translate }}</dt>
                        <dd [innerHTML]="selected?.metadatasEntity.get('description')"></dd>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('indexation') &&
            selected?.indexationString &&
            selected?.indexationString !== ''
          "
                >
                    <dt class="indexation">{{ "generic.indexation" | translate }}</dt>
                    <dd>{{ selected?.indexationString }}</dd>
                </ng-container>

                <ng-container
                    *ngIf="
            displayField('size') &&
            selected?.ressourceEntity &&
            selected?.ressourceEntity.get('reference').filesize?.length > 0
          "
                >
                    <dt class="size">{{ "generic.size" | translate }}</dt>
                    <dd>
                        {{
                        selected?.ressourceEntity.get("reference").filesize === ""
                            ? "-"
                            : selected?.ressourceEntity.get("reference").filesize
                        }}
                    </dd>
                </ng-container>

                <ng-container
                    *ngIf="
            corpusService.isCommunityCorpus() &&
            displayField('pseudo') &&
            selected?.ownerName
          "
                >
                    <dt class="author">{{ "generic.author" | translate }}</dt>
                    <dd>{{ selected?.ownerName }}</dd>
                </ng-container>

                <ng-container *ngIf="authService.isTrainer() && displayField('shared') && selected?.ressourceEntity">
                    <dt class="shared" *ngIf="mode === 'normal'">{{'generic.shared_content' | translate }}</dt>
                    <dt class="shared" *ngIf="mode !== 'normal'">{{'generic.shared_content_user' | translate }}</dt>
                    <ng-container *ngIf="mode !== 'normal'">
                        <span
                            *ngIf="learnerAllowedToConsult(selected?.ressourceEntity.get('minimalRole'))">{{"generic.shared_content_yes" | translate}}</span>
                        <span
                            *ngIf="!learnerAllowedToConsult(selected?.ressourceEntity.get('minimalRole'))">{{"generic.shared_content_no" | translate}}</span>
                    </ng-container>

                    <ng-container *ngIf="mode === 'normal'">
                        <span class="groups-and-workGroups-list"
                              *ngFor="let c of selected?.ressourceEntity.get('groups')">{{c.label}}</span>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="isShareableCommunity">
                    <dt>
                        {{
                        (!!sharedCommunity
                                ? "corpus.shared_to_community"
                                : "corpus.not_shared_to_community"
                        ) | translate
                        }}
                    </dt>
                    <dd>
                        <mat-slide-toggle
                            [checked]="sharedCommunity"
                            (change)="shareToCommunity($event)"
                        >
                        </mat-slide-toggle>
                    </dd>
                </ng-container>
            </dl>
        </div>
    </ng-container>
</div>
<!-- / SIDENAV CONTENT -->
