<div mat-dialog-title>
    <h2>{{ "add_text_modal.title" | translate }}</h2>
    <button  class="close" (click)="selfDialogRef.close()" aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content >

<!--    <textarea matInput rows="5" [(ngModel)]="text" [placeholder]="'add_text_modal.placeholder' | translate" name="text"></textarea>-->
    <app-wysiwyg [(content)]="text"></app-wysiwyg>
    <div class="center text-center">
        <button  class="save-button mat-accent"
                (click)="onSave() ">{{'generic.save' | translate}}
        </button>
        <button  class="save-button mat-accent"
                (click)="onCancel() ">{{'generic.cancel' | translate}}
        </button>
    </div>

</mat-dialog-content>