<mat-toolbar>
    <mat-toolbar-row>
        <button mat-icon-button [matMenuTriggerFor]="menu" [disableRipple]="true">
            <mat-icon svgIcon="trala_btn"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" backdropClass="tralalere-bar" class="tralabar">
            <button mat-menu-item *ngFor="let link of links" (click)="goToSite(link.get('href'))">
                <mat-icon *ngIf="displayTralabarIcons" >web_asset</mat-icon>
                <span>{{ link.get('label') | translate }}</span>
            </button>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>
