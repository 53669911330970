<div class="groups-list page-layout simple inner-scroll"
     fusePerfectScrollbar>
    <app-banner-info class="groups-list__banner-info"
                     [rolesCanShowBanner]="rolesCanShowBannerInfo"
                     [situation]="type">
    </app-banner-info>

    <mat-toolbar *ngIf="shouldDisplayFilters()"
                 class="groups-list__toolbar filters"
                 [ngClass]="filtersExpanded ? 'isExpanded' : 'isCollapsed'">
        <!-- TODO : Reporter dans les autres templates ou en faire un composant -->
        <mat-toolbar-row fxLayoutGap="18px"> <!-- Ligne des filtres et Actions spéciales -->
            <ng-container> <!-- Filtres -->
                <mat-form-field *ngIf="displayFilter('nickname') && nicknameContextAllowed"
                                [field]="'nickname'"
                                [fields]="collapsibleFields"
                                [floatLabel]="floatLabel"
                                isCollapsibleField>
                    <mat-label>{{ 'groups-management.filter_nickname' | translate }}</mat-label>
                    <input matInput
                           #nicknameInput="matInput"
                           [formControl]="nicknameCtrl"
                           >
                </mat-form-field>

                <mat-form-field *ngIf="displayFilter('school') && hasField('schoolyear')" [field]="'school'"
                                [fields]="collapsibleFields" class="field-school" isCollapsibleField>
                    <mat-label>{{ 'groups-management.chose_school.year' | translate }}</mat-label>
                    <mat-select (selectionChange)="applyFilterSchoolYear($event)"
                                [value]="groupsManagementService.schoolyearDefaultMatSelectValue"
                                name="schoolyear"
                                >
                        <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                        <mat-option
                            *ngFor="let shoolYear of groupsManagementService.schoolYearsList"
                            [value]="shoolYear.id">  {{ groupsManagementService.displaySchoolYear(shoolYear.get('name'))}} </mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container *ngIf="type === 'learner'">
                    <mat-form-field *ngIf="displayFilter('workgroup')" [field]="'workgroup'"
                                    [fields]="collapsibleFields" isCollapsibleField>
                        <mat-label>{{ 'groups-management.filter_by_workgroup' | translate }}</mat-label>
                        <mat-chip-grid #workgroupsChipList>
                            <mat-chip-row
                                (removed)="removeChip(workgroupChip, 'workgroups')"
                                *ngFor="let workgroupChip  of workgroupsChips"
                                [removable]="workgroupsRemovable">
                                {{workgroupChip}}
                                <mat-icon *ngIf="workgroupsRemovable" matChipRemove svgIcon="cross"></mat-icon>
                            </mat-chip-row>
                            <input
                                #workgroupsChipInput
                                (matChipInputTokenEnd)="blurAllChipsList()"
                                [formControl]="workgroupsCtrl"
                                [matAutocomplete]="workgroups"
                                [matChipInputAddOnBlur]="workgroupsAddOnBlur"
                                [matChipInputFor]="workgroupsChipList"
                                [matChipInputSeparatorKeyCodes]="workgroupsSeparatorKeysCodes"
                                [matAutocompleteDisabled]="!workgroupsSelectable"
                                [ngClass]="!workgroupsSelectable?'chip-read-only':''"
                                />
                        </mat-chip-grid>

                        <mat-autocomplete #workgroups="matAutocomplete"
                                          (optionSelected)="chipSelected($event, 'workgroups')">
                            <mat-option *ngFor="let workgroupChip of workgroupsFilteredChips | async"
                                        [value]="workgroupChip.workgroupname">
                                {{ workgroupChip.workgroupname }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayFilter('groups')" [field]="'groups'" [fields]="collapsibleFields"
                                    isCollapsibleField>
                        <mat-label>{{ 'groups-management.filter_by_class' | translate }}</mat-label>
                        <mat-chip-grid #groupsChipList>
                            <mat-chip-row
                                (removed)="removeChip(groupChip, 'groups')"
                                *ngFor="let groupChip  of groupsChips"
                                [removable]="groupsRemovable">
                                {{groupChip}}
                                <mat-icon *ngIf="groupsRemovable" matChipRemove svgIcon="cross"></mat-icon>
                            </mat-chip-row>

                            <input
                                #groupsChipInput
                                (matChipInputTokenEnd)="blurAllChipsList()"
                                [formControl]="groupsCtrl"
                                [matAutocomplete]="groups"
                                [matChipInputAddOnBlur]="groupsAddOnBlur"
                                [matChipInputFor]="groupsChipList"
                                [matChipInputSeparatorKeyCodes]="groupsSeparatorKeysCodes"
                                [matAutocompleteDisabled]="!groupsSelectable"
                                [ngClass]="!groupsSelectable?'chip-read-only':''"
                                />
                        </mat-chip-grid>
                        <mat-autocomplete
                            #groups="matAutocomplete"
                            (optionSelected)="chipSelected($event, 'groups')">
                            <mat-option *ngFor="let groupChip of groupsFilteredChips | async"
                                        [value]="groupChip.groupname">
                                {{ groupChip.groupname }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                </ng-container>

                <ng-container
                    *ngIf="displayFilter('archived') && archiveEntitySettings && authService.isAtLeastTrainer()"
                    [field]="'archived'" [fields]="collapsibleFields" isCollapsibleField>
                    <mat-form-field>
                        <mat-label>{{ 'groups-management.show-dearchive-class' | translate }}</mat-label>
                        <mat-select
                            (selectionChange)="applyFilters($event)"
                            [(ngModel)]="defaultArchive"
                            >
                            <mat-option [value]="false">{{ 'generic.notArchived' | translate }}</mat-option>
                            <mat-option [value]="true">{{ 'generic.archived' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>
            </ng-container>

            <ng-container> <!-- Actions Spéciales -->
                <ng-container *ngIf="canBeJoined && authService.hasLevel(['learner']) && type === 'class'">
                    <button (click)="joinGroup()"
                            ariaLabelTranslate="{{ 'account-management.register' | translate }}"
                            mat-raised-button>
                        {{ 'group-management.join_class' | translate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="authService.hasLevel(['trainer']) && type === 'learner'">
                    <button (click)="importLearners()" *ngIf="isImportLearnerEnabled"
                            ariaLabelTranslate="{{ 'group-management.import_learners' | translate }}"
                            mat-raised-button>
                        {{ 'group-management.import_learners' | translate }}
                    </button>

                    <button
                        *ngIf="isExportEnabled"
                        (click)="exportList()"
                        ariaLabelTranslate="{{ 'group-management.export_list' | translate }}"
                        mat-raised-button>
                        {{ 'group-management.export_list' | translate }}
                    </button>
                </ng-container>
            </ng-container>
            <!--help video button-->
            <button
                (click)="playHelpVideo()"
                *ngIf="displayFilter('button-video-help') && videoUrlToLaunch()!==''
                && authService.isAtLeastTrainer() && type === 'learner'"
                [field]="'activitiesType'" [fields]="collapsibleFields" class="btn-videoUrlToLaunch"
                color="primary"
                isCollapsibleField mat-raised-button>
                {{ 'generic.lessons.video.help' | translate }}
            </button>
            <button (click)="playHelpVideo()"
                    *ngIf="videoUrlToLaunch()!=='' && authService.isAtLeastTrainer() && type === 'learner'"
                    class="btn-videoUrlToLaunch" color="primary"
                    mat-raised-button>{{ 'generic.lessons.video.help' | translate }}</button>

        </mat-toolbar-row>

        <mat-toolbar-row fxLayoutGap="18px">
            <!-- Eléments disponible pour la sélection -->
            <ng-container *ngIf="showMultiSelectionAction">
                <span>{{'generic.for_selection' | translate}} : </span> <!-- TODO Sa place n'est pas ici mais au dessus, mais si l'on le fait il
                    faut tester si au moins un bouton/une action est disponible pour l'utilisateur-->

                <button (click)="archiveListEntity()"
                        *ngIf="archiveEntitySettings && !archived"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="archive" class="groups-list__filters__button"
                        >
                    <mat-icon svgIcon="archive"></mat-icon>
                    <span>{{'generic.archive' | translate}}</span>
                </button>

                <button (click)="dearchiveListEntity()"
                        *ngIf="dearchiveEntitySettings && archived"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="dearchive"
                        mat-raised-button>
                    <mat-icon svgIcon="unarchive"></mat-icon>
                    <span>{{'generic.unarchive' | translate}}</span>
                </button>

                <button (click)="activateMetacognitionListEntity()"
                        *ngIf="canActiveMetacognition() && activateMetacognitionEntitySettings"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="activateMetacognition"
                        mat-raised-button>
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span>{{'generic.activateMetacognition' | translate}}</span>
                </button>

                <button (click)="deactivateMetacognitionListEntity()"
                        *ngIf="canActiveMetacognition() && deactivateMetacognitionEntitySettings"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="deactivateMetacognition"
                        mat-raised-button>
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span>{{'generic.deactivateMetacognition' | translate}}</span>
                </button>

                <button (click)="deleteListEntity()"
                        *ngIf="deleteEntitySettings && deleteLearners"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="remove"
                        mat-raised-button>
                    <mat-icon svgIcon="delete"></mat-icon>
                    <span>{{'generic.delete' | translate}}</span>
                </button>
            </ng-container>

            <ng-container *ngIf="type === 'learner'">
                <mat-form-field *ngIf="displayFilter('groupAssociate')" [field]="'activitiesType'"
                                [fields]="collapsibleFields" isCollapsibleField>
                    <mat-label>{{ 'groups-management.associate_group' | translate }}</mat-label>
                    <mat-chip-grid #groupsAssociateChipList>
                        <mat-chip-row
                            (removed)="removeGroupToAssociate(groupChip)"
                            *ngFor="let groupChip  of groupsAssociateChips"
                            [removable]="true"
                            [selectable]="true">
                            {{groupChip}}
                            <mat-icon matChipRemove svgIcon="cross"></mat-icon>
                        </mat-chip-row>

                        <input
                            #groupsAssociateChipInput
                            (matChipInputTokenEnd)="blurAllChipsList()"
                            [formControl]="groupsAssociateCtrl"
                            [matAutocomplete]="groupsAssociate"
                            [matChipInputAddOnBlur]="false"
                            [matChipInputFor]="groupsAssociateChipList"
                            [matChipInputSeparatorKeyCodes]="groupsAssociateSeparatorKeysCodes"
                            />
                    </mat-chip-grid>

                    <mat-autocomplete #groupsAssociate="matAutocomplete"
                                      (optionSelected)="groupToAssociateSelected($event)">
                        <mat-option *ngFor="let groupChip of groupsAssociateFilteredChips | async"
                                    [value]="groupChip.groupname">
                            {{ groupChip.groupname }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field *ngIf="displayFilter('workgroupAssociate')" [field]="'activitiesType'"
                                [fields]="collapsibleFields" isCollapsibleField>
                    <mat-label>{{ 'groups-management.associate_workgroup' | translate }}</mat-label>
                    <mat-chip-grid #workgroupsAssociateChipList>
                        <mat-chip-row
                            (removed)="removeWorkGroupToAssociate(workgroupChip)"
                            *ngFor="let workgroupChip  of workgroupsAssociateChips"
                            [removable]="true"
                            [selectable]="true">
                            {{workgroupChip}}
                            <mat-icon matChipRemove svgIcon="cross"></mat-icon>
                        </mat-chip-row>

                        <input
                            #workgroupsAssociateChipInput
                            (matChipInputTokenEnd)="blurAllChipsList()"
                            [formControl]="workgroupsAssociateCtrl"
                            [matAutocomplete]="workgroupsAssociate"
                            [matChipInputAddOnBlur]="false"
                            [matChipInputFor]="workgroupsAssociateChipList"
                            [matChipInputSeparatorKeyCodes]="workgroupsAssociateSeparatorKeysCodes"
                            />
                    </mat-chip-grid>

                    <mat-autocomplete #workgroupsAssociate="matAutocomplete"
                                      (optionSelected)="workgroupToAssociateSelected($event)">
                        <mat-option *ngFor="let workgroupChip of workgroupsAssociateFilteredChips | async"
                                    [value]="workgroupChip.workgroupname">
                            {{ workgroupChip.workgroupname }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <button (click)="associateToLearners()"
                        *ngIf="displayFilter('workgroupAssociate') || displayFilter('groupAssociate')"
                        [disabled]="isSelectionActionIsEnabled() === false"
                        aria-label="associate"
                        mat-raised-button>
                    <span>{{'groups-management.associate_to_learner' | translate}}</span>
                </button>

            </ng-container>

            <button (click)="showHideFields()" *ngIf="isFiltersCollapsable" class="btn-collapse">
                <span>{{ (filtersExpanded ? 'filters.button.collapse' : 'filters.button.expand') | translate }}</span>
                <mat-icon [svgIcon]="filtersExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"
                          class="collapsable-arrow"></mat-icon>
            </button>

        </mat-toolbar-row>
    </mat-toolbar>

    <div id="groups-list-table"
         class="groups-list__table-wrapper content"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="{{align}}"
         fusePerfectScrollbar>
        <table class="groups-list__table"
               mat-table
               [dataSource]="dataSource"
               [@animateStagger]="{value:'50'}"
               #table>

            <!-- TODO Lister tous les champs qu'on peut vouloir afficher -->

            <!-- Checkbox Column -->
            <ng-container matColumnDef="checkbox">
                <th *matHeaderCellDef mat-header-cell>
                    <mat-checkbox (change)="updateCheck($event)" [(ngModel)]="selectAll"></mat-checkbox>
                </th>
                <td *matCellDef="let entity" mat-cell>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="checkboxes[entity.id]">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Avatar Column -->
            <ng-container matColumnDef="avatar">
                <th *matHeaderCellDef mat-header-cell>{{'generic.avatar' | translate}}<span
                    class="th__span--hidden-accessibility">{{'accessibility.th_hidden_avatar' | translate}}</span></th>
                <td *matCellDef="let entity" mat-cell>
                    <img *ngIf="entity.avatar" alt="" [src]="entity.avatar"
                         class="avatar"/>
                </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="color">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.color' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <div [style.background-color]="entity.color" class="pastille"></div>
                </td>
            </ng-container>

            <!-- nickname Column -->
            <ng-container matColumnDef="nickname">
                <th *matHeaderCellDef mat-header-cell>{{ 'account-management.pseudo' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.nickname}}</p>
                </td>
            </ng-container>

            <!-- pseudo Column -->
            <!-- TODO: we use this field "pseudo" and not "username" because we need another name for this field, we cant use another translated term, need to find better fix -->
            <ng-container matColumnDef="pseudo">
                <th *matHeaderCellDef mat-header-cell>
                    <ng-container *ngIf="authService.isGAR()">{{ 'groups-management.nickname_GAR' | translate }}</ng-container>
                    <ng-container *ngIf="!authService.isGAR()">{{ 'groups-management.nickname' | translate }}</ng-container>
                </th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.username}}</p>
                </td>
            </ng-container>

            <!-- Username Column -->
            <ng-container matColumnDef="username">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_students' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.username}}</p>
                </td>
            </ng-container>


            <!-- Trainer username Column -->
            <ng-container matColumnDef="trainer-name">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_trainers' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.username}}</p>
                </td>
            </ng-container>


            <!-- Email Column -->
            <ng-container matColumnDef="email">
                <th *matHeaderCellDef fxHide fxShow.gt-sm mat-header-cell>Email</th>
                <td *matCellDef="let entity" fxHide fxShow.gt-sm mat-cell>
                    <p class="email text-truncate">
                        {{entity.email}}
                    </p>
                </td>
            </ng-container>

            <!-- codeid Column -->
            <ng-container matColumnDef="codeid">
                <th *matHeaderCellDef mat-header-cell>{{ 'account-management.codeid' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.codeid}}</p>
                </td>
            </ng-container>

            <!-- Type Trainer Column -->
            <ng-container matColumnDef="type">
                <th *matHeaderCellDef mat-header-cell>Type</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{getTranslate(entity.type) | translate}}</p>
                </td>
            </ng-container>

            <!-- Date Trainer Column -->
            <ng-container matColumnDef="access">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.date_last_connection' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.access}}</p>
                </td>
            </ng-container>

            <!-- Institution Group Column -->
            <ng-container matColumnDef="institutiongroupname">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.institution' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.institutiongroupname}}</p>
                </td>
            </ng-container>

            <!-- Group Column -->
            <ng-container matColumnDef="groupname">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.label_group' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.groupname}}</p>
                </td>
            </ng-container>

            <!-- Workgroup Column -->
            <ng-container matColumnDef="workgroupname">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.group' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.workgroupname}}</p>
                </td>
            </ng-container>

            <!-- Groups Column -->
            <ng-container matColumnDef="groups">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_classes' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p *ngIf="entity.groups?.length" class="text-truncate">{{entity.groups.join(', ')}}</p>
                    <button mat-raised-button class="btn-assign-only"
                            *ngIf="!entity.groups?.length && editEntitySettings && editEntitySettings.isAuthorized(entity) && isAssignButtonInGroupListMustBeDisplayed()"
                            (click)="openAssignGroups(entity, true)">
                        <span>{{'generic.assign'|translate}}</span>
                    </button>
                </td>
            </ng-container>

            <!-- Workgroups Column -->
            <ng-container matColumnDef="workgroups">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_workgroups' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p *ngIf="entity.workgroups?.length" class="text-truncate">{{entity.workgroups.join(', ')}}</p>
                </td>
            </ng-container>

            <!-- Checkbox Parental consent Column -->
            <ng-container matColumnDef="parentalConsent">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.parental_consent' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <mat-checkbox
                        [(ngModel)]="entity.parentalConsent"
                        [disabled]="true"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- result Column -->
            <ng-container matColumnDef="results">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.results' | translate }}</th>
                <td (click)="navigateToResult(entity)" *matCellDef="let entity" mat-cell>
                    <mat-icon svgIcon="analytics"></mat-icon>
                </td>
            </ng-container>

            <!-- assignation tab result Column -->
            <ng-container matColumnDef="assignation_tab_results">
                <th *matHeaderCellDef
                    mat-header-cell>
                    <span class="th__span--hidden-accessibility">{{ 'accessibility.th_hidden_char' | translate }}</span>
                </th>
                <td *matCellDef="let entity"
                    mat-cell
                    >
                    <mat-icon svgIcon="bar_chart"
                              tabindex="0"
                              role="button"
                              (click)="navigateToAssignationTabResult(entity)"
                              (keydown.enter)="navigateToAssignationTabResult(entity)"
                    ></mat-icon>
                </td>
            </ng-container>

            <!-- Learners Column -->
            <ng-container matColumnDef="learners">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.number_of_student' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate">{{entity.learners}}</p>
                </td>
            </ng-container>

            <!-- Learners List Column -->
            <ng-container matColumnDef="learnerslist">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_students' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p>{{entity.learners.join(', ')}}</p>
                </td>
            </ng-container>

            <!-- Level Column utilisé pour age-->
            <ng-container matColumnDef="level">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.level' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate">{{formatLevel(entity.level)}}</p>
                </td>
            </ng-container>

            <!--school year-->
            <ng-container matColumnDef="schoolyear">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.school.year' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p *ngIf="entity.schoolyear_term"
                       class="text-truncate">{{groupsManagementService.displaySchoolYear(entity.schoolyear_term.name)}}</p>
                    <p *ngIf="!entity.schoolyear_term" class="text-truncate">not set</p>
                </td>
            </ng-container>

            <!-- Code Column -->
            <ng-container matColumnDef="code">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.code_class' | translate }}</th>
                <td *matCellDef="let entity" [ngClass]="{ 'disabled' : isCodeClassDisabled() }" mat-cell>
                    <p class="text-truncate">{{entity.code}}</p>
                </td>
            </ng-container>

            <!-- Institution Group Column address-->
            <ng-container matColumnDef="address">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.address' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.address}}</p>
                </td>
            </ng-container>

            <!-- Institution Group Column code postal-->
            <ng-container matColumnDef="postalCode">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.postal-code' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.postalCode}}</p>
                </td>
            </ng-container>

            <!-- Institution Group Column city-->
            <ng-container matColumnDef="city">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.city' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.city}}</p>
                </td>
            </ng-container>

            <!-- Institution Group Column country-->
            <ng-container matColumnDef="country">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.country' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate font-weight-600">{{entity.country}}</p>
                </td>
            </ng-container>


            <!-- Institutions Column -->
            <ng-container matColumnDef="institutions">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.my_institution' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate">{{entity.parent?.label}}</p>
                </td>
            </ng-container>


            <!-- trainer Institutions Column -->
            <ng-container matColumnDef="trainer-institutions">
                <th *matHeaderCellDef mat-header-cell>{{ 'groups-management.institution' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <p class="text-truncate">{{entity.institution}}</p>
                </td>
            </ng-container>

            <!-- metacognitionState Column -->
            <ng-container matColumnDef="metacognitionState">
                <th *matHeaderCellDef mat-header-cell>{{ 'generic.metacognition' | translate }}</th>
                <td *matCellDef="let entity" mat-cell>
                    <div [ngClass]="entity.metacognitionActive ? 'metacognitionActivated' : 'metacognitionDeactivated'">
                    </div>
                    <span>
                            {{(entity.metacognitionActive ? 'generic.metacognition.activated' : 'generic.metacognition.deactivated') | translate}}
                        </span>
                </td>
            </ng-container>

            <!--expand Column-->
            <ng-container matColumnDef="expandRow">
                <th *matHeaderCellDef mat-header-cell><span
                    class="th__span--hidden-accessibility">{{'accessibility.th_hidden_collapse' | translate}}</span>
                </th>
                <td *matCellDef="let entity; let i = index" mat-cell>
                    <ng-container *ngIf="canExpand(entity)">
                        <mat-icon [ngClass]="{'hide':isOpenAddingLearnerRow(i)}" class="collapsable-arrow"
                                  svgIcon="keyboard_arrow_up"
                                  tabindex="0"
                                  aria-hidden="false"
                                  role="button"></mat-icon>
                        <mat-icon (click)="collapseLearnerRow($event)"
                                  (keyup.enter)="collapseLearnerRow($event)"
                                  [ngClass]="{'hide':!isOpenAddingLearnerRow(i)}"
                                  class="collapsable-arrow" svgIcon="keyboard_arrow_down"
                                  tabindex="0"
                                  aria-hidden="false"
                                  role="button"></mat-icon>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <th *matHeaderCellDef mat-header-cell><span
                    class="th__span--hidden-accessibility">{{'accessibility.th_hidden_more_menu' | translate}}</span>
                </th>
                <td *matCellDef="let entity let i = index" mat-cell>
                    <!-- TODO - Add settings for access management -->
                    <div *ngIf="canSeeMenu(entity)">
                        <button
                            (click)="$event.stopPropagation();"
                            [matMenuTriggerFor]="moreMenu"
                            aria-label="More"
                            [matTooltip]="'activities.lesson.more' | translate"
                            mat-icon-button>
                            <mat-icon svgIcon="submenu"></mat-icon>
                        </button>

                        <mat-menu #moreMenu="matMenu">
                            <button
                                (click)="editEntity(entity, i)"
                                *ngIf="editEntitySettings && editEntitySettings.isAuthorized(entity)"
                                aria-label="edit"
                                mat-menu-item>
                                <mat-icon svgIcon="edit"></mat-icon>
                                <span>{{'generic.modify' | translate}}</span>
                            </button>

                            <button
                                (click)="archiveEntity(entity)"
                                *ngIf="archiveEntitySettings && archiveEntitySettings.isAuthorized(entity) && canArchive && !entity.archived"
                                aria-label="archive"
                                mat-menu-item>
                                <mat-icon>archive</mat-icon>
                                <span>{{'generic.archive' | translate}}</span>
                            </button>

                            <button
                                (click)="deArchiveEntity(entity)"
                                *ngIf="dearchiveEntitySettings && dearchiveEntitySettings.isAuthorized(entity) && canArchive && entity.archived"
                                aria-label="dearchive"
                                mat-menu-item>
                                <mat-icon>archive</mat-icon>
                                <span>{{'generic.unarchive' | translate}}</span>
                            </button>


                            <button
                                (click)="activateMetacognitionEntity(entity)"
                                *ngIf="activateMetacognitionEntitySettings && activateMetacognitionEntitySettings.isAuthorized(entity) && !entity.metacognitionActive && canActiveMetacognition()"
                                aria-label="activateMetacognition"
                                mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                <span>{{'generic.activateMetacognition' | translate}}</span>
                            </button>

                            <button
                                (click)="deactivateMetacognitionEntity(entity)"
                                *ngIf="deactivateMetacognitionEntitySettings && deactivateMetacognitionEntitySettings.isAuthorized(entity) && entity.metacognitionActive && canActiveMetacognition()"
                                aria-label="deactivateMetacognition"
                                mat-menu-item>
                                <mat-icon>edit</mat-icon>
                                <span>{{'generic.deactivateMetacognition' | translate}}</span>
                            </button>

                            <button
                                (click)="deleteEntity(entity)"
                                *ngIf="deleteEntitySettings && deleteEntitySettings.isAuthorized(entity) && deleteLearner"
                                aria-label="remove"
                                mat-menu-item>
                                <mat-icon svgIcon="delete"></mat-icon>
                                <span>{{'generic.delete' | translate}}</span>
                            </button>

                            <button (click)="execCustomActions(customActions[action], entity)"
                                    *ngFor="let action of getCustomActionsKeyToShow(entity)"
                                    mat-menu-item>
                                <mat-icon *ngIf="customActions[action].icon"
                                          svgIcon="{{customActions[action].icon}}"></mat-icon>
                                <span>{{customActions[action].label | translate}}</span>
                            </button>
                        </mat-menu>

                    </div>

                </td>
            </ng-container>

            <!--use for inline editing-->
            <ng-container class="mat-row-editing" matColumnDef="editing">
                <td *matCellDef="let item" [attr.colspan]="displayedColumns.length" mat-cell>
                    <app-groups-inline-modal (saveInProgress)="saveInProgress()"
                                             (savedOrCancelOccured)="resetIndexEditedRow()"
                                             *ngIf="!showGlobalAddButton"
                                             [addEntity]="editedValue(item)"
                                             class="groups-inline-modal">
                    </app-groups-inline-modal>
                </td>
            </ng-container>

            <!--use for show app-learner inside a row-->
            <ng-container class="mat-row-editing"
                          matColumnDef="addingLearner">
                <td mat-cell
                    *matCellDef="let item"
                    [attr.colspan]="displayedColumns.length">
                    <app-learner class="groups-inline-modal-learner"
                                 [groupNameClassroomSelected]="groupnameFilter"
                                 [groupNameSelected]="workgroupnameFilter"
                                 [isAddingInlineLearnerRowAddButton]="true"
                                 [isAddingInlineLearnerRow]="true"
                                 [isAddingLearnerFromGroupOrWorkgroup]="addingLearnerRow !== -2">
                    </app-learner>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr class="mat-mdc-row"
                *matNoDataRow>
                <td *ngIf="type==='class' && authService.isGAR()" class="mat-cell"
                    [attr.colspan]="displayedColumns.length">{{'groups-management.empty_learners_gar' | translate}}</td>
                <td *ngIf="type==='class' && !authService.isGAR()" class="mat-cell"
                    [attr.colspan]="displayedColumns.length">{{'generic.empty_groups_not_gar' | translate}}</td>
                <td *ngIf="type === 'group'" class="mat-cell"
                    [attr.colspan]="displayedColumns.length">{{ 'groups-management.empty_groups' | translate }}</td>
                <td *ngIf="type === 'learner'" class="mat-cell"
                    [attr.colspan]="displayedColumns.length">{{ termInRegardOfGarOrNot | translate }}</td>
            </tr>
            <tr (click)="editEntity(entity, i, true)"
                (keyup.enter)="editEntity(entity, i, true)"
                *matRowDef="let entity; columns: displayedColumns;let i = index;"
                [@animate]="{value:'*',params:{y:'100%'}}"
                [ngClass]="{'selected':checkboxes[entity.id]}"
                class="entity"
                mat-row
                matRipple>
            </tr>
            <!--if row is editing it will show this specific-->
            <tr *matRowDef="let row; columns: ['editing']; when: isEditingRow;" class="mat-row-editing" mat-row></tr>
            <!--if adding student zone is activated will show row and adding row zone-->
            <tr *matRowDef="let row; columns: ['addingLearner']; when: isAddingLearnerRow;" class="mat-row-editing"
                mat-row></tr>
        </table>

    </div>
    <!--component for adding element inline instead of with an opening modal-->
    <app-groups-inline-modal *ngIf="!showGlobalAddButton && isAddingInlineLearnerRowAddButton && this.authService.accessLevel!=='learner'"
        class="groups-inline-modal"
        [addEntity]="addEntity"
        [groupNameClassroomSelected]="groupNameClassroomSelected"
        [groupNameSelected]="groupNameSelected"
        [isAddingLearnerFromGroupOrWorkgroup]="isAddingLearnerFromGroupOrWorkgroup"
        [joinLearner]="getJoinLearnerForThisGroup()"
        [learnersList]="learnersList"
        (savedOrCancelOccured)="manageAddLearnerOpenedLine()">
    </app-groups-inline-modal>

</div>