import {Component, effect, input, output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-compass-search-form',
  standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        ReactiveFormsModule,
        TranslateModule,
        MatButton
    ],
  templateUrl: './compass-search-form.component.html',
  styleUrl: './compass-search-form.component.scss'
})
export class CompassSearchFormComponent {
    searchValue = input<string>('');
    searchValueChanged = output<string>();

    done = output<string>();

    form = new FormGroup({
        search: new FormControl(this.searchValue())
    });

    constructor() {
        effect(() => this.form.get('search').setValue(this.searchValue()))
        this.form.get('search').valueChanges.subscribe(value => this.searchValueChanged.emit(value));
    }

    public submit(): void {
        this.done.emit(this.form.get('search').value);
    }
}