<mat-card *ngIf="!DashWidgetConf.displayModeAccordion">
    <mat-card-header *ngIf="DashWidgetConf.displayDashWidgetTitle" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayoutAlign="start center" >
            <mat-icon svgIcon="{{ DashWidgetConf.headerIcon }}"></mat-icon>
            {{ DashWidgetConf.headerTitle | translate }}
        </mat-card-title>
        <a *ngIf="DashWidgetConf.displayHeaderLink" [routerLink]="DashWidgetConf.headerLink"
           class="mat-icon-button"
           fxFlex="0 0 auto" fxLayout="column" fxLayoutAlign="space-around center">
            <span>{{ 'generic.see_more' | translate }}</span>
            <mat-icon svgIcon="dropdown"></mat-icon>
        </a>
    </mat-card-header>
    <mat-card-content>
        <ng-content select="[widgetCard]"></ng-content>
    </mat-card-content>
    <!--mat-card-actions>
    </mat-card-actions-->
</mat-card>

<mat-accordion *ngIf="DashWidgetConf.displayModeAccordion">
    <mat-expansion-panel [expanded]="true" >
        <mat-expansion-panel-header [collapsedHeight]="''" [expandedHeight]="''">
            <mat-panel-title>
                {{ DashWidgetConf.headerTitle | translate }}
            </mat-panel-title>
            <span *ngIf="DashWidgetConf.countAssignment">
                    {{DashWidgetConf?.countAssignment}} {{ DashWidgetConf.headerCountTitle | translate }}
                </span>
            <mat-icon svgIcon="expand"></mat-icon>
        </mat-expansion-panel-header>

        <ng-content select="[widgetAccordion]"></ng-content>

        <mat-action-row fxLayout="column">
            <a *ngIf="DashWidgetConf.displayHeaderLink" [routerLink]="DashWidgetConf.headerLink"
               fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">
                <span>{{ 'generic.see_more' | translate }}</span>
            </a>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>



