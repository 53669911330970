<mat-card>
    <mat-card-header>
        <button class="close"
                (click)="close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </mat-card-header>
    <app-voice-recorder
        [flashcard]="data"
    ></app-voice-recorder>
</mat-card>