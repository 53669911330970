<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>

    <div class="content"
         fusePerfectScrollbar>

        <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo"
                         [situation]="displayApplication">
        </app-banner-info>

        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <app-search-filters (launchSearch)="refreshNotes($event).subscribe()"
                                    [countEntities]="paginator?.count"
                                    [customLists]="{lessons: alreadyAssociatedLessonsFilterList}"
                                    [fields]="filters"
                                    [customFieldName]="[{field: 'metadatas_title', value: 'generic.title'}]">
                </app-search-filters>
            </mat-toolbar-row>
        </mat-toolbar>

        <div *ngIf="isLoadingDone; then listBlock; else loadingBlock"></div>

        <ng-template #listBlock>
            <div *ngIf="this.gamecodes.length === 0"
                 class="empty-list p-16">
                <p>{{ 'gamecode.empty_list' | translate }}</p>
                <p>{{ 'gamecode.add_gamecode_instruction' | translate }}</p>
            </div>
            <div class="gamecode-card-list"
                 fxFlex>
                <div class="p-16 fake-card"
                     fxFlex="auto"
                     fxFlex.gt-lg="33"
                     fxFlex.gt-xs="50">
                    <div *ngIf="isLoadingDone"
                         role="button"
                         tabindex="0"
                         (click)="createNote($event)"
                         (keyup.enter)="createNote($event)">
                        <mat-card class="mat-card">
                            <mat-card-content class="mat-card-content">
                                <mat-icon svgIcon="add"></mat-icon>
                                <h3>{{ (displayApplication + '.add_note') | translate}}</h3>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <div *ngFor="let gamecode of gamecodes"
                     class="p-16"
                     fxFlex="auto"
                     fxFlex.gt-lg="33"
                     fxFlex.gt-xs="50">
                    <app-gamecode-card (hasChanged)="onGamecodeChanged(gamecode)"
                                       [gamecodeBasicSearchEntity]="gamecode"
                                       class="card-split-media-info">
                    </app-gamecode-card>
                </div>
            </div>
            <mat-paginator (page)="onPaginateChange($event)"
                           [length]="paginator.count"
                           [pageIndex]="paginator.page -1"
                           [pageSize]="gamecodeListRange">
            </mat-paginator>
        </ng-template>

        <ng-template #loadingBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>

</div>