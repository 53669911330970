<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [wording]="wording"
                [wordingAudio]="wordingAudio"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                questionTypeName="{{ 'activities.questionTypeName.memory' | translate }}">
            </app-consignes>
            <app-consignes
                *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                [instruction]="instruction"
                [ngClass]="{'consigneImg': isInstructionImg() || isWordingImg()}"
                [wording]="wording"
                [wordingAudio]="wordingAudio"
                [instructionAudio]="instructionAudio"
                [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
                questionTypeName="{{ 'activities.questionTypeName.memory' | translate }}">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <ng-container *ngIf="!!data">
            <app-memory-find-zone [ngClass]="answersWrapperClass"
                                  (idItemClicked)="changeState($event)"
                                  [data]="data"
                                  [disable]="displayForSummary || displaySolution"
                                  [selectedItem]="selectedItems()"
                                  [stateOfLastSelectedItems]="stateOfLastSelectedItems"
                                  (playState)="cardPlayState($event)">
            </app-memory-find-zone>
        </ng-container>
    </mat-card-content>

</mat-card>

<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>