<div class="container">
    <!--use to not have dependencies with gamification module-->
    <app-dynamic *ngIf="useAvatar" [room]="'gamification'"
                 [subject]="'animatedCanvaForInMyProgressComponent'"></app-dynamic>
    <div *ngIf="!useAvatar" [ngClass]="icon!==''?'imageRewardContainer':'imageRewardContainer noIconYet'">
        <img *ngIf="icon!==''" [attr.src]="icon" alt=""/>
        <span class="title">{{ 'generic.new' | translate }} {{assetType}}</span>
        <div [ngClass]="icon===''?'rideau':'rideau rideau-open'">
        </div>
        <div [ngClass]="showStarAnimation?'show-star-animation':''"></div>
    </div>
    <div [ngClass]="useAvatar?'avatar-square':'square'"></div>
    <div [ngClass]="useAvatar?'avatar-dot':'dot'"></div>
    <span [ngClass]="useAvatar?'avatar-value':''">{{ 'generic.level' | translate }} {{valueToShow}}</span>
</div>