<div class="page-layout simple"><!--activities-list-->


        <mat-toolbar class="filters"><!-- TODO : Reporter dans les autres templates ou en faire un composant -->
        <mat-toolbar-row
                         *ngIf="displayFilters('chapters') && displayFilters('concepts') && displayFilters('skills')">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
                <button mat-stroked-button (click)="tagsPopUp('chapters')"
                        *ngIf="displayFilters('chapters') && !tagsSelected['chapters'].length">
                    <span>{{ 'generic.chapters' | translate }}
                        (<span>{{displayedFiltersLength('chapters') | translate }}</span>)
                    </span>
                </button>
                <mat-button-toggle-group>
                    <mat-button-toggle (click)="tagsPopUp('chapters')"
                                       *ngIf="displayFilters('chapters') && tagsSelected['chapters'].length"
                                        class="">
                        <span>{{ 'generic.chapters' | translate }}
                            (<span>{{displayedFiltersLength('chapters') | translate }}</span>)
                        </span>
                    </mat-button-toggle>
                    <mat-button-toggle (click)="resetTags('chapters')"
                                       *ngIf="displayFilters('chapters') && tagsSelected['chapters'].length">
                        <mat-icon *ngIf="displayedFiltersIcons" svgIcon="cross"></mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <button mat-stroked-button (click)="tagsPopUp('concepts')"
                        *ngIf="displayFilters('concepts') && !tagsSelected['concepts'].length">
                    <span>{{ 'generic.concepts' | translate }}
                        (<span>{{displayedFiltersLength('concepts') | translate }}</span>)
                    </span>
                </button>
                <mat-button-toggle-group>
                    <mat-button-toggle (click)="tagsPopUp('concepts')"
                                       *ngIf="displayFilters('concepts') && tagsSelected['concepts'].length"
                                       class="">
                        <span>{{ 'generic.concepts' | translate }}
                            (<span>{{displayedFiltersLength('concepts') | translate }}</span>)
                        </span>
                    </mat-button-toggle>
                    <mat-button-toggle (click)="resetTags('concepts')"
                                       *ngIf="displayFilters('concepts') && tagsSelected['concepts'].length">
                        <mat-icon *ngIf="displayedFiltersIcons" svgIcon="cross"></mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>

                <button mat-stroked-button (click)="tagsPopUp('skills')"
                        *ngIf="displayFilters('skills') && !tagsSelected['skills'].length">
                    <span>{{ 'generic.skills' | translate }}
                        (<span>{{displayedFiltersLength('skills') | translate }}</span>)</span>
                </button>
                <mat-button-toggle-group>
                    <mat-button-toggle (click)="tagsPopUp('skills')"
                                       *ngIf="displayFilters('skills') && tagsSelected['skills'].length"
                                       class="">
                        <span>{{ 'generic.skills' | translate }}
                            (<span>{{displayedFiltersLength('skills') | translate }}</span>)
                        </span>
                    </mat-button-toggle>
                    <mat-button-toggle (click)="resetTags('skills')"
                                       *ngIf="displayFilters('skills') && tagsSelected['skills'].length">
                        <mat-icon *ngIf="displayedFiltersIcons" svgIcon="cross"></mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </mat-toolbar-row>
        <mat-toolbar-row>
            <form name="searchForm" fxFlex fxLayout="column">
                <div fxFlex="1 1 100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
                    <mat-form-field *ngIf="displayFilters('type')">
                        <mat-label>{{ 'generic.type' | translate }}</mat-label><mat-select [formControl]="allControls.typeFilter.control"
                                    
                                    #typeSelect
                                    (selectionChange)="blurElementRef()">
                            <mat-option value="all">
                                <span>
                                    <mat-icon *ngIf="displayedFiltersIcons" svgIcon="allTypes"
                                              title="allTypes"></mat-icon>
                                </span>{{ 'generic.all_types' | translate }}
                            </mat-option>
                            <mat-option [value]="option.id" *ngFor="let option of allTypes">
                               <span>
                                    <mat-icon *ngIf="displayedFiltersIcons" svgIcon="{{ option?.label?.toLowerCase() }}"
                                      title="{{ option?.label?.toLowerCase() }}"></mat-icon>
                                </span> {{ option?.localized }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="displayFilters('title')">
                        <mat-label>{{ 'generic.title' | translate }}</mat-label><input #titleInput="matInput" matInput [formControl]="allControls.titleFilter.control"
                                *ngIf="displayFilters('title')">
                    </mat-form-field>
                    <mat-form-field *ngIf="displayFilters('keywords')">
                        <mat-label>{{ 'generic.keywords' | translate }}</mat-label><input matInput [formControl]="allControls.keywordsFilter.control"
                               >
                    </mat-form-field>
                    <mat-form-field *ngIf="displayFilters('source')">
                        <mat-label>{{ 'generic.source' | translate }}</mat-label><input matInput [formControl]="allControls.sourceFilter.control"
                               >
                    </mat-form-field>

                    <mat-checkbox [formControl]="allControls.usedFilter.control"
                                  *ngIf="displayFilters('used')">{{ 'generic.activities_not_used' | translate }}</mat-checkbox>

                    <button mat-button (click)="launchSearch()">{{ 'generic.search' | translate }}</button>

                </div>
                <div *ngIf="inSelectionMode || canAddToLesson" fxFlex="1 1 100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
                    <small>{{ 'generic.for_selection' | translate }}</small>

                    <button *ngIf="!inSelectionMode" [disabled]="buttonDisabled"  color="primary"
                            (click)="launchLessonListingModal(checkboxes)">{{ 'generic.add_to_lesson' | translate }} ({{ selectedCount }})
                    </button>
                    <button *ngIf="inSelectionMode" [disabled]="buttonDisabled"

                            (click)="addToLesson()">
                        {{ 'activities.add_to_lesson' | translate}} ({{ selectedCount }})
                    </button>
                    <button *ngIf="inSelectionMode"

                            (click)="lessonsService.goBackToForm()">
                        {{ 'generic.cancel' | translate}}
                    </button>
                    <div class="count-entities py-4 font-size-14">
                        {{ countEntities }} {{'generic.results' | translate }}
                    </div>

                </div>
            </form>
        </mat-toolbar-row>
    </mat-toolbar>

    <table mat-table
        #table
        [dataSource]="dataSource"
        matSort>


        <!-- checkbox Column -->
        <ng-container matColumnDef="checkbox">
            <th mat-header-cell *matHeaderCellDef>
                <!-- hide checkbox "check all" if  we have unique selection -->
                <mat-checkbox *ngIf="settings?.multiSelectionForActivitiesList" (change)="updateCheck()" [(ngModel)]="selectAll"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox
                    [(ngModel)]="checkboxes[row.id]"
                    (change)="onSelectedChange(row, $event)"
                    id="{{ row.id }}"
                    name="corpusCheckboxe"></mat-checkbox>
            </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                fxHide.xs>
                {{ 'generic.type' | translate }}
            </th>
            <td mat-cell
                *matCellDef="let row"
                fxHide.xs>
                <span>
                    <mat-icon *ngIf="displayedFiltersIcons" svgIcon="{{ getContentTypeIcon(row)?.name }}"
                              title="{{ getContentTypeIcon(row)?.translate | translate }}">
                    </mat-icon>
                </span>
            </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.title' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{row.attributes.title}}</td>
        </ng-container>

        <!-- Level Column -->
        <ng-container matColumnDef="level">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'groups-management.level' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{formatArrayToJoinString(row.attributes.level, 'alias')}}
            </td>
        </ng-container>

        <!-- Level Column -->
        <ng-container matColumnDef="difficulty">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                fxHide.xs>
                {{ 'generic.difficulty' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" fxHide.xs>
                {{formatArrayToJoinString(row.attributes.difficulty)}}
            </td>
        </ng-container>

        <!-- used Column -->
        <ng-container matColumnDef="used">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.used' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" fxHide.xs>
                <div *ngIf="!authService.isManager()" class="pastille"
                     [ngClass]="{'primary-400-bg':!checkUsed(row.attributes.used_count), 'green-bg' : checkUsed(row.attributes.used_count), 'pastille': true}"></div>
                <label *ngIf="authService.isManager()">{{+row.attributes.usedSummary.lessons}}&nbsp;{{ 'activities.title_lessons' | translate }}&nbsp;/&nbsp;{{+row.attributes.usedSummary.assignations}}&nbsp;{{ 'assignment.assigned' | translate }}</label>
            </td>
        </ng-container>

        <!-- success rate Column -->
        <ng-container matColumnDef="success-rate">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.success_rate' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" fxHide.xs>
                {{roundRate(row.get('successRate')?.value)}}%
            </td>
        </ng-container>

        <!-- success rate Column -->
        <ng-container matColumnDef="answers-count">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.answers_count' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" fxHide.xs>
                {{row.get('successRate')?.count}}
            </td>
        </ng-container>

        <!-- Author Column -->
        <ng-container matColumnDef="author">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.author' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">{{row.attributes.author}}</td>
        </ng-container>

        <!-- date changed Column -->
        <ng-container matColumnDef="changed">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.changedDate' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{localeDate(row.attributes.changed)}}
            </td>
        </ng-container>

        <!-- date source Column -->
        <ng-container matColumnDef="source">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header>
                {{ 'generic.source' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                <span [innerHTML]="row.attributes.source"></span>
            </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'generic.actions' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                <button  class="mat-elevation-z1" (click)="launchPreview(row)">
                    <mat-icon svgIcon="play"></mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                 matRipple
                 [@animate]="{value:'*',params:{y:'100%'}}">
        </tr>
    </table>

    <mat-paginator
        [length]="countEntities"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageRangeOptions"
        (page)="onPaginateChange($event)">
    </mat-paginator>
</div>
