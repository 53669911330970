<ng-container *ngIf="hideAllPageWhileLoading === false else spinner">
    <header>
        <h1>{{ lesson?.get('metadatas').title }}</h1>
        <button  (click)="navigateToLessonList()"
                aria-label="Retour aux leçons">{{'activities.return_to_list' | translate}}</button>
    </header>
    <main>
        <mat-tab-group
            *ngIf="!loading else spinner"
            [selectedIndex]="selectedIndex"
            (selectedIndexChange)="onTabChange($event)">

            <mat-tab *ngFor="let subLesson of subLessons; let i=index" label="{{subLesson?.get('metadatas')?.title}}">
                <app-tips *ngIf="isTips" [label]="'generic.activities.tips' | translate"></app-tips>

                <!-- Liste des activités -->
                <ul *ngIf="activities[subLesson.id]?.length > 0; else noActivities">
                    <li *ngFor="let activity of activities[subLesson.id]; index as i">
                        <app-activty-card [isActivitiesIsDone]="isActivitiesIsDone(activity)"
                                          [title]="activity?.get('metadatas')?.title"
                                          [image]="getActivityTypeImage(activity)" [subLesson]="subLesson"
                                          [i]="i" (playEvent)="playEvent($event)"></app-activty-card>
                    </li>
                </ul>

                <!-- Message si pas d'activités -->
                <ng-template #noActivities>
                    <p *ngIf="activitiesLoaded" class="no-activities">{{ getNoActivitiesMessage(subLesson?.get('metadatas')?.title) }}</p>
                </ng-template>

            </mat-tab>

        </mat-tab-group>

    </main>

</ng-container>

<ng-template #spinner>
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
