<!-- TODO: la page est trop longues, trop d'intelligences différentes, il faut découper en components enfants.-->
<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>

    <!-- HEADER -->
    <div *ngIf="displayHeader" class="header p-24" fxLayout="row" fxLayoutAlign="start center">
        <h2 *ngIf="displayHeaderTitle">{{'dashboard.title' | translate}}</h2>
    </div>
    <!-- / HEADER -->

    <div *ngIf="empty" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="12px">

        <mat-icon class="iconEmptyWidget" svgIcon="chat_sad"></mat-icon>

        <label>{{ 'generic.empty_widget' | translate }}</label>
        <label>{{ 'generic.to_take_over_resources' | translate }}</label>

        <button mat-raised-button
                class="mat-accent"
                (click)="goToHelpPage()">
            {{ 'generic.read_the_tutorials' | translate }}
        </button>
    </div>
    <div *ngIf="!empty" class="content inner-scroll touch-scroll">
        <div *ngIf="!displayTabsPage()" fxLayout="column">
            <div *ngIf="haveFilters"> <!-- TODO unnecessary div -->
                <mat-toolbar class="filters group">
                    <mat-toolbar-row fxLayoutGap="24px">
                        <!-- Groups Field -->
                        <mat-form-field appMoveElement
                                        [movableElementIsActive]="orderFields"
                                        [movableElementFields]="fields" [movableElementField]="'group'"
                                        *ngIf="authService.isAtLeastTrainer() && displayFilter('group')">
                            <mat-select placeholder="{{ 'filter.group' | translate }}" [formControl]="groupControl">
                                <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                                <mat-option *ngFor="let group of groups"
                                            [value]="group">{{ group.groupname }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
            <div class="dash-widget-wrapper-parent" fxFlex fxLayout="column" fxLayoutAlign="start start">
                <!-- TODO unnecessary div flex -->
                <div class="dash-widget-wrapper" fxLayout="row wrap" fxLayout.xs="column">
                    <!-- TODO unnecessary div flex -->

                    <!-- dash-widget-project -->
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'project'"
                        *ngIf="isWidgetAllowed('project')"
                        [DashWidgetConf]="DashWidgetConfProjectsManagement"
                        class="{{ DashWidgetConfProjectsManagement.dashWidgetClassName }}">
                        <app-dashwidget-project widgetCard
                                                [projectsSubscription]="projectsSubscription"></app-dashwidget-project>
                    </app-dash-widget>

                    <!-- dash-widget-group -->
                    <app-dash-widget
                        *ngIf="isWidgetAllowed('group')"
                        [DashWidgetConf]="DashWidgetConfGroupsManagement"
                        class="{{ DashWidgetConfGroupsManagement.dashWidgetClassName }}">
                        <app-dashwidget-group widgetCard
                                              [groupsSubscription]="groupsSubscription"></app-dashwidget-group>
                    </app-dash-widget>

                    <!-- dash-widget-workgroup -->
                    <app-dash-widget
                        *ngIf="authService.hasLevel(['learner']) && isWidgetAllowed('workgroup')"
                        [DashWidgetConf]="DashWidgetConfWorkgroupsManagement"
                        class="{{ DashWidgetConfWorkgroupsManagement.dashWidgetClassName }}">
                        <app-widget-workgroup widgetCard
                                              [workgroupsSubscription]="workgroupsSubscription"></app-widget-workgroup>
                    </app-dash-widget>

                    <!-- dash-widget-recommendation -->
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'recommendation'"
                        *ngIf="isWidgetAllowed('recommendation')"
                        [DashWidgetConf]="DashWidgetConfRecommendation"
                        class="{{ DashWidgetConfRecommendation.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="recommendation" subject="recommendationListComponent" [params]="{options: {limit: 5, redirectUrl: '/lessons/list/recommendation'}}"></app-dynamic>
                    </app-dash-widget>

                    <!-- dash-widget-news -->
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'news'"
                        *ngIf="isWidgetAllowed('news')"
                        [DashWidgetConf]="DashWidgetConfNews"
                        class="{{ DashWidgetConfNews.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="news" subject="newsListComponent"></app-dynamic>
                    </app-dash-widget>

                    <!-- dash-widget-agenda-weekly -->
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'agenda-weekly'"
                        *ngIf="isWidgetAllowed('agenda-weekly')"
                        [DashWidgetConf]="DashWidgetConfAgendaWeeklyManagement"
                        class="{{ DashWidgetConfAgendaWeeklyManagement.dashWidgetClassName }}">
                        <app-dynamic widgetAccordion room="agenda" subject="agendaWeeklyComponent"></app-dynamic>
                    </app-dash-widget>

                    <!-- dash-widget-favorites-lessons  -->
                    <app-dash-widget
                        *ngIf="isWidgetAllowed('favorites-lessons')"
                        [DashWidgetConf]="DashWidgetConfFavoritesLessonsManagement"
                        class="{{ DashWidgetConfFavoritesLessonsManagement.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="lessons" subject="lessonsFavoritesComponent"></app-dynamic>
                    </app-dash-widget>

                    <!-- dash-widget-favorites-corpus -->
                    <app-dash-widget
                        *ngIf="isWidgetAllowed('favorites-corpus')"
                        [DashWidgetConf]="DashWidgetConfFavoritesCorpusManagement"
                        class="{{ DashWidgetConfFavoritesCorpusManagement.dashWidgetClassName }}">
                        <!-- <app-corpus-favorites widgetCard></app-corpus-favorites> -->
                        <app-dynamic widgetCard room="corpus" subject="corpusFavoritesComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        *ngIf="isWidgetAllowed('consulted-corpus')"
                        [DashWidgetConf]="DashWidgetConfConsultedCorpusManagement"
                        class="{{ DashWidgetConfConsultedCorpusManagement.dashWidgetClassName }}">
                        <!-- <app-corpus-favorites widgetCard></app-corpus-favorites> -->
                        <app-dynamic widgetCard room="corpus" subject="corpusConsultedComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        *ngIf="isWidgetAllowed('created-corpus')"
                        [DashWidgetConf]="DashWidgetConfCreatedCorpusManagement"
                        class="{{ DashWidgetConfCreatedCorpusManagement.dashWidgetClassName }}">
                        <!-- <app-corpus-favorites widgetCard></app-corpus-favorites> -->
                        <app-dynamic widgetCard room="corpus" subject="corpusCreatedComponent"></app-dynamic>
                    </app-dash-widget>

                    <!-- dash-widget-MessageThreads -->
                    <app-dash-widget appMoveElement
                                     [movableElementIsActive]="orderFields"
                                     [movableElementFields]="fields" [movableElementField]="'MessageThreads'"
                                     *ngIf="isWidgetAllowed('MessageThreads')"
                                     [DashWidgetConf]="DashWidgetConfMessageThreadsManagement"
                                     class="{{ DashWidgetConfMessageThreadsManagement.dashWidgetClassName }}">
                        <app-dashwidget-message widgetAccordion
                                                [messageThreadsSubscription]="messageThreadsSubscription"></app-dashwidget-message>
                    </app-dash-widget>

                    <!-- ------ dash-widget-sheetModels   &&   dash-widget-formModels ------ -->
                    <!-- ------------------------------------------------------------------------- -->
                    <div
                        *ngIf="authService.isAtLeastTrainer() && isWidgetAllowed('sheetModels') || isWidgetAllowed('formModels')"
                        fxFlex fxLayout="row wrap" fxLayout.xs="column"
                        class="dash-widget-group">
                        <!-- dash-widget-sheetModels -->
                        <app-dash-widget appMoveElement
                                         [movableElementIsActive]="orderFields"
                                         [movableElementFields]="fields" [movableElementField]="'sheetModels'"
                                         *ngIf="isWidgetAllowed('sheetModels')"
                                         [DashWidgetConf]="DashWidgetConfResearchSheetTemplate"
                                         class="{{ DashWidgetConfResearchSheetTemplate.dashWidgetClassName }}">
                            <app-widget-research-sheet-models widgetCard
                                                              [sheetTemplateSubscription]="sheetTemplateSubscription"></app-widget-research-sheet-models>
                        </app-dash-widget>

                        <!-- dash-widget-formModels -->
                        <app-dash-widget
                            appMoveElement
                            [movableElementIsActive]="orderFields"
                            [movableElementFields]="fields" [movableElementField]="'formModels'"
                            *ngIf="isWidgetAllowed('formModels')"
                            [DashWidgetConf]="DashWidgetConfFormModels"
                            class="{{ DashWidgetConfFormModels.dashWidgetClassName }}">
                            <!-- <app-widget-form-models widgetCard [formModelsSubscription]="formModelsSubscription"></app-widget-form-models> -->
                        </app-dash-widget>
                    </div>

                    <!-- ------ dash-widget-assignmentsGroup   &&   dash-widget-assignments ------ -->
                    <!-- ------------------------------------------------------------------------- -->
                    <div fxFlex fxLayout="column" class="dash-widget-group assignments-widget-Group"
                         appMoveElement
                         [movableElementIsActive]="orderFields"
                         [movableElementFields]="fields" [movableElementField]="'assignementsContainer'">
                        <!-- dash-widget-assignmentsGroup -->
                        <app-dash-widget
                            *ngIf="authService.isAtLeastTrainer() && !authService.hasLevel(['manager']) && isWidgetAllowed('assignmentsGroup')"
                            [DashWidgetConf]="DashWidgetConfAssignmentsGroup"
                            class="{{ DashWidgetConfAssignmentsGroup.dashWidgetClassName }}">
                            <app-widget-assignments-group widgetAccordion
                                                          [assignmentsGroupsSubscription]="assignmentsGroupsSubscription"></app-widget-assignments-group>
                        </app-dash-widget>

                        <!-- dash-widget-assignments all assignment-->
                        <app-dash-widget *ngIf="isWidgetAllowed('assignments')"
                                         [DashWidgetConf]="DashWidgetConfAssignments"
                                         class="{{ DashWidgetConfAssignments.dashWidgetClassName }}">
                            <app-dynamic widgetAccordion room="assignation"
                                         subject="widgetAssignmentsLessonComponent"></app-dynamic>
                        </app-dash-widget>

                        <!-- dash-widget-assignments list of learner without active assignement -->
                        <app-dash-widget *ngIf="authService.loggedUser && isWidgetAllowed('learnersWithoutAssignments')"
                                         [DashWidgetConf]="DashWidgetConfAssignmentsWithoutAssignment"
                                         class="{{ DashWidgetConfAssignmentsWithoutAssignment.dashWidgetClassName }}">
                            <app-dynamic widgetAccordion room="assignation"
                                         subject="WidgetAssignmentsLearnersWithoutActiveAssignmentComponent"
                                         [params]="assignmentsWithoutParams"></app-dynamic>
                        </app-dash-widget>

                        <!-- dash-widget-assignments closed only -->
                        <app-dash-widget *ngIf="isWidgetAllowed('assignmentsClosed')"
                                         [DashWidgetConf]="DashWidgetConfAssignmentsClosed"
                                         class="{{ DashWidgetConfAssignmentsClosed.dashWidgetClassName }}">
                            <app-dynamic widgetAccordion room="assignation" [params]="assignmentsClosedParams"
                                         subject="widgetAssignmentsLessonComponent"></app-dynamic>
                        </app-dash-widget>


                    </div>


                    <div appMoveElement
                         [movableElementIsActive]="orderFields"
                         [movableElementFields]="fields" [movableElementField]="'analytics'"
                         *ngIf="isWidgetAllowed('analytics')" class="cards card-mini" fxLayout="row" fxFlex="100"
                         fxLayoutAlign="start start" fusePerfectScrollbar>
                        <div *ngFor="let analytic of analyticsDetails" class="p-16" fxLayout="column"
                             fxLayoutAlign="center center" fxFlex="auto" fxFlex.gt-md="25" fxFlex.gt-lg="25">

                            <mat-card class="mat-elevation-z0">
                                <mat-card-content class="p-16" [ngClass]="{'learner': backgroundLearner(analytic)}"
                                                  fxLayout="column" fxLayoutAlign="center center">
                                    <label
                                        class="label">{{translateTerm(analytic, 'label', 'string')  | translate}}</label>
                                    <label
                                        class="value1">{{translateTerm(analytic, 'value', 'number')  | translate}}</label>
                                    <div class="label2 value2" fxLayout="row" fxLayoutAlign="center center">
                                        (<label>{{translateTerm(analytic, 'label2', 'string') | translate }}</label>&nbsp;:&nbsp;<label>{{translateTerm(analytic, 'value2', 'number')  | translate}}</label>)
                                    </div>
                                </mat-card-content>
                            </mat-card>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="displayTabsPage()" fxLayout="column">
            <mat-tab-group dynamicHeight="true" [attr.mat-align-tabs]="alignMainDashboardTabs()">
                <mat-tab *ngIf="isWidgetAllowed('favorites-theme') || isWidgetAllowed('created-theme')"
                         label="{{ 'activities.title_themes' | translate }}">

                    <!--                TODO: Faire un component enfant de dashboad ici.-->
                    <h2 *ngIf="dashboardService.settings.displaySentenceBeforeHeader">{{ 'dashboard.find_here_favorites' | translate }}</h2>
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'favorites-theme'"
                        *ngIf="isWidgetAllowed('favorites-theme')"
                        [DashWidgetConf]="DashWidgetConfFavoritesTheme"
                        class="{{ DashWidgetConfFavoritesTheme.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="theme" subject="themeFavoritesComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'created-theme'"
                        *ngIf="isWidgetAllowed('created-theme')"
                        [DashWidgetConf]="DashWidgetConfCreatedTheme"
                        class="{{ DashWidgetConfCreatedTheme.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="theme" subject="themeCreatedComponent"></app-dynamic>
                    </app-dash-widget>

                </mat-tab>
                <mat-tab label="{{ 'activities.title_lessons' | translate }}"
                         *ngIf="isWidgetAllowed('favorites-lessons') || isWidgetAllowed('consulted-lessons') ||isWidgetAllowed('created-lessons')">

                    <!--                TODO: Faire un component enfant de dashboad ici.-->
                    <h2 *ngIf="dashboardService.settings.displaySentenceBeforeHeader">{{ 'dashboard.find_here_lessons' | translate }}</h2>
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'favorites-lessons'"
                        *ngIf="isWidgetAllowed('favorites-lessons')"
                        [DashWidgetConf]="DashWidgetConfFavoritesLessons"
                        class="{{ DashWidgetConfFavoritesLessons.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="lessons" subject="lessonsFavoritesComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'consulted-lessons'"
                        *ngIf="isWidgetAllowed('consulted-lessons')"
                        [DashWidgetConf]="DashWidgetConfConsultedLessons"
                        class="{{ DashWidgetConfConsultedLessons.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="lessons" subject="lessonsConsultedComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'created-lessons'"
                        *ngIf="isWidgetAllowed('created-lessons')"
                        [DashWidgetConf]="DashWidgetConfCreatedLessons"
                        class="{{ DashWidgetConfCreatedLessons.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="lessons" subject="lessonsCreatedComponent"></app-dynamic>
                    </app-dash-widget>

                </mat-tab>


                <mat-tab label="{{ 'activities.title_resources' | translate }}"
                         *ngIf="isWidgetAllowed('favorites-corpus') || isWidgetAllowed('consulted-corpus') ||isWidgetAllowed('created-corpus')">
                    <h2 *ngIf="dashboardService.settings.displaySentenceBeforeHeader">{{ 'dashboard.find_here_resources' | translate }}</h2>
                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'favorites-corpus'"
                        *ngIf="isWidgetAllowed('favorites-corpus')"
                        [DashWidgetConf]="DashWidgetConfFavoritesCorpusManagement"
                        class="{{ DashWidgetConfFavoritesCorpusManagement.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="corpus" subject="corpusFavoritesComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'consulted-corpus'"
                        *ngIf="isWidgetAllowed('consulted-corpus')"
                        [DashWidgetConf]="DashWidgetConfConsultedCorpusManagement"
                        class="{{ DashWidgetConfConsultedCorpusManagement.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="corpus" subject="corpusConsultedComponent"></app-dynamic>
                    </app-dash-widget>

                    <app-dash-widget
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'created-corpus'"
                        *ngIf="isWidgetAllowed('created-corpus')"
                        [DashWidgetConf]="DashWidgetConfCreatedCorpusManagement"
                        class="{{ DashWidgetConfCreatedCorpusManagement.dashWidgetClassName }}">
                        <app-dynamic widgetCard room="corpus" subject="corpusCreatedComponent"></app-dynamic>
                    </app-dash-widget>

                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

</div>
