<ng-container *ngIf="isActivityValid(); else invalidActivityTemplate">

    <app-pretty-card>
        <ng-container data-header>
            <ng-content select="[data-header]"></ng-content>
        </ng-container>

        <div data-body class="app-pretty-card__content">
                    <h3 class="pretty-activity-card__activity-name"
                        data-e2e="activity-name">
                        {{ activityName() | timeSlotToString:{language_key: getActivitylanguage()} }}
                    </h3>
                <app-activity-sample [activity]="activity"></app-activity-sample>
                <h3 class="pretty-activity-card__typology-label">
                    {{ getUserFriendlyTypologyLabel(typology, activity) | translate }}
                </h3>
        </div>

        <ng-container data-footer>
            <ng-content select="[data-actions]"></ng-content>
        </ng-container>
    </app-pretty-card>

</ng-container>

<ng-template #invalidActivityTemplate>

    <h3 class="pretty-activity-card__activity-name"
        data-e2e="activity-name">
        {{ 'activity.bad_granule' | translate }}
    </h3>

</ng-template>