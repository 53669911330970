<ng-container *ngIf="currentCard">
    <mat-card class="activities-card">
        <mat-card-header>
            <mat-card-title>
                <app-consignes [instruction]="instruction"
                               [instructionAudio]="instructionAudio">
                </app-consignes>
            </mat-card-title>
        </mat-card-header>
        
        <mat-card-content>
            <app-voice-recorder [flashcard]="currentCard"></app-voice-recorder>
        </mat-card-content>
        
        <mat-card-footer>
            <button class="close-activity" (click)="closeActivity()">
                {{'generic.choose_another_card' | translate}}
                <mat-icon svgIcon="overview_key"></mat-icon>
            </button>
        </mat-card-footer>
    </mat-card>
</ng-container>
<ng-container *ngIf="!currentCard">
    <app-dictionary-keyboard [key]="filteredLetter" (selectedElement)="filterToApply($event)"></app-dictionary-keyboard>
    <div class="container"
         infinite-scroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="200"
         [immediateCheck]="true"
         [scrollWindow]="false"
         (scrolled)="onScroll()"
    >
        <app-dictionary-card
            *ngFor="let card of cards"
            [flashCard]="card"
            [idCardPlaying]="idCardPlaying"
            (state)="onStateChange($event, card)"
            (openCard)="openActivity(card)"
        ></app-dictionary-card>

        <audio #stream [attr.id]="currentFlashCardReading?.audio?.id" controls
               [attr.src]="currentFlashCardReading?.audio?.uri" [attr.aria-label]="currentFlashCardReading?.audio?.alt">
            Your browser does not support the <code>audio</code> element.
        </audio>
    </div>
</ng-container>
<div *ngIf="loader" class="loader" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-spinner></mat-spinner>
</div>
