<module-groups-listing [activateMetacognitionEntity]="activateMetacognitionGroup"
                       [addEntity]="newGroup"
                       [archiveEntity]="archiveGroup"
                       [dataSource]="dataSource"
                       [deArchiveEntity]="deArchiveGroup"
                       [deactivateMetacognitionEntity]="deactivateMetacognitionGroup"
                       [deleteEntity]="deleteGroup"
                       [displayedColumns]="displayedColumns"
                       [editEntity]="editGroup"
                       [options]="options"
                       [displayedFilters]="displayedFilters"
                       fxFlex
                       fxLayout="column"
></module-groups-listing>
