<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [wording]="wording"
                [wordingAudio]="wordingAudio"
                (isReading)="speakStateChanged($event, uuid)"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
            <!-- to set false qcmOption and qcuOptions and send answerStatus -->
            <app-options [activityId]="activityId" [answerStatus]="answerStatus" [contextId]="assignmentId"
                         [mode]="'textMatching'">
            </app-options>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <app-consignes
            *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
            [ngClass]="{'consigneImg': isInstructionImg()}"
            [instruction]="instruction"
            [instructionAudio]="instructionAudio"
            (isReading)="speakStateChanged($event, uuid)"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
        </app-consignes>
        <ng-container *ngIf="!!currentImageToGuess">
            <div class="even-answers">
                <ng-container *ngFor="let answer of answerToDisplay; let index = index">
                    <button *ngIf="isIntPair(index) && answerContainAudio(answer.source)"
                            [id]="answer.id"
                            class="button-audio"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setAvailableAnswerSelected(answer)">
                        <mat-icon class="button-audio__icon"
                                  svgIcon="speaker-equalizer">
                        </mat-icon>
                        <div [innerHtml]="answer?.source | stringToHtml"></div>
                    </button>
                    <button *ngIf="isIntPair(index) && !isConsigneContainMedia(answer.source)"
                            [id]="answer.id"
                            class="button-text"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setAvailableAnswerSelected(answer)">
                        <p [innerHtml]="answer?.source"></p>
                    </button>
                    <button *ngIf="isIntPair(index) && answerContainImg(answer.source)"
                            [id]="answer.id"
                            class="button-image"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setAvailableAnswerSelected(answer)"
                            [innerHtml]="answer?.source | stringToHtml">
                    </button>
                </ng-container>
            </div>
            <div class="odd-answers">
                <ng-container *ngFor="let answer of answerToDisplay; let index = index">
                    <button *ngIf="!isIntPair(index) && answerContainImg(answer.target)"
                            [id]="'answer-selected-' + answer.id"
                            class="button-image"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setSelectedAnswers(answer)"
                            [innerHtml]="answer?.target | stringToHtml">
                    </button>
                    <button *ngIf="!isIntPair(index) && !isConsigneContainMedia(answer.target)"
                            [id]="'answer-selected-' + answer.id"
                            class="button-text"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setSelectedAnswers(answer)">
                        <p [innerHtml]="answer?.target"></p>
                    </button>
                    <button *ngIf="!isIntPair(index) && answerContainAudio(answer.target)"
                            [id]="'answer-selected-' + answer.id"
                            class="button-audio"
                            [ngClass]="getStateClassCss(answer)"
                            [disabled]="answer.state === 'was-correct'"
                            (click)="setSelectedAnswers(answer)">
                        <mat-icon class="button-audio__icon"
                                  svgIcon="speaker-equalizer">
                        </mat-icon>
                        <div [innerHtml]="answer?.target | stringToHtml"></div>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <button
                class="btn-option"
                [class]="buttonState"
                [disabled]="isButtonValidateMustBeDisabled"
                (click)="validate()">
                <span>{{ 'generic.register_' + buttonState | translate }}</span>
            </button>
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"
                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>