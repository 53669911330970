<mat-spinner *ngIf="showSpinner; else sessionsList"></mat-spinner>

<ng-template #sessionsList>
    <ng-container *ngFor="let cachedUser of cachedUsers">
        <div class="session-list__cached-user">
            <button class="session-list__user-login-button"
                    (click)="login(cachedUser)">
                <span class="session-list__user-login-button-label">{{ cachedUser.nickname }}</span>
                <img *ngIf="cachedUser.picture"
                     class="session-list__user-login-button-image"
                     [src]="cachedUser.picture"
                     aria-hidden="true">
            </button>
            <button class="session-list__remove-user-button"
                    (click)="deleteCachedUser(cachedUser)"
                    [attr.aria-label]="'generic.delete' | translate">
                <mat-icon svgIcon="trash"
                          alt="supprimer">
                </mat-icon>
            </button>
        </div>
    </ng-container>
    <div>
        <button class="session-list__new-login-button"
                (click)="login(null)">
            {{ "authentication.login_other_account" | translate }}
        </button>
    </div>
</ng-template>