<div class="container join-group__container">

    <h4 class="join-group__title">{{ 'account-management.join_group_title' | translate }}</h4>

    <div class="join-group__pseudo-form">
        <mat-form-field class="join-group__group-code-field">
            <mat-label>{{'account-management.join_group_code_placeholder' | translate}}</mat-label>
            <input matInput
                   name="code"
                   [formControl]="codeControl">
            <mat-error *ngIf="codeControl?.errors?.invalidFormat">
                {{ 'generic.invalid_format_code_group' | translate }}
            </mat-error>
            <mat-error *ngIf="codeControl?.errors?.alreadyJoin">
                {{ 'generic.invalid_group_already_join' | translate }}
            </mat-error>

            <mat-error *ngIf="codeControl?.errors?.cantJoinInstitution">
                {{ 'account-management.join-group-error' | translate }}
            </mat-error>
            <mat-error *ngIf="codeControl?.errors?.invalidCode">
                {{ 'account-management.join-group-error-code-not-exist' | translate }}
            </mat-error>
        </mat-form-field>
        <button class="join-group__join-button"
                (click)="joinSchool()"
                [disabled]="!codeControl?.value || codeControl?.value === '' || codeControl?.errors">
            {{ 'account-management.join_school.join' | translate}}
            <mat-icon svgIcon="check-rounded"></mat-icon>
        </button>
    </div>

    <ng-container *ngIf="userSchools && userSchools.length > 0">
        <div *ngFor="let school of userSchools"
             class="join-group__group-container">
            <div class="join-group__user-group">
                {{ school['groupname'] }} - {{ school['code'] }}
            </div>
            <button class="join-group__leave-button"
                    (click)="leaveGroup(school.id)">
                {{ 'account-management.join_group_leave' | translate }}
                <mat-icon svgIcon="log_out"></mat-icon>
            </button>
        </div>
    </ng-container>

</div>