<ng-container *ngIf="service.state$ | async as state">
    <div class="container-voice-recorder">
<!--        <div class="pseudo-consignes" [ngClass]="{ 'consigneImg': state?.image }" *ngIf="state?.image?.uri">
            <div class="container">
                <div class="is-picture">
                    <img  [src]="state?.image?.uri" [alt]="state?.image?.title"/>
                </div>
                <span *ngIf="showTitle">{{flashcard.title}}</span>
            </div>
        </div>-->

        <div class="available-answers">
            <button (click)="incomingAudio?.paused === false? pauseIncomingAudio(): playIncomingAudio()"
                    class="bem-voice-recorder__button">
                {{ 'activities.listen_incoming_audio' | translate }}
                <mat-icon svgIcon="sound_off"></mat-icon>
            </button>
            <!-- ↑ Legacy HTML element (plateforme) ↑ -->
            <button *ngIf="!displayForSummary"
                    (click)="myAudio()"
                    class="bem-voice-recorder__button">
                {{ 'activities.listen_and_compare' | translate }}
                <mat-icon svgIcon="headphones"></mat-icon>
            </button>
        </div>
    </div>
</ng-container>
