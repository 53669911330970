<div class="ckeditor-main-container">
    <div class="ckeditor-editor-container editor-container_classic-editor">
        <div class="editor-container__editor">
            <ckeditor
                *ngIf="isLayoutReady"
                [editor]="Editor"
                [config]="config"
                [disabled]="disabled"
                [attr.required]="required"
                [ngModel]="content"
                (ngModelChange)="contentChange.emit($event)"
            />
        </div>
    </div>
</div>