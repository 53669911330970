<div fxflex="" fxlayout="column" id="entity" class="page-layout simple p-16">

    <!-- CONTENT -->
    <div class="content mat-white-bg" fusePerfectScrollbar>
        <ng-template #loading>
            <div class="center spinner-container">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
        <ng-container *ngIf="pending === false else loading">
            <mat-toolbar class="filters"><!-- TODO : Reporter dans les autres templates ou en faire un composant -->
                <mat-toolbar-row>
                    <mat-form-field>
                        <mat-label class="custom-width">{{ 'generic.filter_by_license_type' | translate }}</mat-label>
                        <mat-label>{{'generic.license_type' | translate }}</mat-label><mat-select name="license" 
                                    [formControl]="filterControls?.licenseType">
                            <mat-option [value]="'generic.all'">{{'generic.all' | translate}}</mat-option>
                            <mat-option *ngFor="let license of licenseTypes" [value]="license">
                                {{getTranslation(license) | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'generic.start_date' | translate}}</mat-label><input name="startDate"
                               [formControl]="filterControls?.startDate"
                               matInput
                               [matDatepicker]="startPicker"
                               
                               [max]="filterControls?.endDate?.value"
                        />
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>{{'generic.filter_by_end_date' | translate}}</mat-label><input name="endDate"
                               [formControl]="filterControls?.endDate"
                               matInput
                               [matDatepicker]="endPicker"
                               
                               [min]="filterControls?.startDate?.value"/>
                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field>
                        <label>
                            <mat-label>{{ 'generic.filter_by_name' | translate }}</mat-label><input type="text"
                                   [formControl]="filterControls?.name"
                                   matInput
                                   name="name"
                                   
                            >
                        </label>
                    </mat-form-field>
                    <button (click)="loadUsersWithFilters()" mat-raised-button>
                        <span>{{'generic.validate'|translate}}</span>
                    </button>

                </mat-toolbar-row>
            </mat-toolbar>
            <table mat-table
                   #table
                   [dataSource]="usersInTable"
                   *fuseIfOnDom matSort
                   [@animateStagger]="{value:'50'}">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.name' | translate }}
                    </th>
                    <td [ngClass]="row.status ==='0'?'lockedUser':''" mat-cell *matCellDef="let row">
                        {{row.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.role' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        <ng-container *ngFor="let role of associateRoleWithRoleMapping(row.roles)">{{role}}&nbsp;
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="license">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.license_type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">


                        <mat-chip-set aria-label="licenses type">
                            <mat-chip class="{{ licenseType(row) }}"
                                      [ngClass]="associateRoleWithRoleMapping(row.roles)">
                                {{ getTranslation(licenseType(row) ? licenseType(row) : 'free') | translate }}
                            </mat-chip>
                        </mat-chip-set>

                    </td>
                </ng-container>

                <ng-container matColumnDef="institution">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.institution' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{showLabelInstitutionWithUai(getUserInstitution(row))}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.licenseEndDate' | translate }}
                    </th>
                    <td class="icon-custom" mat-cell *matCellDef="let row">
                        {{licenseEndDate(row)}}
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_lock' | translate}}</span></th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container>
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                    (click)="$event.stopPropagation();">
                                <mat-icon svgIcon="submenu"></mat-icon>
                            </button>
                            <mat-menu #moreMenu="matMenu">
                                <button mat-menu-item aria-label="edit"
                                        (click)="edit(row)">
                                    <mat-icon svgIcon="edit"></mat-icon>
                                    <span>{{ 'assignment.edit' | translate }}</span>
                                </button>

                                <button mat-menu-item aria-label="lockUser"
                                        (click)="lockUserAccess(row)">
                                    <mat-icon svgIcon="delete"></mat-icon>
                                    <span>{{ 'licence-management.lock-unlock-user' | translate }}</span>
                                </button>
                            </mat-menu>
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="{'selected': false}"
                    matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>
            <div mat-dialog-actions>
                <button mat-raised-button class="add-user add mat-button-base" (click)="createUser()">
                    <span>{{'generic.add-user' | translate}}</span>
                </button>
            </div>
            <mat-paginator
                [length]="countEntities"
                [pageIndex]="pageIndex"
                [pageSizeOptions]="pageRangeOptions"
                (page)="onPaginateChange($event)">
            </mat-paginator>
        </ng-container>
    </div>


</div>