<div class="title">
    <h2>
        {{'generic.wins-summary_title' | translate}}
    </h2>
    <button mat-mini-fab
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<div class="flexContainer">
    <!-- rows titles -->
    <div class="flexRowTitle">
        <div> {{'generic.step' | translate}}</div>
        <div clas
             s="bronze">{{'generic.bronze_star' | translate}} </div>
        <div class="argent">{{'generic.silver_star' | translate}}</div>
        <div>{{'generic.gold_star' | translate}}</div>
        <div>{{'generic.platinium_star' | translate}}</div>
    </div>
    <div class="flexRow" *ngFor="let win of data">
        <!--medal-->
        <app-medal [medal]="win.medal"></app-medal>

        <!-- star wins-->
        <app-star [level]="win.bronze!=='locked'?'bronze':'locked'" [score]="win.bronze"
                  [disabledStar]="win.bronze===''"></app-star>

        <app-star [level]="win.silver!=='locked'?'silver':'locked'" [score]="win.silver"
                  [disabledStar]="win.silver===''"></app-star>

        <app-star [level]="win.gold!=='locked'?'gold':'locked'" [score]="win.gold"
                  [disabledStar]="win.gold===''"></app-star>

        <app-star [level]="win.platinium!=='locked'?'platinium':'locked'" [score]="win.platinium"
                  [disabledStar]="win.platinium===''"></app-star>
    </div>
</div>




