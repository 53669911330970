<div mat-dialog-title>
    <h2>{{ dialogTitle | translate }}</h2>
    <button class="close" (click)="dialogRef.close()" aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <ng-template #loading>
        <div mat-dialog-content>
            <div class="spinner-wrapper">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="isLoading === false; else loading">
        <form *ngIf="entityForm" [formGroup]="entityForm" name="entityForm" novalidate>

            <!-- Form Name Field -->
            <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('title')">
                <mat-form-field fxFlex>
                    <mat-label>{{ 'activities.lesson.name' | translate }}</mat-label><input matInput name="title" formControlName="title"
                            [required]="displayRequiredField('title')">
                </mat-form-field>
            </div>

            <input type="hidden" name="tagModified" formControlName="tagModified">

            <!--level Field (lamorim : target age = educational level )-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('target-age') || displayField('educationalLevel')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ displayField('target-age') ? ('activities.target_age' | translate) : ('generic.level' | translate) }}</mat-label><mat-select
                                formControlName="educationalLevel" [required]="displayRequiredField('educationalLevel')">
                        <mat-option *ngFor="let level of levelsDataEntity" [value]="level.id">
                            {{level.get('label')}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--level skill = period-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('skill')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.skills' | translate }}</mat-label><mat-select  formControlName="skills" multiple [required]="displayRequiredField('skill')">
                        <mat-option *ngFor="let skill of skillsDataEntity" [value]="skill.id">
                            {{skill.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--  (selectionChange)="selectLearners($event)" multiple>-->
            </div>

            <!--level = difficulty-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('difficulty')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.difficulty' | translate }}</mat-label><mat-select  formControlName="difficulty" [required]="displayRequiredField('difficulty')">
                        <mat-option *ngFor="let difficulty of difficultiesDataEntity" [value]="difficulty.id">
                            {{difficulty.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--usage-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('usage')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.usage' | translate }}</mat-label><mat-select  formControlName="usage" [required]="displayRequiredField('usage')" [(value)]="storedUsage" multiple>
                        <mat-option *ngFor="let usage of usageDataEntity" [value]="usage.id">
                            {{usage.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- assignation_type -->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('assignation_type')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.type' | translate }}</mat-label><mat-select  formControlName="assignation_type" [required]="displayRequiredField('assignation_type')">
                        <mat-option *ngFor="let assignation_type of assignation_typeDataEntity" [value]="assignation_type.id">
                            {{ localizedType(assignation_type.attributes.label) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- chapters -->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('chapters')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.chapters' | translate }}</mat-label><mat-select  formControlName="chapters" [required]="displayRequiredField('chapters')">
                        <mat-option *ngFor="let chapters of chaptersDataEntity" [value]="chapters.id">
                            {{chapters.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!--themes = theme-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('theme')">
                <mat-form-field fxFlex="100">
                    <mat-label>{{'generic.theme' | translate }}</mat-label><mat-select  formControlName="theme" [required]="displayRequiredField('theme')">
                        <mat-option *ngFor="let theme of themesDataEntity" [value]="theme.id">
                            {{theme.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- Groups field-->
            <div fxLayout="row" fxLayoutAlign="space-around center" *ngIf="displayField('tags')">
                <mat-form-field fxFlex="100">
                    <mat-chip-grid #chipList>
                        <mat-chip-row *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable"
                                  (removed)="remove(chip)">
                            {{chip.label}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip-row>
                        <mat-label>{{ 'activities.lesson.tags' | translate }}</mat-label><input  [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="add($event)">
                    </mat-chip-grid>
                </mat-form-field>
            </div>

            <!-- Short Description Field -->
            <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('description')">
                <mat-form-field fxFlex class="description-field">
                <mat-label>{{ 'activities.lesson.short_description' | translate }}</mat-label><textarea matInput name="description" formControlName="description" rows="10"
                           [required]="displayRequiredField('description')"></textarea>
                </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutAlign="start stretch" *ngIf="displayField('method')">
                <mat-form-field fxFlex>
                    <mat-label>{{ 'activities.lesson.method.assignated' | translate }}</mat-label><mat-select name="licenseContent" formControlName="licenseContent"
                                 [required]="displayRequiredField('method')">
                        <mat-option [value]="method.id" *ngFor="let method of methods">
                            {{ method?.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="mat-hint">{{'activities.lesson.method.informations' | translate}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('source-author')">
                <mat-form-field fxFlex>
                    <mat-label>{{ 'generic.source_author' | translate }}</mat-label><input matInput name="source-author" formControlName="source-author"
                            [required]="displayRequiredField('source-author')">
                </mat-form-field>
            </div>
        </form>

        <!--upload local image-->
        <ng-container *ngIf="displayField('thumbnail')">
            <div class="mat-form-field">
                <div class="mat-form-field-appearance-legacy">
                    <div class="mat-form-field-wrapper">
                        <div class="mat-form-field-flex">
                            <div class="mat-form-field-infix">
                                <span class="mat-form-field-label-wrapper">
                                    <label class="mat-form-field-empty mat-form-field-label">
                                        {{ 'generic.thumbnail' | translate }}
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center text-center">
                <span>{{ 'corpus.import_url.informations' | translate }}</span><br>
                <em><small>{{ 'corpus.import_url.details' | translate }} {{ imageUploadExtenssion }}</small></em>
                <ngx-file-drop (onFileDrop)="dropped($event, fileType.image)">
<!--                    TODO Déprécié headertext="{{ 'corpus.upload_dragndrop_message' | translate }}"-->
<!--                    TODO Déprécié customstyle="upload-dragndrop"-->
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <button mat-raised-button mat-primary class="add-file-button" (click)="openFileSelector()"
                                aria-label="Add file">
                            <span>{{ 'generic.browse' | translate }}</span>
                        </button>
                    </ng-template>
                </ngx-file-drop>
            </div>

            <div class="file-uploader">
                <input hidden type="file" [attr.accept]="imageUploadFormatAllowed" #fileInput
                       (change)="onFileChanged($event, fileType.image)" id="fileToUpload"/>
            </div>
            <!--file thumbnail name-->
            <div class="center text-center">
                <em><small> {{ currentThumbnail | translate}}</small></em>
            </div>

            <!--alert not supported format-->
            <div *ngIf="!isFileFormatAllowed">
                <span class="mat-error">{{'activities.thumbnail.format' | translate}}</span>
            </div>

            <div class="center text-center">
                <!--TODO TRANSLATION to store when prototype will be done-->
                <span>{{ 'corpus.import_corpus.informations' | translate }}</span><br>
                <!--my corpus-->
                <button mat-raised-button class="save-button mat-accent"
                        (click)="openCorpus(true) ">{{'corpus.title.user' | translate}}</button>
                <!--corpus géneral-->
                <button mat-raised-button class="save-button mat-accent"
                        (click)="openCorpus(false) ">{{'corpus.title.generic' | translate}}
                </button>
                <!--file thumbnail corpus title-->
                <div class="center text-center" *ngIf="thumbnail">
                    <em><small> {{ thumbnail?.title | translate}}</small></em>
                </div>
            </div>

        </ng-container>

        <!--upload local pdf-->
        <ng-container *ngIf="displayField('files')">

            <div class="mat-form-field mat-form-field-wrapper mat-form-field-infix w-100-p">
                <span class="mat-form-field-label-wrapper">
                    <label class="mat-form-field-empty mat-form-field-label">
                        {{ 'generic.fileUpload' | translate }}
                    </label>
                </span>
            </div>

            <div class="center text-center">
                <span>{{ 'corpus.import_url.informations' | translate }}</span><br>
                <em><small>{{ 'corpus.import_url.details' | translate }} pdf</small></em>
                <ngx-file-drop (onFileDrop)="dropped($event, fileType.document)">
<!--                    TODO Déprécié customstyle="upload-dragndrop"-->
<!--                    TODO Déprécié headertext="{{ 'corpus.upload_dragndrop_message' | translate }}"-->
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <button mat-raised-button class="add-file-button" (click)="openFileSelector()"
                                aria-label="Add file">
                            <span>{{ 'generic.browse' | translate }}</span>
                        </button>
                    </ng-template>
                </ngx-file-drop>
            </div>

            <div class="file-uploader">
                <input hidden type="file" accept="application/pdf" #fileDocInput
                       (change)="onFileChanged($event, fileType.document)" id="docToUpload"/>
            </div>
            <!--file pdf name-->
            <div class="center text-center">
                <em><small> {{ currentDocument | translate}}</small></em>
            </div>

            <!--alert not supported format-->
            <div *ngIf="!isFileFormatDocumentAllowed">
                <span class="mat-error">{{'activities.document.format' | translate}}</span>
            </div>

        </ng-container>

    </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()" class="save-button mat-accent"
            [disabled]="entityForm?.invalid || isLoading" aria-label="SAVE">
        {{ 'generic.save' | translate }}
    </button>
</mat-dialog-actions>