<div>
    <p>{{ description | loadTemplateData$ | async }}</p>

    <ng-container *ngFor="let collection of collections">
        <button mat-raised-button (click)="navigate(collection)" class="inverted">
            <img [src]="collection.image" alt="">
            <div>
                <h3>{{ collection.title }}</h3>
                <p>{{ collection.description }}</p>
            </div>
        </button>
    </ng-container>
</div>
