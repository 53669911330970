<div *ngIf="graphGenerated" fxLayout="column" fxLayoutAlign="center center" class="graph-wrapper">
  <canvas
          #chart
          baseChart
          [type]="chartType"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [legend]="legend"
          (chartClick)="onChartClick($event)"
          tabindex="0"
          (keydown)="handleKeyDown($event)">
  </canvas>
  <app-graph-details-modal
          *ngIf="showTooltip"
          [infoSettings]="infoSettings">
  </app-graph-details-modal>
</div>
