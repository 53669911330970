import { Routes } from '@angular/router';
import {BasicPageListAsCardsComponent} from 'fuse-core/components/basic-page/basic-page-list-as-cards/basic-page-list-as-cards.component';
import {FullPageComponent} from 'fuse-core/components/basic-page/full-page/full-page.component';
import {CollectionComponent} from 'fuse-core/components/collection/collection.component';
import {DefaultRouteComponent} from 'fuse-core/components/default-route/default-route.component';
import {HomePageComponent} from 'fuse-core/components/home-page/home-page.component';
import {InformationsLegalesComponent} from 'fuse-core/components/informations-legales/informations-legales.component';
import {IsAllowedBySettings, IsUserLogged} from '../../../../src/app/guards';
import {MaintenanceComponent} from '../../../../src/app/maintenance/maintenance.component';

export const routes: Routes = [
{
        path: '',
        component: DefaultRouteComponent,
        canActivate: [IsUserLogged, IsAllowedBySettings],
    },
    {
        path: 'home',
        component: HomePageComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'further',
        canActivate: [IsUserLogged, IsAllowedBySettings],
        children: [
            {
                path: ':alias',
                component: HomePageComponent,
                canActivate: [IsAllowedBySettings],
            }
        ]
    },
    {
        title: 'aventure',
        path: 'page/aventure',
        component: FullPageComponent,
        canActivate: [IsUserLogged, IsAllowedBySettings]
    },
    {
        path: 'page/:alias',
        component: FullPageComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'informations-legales',
        component: InformationsLegalesComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'page-as-card-list/:alias',
        component: BasicPageListAsCardsComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'collection/:alias',
        component: CollectionComponent,
        canActivate: [IsAllowedBySettings],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
    },
];
