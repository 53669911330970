<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>
    <button *ngIf="embedded"
            class="close"
            (click)="close()"
            [attr.aria-label]="'generic.close' | translate"
            [matTooltip]="'generic.close' | translate">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
    <div class="content inner-scroll">
        <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="notepad-list"></app-banner-info>
        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <app-search-filters (launchSearch)="refreshNotes($event).subscribe()" [countEntities]="paginator?.count"
                                    [fields]="filters"
                                    [overrideEndpointFilterName]="[
                                        {originalFilterName: 'lessons', targetFilterName: 'associated_node'}
                                    ]"
                                    [customFieldName]="[{field: 'metadatas_title', value: 'generic.title'}]"
                ></app-search-filters>
            </mat-toolbar-row>
        </mat-toolbar>

        <div *ngIf="isLoadingDone; then listBlock; else loadingBlock"></div>

        <ng-template #listBlock>
                <div class="notepad-card-list">
                    <div class="content">
                        <div class="fake-card">
                            <div (click)="createNote($event)"
                                 role="button"
                                 (keyup.enter)="createNote($event)"
                                 tabindex="0"
                                 *ngIf="isLoadingDone" >
                                <mat-card class="mat-card">
                                    <mat-card-content class="mat-card-content">
                                        <mat-icon svgIcon="add"></mat-icon>
                                        <h3>{{'notepad.add_note' | translate}}</h3>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <div *ngFor="let notepad of notepads">
                            <app-notepad-card (hasChanged)="onNotepadChanged(notepad)"
                                              [notepadBasicSearchEntity]="notepad"
                                              [embedded]="embedded"
                                              [forceAssociationOnSave]="forceAssociationOnSave"
                                              (actionTriggered)="onActionTriggered($event)"
                                              class="card-split-media-info"></app-notepad-card>
                        </div>
                    </div>
                </div>
                <mat-paginator (page)="onPaginateChange($event)" [length]="paginator.count"
                               [pageIndex]="paginator.page -1"
                               [pageSize]="notepadListRange"></mat-paginator>
        </ng-template>

        <ng-template #loadingBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>
</div>
