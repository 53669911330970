<header mat-dialog-title>
    <h2>
        {{ "corpus.share_to_groups_title" | translate }}
    </h2>

    <button class="close" mat-dialog-close aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</header>

<main mat-dialog-content class="corpus-upload">
    <h3>
        {{ "corpus.share_to_groups_content_title" | translate }}
    </h3>

    <mat-chip-set>
        <mat-chip
            *ngFor="let group of selectedGroupList$ | async"
            (removed)="service.removeGroup(group)"
        >
            {{ group.label }}
            <mat-icon matChipRemove svgIcon="cross"></mat-icon>
        </mat-chip>
    </mat-chip-set>

    <mat-form-field>
        <input
            type="text"
            [placeholder]="'corpus.share_to_groups_input_placeholder' | translate"
            matInput
            [matAutocomplete]="auto"
        />
        <mat-icon matSuffix>search</mat-icon>

        <mat-autocomplete
            [displayWith]="displayFn"
            (optionSelected)="service.selectGroup($event)"
            autoActiveFirstOption
            #auto="matAutocomplete"
        >
            <mat-option *ngFor="let group of groupList$ | async" [value]="group">
                {{ group.label }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <footer mat-dialog-actions class="pt-24">
        <button
            (click)="validate()"
            mat-raised-button
            mat-dialog-close
            color="accent"
            class="submit-button"
        >
            <span>{{ "generic.validate" | translate }}</span>
        </button>
    </footer>
</main>