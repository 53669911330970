<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">
  <table
          mat-table
          #table
          [dataSource]="dataSource"
          [@animateStagger]="{value:'50'}"
          fxFlex="100"
          *ngIf="!empty">

    <!-- Name research-sheet template Column -->
    <ng-container matColumnDef="nameResearchSheetTemplate">
      <th mat-header-cell *matHeaderCellDef>{{ 'projects-management.name_research_sheet' | translate }}</th>
      <td mat-cell *matCellDef="let entity">
        <span class="text-truncate text-bold">{{entity.name}}</span>
      </td>
    </ng-container>

    <!-- Name author research-sheet template Column -->
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef>{{ 'generic.author' | translate }}</th>
      <td mat-cell *matCellDef="let entity">
        <span class="text-truncate">{{ getNameAuthor(entity)}}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
        class="entity"
        matRipple
        [@animate]="{value:'*',params:{y:'100%'}}"
        (click)="getLinkTemlplate(entity)">
    </tr>
  </table>

  <div *ngIf="empty" class="emptyResourceLabel">{{ 'projects-management.research_sheet_empty_template' | translate }}</div>
</div>
