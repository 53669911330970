<section class="reward-page"
         [ngClass]="{'reward-page--next-level': showNextLevelRewarded}">

    <!-- Visible when nextLevel is false / Hidden when nextLevel is true (toggled in CSS to maintain layout) -->
    <div class="reward-page__reward-wrapper">
        <h1 class="reward-page__title">{{ 'activities.well_done' | translate }}</h1>
        <app-dynamic [room]="'gamification'"
                     [subject]="'AnimatedCanvasInRewardPage'"
                     [params]='{noDance: reward === 0}'>
        </app-dynamic>
        <app-end-screen-score [reward]="reward"
                              [coinsToShow]="coinsToShow">
        </app-end-screen-score>
    </div>

    <!-- Hidden when nextLevel is false / Visible when nextLevel is true (toggled in CSS to maintain layout) -->
    <div class="reward-page__level-wrapper">
        <h1 class="reward-page__title">{{ 'activities.new_level' | translate }} {{newLevel}}!</h1>
        <mat-icon class="reward-page__crown-icon"
                  svgIcon="crown">
        </mat-icon>
    </div>

    <!-- Always visible -->
    <button class="reward-page__next-button"
            [ngClass]="'reward-page__next-button--reward-count-' + reward"
            (click)="next()">
        {{ 'generic.next'| translate }}
    </button>

</section>