
<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">
  <table mat-table
         #table
         *ngIf="empty"
         [dataSource]="dataSource"
         fxFlex="100"
         [@animateStagger]="{value:'50'}">

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
        {{ 'generic.type' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" fxHide.xs>
        <span class="pastille" [ngClass]="row.attributes?.type_term?.label" *ngIf="row.attributes?.type_term">
            <mat-icon svgIcon="{{row.attributes?.type_term?.label}}"
                      title="{{ 'assignment.type.' + row.attributes?.type_term?.label | translate }}">
            </mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="assigned_node_title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
        {{ 'generic.title' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" fxHide.xs>
        {{getTitleAssignment(row)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="start_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
        {{ 'generic.start_date' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
          <span *ngIf="row.attributes?.type_term?.label !== 'assessment'">
              {{localeDate(row.attributes?.dates?.value)}}
          </span>
          <span *ngIf="row.attributes?.type_term?.label === 'assessment'">
              {{localeTime(row.attributes?.dates?.value)}}
          </span>
      </td>
    </ng-container>


    <ng-container matColumnDef="end_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
        {{ 'generic.deadline' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
          <span *ngIf="row.attributes?.type_term?.label !== 'assessment'">
              {{localeDate(row.attributes?.dates?.value2)}}
          </span>
          <span *ngIf="row.attributes?.type_term?.label === 'assessment'">
              {{localeTime(row.attributes?.dates?.value2)}}
          </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="assigned_user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
        {{ 'generic.learner' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-md>
        {{row.attributes.assignated_user?.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
        {{ 'generic.label_group' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>

      </td>
    </ng-container>

    <ng-container matColumnDef="consulted">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'generic.consulted' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" (click)="openAssignment(row)">
        <mat-icon class="logo-icon-svg" svgIcon="check" [ngClass]="{'active': getConsultedAssignment(row) }"></mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="workgroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
        {{ 'generic.label_workgroup' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>

      </td>
    </ng-container>


    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md mat-sort-header>
        {{ 'generic.progress' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
        {{getProgress(row)}}%
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        matRipple
        [@animate]="{value:'*',params:{y:'100%'}}"
        (click)="openAssignment(row)">
    </tr>
  </table>

  <div *ngIf="!empty" class="emptyResourceLabel">{{ 'generic.assignment_empty_granule' | translate}}</div>
</div>
