<div class="content">

    <a class="bem-app-lessons-in-chapters-list__back"
       role="button"
       [routerLink]="parentPageUrl">
        <mat-spinner *ngIf="isChapterLoading$ | async"></mat-spinner>
        <mat-icon svgIcon="back"></mat-icon>
        {{chapterName$ | async}}
    </a>

    <ng-container *ngIf="lessonsWithAssignments$ | async as lessonsWithAssignments">
        <ng-container *ngFor="let batch of lessonsWithAssignments">
            <app-button-list-card [title]="batch.lesson.get('metadatas').title"
                                  [buttons]="getChildrenAsButtons(batch)">
            </app-button-list-card>
        </ng-container>
    </ng-container>

    <div *ngIf="isLessonsLoading$ | async">
        <mat-spinner></mat-spinner>
    </div>
    
</div>