<app-media-upload
    [media]="audio"
    (mediaChange)="handleFileUploadSuccess($event)"
    (mediaSoftDelete)="handleFileUploadSoftDelete($event)"
    [acceptedTypes]="acceptedTypes"
    [uploadMethod]="uploadFile"
    [placeholderMediaType]="placeholderMediaType"
>
    <audio data-media [src]="audio?.uri" controls></audio>
</app-media-upload>
