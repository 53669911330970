<div class="page-layout simple fullwidth p-16" fusePerfectScrollbar>
    <form name="analyticsForm" fxLayoutGap="16px" fxFlex fxLayout="column">
      <div fxLayout="column" *ngFor="let analytic of analyticsListing; let index = index">
        <label class="pb-16">{{ analytic?.title | translate}}&nbsp;
          <span *ngIf="analytic?.link_relative_url === 'csv-export?type=assignations-count'">({{ 'analytics.option_by_chapters'| translate}})</span>
          <span *ngIf="analytic?.link_relative_url === 'csv-export?type=active-users'">({{ 'analytics.option_by_institution'| translate}})</span>
        </label>
        <div fxLayout="row" fxLayoutGap="12px">
          <mat-form-field fxFlex="20">
            <input name="startControl" matInput [matDatepicker]="picker" placeholder="{{'generic.start_period' | translate}}" [formControl]="startControls[index]">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field fxFlex="20">
            <input name="endControl" matInput [matDatepicker]="duepicker" placeholder="{{'generic.end_period' | translate}}" [formControl]="endControls[index]">
            <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
            <mat-datepicker #duepicker></mat-datepicker>
          </mat-form-field>
          <div fxLayout="column" fxLayoutGap="12px" fxFlex="30">
            <mat-form-field required *ngIf="analytic?.link_relative_url === 'csv-export?type=assignations-count'">
              <mat-select placeholder="{{'licensing.method.choose' | translate}}" [formControl]="methodControl">
                <mat-option  [value]="'all'">
                  {{'generic.allf' | translate}}
                </mat-option>
                <mat-option *ngFor="let method of licensingMethods" [value]="method">
                  {{method.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

             <mat-checkbox [formControl]="institutionControl"
                            *ngIf="analytic?.link_relative_url === 'csv-export?type=active-users'">{{ 'analytics.option_by_institution_label' | translate }}&nbsp;({{ 'analytics.long_to_export' | translate }})</mat-checkbox>

            <button  mat-raised-button (click)="generate(analytic, index)">{{ 'generic.download' | translate }}</button>
          </div>
        </div>
      </div>
    </form>
</div>
