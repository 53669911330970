<div id="mainmenuelem" class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

    <!-- Vertical Navigation Layout -->
    <ng-container *ngIf="layout === 'vertical'">

        <div class="navbar-content">

            <ng-container *ngFor="let item of navigation">
                <fuse-nav-vertical-group
                    *ngIf="item.type === 'group'"
                    [ngClass]="'wrapper-menu-group-' + item.id + ' ' + (item.class?.join(' ') || '')"
                    [item]="item"
                ></fuse-nav-vertical-group>

                <fuse-nav-vertical-collapsable
                    *ngIf="item.type === 'collapse'"
                    [ngClass]="'wrapper-menu-collapsable-' + item.id + ' ' + (item.class?.join(' ') || '')"
                    [item]="item"
                ></fuse-nav-vertical-collapsable>

                <fuse-nav-vertical-item
                    *ngIf="item.type === 'item'"
                    [ngClass]="'wrapper-menu-item-' + item.id + ' ' + (item.class?.join(' ') || '')"
                    [item]="item"
                ></fuse-nav-vertical-item>
            </ng-container>

        </div>
        <div class="menu-link-block">
            <ng-container *ngFor="let item of navigation">
                <ng-container *ngIf="item.type==='link'">
                    <a class="menu-link" *ngIf="isInternalUrl(item)" [routerLink]="[item.url]">
                        <mat-icon class="nav-link-icon svg-icon" *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                        <span class="nav-link-title">{{item.translate | translate }}</span>
                    </a>
                    <a class="menu-link" *ngIf="!isInternalUrl(item)" href="{{item.url}}" target="_blank">
                        <mat-icon class="nav-link-icon svg-icon" *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                        <span class="nav-link-title">{{item.translate | translate }}</span>
                    </a>
                </ng-container>
            </ng-container>
        </div>

    </ng-container>
    <!-- / Vertical Navigation Layout -->

    <!-- Horizontal Navigation Layout -->
    <ng-container *ngIf="layout === 'horizontal'">

        <ng-container *ngFor="let item of navigation">

            <fuse-nav-horizontal-collapsable *ngIf="item.type==='group'" [item]="item"></fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-collapsable *ngIf="item.type==='collapse'" [item]="item">
            </fuse-nav-horizontal-collapsable>
            <fuse-nav-horizontal-item *ngIf="item.type==='item'" [item]="item"></fuse-nav-horizontal-item>
            <a class="menu-link" *ngIf="item.type==='link'" [routerLink]="[item.url]">
                <mat-icon class="nav-link-icon svg-icon" *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
                <span class="nav-link-title">{{item.translate | translate }}</span>
            </a>
        </ng-container>

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>