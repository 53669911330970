<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="page-layout simple fullwidth mat-white-bg">
    <div class="content">
        <div *ngIf="isLoadingDone else loadingBlock"
             class="content-editor">

            <div class="notepad-header">
                <h1>{{notepadTitle}}</h1>
                <button class="close"
                        (click)="tryToClose()"
                        [attr.aria-label]="'generic.close' | translate"
                        [matTooltip]="'generic.close' | translate">
                    <mat-icon svgIcon="cross"></mat-icon>
                </button>
            </div>

            <div class="notepad-content">
                <app-wysiwyg [(content)]="text"
                             [disabled]="isSaving">
                </app-wysiwyg>
            </div>

            <div class="notepad-action">
                <button mat-raised-button
                        (click)="reset($event)"
                        [disabled]="this.canBeReset() === false">
                    {{'notepad.clear_all' | translate}}
                </button>
                <button class="mat-accent"
                        mat-raised-button
                        (click)="save($event)"
                        [disabled]="this.canBeSave() === false">
                    <mat-spinner *ngIf="this.isSaving"></mat-spinner>
                    <span>{{'generic.save' | translate}}</span>
                </button>
            </div>

        </div>
    </div>
</div>