<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>{{ title }}</h3>
        </mat-card-title>

        <button [matMenuTriggerFor]="menu"
                ariaLabelTranslate="{{'generic.menu' | translate}}"
                class="mat-icon-button"
                mat-icon-button matTooltip="{{'generic.menu' | translate}}">
            <mat-icon svgIcon="submenu"></mat-icon>
            <mat-menu #menu="matMenu">
                <button (click)="edit($event)" mat-menu-item>
                    <mat-icon svgIcon="edit"></mat-icon>
                    {{ 'generic.edit' | translate }}
                </button>
                <button (click)="delete($event)" mat-menu-item>
                    <mat-icon svgIcon="delete"></mat-icon>
                    {{ 'generic.delete' | translate }}
                </button>
            </mat-menu>
        </button>
    </mat-card-header>

    <img alt="" mat-card-image src="{{ imageUri }}">

    <mat-card-content>
        <dl class="dl-listitem-inline" role="list">
            <dt>{{'generic.createdDate' | translate}}</dt>
            <dd>{{creationDate}}</dd>
            <dt>{{'generic.changedDate' | translate}}</dt>
            <dd>{{lastUpdate}}</dd>
            <ng-container *ngIf="associatedLessonIsLoading">
                <mat-spinner diameter="20"></mat-spinner>
            </ng-container>

            <ng-container *ngIf="!associatedLessonIsLoading && associatedLessons?.length > 0">
                <dt>{{'mindmap.linked_lesson' | translate}}</dt>
                <dd>
                    <mat-chip-set>
                        <mat-chip
                            *ngFor="let associatedLesson of associatedLessons"
                            (click)="goToLesson(associatedLesson.id)"
                        >{{associatedLesson.title}}
                            <div>
                            <mat-icon matChipRemove svgIcon="cross" (click)="dissociateLesson($event, associatedLesson)">
                        </mat-icon>
                            </div>
                        </mat-chip>
                    </mat-chip-set>
                </dd>
            </ng-container>
        </dl>
    </mat-card-content>
</mat-card>