// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entity-form-dialog .mat-dialog-container .mat-toolbar {
  min-height: initial;
}
.entity-form-dialog .mat-dialog-container .toolbar-bottom {
  height: auto;
}
.entity-form-dialog .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}
.entity-form-dialog .mat-radio-button ~ .mat-radio-button {
  height: 53px;
  width: 53px;
}
.entity-form-dialog .mat-radio-button {
  /* this rule for the color of the center of your radio button :*/
}
.entity-form-dialog .mat-radio-button .mat-radio-outer-circle,
.entity-form-dialog .mat-radio-button .mat-radio-inner-circle {
  height: 53px;
  width: 53px;
  border: none;
  background: transparent;
}
.entity-form-dialog .mat-radio-button.mat-accent {
  /*circle which appears when you click on the radio button :*/
}
.entity-form-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: transparent;
}
.entity-form-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  box-shadow: initial;
  box-sizing: initial;
}
.entity-form-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background: transparent;
}
.entity-form-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-listing/groups-form/groups-form.component.scss"],"names":[],"mappings":"AAMQ;EACI,mBAAA;AALZ;AAQQ;EACI,YAAA;AANZ;AAUI;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AARR;AAYI;EACE,YAAA;EACA,WAAA;AAVN;AAaE;EAEE,gEAAA;AAZJ;AAaI;;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AAXN;AAcI;EAaE,4DAAA;AAxBN;AAYM;EACE,uBAAA;AAVR;AAaQ;EACE,mBAAA;EACA,mBAAA;AAXV;AAaQ;EACE,uBAAA;AAXV;AAgBQ;EACE,6BAAA;AAdV","sourcesContent":["@import \"@fuse/scss/fuse\";\n\n.entity-form-dialog {\n\n    .mat-dialog-container {\n\n        .mat-toolbar {\n            min-height: initial;\n        }\n\n        .toolbar-bottom {\n            height: auto;\n        }\n    }\n\n    .dialog-content-wrapper {\n        max-height: 85vh;\n        display: flex;\n        flex-direction: column;\n    }\n\n\n    .mat-radio-button ~ .mat-radio-button {\n      height: 53px;\n      width: 53px;\n    }\n\n  .mat-radio-button{\n\n    /* this rule for the color of the center of your radio button :*/\n    .mat-radio-outer-circle ,\n    .mat-radio-inner-circle {\n      height: 53px;\n      width: 53px;\n      border: none;\n      background: transparent;\n    }\n\n    &.mat-accent{\n      .mat-radio-inner-circle{\n        background: transparent;\n      }\n      &.mat-radio-checked  {\n        .mat-radio-outer-circle {\n          box-shadow: initial;\n          box-sizing: initial;\n        }\n        .mat-radio-inner-circle {\n          background: transparent;\n        }\n      }\n      /*circle which appears when you click on the radio button :*/\n      .mat-radio-ripple {\n        .mat-ripple-element {\n          background-color:transparent;\n        }\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
