<form class="search-filters__form"
      fxFlex
      fxLayout="column"
      name="searchForm">
    <div class="search-filters__container"
         fxFlex="1 1 100"
         fxLayout="row wrap"
         fxLayoutAlign="start center"
         fxLayoutGap="24px">
        <mat-form-field appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'type'"
                        *ngIf="displayField('type')">
            <!--TODO replace 'projects-management.lesson_space' by a value coming form input because it could have two value-->
            <mat-label>{{ getFieldTerms('type')!=='' ? (getFieldTerms('type') | translate): ('generic.type_global_corpus' | translate) }}</mat-label><mat-select name="typeFilter"
                        [formControl]="controls['typeFilter'].formControl"
                        >
                <mat-option value="all">
                    <mat-icon *ngIf="displayedFiltersIcons" svgIcon="allTypes" title="audio"></mat-icon>
                    {{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let type of customLists['types']" [value]="type.id">
                    <span *ngIf="type.id === 'audio'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="audio"
                                                                title="audio"></mat-icon>
                        {{ 'generic.audio' | translate }}</span>
                    <span *ngIf="type.id === 'document'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="document"
                                                                   title="audio"></mat-icon>
                        {{ 'generic.document' | translate }}</span>
                    <span *ngIf="type.id === 'image'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="image"
                                                                title="audio"></mat-icon>
                        {{ 'generic.image' | translate }}</span>
                    <span *ngIf="type.id === 'media'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="media"
                                                                title="audio"></mat-icon>
                        {{ 'generic.media' | translate }}</span>
                    <span *ngIf="type.id === 'text'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="text"
                                                               title="audio"></mat-icon>
                        {{ 'generic.text' | translate }}</span>
                    <span *ngIf="type.id === 'url'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="url"
                                                              title="audio"></mat-icon>
                        {{ 'generic.url' | translate }}</span>
                    <span *ngIf="type.id === 'video'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="video"
                                                                title="audio"></mat-icon>
                        {{ 'generic.video' | translate }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--TODO replace generic.title by a value coming form input because it could have multiple value-->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-title"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'title'"
                        *ngIf="displayField('title')">
            <mat-label>{{ getFieldTerms('title')!=='' && getFieldTerms('title')!=='generic.title' ? (getFieldTerms('title') | translate): defaultFieldTitle}}</mat-label><input #titleInput="matInput" matInput [formControl]="controls['titleFilter'].formControl"
                   >
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-metadatas_title"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'metadatas_title'"
                        *ngIf="displayField('metadatas_title')">
            <mat-label>{{ getFieldTerms('metadatas_title')!=='' && getFieldTerms('title')!=='generic.title' ? (getFieldTerms('metadatas_title') | translate): defaultFieldTitle}}</mat-label><input #titleInput="matInput" matInput [formControl]="controls['metadatas_title'].formControl"
                   >
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-skills"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'skills'"
                        *ngIf="displayField('skills')">
            <mat-label>{{ 'generic.skills' | translate }}</mat-label><mat-select
                name="searchSkillsFilter"
                [formControl]="controls['searchSkillsFilter'].formControl"
                >
                <mat-option [value]="'all'">{{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let skills of dataLists['skills']" [value]="skills.id">
                    <span>{{skills.get('label')}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-difficulty"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'difficulty'"
                        *ngIf="displayField('difficulty')">
            <mat-label>{{'generic.difficulty' | translate }}</mat-label><mat-select name="difficultyFilter" 
                        [formControl]="controls['difficultyFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let difficulty of dataLists['difficulty']" [value]="difficulty.id">
                    {{difficulty.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-usage"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'usage'"
                        *ngIf="displayField('usage')">
            <mat-label>{{'generic.usage' | translate }}</mat-label><mat-select name="difficultyFilter" 
                        [formControl]="controls['usageFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let usage of dataLists['usage']" [value]="usage.id">
                    {{(usage.get('label') | translate)}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-theme"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'theme'"
                        *ngIf="displayField('theme')">
            <mat-label>{{ 'generic.theme' | translate }}</mat-label><mat-select
                name="searchThemeFilter"
                [formControl]="controls['searchThemeFilter'].formControl"
                >
                <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let theme of dataLists['themes']" [value]="theme.id">
                    <span>{{theme.get('label')}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-educationnalLevel"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'educationnalLevel'"
                        *ngIf="displayField('educationnalLevel')
                            && !displayField('educationnalLevel-toogle')">
            <mat-label>{{'generic.level' | translate }}</mat-label><mat-select name="educationnalLevelFilter" 
                        [formControl]="controls['educationnalLevelFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let level of dataLists['educational_level']" [value]="level.id">
                    {{level.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-method"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'licenseFilter'"
                        *ngIf="displayField('method')">
            <mat-label>{{ 'licensing.method' | translate }}</mat-label><mat-select name="licenseFilter" [formControl]="controls['licenseFilter'].formControl"
                        >
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option [value]="method.id" *ngFor="let method of customLists['methods']">
                    {{ method.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="displayField('chapters') && !displayField('chapters-toogle')"
                        class="search-filters__chapter-field filters-field-chapters"
                        appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fieldsFormatedForDirective"
                        [movableElementField]="'chapters'">
            <mat-label>{{'generic.chapters' | translate }}</mat-label><mat-select name="chaptersFilter"
                        
                        [formControl]="controls['chaptersFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let chapters of dataLists['chapters']"
                            [value]="chapters.id">
                    {{chapters.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- This version use button list whith only one choice possible -->
        <div *ngIf="displayField('chapters-toogle')"
             class="search-filters__chapter-toggle filters-field-chapters"
             appMoveElement
             [movableElementIsActive]="orderFields"
             [movableElementFields]="fieldsFormatedForDirective"
             [movableElementField]="'chapters'">
            <mat-button-toggle-group class="search-filters__toggle-group"
                                     name="chaptersFilter"
                                     hideSingleSelectionIndicator="true"
                                     [formControl]="controls['chaptersFilter'].formControl">
                <mat-button-toggle *ngFor="let chapter of dataLists['chapters']; let index = index;"
                                   class="search-filters__toggle-button-wrapper exclude-elem-to-order-directive"
                                   [class.selected]="controls['chaptersFilter'].formControl.value === chapter.id"
                                   [class.search-filters__toggle-button-wrapper--selected]="controls['chaptersFilter'].formControl.value === chapter.id"
                                   [value]="chapter.id"
                                   (click)="launchSearch()">
                    {{ chapter.get('label') }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-concepts"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'concepts'"
                        *ngIf="displayField('concepts')">
            <mat-label>{{'generic.concepts' | translate }}</mat-label><mat-select name="conceptsFilter" 
                        [formControl]="controls['conceptsFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let concepts of dataLists['concepts']" [value]="concepts.id">
                    {{concepts.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-thematics"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'thematics'"
                        *ngIf="displayField('thematics')">
            <mat-label>{{'generic.thematics' | translate }}</mat-label><mat-select name="thematicsFilter" 
                        [formControl]="controls['thematicsFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let thematics of dataLists['thematics']" [value]="thematics.id">
                    {{thematics.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-notions"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'notions'"
                        *ngIf="displayField('notions')">
            <mat-label>{{'generic.notions' | translate }}</mat-label><mat-select name="notionsFilter" 
                        [formControl]="controls['notionsFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let notions of dataLists['notions']" [value]="notions.id">
                    {{notions.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-assignation_type"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'assignation_type'"
                        *ngIf="displayField('assignation_type')">
            <mat-label>{{'generic.type' | translate }}</mat-label><mat-select name="assignation_typeFilter" 
                        [formControl]="controls['assignation_typeFilter'].formControl">
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let assignation_type of dataLists['assignation_type']"
                            [value]="assignation_type.id">
                    {{ localizedType(assignation_type.get('label')) | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-schoolyears"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'schoolyearsFilter'"
                        *ngIf="displayField('schoolyears')">
            <mat-label>{{ 'groups-management.chose_school.year' | translate }}</mat-label><mat-select name="schoolyearsFilter" [formControl]="controls['schoolyearsFilter'].formControl"
                        >
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option
                    *ngFor="let shoolyear of dataLists['schoolyears']"
                    [value]="shoolyear.id">{{ displaySchoolYear(shoolyear.get('name')) }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-lessons"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'lessonsFilter'"
                        *ngIf="displayField('lessons')">
            <mat-label>{{ 'generic.lessons' | translate }}</mat-label><mat-select name="lessonsFilter" [formControl]="controls['lessonsFilter'].formControl"
                        >
                <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                <mat-option [value]="lesson.id" *ngFor="let lesson of customLists['lessons']">
                    {{ lesson.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--author is also use for theme todo field name placeholder -->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-author"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'author'"
                        *ngIf="displayField('author')">
            <mat-label>{{ getFieldTerms('author')!=='' ? (getFieldTerms('author') | translate): ('generic.author_global_corpus' | translate) }}</mat-label><input matInput [formControl]="controls['authorFilter'].formControl"
                   >
        </mat-form-field>

        <!--group is also use for niveau todo field name placeholder-->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-group"
                        [movableElementFields]="fieldsFormatedForDirective" [movableElementField]="'group'"
                        *ngIf="displayField('group')">
            <mat-label>{{ getFieldTerms('group')!=='' ? (getFieldTerms('group') | translate): ('generic.group_global_corpus' | translate) }}</mat-label><input matInput [formControl]="controls['groupFilter'].formControl"
                   >
        </mat-form-field>


        <button class="search-filters__search-button filters-field-launchSearch"
                appMoveElement
                [movableElementIsActive]="orderFields"
                [movableElementFields]="fieldsFormatedForDirective"
                [movableElementField]="'launchSearch'"
                (click)="launchSearch()">
            {{ 'generic.search' | translate }}
        </button>

        <span *ngIf="displayField('countEntities')"
              class="filters-field-countEntities"
              fxlayout="row"
              fxlayoutalign="start center"
              appMoveElement
              [movableElementIsActive]="orderFields"
              [movableElementFields]="fieldsFormatedForDirective"
              [movableElementField]="'countEntities'">
            {{countEntities }} {{(countEntities < 2 ? 'generic.result' : 'generic.results') | translate}}
        </span>

        <mat-checkbox *ngIf="displayField('bookmarks')"
                      class="search-filters__bookmark-checkbox filters-field-bookmarks"
                      name="filterBookmarks"
                      appMoveElement
                      [movableElementIsActive]="orderFields"
                      [movableElementFields]="fieldsFormatedForDirective"
                      [movableElementField]="'bookmarks'"
                      [formControl]="controls['searchBookmarkFilter'].formControl"
                      (change)="launchSearch()">
            {{ 'generic.filter_by_bookmarks' | translate }}
        </mat-checkbox>

        <mat-checkbox *ngIf="displayField('autocorrection')"
                      class="search-filters__autocorrect-checkbox"
                      name="filterAutocorrection"
                      appMoveElement
                      [movableElementIsActive]="orderFields"
                      [movableElementFields]="fieldsFormatedForDirective"
                      [movableElementField]="'autocorrection'"
                      [formControl]="controls['searchAutocorrectionFilter']?.formControl"
                      (change)="launchSearch()">
            {{ 'generic.filter_by_autocorrection' | translate }}
        </mat-checkbox>

    </div>
</form>