<input #fileInput (change)="onFileChange($event)" [accept]="acceptedTypes" style="display: none" type="file"/>

<div class="app-media-upload__infos" *ngIf="!media">
    <mat-icon svgIcon="info"></mat-icon>
    <span>{{ 'generic.format_ratio' | translate }}</span> -- <span>{{ 'generic.format_max_media_upload' | translate }}</span>
</div>

<ul *ngIf="errors.length > 0">
    <li *ngFor="let error of errors">{{ error | translate }}</li>
</ul>

<ng-container *ngIf="mediaIsLoading">
    <mat-spinner class="app-media-upload__spinner"></mat-spinner>
</ng-container>

<ng-container *ngIf="!!media">
    <ng-content select="[data-media]"></ng-content>
    <button class="app-media-upload__onActivateDeleteClick" (click)="onActivateDeleteClick($event)" *ngIf="isDeleteActive === false" type="button">
        <mat-icon svgIcon="delete"></mat-icon>
    </button>
    <ng-container *ngIf="isDeleteActive">
        <button class="app-media-upload__onDeleteClick" (click)="onDeleteClick($event)" type="button">
            {{ 'activity_edition.media_delete' | translate }}
        </button>
        <button class="app-media-upload__onCancelDelete" (click)="onCancelDelete($event)"
                type="button">{{ 'activity_edition.avoid_media_delete' | translate }}
        </button>
    </ng-container>
</ng-container>

<button class="app-media-upload__fileInput" *ngIf="!media" (click)="fileInput.click()" type="button">
    <mat-icon svgIcon="upload"></mat-icon>
    <span>{{ 'activity_edition.media_upload' | translate }}</span>
</button>