<div class="page-layout simple tabbed inner-scroll" fxFlex>

  <!-- HEADER -->
  <div class="header p-24" *ngIf="displayHeader" fxLayout="column" fxLayout="space-between start">
    <h2>{{ groupsService.getHeaderTitle() | translate }}</h2>
    <app-getting-started [alias]="groupsService.gettingStarted"></app-getting-started>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <content class="inner-scroll mat-white-bg" fxFlex fxLayout="column">

      <router-outlet></router-outlet>

  </content>
  <!-- / CONTENT -->

</div>
