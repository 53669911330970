<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<ng-container *ngIf="isLoading === false else loadingBlock" >
    <div mat-dialog-title>
        <h2>{{bdTitle}}</h2>
        <button class="close"
                (click)="close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <mat-dialog-content [innerHTML]="text"></mat-dialog-content>
</ng-container>