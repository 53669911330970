<!--Checkbox Options-->
<div *ngIf="mode === 'qcm'" class="checkbox-group"
     [ngClass]="{showFinalAnswers: showFinalAnswers, answersChecked: testAnswer}">
    <mat-checkbox
        *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' "
        [ngClass]="optionState(option)"
        (change)="informThatUserInteracted($event)"
        [disableRipple]="true"
        [disabled]="showFinalAnswers || testAnswer || validated || lessonsService.isAtLeastTrainerAndAssignmentExist || disabled"
        [(ngModel)]="option.select ">
        <span [innerHTML]="option.answer | stringToHtml"></span>
    </mat-checkbox>
</div>

<!--Radio Options-->
<mat-radio-group *ngIf="mode === 'qcu'" [ngClass]="{showFinalAnswers: showFinalAnswers, answersChecked: testAnswer}"  [(ngModel)]="valueChecked">
    <mat-radio-button *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select'"
                      (change)="radioChange($event.value)"
                      [disabled]="showFinalAnswers || testAnswer || validated || lessonsService.isAtLeastTrainerAndAssignmentExist || disabled"
                      [ngClass]="optionState(option)"
                      [disableRipple]="true" [value]="option.answer">
        <span [innerHTML]="option.answer | stringToHtml"></span>
    </mat-radio-button>
</mat-radio-group>