<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>

<!--    <div class="header"></div>-->

    <div class="content" fxFlex fxLayout="column">

        <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="bd-list"></app-banner-info>

        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <app-search-filters (launchSearch)="refreshNotes($event).subscribe()" [countEntities]="paginator?.count"
                                    [customLists]="{lessons: alreadyAssociatedLessonsFilterList}"
                                    [fields]="filters"
                                    [overrideEndpointFilterName]="[
                                        {originalFilterName: 'lessons', targetFilterName: 'associated_node'}
                                    ]"
                                    [customFieldName]="[{field: 'metadatas_title', value: 'generic.title'}]"
                ></app-search-filters>
            </mat-toolbar-row>
        </mat-toolbar>

        <div *ngIf="isLoadingDone; then thenBlock; else loadingBlock"></div>

        <ng-template #thenBlock>
            <div>
                <div class="bd-card-list">
                    <div
                        class="p-16 fake-card"
                        fxFlex="auto"
                        fxFlex.gt-lg="33"
                        fxFlex.gt-xs="50">
                        <div (click)="createNote($event)" *ngIf="isLoadingDone"
                            role="button"
                            (keyup.enter)="createNote($event)"
                            tabindex="0">
                            <mat-card class="mat-card">
                                <mat-card-content class="mat-card-content">
                                    <mat-icon svgIcon="add"></mat-icon>
                                    <h3>{{'bd.add_bd' | translate}}</h3>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div *ngFor="let bd of bds"
                        class="p-16"
                        fxFlex="auto"
                        fxFlex.gt-lg="33"
                        fxFlex.gt-xs="50">
                        <app-bd-card (hasChanged)="onBdChanged(bd)"
                                    [bdBasicSearchEntity]="bd"
                                    class="card-split-media-info"></app-bd-card>
                    </div>
                </div>
            </div>
            <mat-paginator (page)="onPaginateChange($event)" [length]="paginator.count"
                [pageIndex]="paginator.page -1"
                [pageSize]="bdListRange"></mat-paginator>
        </ng-template>

        <ng-template #emptyBlock>
            <div class="empty-list">
                <p>{{ 'bd.empty_list' | translate }}</p>
                <p>{{ 'bd.add_bd_instruction' | translate }}</p>
            </div>
        </ng-template>

        <ng-template #loadingBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>

    <!-- <button (click)="createNote($event)"
            *ngIf="isLoadingDone"
            class="mat-fab-bottom-right"
            color="primary"
            mat-fab
            matTooltip="{{'bd.add_bd' | translate}}"
            matTooltipPosition="before">
        <mat-icon svgIcon="add"></mat-icon>
    </button>-->
</div>
