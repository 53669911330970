<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>
<form (ngSubmit)="onSubmit(noteForm.value)" [formGroup]="noteForm">
    <mat-dialog-content>
        <div mat-dialog-title>
            <h2>{{ "gamecode.create_gamecode_modal_title" | translate }}</h2>
            <button (click)="selfDialogRef.close()" aria-label="Close dialog" type="button" class="close">
                <mat-icon svgIcon="cross"></mat-icon>
            </button>
        </div>

        <ng-container *ngIf="isInitializing === false else loading">

            <div class="form-content">
                <div class="title-field">
                    <mat-form-field class="w-100-p">
                        <mat-label>{{ 'gamecode.create_gamecode_title_label' | translate }}</mat-label>
                        <input formControlName="title" matInput
                               placeholder="{{ 'gamecode.create_gamecode_title_placeholder' | translate }}"/>
                        <mat-error *ngIf="noteForm.get('title').errors?.required">
                            {{ 'generic.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="isSaving || noteForm.get('title').errors?.required" class="button mat-button" mat-button
                mat-raised-button type="submit">
            <mat-spinner *ngIf="isSaving"></mat-spinner>
            {{'generic.create' | translate}}
        </button>
    </mat-dialog-actions>
</form>