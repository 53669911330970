<div *ngIf="globalConfigurationService.getUseRootFilter() && isAllowed"
     class="root-filter">

    <label tabindex="0" class="root-filter__checkbox-container" id="root-filter-first-answer"
           (keydown.enter)="selectOption(filterState.junior)"
           #labelContainer>
        <span class="root-filter__checkbox-label">Citizen Code Junior</span>
        <mat-icon class="root-filter__checkbox-icon"
                  svgIcon="logo_CTZC_junior"></mat-icon>
        <input class="root-filter__checkbox-input"
               type="radio"
               [(ngModel)]="selectedOption"
               [value]="filterState.junior"
               (change)="update()"
               #inputContainer>
    </label>

    <label tabindex="0" class="root-filter__checkbox-container"
           (keydown.enter)="selectOption(filterState.explorer)" #labelContainer>
        <span class="root-filter__checkbox-label">Citizen Code Collège</span>
        <mat-icon class="root-filter__checkbox-icon"
                  svgIcon="logo_CTZC_college"></mat-icon>
        <input class="root-filter__checkbox-input"
               type="radio"
               [(ngModel)]="selectedOption"
               [value]="filterState.explorer"
               (change)="update()"
               #inputContainer>
    </label>

    <label tabindex="0" class="root-filter__checkbox-container"
           (keydown.enter)="selectOption(filterState.python)" #labelContainer>
        <span class="root-filter__checkbox-label">Citizen Code Python</span>
        <mat-icon class="root-filter__checkbox-icon"
                  svgIcon="logo_CTZC_python"></mat-icon>
        <input class="root-filter__checkbox-input"
               type="radio"
               [(ngModel)]="selectedOption"
               [value]="filterState.python"
               (change)="update()"
               #inputContainer>
    </label>
</div>