<mat-card [class.card--learner-user]="isLearner"
          [class.card--locked-lesson]="!isLessonUnlocked">

    <div *ngIf="!isLessonUnlocked"
         class="lessonLocked">
        <mat-icon aria-hidden="true"
                  svgIcon="lock">
        </mat-icon>
        <p *ngIf="requirementsToUnlockLesson?.length"
           class="lessonLocked__description">
            {{requirementsToUnlockLesson}}
        </p>
    </div>

    <div *ngIf="isLessonAssigned"
         class="card__assignation">
        <mat-icon class="card__assignation-icon"
                  svgIcon="assign">
        </mat-icon>
        <span class="card__assignation-label">{{ 'generic.todo' | translate }}</span>
    </div>

    <app-lesson-card-breadcrumb *ngIf="displayLessonBreadcrumb"
                                [fields]="LessonBreadcrumbFields"
                                [attr.aria-hidden]="!hasLessonBreadcrumb"
                                [previousTargetName]="uuid">
    </app-lesson-card-breadcrumb>

    <mat-card-header [ngClass]="headerClasses">

        <div *ngIf="displayField('titleIcon')" class="pastille pastille-icon">
            <mat-icon svgIcon="{{ headerTitleIcon }}"></mat-icon>
        </div>

        <mat-card-title *ngIf="displayField('title')">
            <h3>{{ headerTitle }}</h3>
        </mat-card-title>

        <ng-container *ngIf="isMenuIsNecessary$() | async">
            <button [matMenuTriggerFor]="menu"
                    ariaLabelTranslate="{{'generic.menu' | translate}}"
                    class="mat-icon-button"
                    mat-icon-button
                    matTooltip="{{'generic.menu' | translate}}">
                <mat-icon svgIcon="submenu"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngFor="let link of menuLinks">
                    <button *ngIf="link.displayCondition() | async"
                            (click)="link.function()"
                            mat-menu-item>
                        <mat-icon svgIcon="{{link.icon}}"></mat-icon>
                        {{ link.label | translate }}
                    </button>
                </ng-container>
            </mat-menu>
        </ng-container>
    </mat-card-header>

    <img *ngIf="displayField('image') && (typeOfCard.isTheme || typeOfCard.isLesson || typeOfCard.isSequence)"
         alt="" mat-card-image src="{{ contentDetails.image?.uri }}">

    <img *ngIf="displayField('dynamicLevelTypeImage')
    && (typeOfCard.isTheme || typeOfCard.isLesson || typeOfCard.isSequence)"
         [src]="getDynamicLevelTypeImage(dataCard.resource)" alt="" mat-card-image>

    <img *ngIf="!typeOfCard.isLesson && !typeOfCard.isSequence && !typeOfCard.isVideo && !typeOfCard.isTheme"
         [ngClass]="getLabelToUse()" mat-card-image
         alt="" class="card-cover" src="{{thumb}}"/>

    <!-- TODO utiliser app-shared-video mais il est dans un autre module et l'importer génère des conflits. Il faut refacto les outils partagés -->
    <video *ngIf="!displayOnlyMediaIconsType && !typeOfCard.isLesson && !typeOfCard.isSequence && typeOfCard.isVideo"
           class="card-cover">
        <source src="{{thumb}}" type="{{dataCard.originalResource?.ressourceEntity.get('reference').filemime}}">
        Your browser does not support HTML5 video.
    </video>

    <img *ngIf="displayOnlyMediaIconsType && !typeOfCard.isLesson && !typeOfCard.isSequence && typeOfCard.isVideo"
         class="card-cover" mat-card-image
         alt="" class="card-cover" src="{{'assets/' + brand + '/icons/videoUrl.svg'}}"/>

    <mat-card-content>
        <dl *ngIf="shouldDisplayFields()" class="dl-list content-list" fxFlex fxLayout="column" role="list">
            <div *ngFor="let field of getDisplayedFields()" [fxFlexOrder]="getOrder(field)" class="{{field}}-field">
                <ng-container *ngIf="getFieldTitle(field) && getFieldContent(field)">
                    <dt class="field-title" role="listitem">{{ getFieldTitle(field) | translate }}</dt>
                    <dd class="field-content" role="listitem" [innerHTML]="getFieldContent(field)"></dd>
                </ng-container>
            </div>
        </dl>

        <dl class="dl-list keywords-list" role="list" >
            <div *ngIf="displayField('keywords') && isLessonOrSequence" class="keywords">
                <dt *ngFor="let keyword of metadatas?.indexation">
                    <a [routerLink]="['/lessons/list/search']"
                       [queryParams]="{usage: LessonBreadcrumbFields.usages , title: keyword.label}">{{keyword.label}}</a>
                </dt>
            </div>

            <mat-chip-set *ngIf="displayField('metadatas') && isLessonOrSequence" class="metadatas" tabIndex="-1">
                <mat-chip (click)="dataCard.openMetaDialog($event, metadatas)" class="meta-info">
                    <mat-icon svgIcon="info"></mat-icon>
                    <span>{{ 'activities.metadata' | translate }}</span>
                </mat-chip>
            </mat-chip-set>
        </dl>

        <div class="tags">
            <mat-chip-set *ngIf="displayField('keywords-chip-list') && isLessonOrSequence" class="keywords"
                           tabIndex="-1">
                <mat-chip *ngFor="let keyword of metadatas?.indexation">
                    <a [routerLink]="['/lessons/list/search']"
                       [queryParams]="{usage: LessonBreadcrumbFields.usages , title: keyword.label}">{{keyword.label}}</a>
                </mat-chip>
            </mat-chip-set>
            <mat-chip-set *ngIf="displayField('educationalLevel-chip-list') && isLessonOrSequence"
                           class="educationalLevel" tabIndex="-1">
                <mat-chip *ngFor="let educationalLevelItem of metadatas?.educationalLevel">
                    <a [routerLink]="['/lessons/list/search']"
                       [queryParams]="{usage: LessonBreadcrumbFields.usages , educationalLevel: educationalLevelItem.id}">{{educationalLevelItem.label}}</a>
                </mat-chip>
            </mat-chip-set>
        </div>

        <div *ngIf="showProgressLabel"
             class="card__progress-label">
            <ng-container *ngIf="getActivitiesCount() > 1; else singleActivityTemplate">
                <span class="card__activity-count">{{ getActivitiesCount() }} {{ 'generic.activities' | translate }}</span>
            </ng-container>
            <ng-template #singleActivityTemplate>
                <span class="card__activity-count">{{ getActivitiesCount() }} {{ 'generic.activity' | translate }}</span>
            </ng-template>
            <span *ngIf="lessonProgress > 0"
                  class="card__lesson-progress">
                 ({{ lessonProgress }}%)
            </span>
        </div>

    </mat-card-content>

    <mat-card-actions class="forceAddingTabIndex" appMoveFocusToNextAllowedElement [previousTarget]="uuid + '-0'">
        <!--assign------------------------------------------------------------------- -->
        <span (click)="openAssignments()"
              *ngIf="displayField('assigned') && dataCard.displayAssignedCount && (isLessonOrSequence)"
              class="info-assignated" fxLayout="row"
              fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'active': assignedCount >= 1 }"
                      svgIcon="assignment_ind">
            </mat-icon>
            <label *ngIf="assignedCount !== 0">

                {{ 'generic.assignated_to' | translate }}
                {{assignedCount}}&nbsp;

                <span *ngIf="assignedCount > 1">
                    {{ 'generic.times' | translate }}
                </span>
                <span *ngIf="assignedCount === 1">
                    {{ 'generic.time' | translate }}
                </span>
            </label>
        </span>
        <!--duplication------------------------------------------------------------------- -->
        <span *ngIf="displayField('duplication') && dataCard.displayDuplicationCount && (isLessonOrSequence)"
              class="info-assignated" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'active': duplicationCount >= 1 }"
                      svgIcon="duplicate">
            </mat-icon>
            <ng-container *ngIf="duplicationCount !== 0">

                {{ 'generic.duplicated' | translate }}
                {{duplicationCount}}&nbsp;

                <span *ngIf="duplicationCount > 1">
                    {{ 'generic.times' | translate }}
                </span>
                <span *ngIf="duplicationCount === 1">
                    {{ 'generic.time' | translate }}
                </span>
            </ng-container>
        </span>


        <ng-template #actionsBar> <!--Affiche tous les boutons directement dans <mat-card-actions-->
            <ng-container *ngFor="let action of actions">
                <button *ngIf="action.displayCondition() | async"
                        [id]="action.id"
                        [title]="(action.title | translate) ?? undefined"
                        [ariaLabelTranslate]="(action.title | translate) ?? undefined"
                        [attr.mat-mini-fab]="action.matButtonType === 'mat-mini-fab' && !moveActionsButtonInMenu ?? undefined"
                        [attr.mat-button]="action.matButtonType === 'mat-button' && !moveActionsButtonInMenu ?? undefined"
                        [attr.mat-raised-button]="action.matButtonType === 'mat-raised-button' && !moveActionsButtonInMenu ?? undefined"
                        (click)="action.function($event)"
                        [ngClass]="getClasses(action)"
                        [fxLayout]="action.fxLayout ? action.fxLayout : undefined"
                        [fxLayoutAlign]="action.fxLayoutAlign ? action.fxLayoutAlign : undefined"
                        [matMenuTriggerFor]="action.matMenuTriggerFor ? getMenuTriggerFor(action.matMenuTriggerFor) : undefined"
                        [attr.data-e2e]="action.id"
                >
                    <mat-icon *ngIf="action.icon" svgIcon="{{action.icon}}"></mat-icon>
                    <span *ngIf="action.displayLabel">{{ action.displayLabel | translate }}</span>
                </button>
            </ng-container>
        </ng-template>

        <ng-container *ngIf="moveActionsButtonInMenu else actionsBar"> <!--Affiche les boutons dans un menu-->
            <button class="mat-mdc-card-actions__btn-menu-trigger"
                    [matMenuTriggerFor]="menuActions"
                    tabindex="0"
                    ariaLabelTranslate="{{'generic.menu' | translate}}"
                    matTooltip="{{'generic.menu' | translate}}">
                <span>{{ 'lesson.card_btn-menu' | translate }}</span>
                <mat-icon svgIcon="card-actions__icon-menu-trigger"></mat-icon>
            </button>
            <mat-menu #menuActions="matMenu">
                <ng-container *ngFor="let action of actions">
                    <button *ngIf="action.displayCondition() | async"
                            [id]="action.id"
                            [title]="(action.title | translate) ?? undefined"
                            [ariaLabelTranslate]="(action.title | translate) ?? undefined"
                            mat-menu-item
                            (click)="action.function($event)"
                            [ngClass]="getClasses(action)"
                            [fxLayout]="action.fxLayout ? action.fxLayout : undefined"
                            [fxLayoutAlign]="action.fxLayoutAlign ? action.fxLayoutAlign : undefined"
                            [attr.data-e2e]="action.id"
                            [disabled]="action.disabled ? (action.disabled() | async) : false">
                        <mat-icon *ngIf="action.icon"
                                  svgIcon="{{action.icon}}">
                        </mat-icon>
                        <span *ngIf="action.displayLabel">{{ action.displayLabel | translate }}</span>
                    </button>
                </ng-container>
            </mat-menu>
            <button *ngIf="showFavoriteButton"
                    class="mat-mdc-card-actions__btn-bookmark"
                    (click)="bookmark()"
                    [class]="[isFavorite() ? 'active' : '', 'button-favorite', 'flag'].join(' ')"
                    ariaLabelTranslate="{{'generic.favoris' | translate}}"
                    matTooltip="{{'generic.favoris' | translate}}">
                <mat-icon svgIcon="heart"></mat-icon>
            </button>
            <button *ngIf="isEditOrDisableButtonActive()"
                    class="mat-mdc-card-actions__btn-edit-or-disable"
                    (click)="onEditClick($event)"
                    ariaLabelTranslate="{{'card.edit_or_disable' | translate}}"
                    matTooltip="{{'card.edit_or_disable' | translate}}"
                    [disabled]="(isEditable$ | async) === false">
                <span>{{'card.edit_or_disable' | translate}}</span>
                <mat-icon svgIcon="edit"></mat-icon>
            </button>
            <button class="mat-mdc-card-actions__btn-play-lesson"
                    (click)="this.openLessonPage(dataCard)"
                    ariaLabelTranslate="{{'play.lesson_card_button' | translate}}"
                    tabindex="0">
                <span>{{ 'lesson.card_open_lesson_page' | translate }}</span>
                <mat-icon svgIcon="play"></mat-icon>
            </button>
        </ng-container>

        <mat-menu #menuBottom="matMenu">
            <button (click)="dataCard.play(dataCard.resource)"
                    mat-menu-item
                    *ngIf="isPreviewAvailable() | async">
                {{ 'generic.run_preview' | translate }}
            </button>
            <button (click)="openAssignmentWithUserDataFormModal(dataCard.resource, dataCard)"
                    *ngIf="isLaunchLessonAskModalActive()"
                    mat-menu-item>
                {{ 'generic.run_lesson' | translate }}
            </button>
        </mat-menu>
        <mat-menu #menuPdf="matMenu">
            <button aria-label="download-pdf"
                    mat-raised-button
                    *ngFor="let d of pdfList();let index = index;"
                    (click)="downloadDoc(d.uri)">
                <span>{{'generic.pdf_to_download_' + index |translate}}</span>
            </button>
        </mat-menu>
    </mat-card-actions>
</mat-card>