@if (!isLoading) {
    <div [ngStyle]="{'visibility':hideContent?'hidden':'visible'}"
         class="page-layout inner-scroll">

        <div class="content inner-scroll" fxLayout="{{!displayUserActionButton ? 'column' : 'row'}}" >

            <div *ngIf="!displayUserActionButton('hideSideNavigation')" class="multi-side-nav-previous pr-8 mt-24 mb-24 ml-40 fxNoneColumnCenterNone">
                <button  class="previous" (click)="launchPreviousActivity()"
                         [disabled]="canLaunchPrevious() === false"
                         [style.visibility]="getHideUserActionButtonsWhenDisabled() && !canLaunchPrevious()?'hidden':'visible'"
                         aria-label="previous">
                    <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
                </button>
            </div>

            <content class="content inner-scroll">
                <router-outlet></router-outlet>
            </content>

            <div *ngIf="!displayUserActionButton('hideSideNavigation')"
                 class="multi-side-nav-next fxNoneColumnCenterNone">
                <button  class="next" (click)="launchNextActivity()"
                         [disabled]="canLaunchNext() === false"
                         [style.visibility]="getHideUserActionButtonsWhenDisabled() && !canLaunchNext()?'hidden':'visible'"
                         aria-label="next">
                    <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
                </button>
            </div>
        </div>
    </div>
} @else {
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
}


