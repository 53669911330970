<div class="page-layout simple fullwidth mat-white-bg" fusePerfectScrollbar>
    <mat-toolbar class="filters">
        <form>
            <!-- learner Field -->
            <mat-form-field fxFlex="20" *ngIf="displayFilters('learner')">
                <mat-label>{{ 'data_visualization.learner' | translate }}</mat-label><mat-select  [formControl]="learnerControl">
                    <mat-option *ngFor="let learner of learners"
                                [value]="learner">{{ learner.username }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="20" *ngIf="displayFilters('startDate')">
                <mat-label>{{'generic.start_date' | translate}}</mat-label><input name="startDate" matInput [matDatepicker]="picker" [max]="maxStartDate"
                        [formControl]="startDateControl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="20" *ngIf="displayFilters('dueDate')">
                <mat-label>{{'generic.end_date' | translate}}</mat-label><input name="dueDate" matInput [matDatepicker]="duepicker" [min]="minDueDate"
                        [formControl]="dueDateControl">
                <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
                <mat-datepicker #duepicker></mat-datepicker>
            </mat-form-field>
        </form>
    </mat-toolbar>

    <h1>{{ 'data_visualization.title' | translate }}</h1>
    <app-graph-mixed [config]="graphConfig$"></app-graph-mixed>
</div>