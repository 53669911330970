<div class="rowTable">
    <div class="cellTable">{{this.data.question}}</div>
    <!--checkBox mutli choice-->
    <ng-container *ngIf="multipleChoices ==='1'">
        <div class="cellTable"
             *ngFor="let element of this.data.answers">
            <mat-checkbox [checked]="element.select"
                          (change)="onSelectCheckBox($event, element)">
            </mat-checkbox>
        </div>
    </ng-container>
    <!--radio button simple choice-->
    <ng-container *ngIf="multipleChoices ==='0'">
        <mat-radio-group class="radioGroupCell">
            <ng-container *ngFor="let element of this.data.answers">
                <mat-radio-button class="cellTable"
                                  [checked]="element.select"
                                  [value]="element.id"
                                  (change)="onSelectRadio(element)"></mat-radio-button>
            </ng-container>
        </mat-radio-group>
    </ng-container>
</div>
<div class="spaceRow"></div>