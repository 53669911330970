<nav aria-label="breadcrumbs" class="lesson-card-breadcrump__nav">
    <ol class="lesson-card-breadcrump__nav__list">
        <li *ngIf="fields.concepts" class="lesson-card-breadcrump__nav__item">
            <a *ngFor="let concept of fields.concepts; let index = index;"
               class="lesson-card-breadcrump__nav__link"
               [routerLink]="['/lessons/list/search']"
               [queryParams]="{usage: fields.usages, concepts: concept.id }" [attr.id]="target + '-' + index">
                <span
                    class="lesson-card-breadcrump__nav__item__name">{{ concept.label }}</span>
                <span *ngIf="index !== fields.concepts.length - 1" class="lesson-card-breadcrump__nav__word__separator"
                      aria-hidden="true">,</span>
            </a>
        </li>
        <li *ngIf="fields.chapters" class="lesson-card-breadcrump__nav__item">
            <span *ngIf="fields.concepts" class="lesson-card-breadcrump__nav__item__separator"
                  aria-hidden="true">/</span>
            <a *ngFor="let chapter of fields.chapters; let index = index;"
               class="lesson-card-breadcrump__nav__link"
               [routerLink]="['/lessons/list/search']"
               [queryParams]="{usage: fields.usages, chapters: chapter.id }">
                <span
                    class="lesson-card-breadcrump__nav__item__name">{{ chapter.label }}</span>
                <span *ngIf="index !== fields.chapters.length - 1" class="lesson-card-breadcrump__nav__word__separator"
                      aria-hidden="true">,</span>
            </a>
        </li>
        <li *ngIf="fields.skills" class="lesson-card-breadcrump__nav__item">
            <span *ngIf="fields.concepts || fields.chapters" class="lesson-card-breadcrump__nav__item__separator"
                  aria-hidden="true">/</span>
            <a *ngFor="let skill of fields.skills; let index = index;"
               class="lesson-card-breadcrump__nav__link"
               [routerLink]="['/lessons/list/search']"
               [queryParams]="{usage: fields.usages, skills: skill.id }">
                <span class="lesson-card-breadcrump__nav__item__name">{{ skill.label }}</span>
                <span *ngIf="index !== fields.skills.length - 1" class="lesson-card-breadcrump__nav__word__separator"
                      aria-hidden="true">,</span>
            </a>
        </li>
    </ol>
</nav>