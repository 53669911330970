<div id="mail-confirm" fxLayout="column" fusePerfectScrollbar>

    <div id="mail-confirm-form-wrapper" *ngIf="tokenIsValid" fxLayout="column" fxLayoutAlign="center center">

        <mat-spinner *ngIf="validateEmailStrategyActivated && validateEmailStrategyMode ==  'modal'"></mat-spinner>
        <ng-container *ngIf="!validateEmailStrategyActivated || validateEmailStrategyMode !=  'modal'">
            <div id="mail-confirm-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

                <div class="logo">
                    <mat-icon class="s-96">email</mat-icon>
                </div>

                <div class="title">{{'account-management.email_validate' | translate}}</div>

                <div class="message">
                    <p *ngIf="extentedValidation">
                        <a class="title" routerLink="/profile/show-profile/edit">{{'account-management.email_validate_extend1' | translate}}</a>
                    </p>
                    <p  *ngIf="extentedValidation">{{'account-management.email_validate_extend2' | translate}}
                        <a class="link" [routerLink]="usersProvider.defaultRoute">{{ termForBackRoute | translate}}</a>
                    </p>

                    <a  *ngIf="!extentedValidation" class="link" [routerLink]="usersProvider.defaultRoute">{{ termForBackRoute | translate}}</a>
                </div>

            </div>
        </ng-container>

    </div>

</div>



