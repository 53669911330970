<section class="closing-pop-up">

    <div class="closing-pop-up__header">

        <h2 class="closing-pop-up__title">{{ 'language_lab.pop_up_title' | translate }}</h2>

        <p class="closing-pop-up__text">{{ 'language_lab.pop_up_text' | translate }}</p>

    </div>

    <div class="closing-pop-up__actions">

        <button class="closing-pop-up__skip-button"
                (click)="dialogRef.close('reply')">
            <span class="closing-pop-up__button-label">{{ 'language_lab.skip' | translate }}</span>
            <mat-icon class="closing-pop-up__button-icon"
                      svgIcon="skip">
            </mat-icon>
        </button>

        <button class="closing-pop-up__close-button"
                (click)="dialogRef.close('close')">
            <span class="closing-pop-up__button-label">{{ 'language_lab.exit' | translate }}</span>
            <mat-icon class="closing-pop-up__button-icon"
                      svgIcon="quit">
            </mat-icon>
        </button>

        <button class="closing-pop-up__resume-button"
                (click)="dialogRef.close('')">
            <span class="closing-pop-up__button-label">{{ 'language_lab.continue' | translate }}</span>
            <mat-icon class="closing-pop-up__button-icon"
                      svgIcon="next">
            </mat-icon>
        </button>

    </div>

</section>