<ng-container *ngIf="answersState; else placeholderTemplate">

    <ng-template #buttonTemplate
                 let-answer='answer'
                 let-type='type'>
        <button class="activity-edition-preview-text-matching__answer-button"
                [class.activity-edition-preview-text-matching__image-button]="type === 'image'">
            <ng-container *ngIf="type === 'text'">
                <span>{{ answer }}</span>
            </ng-container>
            <ng-container *ngIf="type === 'image'">
                <img [src]="answer?.uri">
            </ng-container>
            <ng-container *ngIf="type === 'audio'">
                <audio [src]="answer?.uri"
                       controls>
                </audio>
            </ng-container>
        </button>
    </ng-template>

    <ng-container *ngFor="let pair of getPairs(); let i = index">
        <ng-container *ngTemplateOutlet="buttonTemplate; context: {
                          type: answersState.firstType,
                          answer: pair[0]
                      }">
        </ng-container>
        <ng-container *ngTemplateOutlet="buttonTemplate; context: {
                          type: answersState.secondType,
                          answer: pair[1]
                      }">
        </ng-container>
    </ng-container>

</ng-container>

<ng-template #placeholderTemplate>

    <ng-container *ngFor="let item of [].constructor(4); let index = index">
        <button class="activity-edition-preview-text-matching__answer-button">
            <span>{{ 'activity_edition.text_answer_placeholder_' + index | translate }}</span>
        </button>
        <button class="activity-edition-preview-text-matching__media-button-placeholder">
            <mat-icon class="activity-edition-preview__media-icon"
                      svgIcon="image">
            </mat-icon>
        </button>
    </ng-container>

</ng-template>