<ng-template #loading>
    <div fxFill fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<mat-card *ngIf="(!isSaving && isReady) else loading" class="activities-card tool__card inner-scroll mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                [wordingAudio]="wordingAudio"
                [wording]="wording"
                [ngClass]="{'consigneImg': isWordingImg()}"></app-consignes>
            <app-consignes
                *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
                [instructionAudio]="instructionAudio"
                [instruction]="instruction"
                [ngClass]="{'consigneImg': isInstructionImg()}"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="tool__content">
        <ng-container *ngIf="!!iconName else defaultIconTemplate">
            <div class="tool__image-wrapper">
                <img [src]="'assets/' + brand + '/images/tools/' + iconName"
                     [alt]="iconName"/>
            </div>
        </ng-container>
        <ng-template #defaultIconTemplate>
            <mat-icon class="tool__icon-wrapper"
                      svgIcon="tools">
            </mat-icon>
        </ng-template>
        <button *ngIf="isReady"
                class="tool__execute-button" 
                (click)="executeTool()">
            {{textButtonTool | translate}}
        </button>
    </mat-card-content>
    <mat-card-footer>
        <div *ngIf="isPercentileDisplayAllowed"
             class="gradePercentil">{{ 'generic.score' | translate}}{{showPercentile}}</div>
        <ng-container *ngFor="let button of buttons">
            <button
                (click)="onAction(button)"
                *ngIf="button.display"
                [disabled]="button.disable"
                >
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>