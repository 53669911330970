<div class="page-layout simple inner-scroll" [fxLayout]="layout()">
        <app-generic-stepper *ngIf="displayStepper" [lessonMode]="lessonMode" [options]="customStepperOptions" class="sidebar open locked-open">
            <div toolbar-before-stepper>
                <ng-content select="[toolbar-before-stepper]"></ng-content>
            </div>
            <div toolbar-after-stepper>
                <ng-content select="[toolbar-after-stepper]"></ng-content>
            </div>
        </app-generic-stepper>
        <ng-content></ng-content>
</div>

