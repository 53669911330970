<ng-template #loading>
  <div mat-dialog-title>
    <h2>{{ "corpus.add_resource" | translate }}</h2>
  </div>

  <div mat-dialog-content>
    <div class="spinner-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-template>

<div class="dialog-content-wrapper">
  <ng-container *ngIf="isLoading === false; else loading">
    <app-resource-upload-modal-step1
      (fileUploaded)="onFileUploaded($event)"
      (urlValidated)="onUrlValidated($event)"
      (canceled)="onCanceled()"
      (error)="onError()"
      *ngIf="currentUploadPhase === 1"
    ></app-resource-upload-modal-step1>
    <app-resource-upload-modal-step2
      fxLayout="column"
      (fileUploaded)="onPdfFileUploaded($event)"
      (detailsValidated)="onDetailsValidated($event)"
      (canceled)="onCanceled()"
      *ngIf="currentUploadPhase === 2"
    ></app-resource-upload-modal-step2>
    <app-resource-upload-modal-step3
      fxLayout="column"
      (chaptersValidated)="onChaptersValidated($event)"
      (canceled)="onCanceled()"
      *ngIf="currentUploadPhase === 3"
    ></app-resource-upload-modal-step3>
    <app-resource-upload-modal-step4
      fxLayout="column"
      (conceptsValidated)="onConceptsValidated($event)"
      (canceled)="onCanceled()"
      *ngIf="currentUploadPhase === 4"
    ></app-resource-upload-modal-step4>
    <app-resource-upload-modal-error
      (goBack)="reset()"
      *ngIf="currentUploadPhase === 0"
    ></app-resource-upload-modal-error>
  </ng-container>
</div>
