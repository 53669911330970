<mat-card class="activities-card inner-scroll mat-elevation-z0 trueFalseContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                data-trala-identifier="wording"
                [wording]="wording"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                [wordingAudio]="wordingAudio"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content class="page-layout ord-page">
        <app-consignes
            *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
            data-trala-identifier="instruction"
            [instruction]="instruction" [ngClass]="{'consigneImg': isInstructionImg()}"
            [instructionAudio]="instructionAudio"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            questionTypeName="{{ 'activities.questionTypeName.app-true-false' | translate }}"></app-consignes>


        <ng-container *ngFor="let f of referenceActivityGranule.flashcards">
            <app-flashcard [class.button-image]="f.image"
                           [class.is-audio]="f.audio?.uri"
                           [flashCard]="f">
            </app-flashcard>
        </ng-container>

        <div id="container-for-answers-available" class="available-answers" [ngClass]="columnClass"
             *ngIf="displaySaveBtn">
            <ng-container *ngFor="let option of availableAnswers">
                <button
                    class="btn-option"
                    [class]="optionState(option)"
                    [class.currently-correct]="displaySolution && option.correct_answer"
                    (click)="validate(option)"
                    [disabled]="isOptionDisabled"
                    [matTooltip]="(option.answer === 'true' ? 'activities.lesson.true' : 'activities.lesson.false') | translate">
                    <mat-icon [svgIcon]="option.answer"></mat-icon>
                    <span
                        *ngIf="shouldDisplayAnswersLabel">{{ 'activities.question_answer_' + option.answer | translate }}</span>
                </button>
            </ng-container>
        </div>
        <!--if someone know what is the use case of this code and if it use please comment or remove if not used anymore-->
        <mat-radio-group *ngIf="!displaySaveBtn"
                         [ngClass]="{showFinalAnswers: this.testAnswer || this.displaySolution || this.answerSaved, answersChecked: testAnswer}"
                         [(ngModel)]="valueChecked">
            <mat-radio-button *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select'"
                              (change)="validate(option)"
                              [disabled]="isOptionDisabled"
                              [ngClass]="optionState(option)"
                              [disableRipple]="true" [value]="option.answer">
                <span [innerHTML]="option.answer | stringToHtml"></span>
            </mat-radio-button>
        </mat-radio-group>

    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate }}{{ showPercentile }}
        </div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"

                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>