<div id="mail-confirm" fxLayout="column" fusePerfectScrollbar>

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="s-96">email</mat-icon>
            </div>

            <div class="title"  translate>{{formTitle}}</div>

            <div class="subtitle">
              <p translate *ngFor="let text of contentText">
                {{text}}
              </p>
            </div>

            <div class="message">
                <a class="link" [routerLink]="formRedirectionLink" translate>{{formRedirection}}</a>
            </div>

        </div>

    </div>

</div>
