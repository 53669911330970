
    <input [attr.name]="name" type="radio"
           [formControl]="controlName"
           [attr.aria-label]="ariaLabel"
           [value]="idElement">

    <ng-content></ng-content>

    <mat-icon [svgIcon]="icon"></mat-icon>

