<mat-list align="end">

    <button (click)="closeSideBar()"
            mat-fab
            aria-label="close button"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</mat-list>

<mat-divider cdkFocusRegionEnd></mat-divider>

<mat-list class="date" cdkFocusRegionStart>

    <h3 matSubheader cdkFocusInit>
        <span><strong>{{ 'notification.title' | translate }}</strong></span>
    </h3>

</mat-list>

<mat-divider cdkFocusRegionEnd></mat-divider>

<ng-container *ngIf="navBar.opened">

            <table mat-table #table [dataSource]="dataSource" *fuseIfOnDom matSort>

               <ng-container matColumnDef="data">
                   <td mat-cell *matCellDef="let row">

                       <div [ngClass]="{'cardRead' : row.read}"
                            class="card-notification bg-{{getTypeName(row.notificationType)}}
                            " fxLayout="column"
                            fxLayoutAlign="start start">
                           <div fxLayout="row" fxLayoutAlign="space-between start">
                               <button mat-fab
                                   class="mt-8 mat-icon-button notif-icon {{getTypeName(row.notificationType)}} m-4 w-32 h-32"
                                   aria-label="type notification">
                                   <mat-icon [svgIcon]="getIcon(row.notificationType)" class="type-icon s-16" ></mat-icon>
                               </button>


                               <div class="pl-8 pr-8 mt-8 content"
                                    fxFlex fxLayout="column"
                                    fxLayoutAlign="start start"
                                    fxLayoutGap="8px">

                                   <label>{{ row.label | translate }}</label>

                               </div>

                               <button mat-mini-fab class="mat-icon-button close-btn w-16 h-16 m-4"
                                       aria-label="Close dialog"
                                       (click)="deleteNotification(row, $event)">
                                   <mat-icon svgIcon="cross"></mat-icon>
                               </button>
                           </div>

                           <div fxFlex class="w-100-p px-4 py-8 authField" fxLayout="row" fxLayoutAlign="space-between center">
                               <label>{{row.data?.author}}</label>
                               <label>{{ row.formattedDate }}</label>
                           </div>
                       </div>


                   </td>

                </ng-container>

                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                    (click)="onSelect(row)"
                    [ngClass]="{'selected':row == selected}"
                >
                </tr>
            </table>


    <mat-list align="end" *ngIf="notificationsService.unreadNotificationsCount > 0" class="pr-24">
        <button mat-button (click)="notificationsService.setAllAsRead()">
            <span>{{ 'notification.read_all' | translate }}</span>
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </mat-list>

    <mat-list align="end" *ngIf="notificationsService.notificationsCount > 0" class="pr-24">
        <button mat-button (click)="deleteAll()">
            <span>{{ 'notification.delete_all' | translate }}</span>
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </mat-list>

    <mat-list align="end" *ngIf="notificationsService.settings['seeAll']" class="pr-24">
        <button mat-button (click)="goTo('/notifications')">
            <span>{{ 'notification.see_all' | translate }}</span>
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </mat-list>

</ng-container>
