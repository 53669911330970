// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-app-tags-list .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}
fuse-app-tags-list .mat-radio-button ~ .mat-radio-button {
  height: 53px;
  width: 53px;
}
fuse-app-tags-list .mat-radio-button {
  /* this rule for the color of the center of your radio button :*/
}
fuse-app-tags-list .mat-radio-button .mat-radio-outer-circle,
fuse-app-tags-list .mat-radio-button .mat-radio-inner-circle {
  height: 53px;
  width: 53px;
  border: none;
  background: transparent;
}
fuse-app-tags-list .mat-radio-button.mat-accent {
  /*circle which appears when you click on the radio button :*/
}
fuse-app-tags-list .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: transparent;
}
fuse-app-tags-list .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  box-shadow: initial;
  box-sizing: initial;
}
fuse-app-tags-list .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
  background: transparent;
}
fuse-app-tags-list .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/tags-selection/tags-selection-modal-wrapper/tags-selection-modal-wrapper.component.scss"],"names":[],"mappings":"AAIE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAHJ;AAME;EACE,YAAA;EACA,WAAA;AAJJ;AAOE;EACE,gEAAA;AALJ;AAMI;;EAEE,YAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;AAJN;AAOI;EAaE,4DAAA;AAjBN;AAKM;EACE,uBAAA;AAHR;AAMQ;EACE,mBAAA;EACA,mBAAA;AAJV;AAMQ;EACE,uBAAA;AAJV;AASQ;EACE,6BAAA;AAPV","sourcesContent":["@import \"../../../scss/fuse\";\n\nfuse-app-tags-list {\n\n  .dialog-content-wrapper {\n    max-height: 85vh;\n    display: flex;\n    flex-direction: column;\n  }\n\n  .mat-radio-button ~ .mat-radio-button {\n    height: 53px;\n    width: 53px;\n  }\n\n  .mat-radio-button {\n    /* this rule for the color of the center of your radio button :*/\n    .mat-radio-outer-circle,\n    .mat-radio-inner-circle {\n      height: 53px;\n      width: 53px;\n      border: none;\n      background: transparent;\n    }\n\n    &.mat-accent {\n      .mat-radio-inner-circle {\n        background: transparent;\n      }\n      &.mat-radio-checked {\n        .mat-radio-outer-circle {\n          box-shadow: initial;\n          box-sizing: initial;\n        }\n        .mat-radio-inner-circle {\n          background: transparent;\n        }\n      }\n      /*circle which appears when you click on the radio button :*/\n      .mat-radio-ripple {\n        .mat-ripple-element {\n          background-color: transparent;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
