<div class="content p-24 mat-white-bg" fxFlex fxLayout="column">
    <div class="text-center">
        <mat-icon *ngIf="showFlag()" svgIcon="flag" class="header-icon"
                  [ngClass]="{'flag-green' : isLessonValidated || isLessonCorrected}"></mat-icon>

        <!-- MODE TEST or TRAINING -->
        <h1 *ngIf="isLessonTest || isLessonTraining"
            class="heading">{{ 'activities.lesson.end_training' | translate }}</h1>

        <!-- MODE EVALUATION - Running -->
        <div *ngIf="isLessonEvaluationPending">
            <p>{{ 'activities.lesson.end_evaluation_pending_answered' | translate }}</p>
            <p>{{ 'activities.lesson.end_evaluation_pending_complete' | translate }}</p>
        </div>

        <!-- MODE EVALUATION - Validated -->
        <h1 *ngIf="isLessonEvaluationValidated"
            class="heading">{{ 'activities.lesson.end_evaluation_validated' | translate }}</h1>
        <p *ngIf="isLessonEvaluationValidated">
            {{ 'activities.lesson.end_evaluation_validated_see_answers' | translate }}
        </p>

        <!-- MODE EVALUATION - Corrected -->
        <h1 *ngIf="isLessonEvaluationCorrected"
            class="heading">{{ 'activities.lesson.end_evaluation_corrected' | translate }}</h1>
        <p *ngIf="isLessonEvaluationCorrected">
            {{ 'activities.lesson.end_evaluation_corrected_training' | translate }}
        </p>

        <ul id="review" *ngIf="!hideReview">
            <li *ngFor="let activity of activitiesService.activitiesArray; let activityIndex = index; " class="active"
                [ngClass]="{'validated': (isStatusAnswered(activityIndex) && isLessonEvaluation && !isLessonEvaluationCorrected) || !isActivity(activityIndex),
                            'success': isStatusCorrect(activityIndex) && (isLessonEvaluationCorrected || !isLessonEvaluation),
                            'danger': isStatusWrong(activityIndex) && (isLessonEvaluationCorrected || !isLessonEvaluation),
                            'warning': isStatusMissing(activityIndex) && (isLessonEvaluationCorrected || !isLessonEvaluation)}"
                (click)="gotoCurrentPage($event, activityIndex)">


                <mat-icon *ngIf="activity.badge" svgIcon="{{stepIcon(activityIndex)}}"></mat-icon>

                {{ activity.label }}

            </li>
        </ul>

        <p *ngIf="displayGrade && isLessonCorrected">
            {{ 'generic.grade' | translate }} : {{getGrade()}}
        </p>
        <p *ngIf="displayGrade && !isLessonCorrected">
            {{ 'generic.grade_temporary' | translate }} : {{getGrade()}}
        </p>

        <ng-container *ngIf="showDynamicReward()">
            <h1 class="heading">
                {{ 'activity_recap.dynamic_reward_head' | translate }}
            </h1>
            <img class="reward" [alt]="dynamicRewardMilestone"
                 [src]="'assets/'+ brand + '/images/dynamic_reward/' + dynamicRewardMilestone + '.svg'"/>
            <h1 class="heading">
                {{ 'activity_recap.dynamic_reward_' + dynamicRewardMilestone + '_label' | translate }}
            </h1>
        </ng-container>
    </div>
    <div class="metacognition" *ngIf="graphReady" style="background: white; padding: 20px">
        <Label style="font-weight: bold">{{ 'activities.well_done' | translate}}</Label><br/>
        <Label>{{ 'activities.metacognition_result' | translate}}</Label>
        <canvas #chart
                baseChart
                [datasets]="radarChartData"
                [options]="radarChartOptions"
                [labels]="radarChartLabels"
                [chartType]="radarChartType"
                [colors]="chartColors"
                (chartClick)="onChartClick($event)">
        </canvas>
        <app-graph-details-modal
            *ngIf="showTooltip"
            [infoSettings]="infoSettings">
        </app-graph-details-modal>
    </div>

    <!-- TODO move buttons to user-action-button -->
    <mat-card-actions class="activities-card">
        <ng-container *ngIf="displayOptionalLessonRecapButton">
            <button  class="h-52 previous"
                    (click)="goBack($event)">
                {{ 'generic.previous' | translate }}
            </button>
            <button  class="h-52 see-correction"
                    *ngIf="isLessonTraining && !isUserTrainer"
                    (click)="restartPlay($event, false)">
                {{ 'activities.lesson.see_correction' | translate }}
            </button>
            <button  class="h-52"
                    *ngIf="isLessonTest || isLessonTraining && !isUserTrainer"
                    (click)="restartPlay($event, true)">
                {{ 'generic.restart' | translate }}
            </button>
            <button  class="h-52 complete-evaluation"
                    *ngIf="isLessonEvaluationPending && !isUserTrainer"
                    (click)="completeMyHomeWork()">
                {{ 'activities.lesson.complete_evaluation' | translate }}
            </button>
            <button  class="h-52 validate-evaluation"
                    *ngIf="isLessonEvaluationPending && !isUserTrainer"
                    (click)="validateAnswer($event)" [disabled]="loading">
                {{ 'activities.lesson.validate_evaluation' | translate }}
                <mat-spinner *ngIf="loading" class="ml-8"></mat-spinner>
            </button>
            <button  class="h-52 my-evaluation"
                    *ngIf="isLessonEvaluationValidated"
                    (click)="onMyHomeWork($event)">
                {{ 'activities.lesson.my_evaluation' | translate }}
            </button>
        </ng-container>
        <button  class="h-32 next"
                (click)="gotoListPage($event)">
            {{ 'generic.exit' | translate }}
        </button>
    </mat-card-actions>
</div>


