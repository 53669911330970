<div id="app-feedback" class="page-layout simple fullwidth">
    <div class="content">
        <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">

            <img src="assets/{{ assetbrand }}/images/logos/feedback.png" alt="">

            <mat-form-field>
                <mat-label>{{'account-management.email' | translate}}</mat-label><input matInput type="text" 
                       formControlName="email">
                <mat-error>
                    {{ 'generic.email_error' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'error_reporting.subject_label' | translate}}</mat-label><mat-select name="category" 
                            formControlName="category" (selectionChange)="changeCategory($event)">
                    <mat-option *ngFor="let category of categoryList" [value]="category.id">
                        {{ category.label | translate }}</mat-option>
                </mat-select>
                <mat-error>
                    {{ 'generic.field.required' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- only in case of bug and only if detailBugSetting is activated -->
            <ng-container *ngIf="bugDetailInfo && feedbackUseInputFieldForBug">
                <mat-checkbox name="is-bug-computer"
                              (change)="changeDataAutoDetectForBug($event.checked)"
                              aria-label="{{ 'account-management.is_bug_computer' | translate }}"
                              formControlName="bugcomputer">{{'account-management.bug_on_your_computer' | translate}}</mat-checkbox>
                <mat-form-field>
                    <mat-label>{{'account-management.bug_navigator' | translate}}</mat-label><input matInput type="text" 
                           formControlName="navigator" required>
                    <mat-error>
                        {{ 'generic.navigator_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'account-management.bug_os' | translate}}</mat-label><input matInput type="text" 
                           formControlName="os" required>
                    <mat-error>
                        {{ 'generic.os_required' | translate }}
                    </mat-error>
                </mat-form-field>
                <app-upload-file-generic (file)="uploadFileEvent($event)"
                                         (error)="fileError($event)"
                                         [errorMsg]="fileErrorMsg"
                                         [title]="'account-management.upload_file_title' | translate"
                                         [btnLabel]="'account-management.upload_file_btn_label' | translate"
                                         [description]="'account-management.upload_description' | translate"
                                         [maxSizeFile]="maxFileSize">
                </app-upload-file-generic>
                <div *ngFor="let f of files">
                    <span>{{f.name}}</span>
                    <mat-icon svgIcon="cross" (click)="removeFile(f.name)"></mat-icon>
                </div>
                <mat-error *ngIf="tooManyFilesError">
                    {{ 'generic.too_many_files' | translate }}
                </mat-error>
                <mat-form-field>
                     <mat-label>{{'account-management.bug_description' | translate}}</mat-label><textarea matInput 
                               formControlName="description" required></textarea>
                    <mat-error>
                        {{ 'generic.description_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-container>

            <mat-form-field>
                <mat-label>{{'messaging.message' | translate}}</mat-label><textarea matInput 
                          formControlName="content"></textarea>
                <mat-error>
                    {{ 'generic.field.required' | translate }}
                </mat-error>
            </mat-form-field>

            <button type="submit" mat-raised-button color="accent" style="width: 100px; height: 100px;"
                    [disabled]="!feedbackForm.valid">{{'account-management.send_feedback' | translate}}</button>
            <span *ngIf="isMessageSend"> {{'generic.message.sended' | translate}}</span>
            <mat-error *ngIf="messageSendError">
                {{'generic.message.not_send' | translate }}
            </mat-error>
        </form>
    </div>
</div>