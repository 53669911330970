<div class="friseContainer">
    <!--loader when loading data-->
    <div class="example-loading-shade"
         *ngIf="!timelineService.dataReady">
        <mat-spinner></mat-spinner>
    </div>
    <ng-container *ngIf="timelineService.dataReady">
        <!-- main timeline title -->
        <div class="centerInlineBlock">
            <div class="mainTitle">
                <h3 class="mainFirstTitleContent">{{timelineService.title.titledotPeriod}}</h3>
                <p class="mainTitleContent">{{timelineService.title.dates}}</p>
            </div>
        </div>

        <button mat-mini-fab
                *ngIf="canClose"
                class="close-timeline"
                (click)="close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>

        <!--image container-->
        <app-viewer
                    [moveTo]="timelineService.maPosition"
                    [images]="timelineService.imageList"
                    [globalImageWidth]="timelineService.timelineData.dynamic_size"
                    [paramsZoneAround]="timelineService.paramsZone"
                    [activateGesture]="timelineService.settings.activeGesture"
                    (openDocument)="timelineService.openBasicModalWithInfo($event)"
                    (currentImage)="timelineService.currentImage($event)"></app-viewer>

        <!--slider-->
        <app-slider [min]="timelineService.timelineData.dynamic_date.start_date"
                    [max]="timelineService.timelineData.dynamic_date.end_date"
                    [value]="timelineService.currentSliderValue"
                    [separators]="timelineService.positionSeparateurZone"
                    [dots]="timelineService.arrayDot"
                    [dotsMiddle]="timelineService.arrayMiddleDot"
                    [zoneColor]="timelineService.arrayPeriodeColor"
                    [positionIntervals] = "timelineService.positionIntervals"
                    [isActiveCursorDate] = "timelineService.settings.isActiveCursorDate"
                    (moveFrise)="timelineService.sliderMove($event)"
                    (clickFrise)="timelineService.sliderClick($event)"></app-slider>
    </ng-container>
</div>
