<div class="user-review">

    <div class="user-review__header">
        <ng-container *ngIf="isUserAtLeastTrainer(); else learnerTitleTemplate">
            <h1 class="user-review__title">{{ 'user-review.review_content_question_for_teacher' | translate }}</h1>
        </ng-container>
        <ng-template #learnerTitleTemplate>
            <h1 class="user-review__title">{{ 'user-review.review_content_question_for_learner' | translate }}</h1>
        </ng-template>
    </div>

    <div class="user-review__content">
        <button class="user-review__very-dissatisfied-button"
                [attr.aria-label]="'user-review.very_dissatisfied' | translate"
                (click)="selectReviewChoice(ReviewChoices.dissatisfied)">
            <mat-icon class="user-review__very-dissatisfied-icon"
                      svgIcon="user-review__very-dissatisfied">
            </mat-icon>
        </button>
        <button class="user-review__dissatisfied-button"
                [attr.aria-label]="'user-review.dissatisfied' | translate"
                (click)="selectReviewChoice(ReviewChoices.sad)">
            <mat-icon class="user-review__dissatisfied-icon"
                      svgIcon="user-review__dissatisfied">
            </mat-icon>
        </button>
        <button class="user-review__satisfied-button"
                [attr.aria-label]="'user-review.satisfied' | translate"
                (click)="selectReviewChoice(ReviewChoices.satisfied)">
            <mat-icon class="user-review__satisfied-icon"
                      svgIcon="user-review__satisfied">
            </mat-icon>
        </button>
        <button class="user-review__very-satisfied-button"
                [attr.aria-label]="'user-review.very_satisfied' | translate"
                (click)="selectReviewChoice(ReviewChoices.verysatisfied)">
            <mat-icon class="user-review__very-satisfied-icon"
                      svgIcon="user-review__very-satisfied">
            </mat-icon>
        </button>
    </div>

    <div class="user-review__footer">
        <button class="user-review__skip-button"
                (click)="notNowAction()">
            {{ "generic.not_now" | translate }}
        </button>
    </div>

</div>