<table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{'licensing.method' | translate}} </th>
        <td mat-cell *matCellDef="let method"> {{method.name}} </td>
    </ng-container>

    <ng-container matColumnDef="unlockedDate">
        <th mat-header-cell *matHeaderCellDef> {{'licensing.method.date' | translate}} </th>
        <td mat-cell *matCellDef="let method"> {{method.unlockedDate}} </td>
    </ng-container>

    <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef> {{'licensing.method.expiration.date' | translate}} </th>
        <td mat-cell *matCellDef="let method" [ngClass]="{'warning' : method.expired}"> {{method.expirationDate}} </td>
    </ng-container>

    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> {{'licensing.method.code' | translate}} </th>
        <td mat-cell *matCellDef="let method"> {{method.code}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
