<div class="content">
  <div class="title">Errors console</div>
  <div class="error-container">
    <div *ngFor="let error of errors">
      {{ error.message }}: {{ error.originalMessage }} ({{ error.code }})
    </div>
  </div>
  <div class="buttons">
    <input type="button" (click)="ignoreAll()" value="Ignore all">
    <input type="button" (click)="clear()" value="Clear">
    <input type="button" (click)="close()" value="Close">
  </div>
</div>
