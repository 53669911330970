<div
    fxLayout="column"
    fxLayoutAlign="center center"
    id="register-form-wrapper"
>
    <div
        *fuseIfOnDom
        [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
        class="w-50-p"
        fxLayout="column"
        fxLayoutGap="30px"
        id="register-form"
        ngClass.lt-md="w-100-p"
    >
        <div>{{ "account-management.redirect-description" | translate }}</div>
        <button
            (click)="redirectRegistration()"
            class="submit-button"
            color="accent"
            mat-raised-button
        >
            {{ "account-management.redirect-action" | translate }}
        </button>
    </div>
</div>
