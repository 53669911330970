<div class="dialog-content-wrapper">
    <div mat-dialog-title>
        <h2>
            {{'generic.two_steps_selection_filter_title' | translate}}
        </h2>
        <button class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="p-24 m-0">
        <form novalidate>
            <div class="mb-24"
                 fxLayout="row"
                 fxLayoutAlign="start start">
                <mat-form-field class="w-100-p">
                    <mat-label>{{ 'generic.two_steps_selection_Title' | translate }}</mat-label><mat-select [(value)]="selectedValue"
                                
                                (selectionChange)="selectElement($event)">
                        <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                        <mat-option [value]="d.id" *ngFor="let d of onlyDataNotHidden">
                            {{ d.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field [ngStyle]="{'display': dataUnderList.length > 0?'':'none'}"
                            class="w-100-p" *ngIf="!data.params.isMultiChoiceAllowed">
                <mat-label>{{ 'generic.two_steps_selection_Title_underlist' | translate }}</mat-label><mat-select
                    [(value)]="selectedValueSecondList"
                    
                    (selectionChange)="selectElementSecondList($event)">
                    <mat-option [value]="d.id" *ngFor="let d of dataUnderList">
                        {{ d.label }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <div *ngIf="data.params.isMultiChoiceAllowed"
                 class="mb-24 fontBold">{{selectedValues.length}}
                <span
                    *ngIf="selectedValues.length < 2">{{ 'generic.two_steps_selection_selected_value' | translate}}</span>
                <span
                    *ngIf="selectedValues.length > 1">{{ 'generic.two_steps_selection_selecteds_value' | translate}}</span>
                <button mat-icon-button (click)="reset()" *ngIf="selectedValues.length > 0">
                    <mat-icon svgIcon="cross"></mat-icon>
                </button>
            </div>

            <div *ngIf="data.params.isMultiChoiceAllowed"
                 class="mb-24 h-400" fusePerfectScrollbar>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="label">
                        <th mat-header-cell *matHeaderCellDef>{{ 'generic.two_steps_title_array' | translate }}</th>
                        <td mat-cell *matCellDef="let data">
                            <p class="text-truncate">{{data.label}}</p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let data; columns: displayedColumns;"
                        class="entity"
                        (click)="selectedState(data)"
                        [ngClass]="{'selected': checkSelected(data) > -1}">
                    </tr>
                </table>
            </div>
        </form>
    </div>

    <div class="example-loading-shade"
         *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button
                (click)="validate()"
                [disabled]="isLoading || selectedValues.length === 0"
                class="save-button mat-accent"
                aria-label="SAVE">
            {{ 'generic.validate' | translate }}
        </button>
    </div>
</div>