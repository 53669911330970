<button *ngFor="let item of data"
        class="memory-find-zone__button"
        [ngClass]="{
            'button-image': item.selected || item.state !== 'pristine',
            'currently-correct': item.state === 'currently-correct',
            'is-playing': soundIsPlaying && currentSoundUid === item.uid,
            'is-audio': !(soundIsPlaying && currentSoundUid === item.uid),
            'is-selected': item.selected,
            'memory-find-zone__button--flipped': !(item.selected || item.state !== 'pristine'),
            'was-correct': item.state === 'was-correct',
        }"
        [disabled]="disable"
        [attr.aria-label]="'accessibility_card_to_return' | translate"
        (click)="changeState(item.uid)">
    <ng-container *ngIf="item.selected || item.state !== 'pristine'">
        <img class="memory-find-zone__button-image"
             [attr.src]="item.image.uri"
             [attr.alt]="item.image.alt">
        <div *ngIf="getTitle(item.uid)"
             class="memory-find-zone__button-title">
            {{ getTitle(item.uid) }}
        </div>
    </ng-container>
</button>

<!--id si dynamic because he must be unique on a page-->
<audio class="memory-find-zone__audio"
       [attr.id]="idDivPlaySound"
       controls>
    Your browser does not support the <code>audio</code> element.
</audio>