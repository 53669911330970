<mat-card class="activities-card inner-scroll mat-elevation-z0 " >
    <mat-card-header>
        <mat-card-title>
            <app-consignes *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))" [instruction]="instruction"   [ngClass]="{'consigneImg': isInstructionImg()}" [instructionAudio]="instructionAudio" [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-consignes *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))" [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"  [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"  [wordingAudio]="wordingAudio"></app-consignes>

        <!--middle center-->
        <div fxFlex fxLayout="column" fxLayoutAlign="space-around center">
            <!-- TODO add alt text to the image, set in the back -->
            <img *ngIf="imageUrl" [src]="imageUrl" >
        </div>
    </mat-card-content>
</mat-card>