<div mat-dialog-title>
    <h2>{{metadata?.title}}</h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>

    <!--objective-->
    <div class="details-container" *ngIf="display( 'description')">
        <span class="details-header">{{'generic.objective' | translate}}</span>
        <p>{{metadata?.description}}</p>
    </div>

    <!--compétences worked-->
    <div class="details-container" *ngIf="display( 'skills')">
        <span class="details-header">{{'generic.skills-Worked' | translate}}</span>
        <div *ngFor="let skill of metadata?.skills">
            <p>{{skill.label}}</p>
        </div>
    </div>

    <!--complexity-->
    <div class="details-container" *ngIf="display( 'difficulty')">
        <span class="details-header">{{'generic.complexity' | translate}}</span>
        <p>{{metadata?.difficulty.label}}</p>
    </div>

    <!--type of exercice-->
    <div class="details-container" *ngIf="display( 'concepts')">
        <span class="details-header">{{'generic.type-of-exercises' | translate}}</span>
        <div *ngFor="let concept of metadata?.concepts">
            <p>{{concept.label}}</p>
        </div>
    </div>
</mat-dialog-content>