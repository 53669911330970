<div class="end-screen-score"
     [ngClass]="'end-screen-score--reward-count-' + reward">

    <div class="end-screen-score__star-wrapper">
        <div class="end-screen-score__coin"
             [ngClass]="{'end-screen-score__coin--visible': isToshow(0)}">
            <div class="end-screen-score__coin-count">+1</div>
            <mat-icon class="end-screen-score__coin-icon"
                      svgIcon="coin">
            </mat-icon>
        </div>
        <mat-icon class="end-screen-score__star-icon"
                  svgIcon="star">
        </mat-icon>
    </div>

    <div class="end-screen-score__star-wrapper">
        <div class="end-screen-score__coin"
             [ngClass]="{'end-screen-score__coin--visible': isToshow(1)}">
            <div class="end-screen-score__coin-count">+2</div>
            <mat-icon class="end-screen-score__coin-icon"
                      svgIcon="coin">
            </mat-icon>
        </div>
        <mat-icon class="end-screen-score__star-icon"
                  svgIcon="star">
        </mat-icon>
    </div>

    <div class="end-screen-score__star-wrapper">
        <div class="end-screen-score__coin"
             [ngClass]="{'end-screen-score__coin--visible': isToshow(2)}">
            <div class="end-screen-score__coin-count">+3</div>
            <mat-icon class="end-screen-score__coin-icon"
                      svgIcon="coin">
            </mat-icon>
        </div>
        <mat-icon class="end-screen-score__star-icon"
                  svgIcon="star">
        </mat-icon>
    </div>
    
    <div *ngIf="reward === 4"
         class="end-screen-score__diamond-wrapper">
        <div class="end-screen-score__coin"
             [ngClass]="{'end-screen-score__coin--visible': isToshow(3)}">
            <div class="end-screen-score__coin-count">+4</div>
            <mat-icon class="end-screen-score__coin-icon"
                      svgIcon="coin">
            </mat-icon>
        </div>
        <mat-icon class="end-screen-score__diamond-icon"
                  svgIcon="diamond">
        </mat-icon>
    </div>

</div>