<header class="app-demo-choice__header">
    <h1 class="app-demo-choice__header__title">{{'demo.choice-title' | translate}}</h1>
    <p class="app-demo-choice__header__sub-title">{{'demo.choice-description' | translate}} </p>
</header>
<main class="app-demo-choice__content">
    <div class="grid">
        <section class="app-demo-choice__article-junior">
            <mat-icon svgIcon="logo_CTZC_junior"></mat-icon>
            <h1 class="visually-hidden">{{'demo.choice-junior-title'  | translate}}</h1>
            <p class="app-demo-choice__article__suggestedAge">{{'demo.choice-junior-suggested-age' | translate}}</p>
            <p class="app-demo-choice__article__description">{{'demo.choice-junior-description' | translate}}</p>
            <button (click)="playJunior()">{{'demo.choice-button-junior' | translate}}</button>
        </section>
        <section class=" app-demo-choice__article-college">
            <mat-icon svgIcon="logo_CTZC_college"></mat-icon>
            <h1 class="visually-hidden">{{'demo.choice-college-title' | translate}} </h1>
            <p class="app-demo-choice__article__suggestedAge">{{'demo.choice-college-suggested-age' | translate}}</p>
            <p class="app-demo-choice__article__description">{{'demo.choice-college-description' | translate}}</p>
            <button (click)="playCollege()">{{'demo.choice-button-college' | translate}}</button>
        </section>
        <section class="app-demo-choice__article-python">
        <mat-icon svgIcon="logo_CTZC_python"></mat-icon>
        <h1 class="visually-hidden">{{'demo.choice-python-title' | translate}} </h1>
        <p class="app-demo-choice__article__suggestedAge">{{'demo.choice-college-suggested-age' | translate}}</p>
        <p class="app-demo-choice__article__description">{{'demo.choice-python-description' | translate}}</p>
        <button (click)="playPython()">{{'demo.choice-button-python' | translate}}</button>
    </section>
    </div>

</main>
