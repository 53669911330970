<ng-container *ngIf="flashCard">
    <figure *ngIf="flashCard?.image?.uri"
            class="flashcard__figure">
        <img class="flashcard__image"
             [src]="flashCard?.image?.uri"
             [alt]="flashCard?.image?.alt">
        <figcaption *ngIf="!flashCard?.audio?.uri || (flashCard?.audio?.uri && !showTitleOnlyIfNoAudio)"
                    class="flashcard__caption">
            {{ flashCard?.title }}
        </figcaption>
    </figure>
    <audio *ngIf="flashCard?.audio?.uri"
           class="flashcard__audio"
           #stream
           controls
           [attr.id]="flashCard?.audio?.id"
           [attr.src]="flashCard?.audio?.uri"
           [attr.aria-label]="flashCard?.audio?.alt">
        Your browser does not support the <code>audio</code> element.
    </audio>
</ng-container>