<div mat-dialog-title>
    <h2>
        {{'generic.warning' | translate}}
    </h2>
    <button (click)="close()"
            aria-label="Close dialog"
            class="close">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-card>
    <mat-card-content>
        <p>{{ 'generic.mobile_not_recommanded' | translate}}<br/>
            <span class="bold">{{ 'generic.connection_on_non_mobile_device' | translate}}</span><br/>
            <span>{{'generic.see_more_before_subscription' | translate}}</span>
        </p>
    </mat-card-content>
    <mat-card-footer>
        <div mat-dialog-actions>
            <button mat-raised-button class="btn-click-here" (click)="close()">
                <span>{{'generic.click_here' | translate}}</span>
            </button>
        </div>
    </mat-card-footer>
</mat-card>
