<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>
    <div class="content">
        <div *ngIf="isLoadingDone else loadingBlock" class="content-editor">
            <div class="gamecode-content">
                <iframe [src]="urlToGamecode" height="100%" width="100%"></iframe>
            </div>
        </div>
    </div>
</div>
