<div mat-dialog-title>
    <h2>
        {{ 'corpus.import_file' | translate }}
    </h2>

    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<div class="example-loading-shade"
     *ngIf="uploading">
    <mat-spinner *ngIf="uploading"></mat-spinner>
</div>

<div mat-dialog-content class="corpus-upload">
    <div class="content p-24" *ngIf="uploadAllowed.localFile" fusePerfectScrollbar>
        <div class="center text-center">
            <span>{{ 'corpus.import_url.informations' | translate }}</span><br>
            <em><small>{{ 'corpus.import_url.details' | translate }} {{ allowedExtensions }}</small></em>
<!--        TODO déprécié headertext="{{ 'corpus.upload_dragndrop_message' | translate }}"-->
<!--        TODO déprécié customstyle="upload-dragndrop"-->
            <ngx-file-drop [showBrowseBtn]="true" [browseBtnLabel]="'generic.browse' | translate" [dropZoneLabel]="'corpus.upload_dragndrop_message' | translate" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                <button mat-raised-button mat-primary class="add-file-button" (click)="fileInput.click()"
                        aria-label="Add file">
                    <span>{{ 'generic.browse' | translate }}</span>
                </button>
            </ngx-file-drop>
        </div>
    </div>

    <div class="content-url-import" *ngIf="uploadAllowed.url">
        <div fxLayout="column">
            <span>{{ 'corpus.import_url' | translate }}</span>
            <mat-form-field fxFlex="100">
                <input matInput type="text" placeholder="Url" [(ngModel)]="sentUrl" [disabled]="uploading">
            </mat-form-field>
        </div>
    </div>
    <div class="file-uploader">
        <input hidden type="file" [attr.accept]="fileFormatAllowedInExplorer" #fileInput
               (change)="onFileChanged($event)" id="fileToUpload"/>
    </div>

    <div mat-dialog-actions class="pt-24 val">
        <button mat-raised-button color="accent" class="submit-button" value="{{ 'generic.validate' | translate }}"
                (click)="validateUrl()" [disabled]="isValidateDisable()">
            <span>{{ 'generic.validate' | translate }}</span>
        </button>
    </div>
</div>