<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="page-layout simple fullwidth" fxFlex fxLayout="column">
    <div class="content" fxFlex fxLayout="column">
        <app-filters (currentLessonsAndIdGroup)="reloadGraphWithcurrentIdLessonsAndIdGroup($event)" [tabIndex]="this.index" [fieldsAllowedInInfoPopUp]="fieldsAllowedInInfoPopUp"></app-filters>
        <div class="graph-selectors" fxLayout="row">
            <button (click)="selectGraph(graph, index)" *ngFor="let graph of graphs;let index = index;" [ngClass]="{'active': selectedGraph === graph}" mat-button>{{graph.label | translate}}</button>
        </div>
        <div *ngIf="graphIsLoading === false else loadingBlock" class="graph-section" fxFlex fxLayout="row">
            <app-graph-progression (graphClick)="onGraphClick($event)" [barChartData]="chartData" [barChartLabels]="chartLabels"
                                   fxFlex="60"></app-graph-progression>
            <app-details-list *ngIf="displayList" [data]="details" fxFlex="35"></app-details-list>
        </div>
    </div>
</div>
