<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">

  <table mat-table
         #table
         *ngIf="empty"
         [dataSource]="dataSource"
         fxFlex="100"
         [@animateStagger]="{value:'50'}">

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
        {{ 'generic.type' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" fxHide.xs>
        <span class="pastille pastille-icon" [ngClass]="row?.attributes?.type_term?.label" *ngIf="row?.attributes?.type_term">
            <mat-icon svgIcon="{{row?.attributes?.type_term?.label}}"
                      title="{{ 'assignment.type.' + row?.attributes?.type_term?.label | translate }}">
            </mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
        {{ 'generic.title' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  fxHide.xs>
        {{ row?.attributes?.lesson }}
      </td>
    </ng-container>

      <ng-container matColumnDef="start_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
          {{ 'generic.start_date' | translate }}
        </th>
        <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-md>
              <span *ngIf="row?.attributes?.type_term?.label !== 'assessment'">
                  {{localeDate(row?.attributes?.startDate)}}
              </span>
              <span *ngIf="row?.attributes?.type_term?.label === 'assessment'">
                  {{localeTime(row?.attributes?.startDate)}}
              </span>
        </td>
      </ng-container>


    <ng-container matColumnDef="end_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
        {{ 'generic.deadline' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-xs>
          <span>
              <span>
                  <span *ngIf="row?.attributes?.type_term?.label !== 'assessment'">
                      {{localeDate(row?.attributes.endDate)}}
                  </span>
                  <span *ngIf="row?.attributes?.type_term?.label === 'assessment'">
                      {{localeTime(row?.attributes?.endDate)}}
                  </span>
              </span>
          </span>
      </td>
    </ng-container>

      <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
              {{ 'groups-management.my_classes' | translate }}
          </th>
          <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-md>
              {{ filterGroupsOnly(row?.attributes.group) }}
          </td>
      </ng-container>

      <ng-container matColumnDef="workgroup">
          <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
              {{ 'groups-management.workgroups' | translate }}
          </th>
          <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-md>
              {{ filterWorkGroupsOnly(row?.attributes.group) }}
          </td>
      </ng-container>

    <ng-container matColumnDef="learners">
      <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
        {{ 'generic.learners' | translate }}
      </th>
      <td mat-cell *matCellDef="let row"  fxHide fxShow.gt-xs>
        {{countAssignments(row)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
        {{ 'generic.progress' | translate }}
      </th>
      <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
        {{getProgress(row)}}%
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        matRipple
        [@animate]="{value:'*',params:{y:'100%'}}"
        (click)="openAssignmentsGroup(row)">
    </tr>
  </table>

  <div *ngIf="!empty" class="emptyResourceLabel">{{ 'generic.assignment_group_empty_granule' | translate}}</div>
</div>