<div fxLayout="column" class="page-layout simple fullwidth inner-scroll markerInfosContainer" fxFlex>

    <div mat-dialog-title>
        <h2 class="mat-dialog-title-text" *ngIf="isEditor">{{addOrEditTitle | translate}}&nbsp;:&nbsp;{{titleType | translate}}</h2>
        <h2 class="mat-dialog-title-text" *ngIf="!isEditor">{{titleType | translate}}</h2>
        <button
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>


    <mat-dialog-content>
        <form *ngIf="step === 0" [formGroup]="form0" [ngClass]="isEditor?'editor-mode':'play-mode'"
              fxLayout="column" fxLayoutAlign="none">

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">

                <mat-form-field *ngIf="isEditor && displayField('title')" class="title-field" fxFlex="50">
                    <mat-label>{{ 'activities.title-clue' | translate }}</mat-label><input matInput formControlName="title" />
                </mat-form-field>
                <mat-form-field *ngIf="isEditor && displayField('location')" class="location-field" fxFlex="50">
                    <mat-label>{{ 'activities.location-clue' | translate }}</mat-label><input matInput formControlName="location"
                           />
                </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <div [style.position]="'relative'" [style.width.%]="'100'">
                    <app-video-with-markers
                        [videoConfig]="videoConfig"
                    ></app-video-with-markers>
                    <div class="disable-video-controls"></div>
                </div>
            </div>

            <mat-form-field *ngIf="displayField('question')" class="question-field" >
                <mat-label>{{(isEditor?'activities.title_clue' : '') | translate }}</mat-label><input matInput formControlName="question" />
            </mat-form-field>

            <div fxFlex="1" fxLayout="row" fxLayoutGap="8px" class="form-field-row">

                <div fxFlex="50" class="form-field-column-one">
                    <mat-form-field *ngIf="displayField('clue')" fxFill class="field-full-height clue-field">
                        <mat-label>{{ 'activities.location_clue' | translate }}</mat-label><textarea matInput
                                  id="clue"
                                  formControlName="clue"
                                  cdkTextareaAutosize
                                  ></textarea>
                    </mat-form-field>
                </div>

                <div fxFlex="50" fxLayout="column" class="form-field-column-two">
                    <div class="mat-mdc-form-field-fusion" fxLayout="column">
                        <mat-form-field *ngIf="displayField('tracks')">
                            <mat-label>{{ 'activities.tracks_clue' | translate }}</mat-label><textarea id="tracks"
                                      matInput
                                      formControlName="tracks"
                                      cdkTextareaAutosize
                                      ></textarea>
                        </mat-form-field>
                        <mat-form-field *ngIf="displayField('url')">
                            <ng-container *ngFor="let data of urlandString">
                                <a *ngIf="data.isUrl" [href]="data.text" target="_blank">{{data.text}}</a>
                                <span *ngIf="!data.isUrl">{{data.text}}</span>
                            </ng-container>

                            <mat-label>{{ 'activities.url_clue' | translate }}</mat-label><input [hidden]="useUrlLink()" matInput formControlName="url"
                                   >


                        </mat-form-field>
                    </div>
                </div>

            </div>

        </form>

        <form *ngIf="step === 1" [formGroup]="form1" fxLayout="column" fxLayoutAlign="start center"
              class="note">
            <h2 class="form-title-text">{{'activities.survey_notes' | translate}}</h2>

            <button *ngIf="isEditor === false" class="download-pdf" (click)="savePDF()" >
                {{'generic.download_as_pdf' | translate}}
            </button>

            <h3 *ngIf="showVideoMarkersNoteSubtitle" 
                class="form-title-text-subtitle">{{'activities.survey_notes_instruction' | translate}}</h3>

            <mat-form-field fxFlex="100" class="w-100-p">
                <mat-label></mat-label><textarea matInput formControlName="survey-notes" ></textarea>
            </mat-form-field>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions class="mat-dialog-actions">
        <button *ngIf="isEditor && step === 1"  (click)="previous()">
            <span>{{ 'generic.previous' | translate }}</span>
        </button>
        <button *ngIf="isEditor && step === 0"  (click)="next()">
            <span>{{ 'generic.next' | translate }}</span>
        </button>
        <button *ngIf="!isEditor && step === 1" id="previous-question-button"  (click)="previous()">
            <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
            <span>{{ 'generic.previous' | translate }}</span>
        </button>
        <button *ngIf="!isEditor" id="next-question-button"  (click)="next()">
            <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            <span>{{ 'generic.next' | translate }}</span>
        </button>
        <button *ngIf="step === 0 && isEditor"  (click)="save()">
            <span>{{ 'generic.save' | translate }}</span>
            <mat-spinner *ngIf="showSpinner" class="ml-8"></mat-spinner>
        </button>
        <button *ngIf="step === 0 && isEditor && marker && marker.id && openMarkerAutomatic"
                (click)="delete()">
            <span>{{ 'generic.delete' | translate }}</span>
        </button>

    </mat-dialog-actions>


</div>