import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, map, mergeMap, startWith, take, tap} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {DataEntity} from 'octopus-connect';
import {combineLatest} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CompassService} from '../../../compass.service';
import {CompassExploreAttributes} from '../../../types';
import {WarningComponent} from '../../../ui';

@Component({
    selector: 'app-compass-resources',
    templateUrl: './compass-resources.component.html',
})
export class CompassResourcesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit {
    public activatedRoute = inject(ActivatedRoute);
    public dialog = inject(MatDialog);
    private compassService = inject(CompassService);
    private router = inject(Router);

    private exploreItem$ = this.activatedRoute.params.pipe(
        filter(params => params.exploreId),
        mergeMap(params => this.compassService.loadExploreEntity(params.exploreId)),
        startWith(null), // on peut arriver sur la page sans exploreId
    );
    private theme$ = this.activatedRoute.params.pipe(
        filter(params => params.themeId),
        mergeMap(params => this.compassService.loadThemeById(params.themeId)),
    );

    public parentItems$ = combineLatest([this.exploreItem$, this.theme$]).pipe(
        map(([exploreItem, theme]) => ({exploreItem, theme})),
    )


    public displayLoader = true;
    public resources: DataEntity[] = [];
    public goBackLinkName = 'compass.home';
    public selectedThemeTitle = '';

    /****** Lifecycle Hooks ******/

    ngOnInit() {
        this.theme$.pipe(
            take(1),
            tap(theme => this.selectedThemeTitle = theme.get('label')),
            tap(theme => this.initialize(theme.get('ressources')))
        ).subscribe()

        this.activatedRoute.queryParams.subscribe(params => {
            this.goBackLinkName = params.backLinkName ?? 'compass.home';
        });
    }

    /****** Logic ******/

    public getResourceType(resource: DataEntity, useSlug?: boolean): string {
        if (resource.get('type') && resource.get('type')[0] && resource.get('type')[0]['name']) {
            if (useSlug) {
                return this.slugify(resource.get('type')[0]['name']);
            }
            return resource.get('type')[0]['name'];
        }
        return '';
    }

    public isDialogResource(resource: DataEntity) {
        return resource.get('link') && resource.get('link').length > 0;
    }

    private initialize(ids: string[]): void {
        const filterOptions = ids && ids.length ? {filter: {id: ids}} : {};
        const resources$ = this.compassService.loadResources(filterOptions)
            .pipe(
                take(1),
            );

        combineLatest([resources$])
            .pipe(
                take(1),
                tap(([resources]) => this.resources = resources),
                tap(() => this.displayLoader = false)
            )
            .subscribe();
    }
    // slug function convert to lowercase and replace space with - and no special characters

    private slugify(text: string): string {
        const dict = {
            'à': 'a',
            'á': 'a',
            'é': 'e',
            'è': 'e',
            'ê': 'e',
            'ç': 'c',
            'ë': 'e',
            'ï': 'i',
            'î': 'i',
            'ô': 'o',
            'ö': 'o',
            'ù': 'u',
            'û': 'u',
            'ü': 'u',
            'ÿ': 'y',
            'ñ': 'n'
        };
        return text.toString().toLowerCase()
            .replace(/\s+/g, '_')           // Replace spaces with -
            .replace(/[^\w ]/g, function (char) {
                return dict[char] || char;
            })
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\_\_+/g, '_')         // Replace multiple - with single -
            .replace(/^_+/, '')             // Trim - from start of text
            .replace(/_+$/, '');            // Trim - from end of text
    }

    public getQueryParams() {
        const qp = {
            fromUrl: this.router.url.split('?')[0],
            selectedThemeTitle: this.selectedThemeTitle,
        }

        if (Object.keys(this.activatedRoute.snapshot.queryParams).length) {
            qp['fromQueryParams'] = JSON.stringify(this.activatedRoute.snapshot.queryParams);
        }

        return qp;
    }

    public getBackQueryParams() {
        return this.activatedRoute.snapshot.queryParams.fromQueryParams ? JSON.parse(this.activatedRoute.snapshot.queryParams.fromQueryParams) : null
    }

    public getBackUrl() {
        return this.activatedRoute.snapshot.queryParams.fromUrl ?? '../..';
    }

    /***** Events handling *****/

    public onSeeResourceClick(resource: DataEntity) {
        const dialogRef = this.dialog.open(WarningComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                window.open(resource.get('link'), '_blank');
            }
        });
    }

    public getMediaUrl(medias: CompassExploreAttributes['medias']): string {
        return medias && medias.length ? medias[0].url : '';
    }
}