<div *ngIf="numberOfQuestion > 1"
     class="container bem-progress-multi-zone__wrapper">
    <ng-container *ngFor="let answer of answersByZone; let isLast = last; let isFirst = first;">
        <!-- first answer -->
        <div *ngIf="isFirst"
             class="zone isFirst bem-progress-multi-zone__answer bem-progress-multi-zone__first-answer"
             [ngClass]="answer.isAnswerCorrect ? 'trueAnswer bem-progress-multi-zone__correct-answer lastDisplayZone' + isLast : 'falseAnswer bem-progress-multi-zone__wrong-answer lastDisplayZone' + isLast"
             appApplyWidthDelay
             [delay]="200"
             [width]="widthToApply(answer)">
        </div>
        <!--all answer except first and last-->
        <div *ngIf="!isFirst && !(lastTrueAnswersDone() && isLast)"
             class="zone bem-progress-multi-zone__answer"
             [ngClass]="answer.isAnswerCorrect ? 'trueAnswer bem-progress-multi-zone__correct-answer lastDisplayZone' + isLast : 'falseAnswer bem-progress-multi-zone__wrong-answer lastDisplayZone' + isLast"
             appApplyWidthDelay
             [delay]="200"
             [width]="widthToApply(answer)">
        </div>
        <!--last answer-->
        <div *ngIf="answer.isAnswerCorrect && lastTrueAnswersDone() && isLast"
             class="zone isLast trueAnswer bem-progress-multi-zone__answer bem-progress-multi-zone__last-answer bem-progress-multi-zone__correct-answer"
             appApplyWidthDelay
             [delay]="200"
             [width]="widthToApply(answer)">
        </div>
    </ng-container>
    <!--cursor-->
    <div class="progression-cursor bem-progress-multi-zone__cursor"
         [ngStyle]="{'margin-left':lastTrueAnswersDone()?'-3px':''}">
    </div>
    <!--limit max remove only at the end when all answer are done and good-->
    <div [ngStyle]="{width:lastTrueAnswersDone()?'0%':'2%'}"
         class="limitMax bem-progress-multi-zone__limit">
    </div>
</div>