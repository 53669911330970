<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>

    <div class="content">
        <div *ngIf="isLoadingDone else loadingBlock" class="content-editor">

            <div class="mindmap-header">
                <div>{{mindmapTitle}}</div>
                <button class="close"
                        (click)="close()"
                        [attr.aria-label]="'generic.close' | translate"
                        [matTooltip]="'generic.close' | translate">
                    <mat-icon svgIcon="cross"></mat-icon>
                </button>
            </div>

            <div class="mindmap-content">
                <iframe [src]="urlToMindmap" height="100%" width="100%"></iframe>
            </div>
        </div>
    </div>
</div>
