<aside class="activities-feedback"
       [ngClass]="classCss"
       [class.activities-feedback--folded]="folded"
       @fadeAndSlideIn>

    <button class="activities-feedback__toggle-button"
            (click)="toggleFeedback()">
        <mat-icon class="activities-feedback__toggle-icon"
                  svgIcon="keyboard-arrow-down-rounded-full">
        </mat-icon>
    </button>

    <div class="activities-feedback__short-feedback">
        <ng-container>
            <mat-icon class="activities-feedback__short-feedback-icon"
                      [svgIcon]="svgIcon">
            </mat-icon>
            <p class="activities-feedback__short-feedback-text" *ngIf="feedback.innerHtmlContent" [innerHTML]="feedback.innerHtmlContent"></p>
            <p class="activities-feedback__short-feedback-text" *ngIf="feedback.content">{{feedback.content}}</p>
            <p class="activities-feedback__short-feedback-text" *ngIf="feedback.contentWithTermInterface">{{feedback.contentWithTermInterface | translate }}</p>
        </ng-container>
    </div>

    <ng-container *ngFor="let button of buttons">
        <button *ngIf="button.display"
                class="activities-feedback__action-button"
                [disabled]="button.disable"
                (click)="onAction(button)">
            <span class="activities-feedback__action-label">{{ button.title | translate }}</span>
            <mat-icon *ngIf="button.svgIcon"
                      class="activities-feedback__action-icon"
                      [svgIcon]="button.svgIcon">
            </mat-icon>
        </button>
    </ng-container>
</aside>