<mat-card [ngClass]="{'not-done':!isActivitiesIsDone}">
    <mat-card-title>
        <h2>{{ title }}</h2>
    </mat-card-title>
    <img mat-card-image [src]="image" alt="">
    <mat-card-content>

    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button
                title="{{ 'play.lesson' | translate }}" (click)="play(subLesson, i)">
            <mat-icon [svgIcon]="globalConfigurationService.getIconFromPlayLessonInAcitivityCard()"
                      aria-label="Play lesson"></mat-icon>
            <span class="toto">Play</span>
        </button>
    </mat-card-actions>
</mat-card>