<div *ngIf="!imgTag && !includesAudioTag()" class="container md-head head-marg-top">
    <div
        #readMe
        (speaking)="speakStateChanged($event)"
        *ngIf="(wording ||instruction) && isReadable()"
        [autoRead]="autoRead"
        [instructionAudio]="instructionAudio"
        [ngClass]="{
            'tts': true,
            'wording': true,
            'tts-reading': isTTSSpeaking,
            'is-instruction': !!instruction && !imgTag
            }"
        [wordingAudio]="wordingAudio"
        appReadable
        [activateDirective]="isTtsActive()"
    >
        <div
            *ngIf="wording && isReadable()"
            [innerHtml]="wording | stringToHtml"
            [ngClass]="{'wording': true, 'tts-reading': isTTSSpeaking }"
            [appDayRelativeNameToString]="{language_key: language}"
            [appTimeSlotToString]="{language_key: language}"
            class="bem-consignes__content"
        ></div>
        <div
            *ngIf="instructionUpdated"
            [innerHtml]="instructionUpdated | stringToHtml"
            [ngClass]="{
                'instruction': true,
                'tts-reading': isTTSSpeaking,
                'is-picture': imgTag,
                'is-video': instruction.includes('<video')
            }"
            [appDayRelativeNameToString]="{language_key: language}"
            [appTimeSlotToString]="{language_key: language}"
            class="bem-consignes__content"
        >
        </div>

        <span *ngIf="additionalHiddenText && additionalHiddenText!=='' && isReadable()"
              [appDayRelativeNameToString]="{language_key: language}"
              [appTimeSlotToString]="{language_key: language}"
              class="precision">{{additionalHiddenText}}</span>
        <mat-icon
            *ngIf="(wordingAudio && wordingAudio!=='') || (instruction && !imgTag)"
            [svgIcon]="isTTSSpeaking?'sound_on':'sound_off'"
            #tooltip="matTooltip"
            [matTooltip]="'activities.lesson.listen' | translate">
        </mat-icon>
    </div>

    <div *ngIf="wording && !isReadable()"
         [innerHtml]="wording | stringToHtml"
         [appDayRelativeNameToString]="{language_key: language}"
         [appTimeSlotToString]="{language_key: language}"
         class="wording bem-consignes__content"></div>
    <div *ngIf="instruction && !isReadable()"
         [elementToWatch]="instruction"
         [innerHtml]="instruction | stringToHtml"
         appAddClassIfContainsPicture
         [appDayRelativeNameToString]="{language_key: language}"
         [appTimeSlotToString]="{language_key: language}"
         class="instruction bem-consignes__content"></div>
</div>

<div *ngIf="imgTag && imgTag !== ''"
     class="container consigne-img consignes__image"
     [attr.id]="'consigneImg' + uuid">
    <div class="consignes__content"
         [ngClass]="{'instruction': !this.fullscreenService.isFullscreenImageActive,
                     'is-picture': !this.fullscreenService.isFullscreenImageActive,
                     'fullscreen': this.fullscreenService.isFullscreenImageActive}"
         [innerHtml]="imgTag | stringToHtml">
    </div>
    <div *ngIf="isImageFullscreenButtonActive()"
         class="action-buttons consignes__actions">
        <mat-icon *ngIf="!this.fullscreenService.isFullscreenImageActive"
                  class="consignes__expand-icon"
                  svgIcon="image__button-fullscreen"
                  [matTooltip]="'activities.lesson.display' | translate"
                  role="button"
                  (click)="this.fullscreenService.openFullscreen('consigneImg' + uuid)">
        </mat-icon>
    </div>
</div>

<!-- If instruction includes an audio tag -->
<div *ngIf="includesAudioTag()"
     class="consignes__audio-player">
    <mat-icon class="consignes__audio-icon"
              svgIcon="speaker-equalizer">
    </mat-icon>
    <div class="consignes__audio-wrapper"
         [innerHtml]="instruction | stringToHtml">
    </div>
</div>