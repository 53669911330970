<div *ngIf="showLoader" id="absolute-spinner">
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>

<section *ngIf="!showLoader"
         class="home-page-wrapper home-page"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="start center">

    <div class="container-banner-home-header home-page__header">
        <div class="banner home-page__header-banner"
             [style.backgroundImage]="'url('+ imgBannerUrl +')'">
        </div>
        <div class="home-header home-page__header-content">
            <h1 *ngIf="title && !titleTextInHomePageBanner"
                class="title home-page__header-title">
                <span>{{ title }}</span>
                <span *ngIf="isRoleHomepage">, {{ username }}</span>
            </h1>
            <div class="content home-page__header-text"
                 [innerHTML]="content">
            </div>
        </div>
    </div>

    <div *ngIf="showLoaderProgression && isProgressionEnable">
        <div class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <ng-container
        *ngIf="!showLoaderProgression && isProgressionEnable && isClassOrWorkgroupExist && isAssignmentsExist && !!assignmentGroupedByClassesAndGroups.length">
        <h1 class="resume-title">
            <span>{{'generic.home_resume' | translate}}</span>
        </h1>
        <div class="content-cards home-page__progression"
             fxLayout="row wrap">
            <div class="home-page__progression-card-wrapper"
                 fxFlex.gt-sm="49"
                 fxFlex.gt-lg="32"
                 fxFlex.lt-md="100"
                 fxLayoutGap="10px"
                 *ngFor="let dataCard of assignmentGroupedByClassesAndGroups">
                <mat-card class="home-page__progression-card"
                          fxLayout="column">
                    <mat-card-header>
                        <mat-card-title>
                            <h2 class="mat-mdc-card-title-text">
                                <span>
                                    {{(dataCard.group.groupname ? 'progression.resume_with_class' : 'progression.resume_with_workgroup') | translate}}
                                </span>
                                <span>
                                    {{dataCard.group.groupname ? dataCard.group.groupname : dataCard.group.workgroupname}}
                                </span>
                                <span *ngIf="dataCard.concept">
                                    {{dataCard.concept.get('label')}}
                                </span>
                            </h2>
                            <span class="learners-count">{{dataCard.learnersCount}}</span>
                        </mat-card-title>
                    </mat-card-header>
                    <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="wrapper">
                        <div fxLayout="column" fxLayoutAlign="space-between start" class="wrapper-content">
                            <mat-card-content>
                                <div fxFlex fxLayout="row" fxLayoutAlign="start start"
                                     class="wrapper-inner-content-card">
                                    <a role="link" aria-disabled="false"
                                       tabindex="0"
                                       [class.active]="dataCard.assignmentsCollectiveInClasse?.length > 0"
                                       class="inner-content-card collective-assignment"
                                       (click)="openProgression(dataCard, true)"
                                       (keydown)="openProgressionIfEnter($event,dataCard)"
                                    >
                                        <label>
                                            <span>{{dataCard.assignmentsCollectiveInClasse?.length}}</span>&nbsp;
                                            <span>{{(dataCard.assignmentsCollectiveInClasse?.length > 1 ? 'progression.sessions_pending' : 'progression.session_pending') | translate}}</span>
                                        </label>
                                    </a>

                                    <a role="link" aria-disabled="false"
                                       tabindex="0"
                                       [class.active]="dataCard.assignmentsIndividualInClasse?.length > 0"
                                       class="inner-content-card individual-assignment"
                                       (click)="seeIndividualAssignment(dataCard)"
                                       (keydown)="openAssignmentIfEnter($event,dataCard)"
                                    >
                                        <label>
                                            <span>{{dataCard.assignmentsIndividualInClasse?.length}}</span>&nbsp;
                                            <span>{{(dataCard.assignmentsIndividualInClasse?.length > 1 ? 'progression.assignments_pending' : 'progression.assignment_pending') | translate}}</span>
                                        </label>
                                        <label>
                                            <span>{{dataCard.assignmentGroups?.length}}</span>&nbsp;
                                            <span>{{(dataCard.assignmentGroups?.length > 1 ? 'progression.assignments_groups' : 'progression.assignment_group') | translate}}</span>
                                        </label>
                                    </a>
                                </div>
                            </mat-card-content>
                            <mat-card-actions fxFlex fxLayout="row" fxLayoutAlign="end start">
                                <button
                                    *ngIf="isBtnProgressionCanBeDisplayed(dataCard)"
                                    [disabled]="!dataCard.enableProgression"
                                    [title]="'Lancer la prochaine séance'"
                                    (click)="openProgression(dataCard)"
                                    class="button-play" role="button">
                                    <span>{{'progression.launch_next_session' | translate}}</span>
                                    <mat-icon svgIcon="arrow-right"></mat-icon>
                                </button>
                            </mat-card-actions>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <h1 class="discover-title">
            <span>{{'generic.home_discover' | translate}}</span>
        </h1>
    </ng-container>

    <h2 *ngIf="showCollectionTitle"
        class="home-page__collection-title">
        {{ 'generic.home_collection_title' | translate}}
    </h2>

    <div class="content-cards data-cards home-page__collections"
         fxLayout="row wrap">
        <div *ngFor="let dataCard of dataCards"
             class="home-page__collection-card-wrapper"
             fxFlex.gt-sm="49"
             fxFlex.gt-lg="32"
             fxFlex.lt-md="100">
            <mat-card fxLayout="column"
                      class="home-page__collection-card">
                <mat-card-header class="home-page__collection-card-header">
                    <mat-card-title>
                        <span *ngIf="dataCard?.image?.uri"
                              class="image-title home-page__collection-card-image"
                              [style.backgroundImage]="'url('+ dataCard?.image?.uri +')'">
                        </span>
                        <h3 class="mat-mdc-card-title-text home-page__collection-card-title"
                            [ngClass]="dataCard?.image?.uri ? 'image' : 'no-image'">
                            {{ dataCard?.title }}
                        </h3>
                    </mat-card-title>
                </mat-card-header>
                <div class="wrapper-content home-page__collection-card-content-wrapper"
                     fxLayout="column"
                     fxLayoutAlign="space-between start">
                    <mat-card-content class="home-page__collection-card-content">
                        <div fxFlex
                             fxLayout="row"
                             fxLayoutAlign="start start">
                            <dl class="dl-listitem-inline"
                                role="list">
                                <ng-container>
                                    <!-- <dt>{{'generic.goals' | translate}}</dt> -->
                                    <dd [innerHTML]="dataCard?.description?.value"></dd>
                                </ng-container>
                            </dl>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="home-page__collection-card-actions"
                                      fxFlex
                                      fxLayout="row"
                                      fxLayoutAlign="end start">
                        <button *ngIf="dataCard.link_label && dataCard.link_label !== ''"
                                class="button-play home-page__collection-card-button"
                                (click)="play(dataCard)"
                                role="button">
                            <span>{{ dataCard.link_label }}</span>
                        </button>
                    </mat-card-actions>
                </div>
            </mat-card>
        </div>
    </div>

</section>