<mat-card class="activities-card inner-scroll mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <app-consignes *ngIf="(!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))" [wordingAudio]="wordingAudio" [forceStopReading]="forceReset$" [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"  [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)" ></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content *ngIf="!!flashcard">
        <app-voice-recorder
            [displayForSummary]="displayForSummary"
            [flashcard]="flashcard"
            [forcePauseIncomingAudio$]="pauseIncomingAudio$"
            [forceReset$]="forceReset$"
            (activityDone)="activityIsDone($event)"
        ></app-voice-recorder>
    </mat-card-content>
    <mat-card-footer >
        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button
                    (click)="buttonClick(button)"
                    *ngIf="button.display"
                    [disabled]="button.disable || !isActivityIsDone"
                >
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>

<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedBack($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>
