<ng-container *ngIf="answersState; else placeholderTemplate">

    <ng-container *ngIf="answersState.type !== 'flashcard'">
        <button *ngFor="let answer of answersState?.answers"
                class="activity-edition-preview-qcm__answer-button"
                [class.activity-edition-preview-qcm__image-button]="answersState.type === 'image'"
                [class.is-audio]="answersState.type === 'audio'"
                [class.correct]="answer.isCorrectAnswer"
        >
            <ng-container *ngIf="answersState.type === 'text'">
                <span>{{ answer?.value }}</span>
            </ng-container>
            <ng-container *ngIf="answersState.type === 'image'">
                <img [src]="answer?.value?.uri">
            </ng-container>
            <ng-container *ngIf="answersState.type === 'audio'">
                <mat-icon svgIcon="equalizer_1"></mat-icon>
                <audio [src]="answer?.value?.uri" controls></audio>
            </ng-container>
            <ng-container *ngIf="answersState.type === 'flashcard'">
                <app-flashcard [class.button-image]="answer?.value?.image"
                               [class.is-audio]="answer?.value?.audio?.uri"
                               [flashCard]="answer?.value"></app-flashcard>
            </ng-container>
        </button>
    </ng-container>
    <ng-container *ngIf="answersState.type === 'flashcard'">
        <app-flashcard *ngFor="let answer of answersState?.answers"
                       [class.button-image]="answer?.value?.image"
                       [class.is-audio]="answer?.value?.audio?.uri"
                       [flashCard]="answer?.value"></app-flashcard>
    </ng-container>

</ng-container>

<ng-template #placeholderTemplate>

    <ng-container *ngFor="let item of [].constructor(4); let index = index">
        <button class="activity-edition-preview-qcm__answer-button-placeholder">
            <span>{{ 'activity_edition.text_answer_placeholder_' + index | translate }}</span>
        </button>
    </ng-container>

</ng-template>