<div class="grid-fields  answer-type-text">
    <div class="field-wrapper">
        <app-activity-edition-radio-button [controlName]="control"
                                           [icon]="'thumb_up'"
                                           [idElement]="valueTrueButton"
                                           (click)="onclick($event, valueTrueButton)">
            <ng-content select="[data-button-1-label]"></ng-content>
        </app-activity-edition-radio-button>
    </div>
    <div class="field-wrapper">
        <app-activity-edition-radio-button [controlName]="control"
                                       [icon]="'thumb_down'"
                                       [idElement]="valueFalseButton"
                                       (click)="onclick($event, valueFalseButton)">
            <ng-content select="[data-button-2-label]"></ng-content>
        </app-activity-edition-radio-button>
    </div>
</div>
