<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'generic.search' | translate }}">
  </mat-form-field>
</div>

<table mat-table #table [dataSource]="dataSource" *fuseIfOnDom [@animateStagger]="{value:'50'}" matSort>

  <!-- Type Column -->
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef fxFlex="32px"><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_type' | translate}}</span></th>
    <td mat-cell *matCellDef="let row" fxFlex="32px">
      <mat-icon class="type-icon"> {{row.type}}</mat-icon>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="read">
    <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_read' | translate}}</span></th>
      <td mat-cell *matCellDef="let row" [ngClass]="{'unread': row.read === false}">
          <mat-icon class="type-icon">brightness_1</mat-icon>
      </td>
  </ng-container>

  <!-- Type Column -->
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.label' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{row.label}}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.date' | translate }}</th>
    <td mat-cell *matCellDef="let row">{{notificationsService.localeDate(row.date)}} - {{notificationsService.localeTime(row.date)}}</td>
  </ng-container>

  <!-- Owner Column -->
  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_delete' | translate}}</span></th>
    <td mat-cell *matCellDef="let row" (click)="deleteNotification(row, $event)">
        <button *ngIf="row.deletable"><mat-icon class="type-icon" matTooltip="{{ 'generic.delete' | translate }}" matTooltipPosition="left" aria-hidden="false" ariaLabelTranslate="{{ 'generic.delete' | translate }}">delete_forever</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
           (click)="onSelect(row)"
           [ngClass]="{'selected':row == selected}"
  >
  </tr>
</table>

<mat-paginator #paginator
               [pageSize]="10"
               [pageSizeOptions]="[5, 10, 20]">
</mat-paginator>
