<ng-container *ngIf="!showSpinner; else spinnerBlock">

    <button class="period-list__my-lessons-button"
            role="button"
            (click)="goToMyAssignments()">
        <span class="period-list__my-lessons-label">{{ 'generic.my_lessons' | translate }}</span>
        <div class="period-list__my-lessons-count">
            <span>{{ assignmentCount }}</span>
        </div>
    </button>

    <div class="period-list__wrapper">
        <button *ngFor="let c of chapters; let index = index;"
                class="period-list__period-button"
                [ngClass]="index % 2 == 0 ? 'period-list__period-button--even' : 'period-list__period-button--odd'"
                (click)="navigateToLesson(c)">
            <div class="period-list__period-header">
                <div class="period-list__period-index">
                    <span>{{ index + 1 }}</span>
                </div>
                <div class="period-list__period-state"
                     [ngClass]="'period-list__period-state--' + c.state">
                    <mat-icon *ngIf="getIcon(status[c.state])"
                              [svgIcon]="getIcon(status[c.state])">
                    </mat-icon>
                    <span>{{ ('generic.period_' + c.state) | translate }}</span>
                </div>
                <app-progress-bar-generic *ngIf="c.state != undefined"
                                          class="period-list__period-progress-bar"
                                          [ngClass]="c.progress === 100 ? 'period-list__period-progress-bar--filled' : null"
                                          [pourcent]="c.progress">
                </app-progress-bar-generic>
            </div>
            <div class="period-list__period-label">{{ c.label }}</div>
        </button>
    </div>

</ng-container>

<ng-template #spinnerBlock>

    <mat-spinner class="period-list__spinner"></mat-spinner>

</ng-template>

<app-launch-button *ngIf="shouldShowLaunchButton" class="period-list__launch-button"></app-launch-button>