<!--<script src="widget-messaging-thread.component.ts"></script>-->

<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}" class="msg-threads-list-container">

    <table *ngIf="empty" mat-table #table [dataSource]="dataSource" fxFlex="100">

        <ng-container matColumnDef="viewed">
            <th mat-header-cell *matHeaderCellDef> {{ 'generic.unread' | translate }} </th>
            <td mat-cell *matCellDef="let thread">
                <span class="pastille" [ngClass]="{'active': isUnreadThread(thread)}"></span>
            </td>
        </ng-container>

        <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef> {{ 'generic.title' | translate }} </th>
            <td mat-cell *matCellDef="let thread">
                <p class="font-weight-600">{{ thread.label }}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef> {{ 'messaging.last_message' | translate}} </th>
            <td mat-cell *matCellDef="let thread">
                <p class="text-truncate font-weight-600">{{ parseDate(thread.lastMessage) | date: 'dd/MM/yyyy' }}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="groups">
            <th mat-header-cell *matHeaderCellDef> {{ 'generic.label_group' | translate }}</th>
            <td mat-cell *matCellDef="let thread">
                <p class="font-weight-600">{{ toUserFriendlyGroup(thread.groups) }}</p>
            </td>
        </ng-container>

        <ng-container matColumnDef="members">
            <th mat-header-cell *matHeaderCellDef> {{ 'messaging.participants' | translate }}</th>
            <td mat-cell *matCellDef="let thread">
                <span class="font-weight-600" *ngFor="let member of thread.membersDetails">{{ member?.label }}, </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToMessage(row)"></tr>
    </table>

    <div *ngIf="!empty" class="emptyResourceLabel">{{ 'dashboard.messaging_thread_empty_widget' | translate }}</div>
</div>



