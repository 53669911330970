<mat-card class="activities-card inner-scroll mat-elevation-z0" >

    <mat-card-content [class.description]="description" class="wrapper">
        <div class="center-middle">
            <img *ngIf="filemime === 'image/jpg' || filemime === 'image/jpeg' || filemime === 'image/png' || filemime === 'image/gif'" [src]="resourceURI" alt="" />

            <app-shared-video *ngIf="filemime === 'video/mp4' || filemime === 'video/mpeg'" [video]="video" [controls]="true"></app-shared-video>
            <audio *ngIf="filemime === 'audio/mp3' || filemime === 'audio/mpeg'" controls>
                <source src="{{resourceURI}}" type="{{filemime}}">
                Your browser does not support the audio tag.
            </audio>
            <p *ngIf="filemime === 'application/pdf'"><a href="{{resourceURI}}" title="" rel="nofollow noreferrer noopener" target="_blank">{{resourceURI}}</a><br><br></p>
            <div [ngClass]="{'description ck-editor-output':description}" *ngIf="description" [innerHTML]="description"></div>
        </div>
    </mat-card-content>
</mat-card>
