<div mat-dialog-title>
    <h2 translate [translateParams]="{user: username}">{{title}}</h2>
    <button mat-dialog-close
            class="close"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <app-dynamic *ngFor="let view of views" [room]="view.room" [subject]="view.subject" [params]="view.params"></app-dynamic>
</mat-dialog-content>

<mat-dialog-actions>
    <app-dynamic *ngFor="let actionButton of actionsButton" [room]="actionButton.room" [subject]="actionButton.subject" [params]="actionButton.params">
    </app-dynamic>
</mat-dialog-actions>