<div mat-dialog-title>
    <h2>{{'group-management.join_class_title' | translate}}</h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <h3>{{ title | translate}}<span *ngIf="finish && getClassInfo">{{titleGroup}}</span></h3>

    <div
        *ngIf="!finish"
        class="mb-24"
        fxLayout="row"
        fxLayoutAlign="start start">
        <mat-form-field fxFlex>
            <mat-label>{{ 'groups-management.code_class' | translate }}</mat-label><input
                matInput
                
                [formControl]="controlCode">
        </mat-form-field>
    </div>

    <div *ngIf="!classError && !classExist" [ngClass]="{'success' : !finish && getClassInfo}">
        <div>
            <label class="pr-12">{{'groups-management.class_name' | translate}} :</label>
            <label>{{getClassInfo?.name}}</label>
        </div>
        <div>
            <label class="pr-12">{{'groups-management.institution' | translate}} :</label>
            <label>{{getClassInfo?.institution}}</label>
        </div>
    </div>

    <div *ngIf="classExist && !finish && state === 'start'">{{'groups-management.class_already_joined' | translate}}</div>
    <div *ngIf="classError && !finish && state === 'start'">{{'groups-management.code_class_error' | translate}}</div>

</mat-dialog-content>

<mat-dialog-actions>
    <button
        *ngIf="getClassInfo && !finish"
        (click)="joinClass()"
        mat-raised-button
        class="mat-accent">{{'group-management.join_class_confirm' | translate}}</button>

    <button
        *ngIf="!getClassInfo && !finish && !classExist"
        [disabled]="disableTest"
        (click)="testCode()"
        mat-raised-button
        class="mat-accent">{{'generic.test' | translate}}</button>

    <button
            *ngIf="!finish"
            (click)="reset()"
            mat-raised-button
            class="mat-accent">{{'generic.cancel' | translate}}</button>

    <button
            *ngIf="finish && !classError"
            (click)="dialogRef.close()"
            mat-raised-button
            class="mat-accent">{{'generic.close' | translate}}</button>
</mat-dialog-actions>