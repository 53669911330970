<mat-spinner *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="!isLoading">
    <div fusePerfectScrollbar fxLayout="column" id="login">
        <div
            fxLayout="column"
            fxLayoutAlign="center center"
            id="login-form-wrapper"
        >
            <div *fuseIfOnDom
                id="login-form">
                <div *ngIf="settingsAuth.displayLoginLogo" class="logo">
                    <img
                        *ngIf="!brandLogoSvg"
                        src="assets/{{ brand }}/images/logos/brand-square.png"
                        alt=""
                    />
                    <mat-icon
                        *ngIf="brandLogoSvg"
                        class="logo-icon-svg"
                        svgIcon="brand_square"
                    ></mat-icon>
                </div>

                <ng-container>
                    <h2 class="title mat-headline-6">
                        {{ "authentication.set_password_title" | translate }}
                    </h2>
                    <form [formGroup]="renewPasswordForm" name="renewPasswordForm" novalidate>
                        <mat-form-field >
                            <mat-label>{{ 'authentication.new.password' | translate }}</mat-label><input
                                [type]="hideNewPassword ? 'password' : 'text'"
                                autocomplete="off"
                                formControlName="newPassword"
                                matInput
                                name="changePassword"
                                
                                required
                            />
                            <mat-icon
                                (click)="hideNewPassword = !hideNewPassword"
                                matSuffix
                                svgIcon="{{ hideNewPassword ? 'visibility' : 'visibility_off' }}"
                            >
                            </mat-icon>

                        </mat-form-field>

                        <mat-form-field *ngIf="settingsAuth.activeChangePasswordStrategy">
                            <mat-label>{{ 'authentication.confirm_new_password' | translate }}</mat-label><input
                                [type]="hideConfirmPassword ? 'password' : 'text'"
                                autocomplete="off"
                                formControlName="confirmPassword"
                                matInput
                                
                                required
                            />
                            <mat-icon
                                (click)="hideConfirmPassword = !hideConfirmPassword"
                                matSuffix
                                svgIcon="{{ hideConfirmPassword ? 'visibility' : 'visibility_off' }}"
                            >
                            </mat-icon>

                            <!-- Message d'erreur pour les mots de passe non identiques -->
                            <mat-error *ngIf="renewPasswordForm.get('confirmPassword').errors?.mustMatch">
                                {{ "account-management.password-confirm.not-identical" | translate }}
                            </mat-error>
                        </mat-form-field>

                        <button
                            (click)="onSubmit()"
                            [disabled]="renewPasswordForm.invalid"
                            class="submit-button"
                            color="accent"
                            mat-raised-button
                        >
                            {{ "authentication.btn-login" | translate }}
                        </button>
                    </form>

                </ng-container>


            </div>
        </div>
    </div>
</ng-container>
