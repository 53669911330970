<div *ngIf="showLoader"
     id="absolute-spinner">
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>

<ng-container *ngIf="!showLoader">

    <div class="header">
        <button class="inverted"
                mat-raised-button
                (click)="goBackToPrevPage()">
            <mat-icon svgIcon="icon-arrow-back"></mat-icon>
            <span>{{ 'generic.all_collections' | translate }}</span>
        </button>
    </div>
    
    <ng-container *ngFor="let block of customBlocks">
        <ng-container [ngSwitch]="block.type">
            <app-presentation-general *ngSwitchCase="'presentation'"
                                      [block]="block"
                                      [anchor]="block.id">
            </app-presentation-general>
            <app-tag-block *ngSwitchCase="'tag_block'"
                           [block]="block"
                           [anchor]="block.id">
            </app-tag-block>
            <app-key-figures *ngSwitchCase="'keyFigures'"
                             [block]="block"
                             [anchor]="block.id">
            </app-key-figures>
            <app-half-width-cta *ngSwitchCase="'halfWidthCta'"
                                [block]="block"
                                [anchor]="block.id">
            </app-half-width-cta>
            <app-full-width-cta *ngSwitchCase="'fullWidthCta'"
                                [block]="block"
                                [anchor]="block.id">
            </app-full-width-cta>
            <app-lesson-tile *ngSwitchCase="'lessonTile'"
                             [block]="block"
                             [anchor]="block.id">
            </app-lesson-tile>
            <app-faq-block *ngSwitchCase="'faq_block'"
                           [block]="block"
                           [anchor]="block.id">
            </app-faq-block>
        </ng-container>
    </ng-container>

</ng-container>