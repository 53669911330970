<div mat-dialog-title>
    <h2>{{'activities.metadata.title' | translate}}</h2>
    <button
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <h2 *ngIf="display('title')">
        <span>{{'generic.title' | translate}}&nbsp;:&nbsp;</span><span>{{metadata.title}}</span>
    </h2>
    <h3 *ngIf="display( 'method')">
        <span>{{'licensing.method' | translate}}&nbsp;:&nbsp;</span><span>{{methodsNames}}</span>
    </h3>
    <div class="details-container" *ngIf="display( 'chapters')">
        <span class="details-header">{{'generic.chapters' | translate}}</span>
        <div *ngFor="let chapter of metadata.chapters">
            <p>{{chapter.label}}</p>
        </div>
    </div>
    <div class="details-container" *ngIf="display( 'concepts')">
        <span class="details-header">{{'generic.concepts' | translate}}</span>
        <div *ngFor="let concept of metadata.concepts">
            <p>{{concept.label}}</p>
        </div>
    </div>
    <div class="details-container" *ngIf="display( 'skills')">
        <span class="details-header">{{'generic.skills' | translate}}</span>
        <div *ngFor="let skill of metadata.skills">
            <p [innerHTML]="skill.label"></p>
        </div>
    </div>

</mat-dialog-content>
