<ng-template #loadingBlock>
    <div *ngIf="showLoader" class="center spinner-container">
        <app-fake-stepper [options]="loaderOptions"></app-fake-stepper>
    </div>
</ng-template>

<ng-container *ngIf="isReady else loadingBlock">

    <app-graph-mixed *ngIf="!empty else emptyBlock" [config]="graphConfigObs"></app-graph-mixed>
    <ng-template #emptyBlock>
        <div class="empty-block">
            <h3>{{'graph_inclusive.no_progress_data' | translate}}</h3>
        </div>
    </ng-template>
</ng-container>