<video #video
       ref="video"
       class="video-js vjs-default-skin vjs-big-play-centered vjs-big-play-button"
       controls
       controlsList="nodownload"
       oncontextmenu="return false"
       vjs-16-9
       preload="auto"
       data-setup='{}'>
    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
        </a>
    </p>
</video>

<div #toMarker fxFlex fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center" class="w-100-p p-12">
        <label *ngIf="showVideoWithMarkersLabel" [style.fontSize.px]="30">{{'activities.what_you_learn' | translate}}</label>
        <div  fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="w-100-p">
            <button (click)="clickOverlay()"
                    >{{'activities.decryption' | translate}}
            </button>
            <button *ngIf="videoConfig['canEditMarker']" (click)="remove()"
                    >{{ 'generic.remove' | translate}}
            </button>
        </div>
</div>

<div #tooManyMarker fxFlex fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center" class="w-100-p p-12">
        <label [style.fontSize.px]="30">{{'activities.markers_limit_reached' | translate}}</label>
</div>
