<div class="navbar-header">

    <div class="logo">
        <a class="link" (click)="navigate()">
            <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/{{isGar?'brand_gar':'brand'}}.png" alt="">
            <mat-icon *ngIf="brandLogoSvg && !useBrandTwoDifferentSvg" class="logo-icon-svg" svgIcon="brand"></mat-icon>
            <!--to use if you need different icon menu closed and open and different of login menu icon-->
            <mat-icon id="brand_large" *ngIf="brandLogoSvg && useBrandTwoDifferentSvg" class="logo-icon-svg"
                      svgIcon="brand_menu_large"></mat-icon>
            <mat-icon id="brand_icon_small" *ngIf="brandLogoSvg && useBrandTwoDifferentSvg" class="logo-icon-svg"
                      svgIcon="brand_menu"></mat-icon>

            <span class="logo-text">{{ brand }}</span>
        </a>
    </div>

    <button id="togglemenutabgestion" mat-icon-button class="toggle-sidebar-folded" aria-label="Toggle menu" title="Toggle menu" tabindex="1"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" aria-label="Close menu" title="Close menu"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon svgIcon="arrow_back"></mat-icon>
    </button>

</div>

<div  class="navbar-content" fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
    <fuse-navigation layout="vertical" role="navigation"></fuse-navigation>
</div>
