<ng-container *ngIf="!!control">

    <mat-icon *ngIf="!!icon"
              class="activity-editor-text-input__icon"
              [svgIcon]="icon">
    </mat-icon>

    <input *ngIf="isWysiwyg === false"
           class="activity-editor-text-input__user-input"
           [formControl]="control"
           [placeholder]="placeholder ?? ''"
           type="text">
    <div class="app-wysiwyg-wrapper" [ngClass]="{ 'app-wysiwyg-wrapper--only-available-characters' : isWysiwyg === false }">
        <app-wysiwyg *ngIf="isWysiwyg === true"
            [content]="control.value"
            (contentChange)="control.setValue($event)"
            (wordCountUpdate)="onWordCountUpdate($event)"></app-wysiwyg>

        <span *ngIf="characterLimit !== undefined"
              class="activity-editor-text-input__available-characters"
              [ngClass]="{
                  'activity-editor-text-input__available-characters--success': availableCharacters >= 5,
                  'activity-editor-text-input__available-characters--warning': availableCharacters < 5 && availableCharacters > 0,
                  'activity-editor-text-input__available-characters--danger': availableCharacters <= 0
              }">
            {{ availableCharacters }}
        </span>
    </div>

</ng-container>