<form name="followedForm"
      class="followed-filter">

    <mat-toolbar class="followed-filter__toolbar filters">

        <mat-toolbar-row class="followed-filter__toolbar-row"
                         fxLayoutGap="24px">

            <mat-form-field *ngIf="displayFilters('type')">
                <mat-label>{{ 'generic.type' | translate }}</mat-label><mat-select #typeSelect
                            (selectionChange)="selectFilter($event, 'assignments_type')"
                            [formControl]="typeFilter"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let option of allTypes" [value]="option.id">
                        {{ localizedType(option?.label) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('title')" floatLabel="always">
                <mat-label>{{ 'generic.title' | translate }}</mat-label>
                <input #titleInput="matInput" [formControl]="titleFilter" matInput>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('exoName')">
                <mat-label>{{ 'generic.two_steps_selection_Title_underlist' | translate }}</mat-label><input #titleInput="matInput" [formControl]="titleFilter" matInput
                       >
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('schoolyear')">
                <mat-label>{{ 'groups-management.chose_school.year' | translate }}</mat-label><mat-select (selectionChange)="selectFilter($event, 'schoolYearDates')"
                            [value]="currentSchoolYearBegin"
                            name="schoolyear"
                            >
                    <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let schoolYear of schoolYearsList "
                        [value]="schoolYear.get('name')"> {{ displaySchoolYear(schoolYear.get('name'))}} </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('comment')">
                <mat-label>{{ 'generic.label_group' | translate }}</mat-label><input #commentInput="matInput" [formControl]="commentFilter" matInput
                       >
            </mat-form-field>
            <!-- Groups Field -->
            <mat-form-field *ngIf="displayFilters('group')">
                <mat-label>{{ 'filter.group' | translate }}</mat-label><mat-select #groupSelect (selectionChange)="selectGroup($event)"
                            [formControl]="customFilter.groupControl"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let group of groupsList"
                                [value]="group.groupname">{{ group.groupname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Workgroups Field -->
            <mat-form-field *ngIf="displayFilters('workgroup')">
                <mat-label>{{ 'filter.workgroup' | translate }}</mat-label><mat-select #workgroupSelect (selectionChange)="selectWorkGroup($event)"
                            [formControl]="customFilter.workgroupControl"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let workgroup of workgroupsList"
                                [value]="workgroup.workgroupname">{{ workgroup.workgroupname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!--begin-date-->
            <mat-form-field *ngIf="displayFilters('beginDate')">
                <mat-label>{{'generic.begin_period' | translate}}</mat-label><input [formControl]="beginDate" [matDatepicker]="duepicker" matInput name="beginDate"
                       >
                <mat-datepicker-toggle [for]="duepicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #duepicker></mat-datepicker>
            </mat-form-field>

            <!--end-date-->
            <mat-form-field *ngIf="displayFilters('endDate')">
                <mat-label>{{'generic.end_period' | translate}}</mat-label><input [formControl]="endDate" [matDatepicker]="duepicker" matInput name="endDate"
                       >
                <mat-datepicker-toggle [for]="duepicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #duepicker></mat-datepicker>
            </mat-form-field>

            <!-- learner Field -->
            <mat-form-field *ngIf="displayFilters('learner')">
                <mat-label>{{ 'assignment.select_learners' | translate }}</mat-label><mat-select [formControl]="customFilter.learnerControl"
                            #learnerSelect multiple >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let learner of selectableLearners"
                                [value]="learner.id">{{ learner.username }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('state')">
                <mat-label>{{ 'generic.state' | translate }}</mat-label><mat-select [formControl]="customFilter.stateControl" #stateSelect
                            (selectionChange)="selectFilter($event, 'assignments_state')"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let option of allStates" [value]="option.id">
                        {{ localizedState(option?.get('label')) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <!-- educationalLevel Field -->
            <mat-form-field *ngIf="displayFilters('educationalLevel')">
                <mat-label>{{ 'generic.level' | translate }}</mat-label><mat-select (selectionChange)="selectFilter($event, 'educationalLevel')"
                            [formControl]="customFilter['educationalLevelControl']"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let educationalLevel of educationalLevels"
                                [value]="educationalLevel.id">{{ educationalLevel.get('label') }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- concept Field -->
            <mat-form-field *ngIf="displayFilters('concepts')">
                <mat-label>{{ 'generic.concepts' | translate }}</mat-label><mat-select (selectionChange)="selectFilter($event, 'assignment_concepts')"
                            [formControl]="customFilter['conceptControl']"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let concept of concepts"
                                [value]="concept.id">{{ concept.get('label') }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- chapter Field -->
            <mat-form-field
                *ngIf="displayFilters('chapters') || (isConditionalFilters('chapters') && chapterFilterConditionOk)">
                <mat-label>{{ 'generic.chapters' | translate }}</mat-label><mat-select (selectionChange)="selectFilter($event, 'assignment_chapters')"
                            [formControl]="customFilter['chapterControl']"
                            >
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let chapter of chapters"
                                [value]="chapter.id">{{ chapter.get('label') }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!--assignated filter-->
            <div *ngIf="displayFilters('assignated')">
                <mat-checkbox
                    [aria-label]="'generic.user_save_assignated' | translate"
                    [formControl]="customFilter['assignatedControl']"
                    (keydown.enter)="assignatedChangeState($event)">
                    {{ 'generic.user_save_assignated' | translate }}
                </mat-checkbox>
            </div>

            <button class="followed-filter__search-button"
                    mat-raised-button
                    [disabled]="isSearchDisabled()"
                    (click)="onLaunchSearchClick()">
                {{ 'generic.search' | translate }}
            </button>

            <div class="py-4 font-size-14 count-entities"
                 fxlayout="row"
                 fxlayoutalign="start center">
                {{ countEntities }} {{'generic.results' | translate }}
            </div>

        </mat-toolbar-row>

    </mat-toolbar>

</form>