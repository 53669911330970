<div id="file-manager" class="page-layout simple right-sidebar inner-sidebar inner-scroll" >

  <!-- CONTENT -->
  <div class="content">

    <!-- CENTER -->
    <div class="center" fxFlex fusePerfectScrollbar>
      <!-- CONTENT -->
      <div class="content mat-white-bg" >
        <fuse-corpus-file-list (selectionChanged)="onSelectionChanged($event)" mode="readonly" [selection]="selection" #fileslist *ngIf="itemId !== undefined && corpusId !== undefined" [corpusId]="corpusId" [itemId]="itemId"></fuse-corpus-file-list>
      </div>
      <!-- / CONTENT -->

    </div>
    <!-- / CENTER -->

    <!-- SIDENAV -->
    <fuse-sidebar class="sidebar details-sidebar" position="right" lockedOpen="gt-md">
      <fuse-corpus-details-sidenav></fuse-corpus-details-sidenav>
    </fuse-sidebar>
    <!-- / SIDENAV -->

  </div>
</div>
