<div mat-dialog-title>
  <h2 *ngIf="mode === 'creation'">{{ "corpus.add_resource" | translate }}</h2>
  <h2 *ngIf="mode === 'edition'">{{ "generic.edit" | translate }}</h2>

  <button
    class="close"
    (click)="dialogRef.close()"
    aria-label="Close dialog"
  >
    <mat-icon svgIcon="cross"></mat-icon>
  </button>
</div>

<form ngNativeValidate #modalForm2="ngForm">
  <div mat-dialog-content>
    <div
      class="content p-24"
      fxLayout="column"
      fxLayoutAlign="strech"
      fxFlex="1 0 auto"
    >
      <div class="example-loading-shade" *ngIf="uploading">
        <mat-spinner></mat-spinner>
      </div>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'name'"
        fxFlex="100"
        *ngIf="displayField('name') && !displayTextEditorField('name')"
      >
        <input
          [required]="displayRequiredField('name')"
          matInput
          placeholder="{{ 'generic.title' | translate }}"
          type="text"
          [(ngModel)]="name"
          name="name"
          [disabled]="metadataAreCreating"
          #nameTxt="ngModel"
        />
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'scolomDate'"
        fxFlex="100"
        *ngIf="
          displayField('scolomDate') && !displayTextEditorField('scolomDate')
        "
      >
        <input
          [required]="displayRequiredField('scolomDate')"
          matInput
          placeholder="{{ 'generic.date' | translate }}"
          type="text"
          [(ngModel)]="scolomDate"
          name="scolomDate"
          [disabled]="metadataAreCreating"
        />
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="
          displayField('target-age') ? 'target-age' : 'educationalLevel'
        "
        fxFlex="100"
        *ngIf="
          (displayField('educationalLevel') &&
            !displayTextEditorField('educationalLevel')) ||
          (displayField('target-age') && !displayTextEditorField('target-age'))
        "
      >
        <mat-select
          [required]="
            displayRequiredField('educationalLevel') ||
            displayRequiredField('target-age')
          "
          [(ngModel)]="educationalLevels"
          name="educationalLevel"
          placeholder="{{
            displayField('target-age')
              ? ('activities.target_age' | translate)
              : ('generic.level' | translate)
          }}"
          multiple
        >
          <mat-option
            *ngFor="let educationalLevel of educationalLevelList"
            [value]="educationalLevel"
            >{{ educationalLevel.get("label") }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="displayField('concepts')">
        <input
          type="text"
          [placeholder]="'corpus.concepts_input_placeholder' | translate"
          matInput
          [value]="
            concepts[0]
              ? conceptSelectedByUser
                ? concepts[0].name
                : concepts[0].label
              : ''
          "
          [matAutocomplete]="auto"
        />
        <mat-icon matSuffix>search</mat-icon>

        <mat-autocomplete
          [displayWith]="displayConcept"
          (optionSelected)="selectConcept($event)"
          autoActiveFirstOption
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let concept of concepts$ | async"
            [value]="concept"
          >
            {{ concept.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- <mat-form-field *ngIf="displayField('subThemes')">
        <input
          type="text"
          placeholder="corpus.share_to_groups_input_placeholder"
          matInput
          [matAutocomplete]="auto"
        />
        <mat-icon matSuffix>search</mat-icon>

        <mat-autocomplete
          [displayWith]="displayConcept"
          (optionSelected)="selectConcept($event)"
          autoActiveFirstOption
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let concept of concepts$ | async"
            [value]="concept"
          >
            {{ concept.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'author'"
        fxFlex="100"
        *ngIf="displayField('author') && !displayTextEditorField('author')"
      >
        <input
          [required]="displayRequiredField('author')"
          matInput
          placeholder="{{ 'generic.source_author' | translate }}"
          type="text"
          [(ngModel)]="author"
          name="author"
          [disabled]="metadataAreCreating"
        />
      </mat-form-field>

      <input type="hidden" [(ngModel)]="tagModified" name="tagModified" />

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'tags'"
        fxFlex="100"
        *ngIf="displayField('tags')"
      >
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let chip of chips"
            [selectable]="true"
            [removable]="removable"
            (removed)="remove(chip)"
          >
            {{ chip.label }}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip-row>
          <input
            placeholder="{{ 'generic.tags' | translate }}"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-grid>
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'source'"
        fxFlex="100"
        class="m-0"
        *ngIf="displayField('source') && !displayTextEditorField('source')"
      >
        <input
          [required]="displayRequiredField('source')"
          matInput
          placeholder="{{ 'generic.source' | translate }}"
          type="text"
          [(ngModel)]="source"
          name="source"
          [disabled]="metadataAreCreating"
        />
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'description'"
        fxFlex="100"
        *ngIf="
          displayField('description') && !displayTextEditorField('description')
        "
      >
        <textarea
          [required]="displayRequiredField('description')"
          matInput
          rows="7"
          placeholder="{{ 'generic.description' | translate }}"
          type="text"
          name="description"
          [(ngModel)]="description"
          [disabled]="metadataAreCreating"
        ></textarea>
      </mat-form-field>

      <app-wysiwyg
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'description'"
        name="description"
        [(content)]="description"
        [required]="displayRequiredField('description')"
        *ngIf="
          displayField('description') && displayTextEditorField('description')
        "
      ></app-wysiwyg>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'difficulty'"
        fxFlex="100"
        *ngIf="
          displayField('difficulty') && !displayTextEditorField('difficulty')
        "
      >
        <mat-select
          [required]="displayRequiredField('difficulty')"
          [(ngModel)]="difficulty"
          name="difficulty"
          placeholder="{{ 'generic.difficulty' | translate }}"
          multiple
        >
          <mat-option
            *ngFor="let difficulty of difficultyList"
            [value]="difficulty"
            >{{ difficulty.get("label") }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'skills'"
        fxFlex="100"
        *ngIf="displayField('skills') && !displayTextEditorField('skills')"
      >
        <mat-select
          [required]="displayRequiredField('skills')"
          [(ngModel)]="skills"
          name="skills"
          placeholder="{{ 'generic.skills' | translate }}"
          multiple
        >
          <mat-option *ngFor="let skill of skillsList" [value]="skill"
            >{{ skill.get("name") }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appMoveElement
        [movableElementIsActive]="autoOrderFields"
        [movableElementFields]="fields"
        [movableElementField]="'groups'"
        fxFlex="100"
        *ngIf="displayField('groups') && !displayTextEditorField('groups')"
      >
        <mat-select
          [required]="displayRequiredField('groups')"
          [(ngModel)]="groups"
          name="groups"
          placeholder="{{ 'groups-management.assign_to_group' | translate }}"
          multiple
        >
          <mat-option *ngFor="let group of allWorkgroups" [value]="group"
            >{{ group.workgroupname }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div
        class="content p-24"
        *ngIf="displayField('files')"
        fusePerfectScrollbar
      >
        <p>{{ "corpus.import_pdf.title" | translate }}</p>
        <p>{{ "corpus.import_pdf.informations" | translate }}</p>
        <p>{{ "corpus.import_pdf.details" | translate }}</p>
        <ngx-file-drop
          [showBrowseBtn]="true"
          [browseBtnLabel]="'generic.browse' | translate"
          [dropZoneLabel]="'corpus.upload_dragndrop_message' | translate"
          (onFileDrop)="dropped($event)"
        >
          <button
            mat-raised-button
            mat-primary
            class="add-file-button"
            (click)="fileInput.click()"
            aria-label="Add file"
          >
            <span>{{ "generic.browse" | translate }}</span>
          </button>
        </ngx-file-drop>
        <p class="error text-center" *ngIf="isImportPdfInError">
          {{ "corpus.import_pdf_error" | translate }}
        </p>
        <p class="text-center" *ngIf="pdfFile?.name">{{ pdfFile?.name }}</p>
        <div class="file-uploader">
          <input
            hidden
            type="file"
            accept="application/pdf"
            #fileInput
            (change)="onFileChanged($event)"
          />
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="pt-24 val">
    <button
      type="submit"
      mat-raised-button
      color="accent"
      class="submit-button"
      value="{{ 'generic.validate' | translate }}"
      (click)="validateDetails()"
      [disabled]="metadataAreCreating || modalForm2.invalid"
    >
      {{ "generic.validate" | translate }}
    </button>
  </div>
</form>