<div class="form-dialog__header"
     mat-dialog-title>
    <h1 class="form-dialog__title">
        <span>{{ 'form-dialog.title' | translate }}</span>
    </h1>
    <button class="form-dialog__close-button"
            [mat-dialog-close]="true"
            [attr.aria-label]="'generic.close' | translate"
            [matTooltip]="'generic.close' | translate">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<div class="form-dialog__content"
     mat-dialog-content>
    <form id="form"
          class="form-dialog__form"
          [formGroup]="formGroup"
          (ngSubmit)="onSubmit()">
        <ng-container *ngFor="let question of questions; let questionIndex = index">
            <fieldset *ngIf="question.type === 'radio'"
                      class="form-dialog__fieldset">
                <legend class="form-dialog__legend"
                        [innerHTML]="question.question">
                </legend>
                <div *ngFor="let option of question.options; let optionIndex = index"
                     class="form-dialog__field">
                    <input id="option-{{questionIndex}}-{{optionIndex}}"
                           class="form-dialog__input"
                           type="radio"
                           [name]="question.key"
                           [formControlName]="question.key"
                           [value]="option"/>
                    <label class="form-dialog__label"
                           for="option-{{questionIndex}}-{{optionIndex}}"
                           [innerHTML]="option"
                    >
                    </label>
                </div>
            </fieldset>
        </ng-container>
    </form>
</div>

<div class="form-dialog__actions"
     mat-dialog-actions>
    <button class="form-dialog__submit-button"
            mat-raised-button 
            type="submit" 
            form="form"
            [disabled]="!formGroup.valid || isSubmitting">
        <span>{{ 'form-dialog.validate' | translate }}</span>
        <ng-container *ngIf="isSubmitting">
            <mat-spinner diameter="24"></mat-spinner>
        </ng-container>
    </button>
</div>