<div class="page-layout inner-scroll" id="lessons">
    <app-stepper-wrapper [lessonMode]="!!activitiesService.currentAssignment" [stepperOptions]="stepperOptions"
                         class="inner-scroll" [ngClass]="{'progress-multi-zone-is-display':showMultiZoneProgressBar()}">
        <!-- boutons exportables vers les différents stepper ou barre de progression -->
        <ng-template #toolsMenuTemplate>
            <button
                (click)="$event.stopPropagation();" *ngIf="allowedToolsPlugins.length > 0"
                [matMenuTriggerFor]="toolsMenu"
                class="fuse-app-lessons__button-tools"

                [matTooltip]="'activities.lesson.tools' | translate"
                [disabled]="headerButtonAreLocked$ | async">
                <mat-icon svgIcon="tools-circle"></mat-icon>
            </button>
            <mat-menu #toolsMenu="matMenu">
                <button (click)="executeTool(tool)" *ngFor="let tool of allowedToolsPlugins"
                        mat-menu-item>
                    <span>{{ tool.setting.label | translate }}</span>
                </button>
            </mat-menu>
        </ng-template>
        <ng-template #buttonFullscreenTemplate>
            <button
                (click)="toggleFullscreen()" *ngIf="displayFullscreenButton"
                [disabled]="headerButtonAreLocked$ | async"
                class="fuse-app-lessons__button-fullscreen"
                [matTooltip]="(fullscreenService.isFullScreenActive ? 'activities.lesson.exit_full_screen' : 'activities.lesson.enter_full_screen') | translate">
                <mat-icon
                    [svgIcon]="'fullscreen' + (fullscreenService.isFullScreenActive ? '_exit' : '')"></mat-icon>
            </button>
        </ng-template>
        <ng-template #buttonCloseTemplate>
            <button
                (click)="goBack()" [ngClass]="displayCloseButtonPng?'close_header':''"
                class="fuse-app-lessons__button-close"
                [matTooltip]="'activities.lesson.exit' | translate"
                [disabled]="headerButtonAreLocked$ | async">
                <mat-icon svgIcon="cross"></mat-icon>
            </button>
        </ng-template>

        <div toolbar-before-stepper>
            <div *ngTemplateOutlet="toolsMenuTemplate"></div>
        </div>
        <div toolbar-after-stepper>
            <div *ngTemplateOutlet="buttonFullscreenTemplate"></div>
            <div *ngTemplateOutlet="buttonCloseTemplate"></div>
        </div>

        <div [ngClass]="{'js-latex-is-open' : isLatexKeyboardDisplayed}" class="center app-stepper-wrapper__side-generic-stepper" fxFlex fxLayout="column">
            <!-- center car il y a le stepper en sidebar-->
            @if (!isLoading) {
                <div *ngIf="(showHeaderExo || hasLessonSteps) && !displayStepper" class="header fuse-app-lessons-header bem-app-stepper-wrapper__header"
                     [ngClass]="{'no-title' : !this.showActivityTitle}">

                    <div *ngIf="showHeaderExo && totalOfExercises()>0"
                         class="header-top">

                        <button role="button"
                                class="help fuse-app-lessons__button-help"
                                *ngIf="displayHelpButton"
                                [ngStyle]="{ visibility:getFeedBack() === ''?'hidden':'visible'}"
                                (click)="help()" >
                            <mat-icon svgIcon="help"></mat-icon>
                            <span class="sr-only">{{'accessibility.header.exo.help' | translate}}</span>
                        </button>

                        <span *ngIf="showLessonTitle" class="title bem-fuse-app-lessons__title" data-tralalere-identifier="showLessonTitle">
                            <mat-icon *ngIf="lessonTitleIcon" svgIcon="titleIconLesson"></mat-icon>
                            {{lessonTitle}}</span>

                        <span *ngIf="showActivityTitle"
                              class="title"
                              data-tralalere-identifier="showActivityTitle"> {{getStepTitlePrefixPosition() ? currentStep + '-' : ''}} {{activityTitle}}</span>

                        <span *ngIf="showActivityQuestionIndex"
                              class="title"
                              data-tralalere-identifier="showActivityQuestionIndex"> {{'generic.question' | translate}} {{this.activitiesService.currentActivityIndex }}
                            /{{totalOfExercises()}}</span>
                        <div class="toolbar" fxLayoutAlign="start center">
                            <span *ngIf="learnerInfo" class="ml-12 mr-24 learner-info">
                                        {{ 'generic.learner' | translate }} : {{learnerInfo.name}}
                                - {{ 'generic.label_group' | translate }} : {{learnerInfo.classes}}
                                    </span>

                            <div *ngTemplateOutlet="toolsMenuTemplate"></div>
                            <div *ngTemplateOutlet="buttonFullscreenTemplate"></div>
                            <div *ngTemplateOutlet="buttonCloseTemplate"></div>
                        </div>
                    </div>
                    <div *ngIf="hasLessonSteps" class="header-bottom">
                        <h2>{{ stepTitle | translate }}</h2>
                    </div>
                </div>
                <!--hide content until all data are ready to avoid unsynchronize screen opening-->
                <content class="app-stepper-wrapper__side-generic-stepper__content">
                    <app-progress-multi-zone *ngIf="showMultiZoneProgressBar()"
                                             [answers]="answersCopy"
                                             [numberOfQuestion]="numberOfQuestion || totalOfExercises()"
                                             aria-hidden="true">
                    </app-progress-multi-zone>
                    <app-feedback *ngIf="displayFeedbacks"></app-feedback>
                    <div class="outlet-player-navigation">
                        <div class="previous-wrapper">
                            <button [disabled]="isLoading" (click)="previousActivity()"
                                    *ngIf="isArrowNavigationEnabled('previous')"
                                    aria-label="preview"
                                    class="previous" >
                                <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
                            </button>
                        </div>
                        <router-outlet></router-outlet>
                        <div class="next-wrapper">
                            <button [disabled]="isLoading" (click)="nextActivity()"
                                    *ngIf="isArrowNavigationEnabled('next')" aria-label="next"
                                    class="next" >
                                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <app-progress-bar-generic *ngIf="showGenericProgressBar()"
                                              [numberOfSteps]="0"
                                              [pourcent]="progression"
                    ></app-progress-bar-generic>
                </content>

                <!-- This is to manage the user actions. This is not fixed. We need to findout a good way to
    manage show and hide of these buttons. -->
                <div class="footer fuse-app-lessons-content-footer" fxFlex="none">
                    <!--                   <app-user-action-button
                                           [activityId]='getCurrentActivityOrMediaId()'
                                           [activityTitle]="activityTitle"
                                           [isMedia]="isMedia()"
                                           [showButtons]="isUserActionButtonsAllowed"
                                       ></app-user-action-button>-->
                </div>
            }
            @if (isLoading) {
                <div class="spinner-wrapper">
                    <mat-spinner></mat-spinner>
                </div>
            }
        </div>
    </app-stepper-wrapper>
</div>
