<div class="feedback-dialog-by-steps__header"
     mat-dialog-title>

    <h2 class="feedback-dialog-by-steps__title">{{ 'feedback-dialog.title_' + currentConcept | translate }}</h2>

    <button class="feedback-dialog-by-steps__close-button"
            (click)="selfDialogRef.close()"
            [attr.aria-label]="'generic.close' | translate"
            [matTooltip]="'generic.close' | translate">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

</div>


<mat-dialog-content class="feedback-dialog-by-steps__content"
                    fusePerfectScrollbar>

    <!--citizen code junior-->
    <form *ngIf="useSmiley !== null"
          class="feedback-dialog-by-steps__form"
          [formGroup]="form">

        <!--first step choose if you are in group or alone-->
        <div class="feedback-dialog-by-steps__fieldset-wrapper">
            <fieldset *ngIf="currentStep === 0"
                      class="feedback-dialog-by-steps__fieldset">
                <legend class="feedback-dialog-by-steps__legend">{{ 'feedback-dialog.alone_' + currentConcept | translate }}</legend>
                <div class="feedback-dialog-by-steps__input-wrapper">
                    <input class="feedback-dialog-by-steps__input"
                           type="radio"
                           id="alone"
                           formControlName="alone"
                           name="alone"
                           value="true"/>
                    <label class="feedback-dialog-by-steps__label"
                           for="alone">
                        {{ 'feedback-dialog.alone_yes_' + currentConcept | translate }}
                    </label>
                </div>
                <div class="feedback-dialog-by-steps__input-wrapper">
                    <input class="feedback-dialog-by-steps__input"
                           type="radio"
                           id="not_alone"
                           formControlName="alone"
                           name="alone"
                           value="false"/>
                    <label class="feedback-dialog-by-steps__label"
                           for="not_alone">
                        {{ 'feedback-dialog.alone_no_' + currentConcept | translate }}
                    </label>
                </div>
            </fieldset>
        </div>

        <!--citizen code alone-->
        <ng-container *ngIf="form.get('alone')?.value === 'true'">
            <div class="feedback-dialog-by-steps__fieldset-wrapper">
                <fieldset class="feedback-dialog-by-steps__fieldset">
                    <legend class="feedback-dialog-by-steps__legend">
                        {{ 'feedback-dialog.genre_activities_' + currentConcept | translate }}
                    </legend>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="boy"
                               formControlName="genre"
                               name="genre"
                               value="boy"/>
                        <label class="feedback-dialog-by-steps__label"
                               for="boy">
                            {{ 'feedback-dialog.genre.boy_' + currentConcept | translate }}
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="girl"
                               formControlName="genre"
                               name="genre"
                               value="girl"/>
                        <label class="feedback-dialog-by-steps__label"
                               for="girl">
                            {{ 'feedback-dialog.genre.girl_' + currentConcept | translate }}
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="unknow"
                               formControlName="genre"
                               name="genre"
                               value="unknow"/>
                        <label class="feedback-dialog-by-steps__label"
                               for="unknow">
                            {{ 'feedback-dialog.genre.unknow_' + currentConcept | translate }}
                        </label>
                    </div>
                </fieldset>
            </div>
            <!--love it?-->
            <div class="feedback-dialog-by-steps__fieldset-wrapper">
                <fieldset class="feedback-dialog-by-steps__fieldset">
                    <legend class="feedback-dialog-by-steps__legend">
                        {{ 'feedback-dialog.ranking_' + currentConcept | translate }}
                    </legend>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="ranking"
                               id="ranking-1"
                               name="ranking"
                               type="radio"
                               value="1"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="ranking-1">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="ranking"
                               id="ranking-2"
                               name="ranking"
                               type="radio"
                               value="2"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="ranking-2">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="ranking"
                               id="ranking-3"
                               name="ranking"
                               type="radio"
                               value="3"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="ranking-3">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="ranking"
                               id="ranking-4"
                               name="ranking"
                               type="radio"
                               value="4"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="ranking-4">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                </fieldset>
            </div>
            <!--advice before-->
            <div class="feedback-dialog-by-steps__fieldset-wrapper">
                <fieldset class="feedback-dialog-by-steps__fieldset">
                    <legend class="feedback-dialog-by-steps__legend">
                        {{ 'feedback-dialog.before_activities_' + currentConcept | translate }}
                    </legend>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="before"
                               id="before-1"
                               name="before"
                               type="radio"
                               value="1"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="before-1">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="before"
                               id="before-2"
                               name="before"
                               type="radio"
                               value="2"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="before-2">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="before"
                               id="before-3"
                               name="before"
                               type="radio"
                               value="3"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="before-3">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               formControlName="before"
                               id="before-4"
                               name="before"
                               type="radio"
                               value="4"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="before-4">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                </fieldset>
            </div>
            <!--advice after-->
            <div class="feedback-dialog-by-steps__fieldset-wrapper">
                <fieldset class="feedback-dialog-by-steps__fieldset">
                    <legend class="feedback-dialog-by-steps__legend">
                        {{ 'feedback-dialog.after_activities_' + currentConcept | translate }}
                    </legend>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="after-1"
                               formControlName="after"
                               name="after"
                               value="1"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="after-1">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="after-2"
                               formControlName="after"
                               name="after"
                               value="2"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="after-2">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="after-3"
                               formControlName="after"
                               name="after"
                               value="3"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="after-3">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="after-4"
                               formControlName="after"
                               name="after"
                               value="4"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="after-4">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                </fieldset>
            </div>
            <!--will you recommand it-->
            <div class="feedback-dialog-by-steps__fieldset-wrapper">
                <fieldset class="feedback-dialog-by-steps__fieldset">
                    <legend class="feedback-dialog-by-steps__legend">
                        {{ 'feedback-dialog.recommendation_activities_' + currentConcept | translate }}
                    </legend>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="recommendation-1"
                               formControlName="recommendation"
                               name="recommendation"
                               value="1"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="recommendation-1">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="recommendation-2"
                               formControlName="recommendation"
                               name="recommendation"
                               value="2"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="recommendation-2">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__dissatisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="recommendation-3"
                               formControlName="recommendation"
                               name="recommendation"
                               value="3"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="recommendation-3">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                    <div class="feedback-dialog-by-steps__input-wrapper">
                        <input class="feedback-dialog-by-steps__input"
                               type="radio"
                               id="recommendation-4"
                               formControlName="recommendation"
                               name="recommendation"
                               value="4"/>
                        <label class="feedback-dialog-by-steps__label"
                               [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                               for="recommendation-4">
                            <mat-icon *ngIf="useSmiley"
                                      class="feedback-dialog-by-steps__emoji-icon"
                                      svgIcon="feedback-dialog-by-steps__very-satisfied">
                            </mat-icon>
                            <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                        </label>
                    </div>
                </fieldset>
            </div>
        </ng-container>

        <!--citizen code group-->
        <ng-container *ngIf="form.get('alone')?.value === 'false'">
            <ng-container *ngIf="currentStep === 1">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'feedback-dialog.how_many_people_' + currentConcept | translate }}</mat-label>
                    <input matInput
                           type="number"
                           formControlName="numberOfPeople"/>
                    <mat-hint>{{ 'feedback-dialog.numeric_hint_' + currentConcept | translate }}</mat-hint>
                </mat-form-field>
            </ng-container>
            <!--step 2 repartition  boys girls -->
            <ng-container *ngIf="currentStep===2">
                <h3 class="feedback-dialog-by-steps__title">
                    {{ 'feedback-dialog.repartition_group_' + currentConcept | translate }}
                </h3>
                <!--boys-->
                <div>
                    <p class="feedback-dialog-by-steps__paragraph">{{ 'feedback-dialog.repartition_boys_' + currentConcept | translate }} {{form.value?.numberOfBoys}} {{ 'feedback-dialog.repartition_boys_part_2_' + currentConcept | translate }}</p>
                    <div class="slider-container">
                        <mat-slider
                            aria-label="unit(s)"
                            formControlName="numberOfBoys"
                            [max]="form.get('numberOfPeople')?.value"
                            [min]="1"
                            step="1"
                            showTicks="false"
                        ></mat-slider>
                        <ol>
                            <li *ngFor="let value of getListOfValuesForRepartition()">{{ value }}</li>
                        </ol>
                    </div>
                </div>
                <!--girls-->
                <div>
                    <p class="feedback-dialog-by-steps__paragraph">{{ 'feedback-dialog.repartition_girls_' + currentConcept | translate }} {{form.value?.numberOfGirls}} +  {{ 'feedback-dialog.repartition_girls_part_2_' + currentConcept | translate }}</p>
                    <div class="slider-container">
                        <mat-slider
                            aria-label="unit(s)"
                            formControlName="numberOfGirls"
                            [max]="form.get('numberOfPeople')?.value"
                            [min]="1"
                            step="1"
                            showTicks="false"
                        ></mat-slider>
                        <ol>
                            <li *ngFor="let value of getListOfValuesForRepartition()">{{ value }}</li>
                        </ol>
                    </div>
                </div>
                <!--no answers-->
                <div>
                    <p class="feedback-dialog-by-steps__paragraph">{{ 'feedback-dialog.repartition_genre_no_answers_' + currentConcept | translate }} {{form.value?.numberOfGenreNoAnswers}} {{ 'feedback-dialog.repartition_genre_no_answers_part_2_' + currentConcept | translate }}</p>
                    <div class="slider-container">
                        <mat-slider
                            aria-label="unit(s)"
                            formControlName="numberOfGenreNoAnswers"
                            [max]="form.get('numberOfPeople')?.value"
                            [min]="1"
                            step="1"
                            showTicks="false"
                        ></mat-slider>
                        <ol>
                            <li *ngFor="let value of getListOfValuesForRepartition()">{{ value }}</li>
                        </ol>
                    </div>
                </div>
            </ng-container>
            <!--step 3 love experience-->
            <ng-container *ngIf="currentStep===3">
                <h3 class="feedback-dialog-by-steps__title">
                    {{ 'feedback-dialog.grade_group_' + currentConcept | translate }}
                </h3>
                <!--boys-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.grade_boys_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeBoys"
                                   id="gradeBoys-1"
                                   name="gradeBoys"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeBoys-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeBoys"
                                   id="gradeBoys-2"
                                   name="gradeBoys"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeBoys-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeBoys"
                                   id="gradeBoys-3"
                                   name="gradeBoys"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeBoys-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeBoys"
                                   id="gradeBoys-4"
                                   name="gradeBoys"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeBoys-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--girls-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.grade_girls_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeGirls"
                                   id="gradeGirls-1"
                                   name="gradeGirls"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeGirls-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeGirls"
                                   id="gradeGirls-2"
                                   name="gradeGirls"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeGirls-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeGirls"
                                   id="gradeGirls-3"
                                   name="gradeGirls"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeGirls-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeGirls"
                                   id="gradeGirls-4"
                                   name="gradeGirls"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeGirls-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--no answers-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.grade_no_answers_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeNoAnswers"
                                   id="gradeNoAnswers-1"
                                   name="gradeNoAnswers"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeNoAnswers-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeNoAnswers"
                                   id="gradeNoAnswers-2"
                                   name="gradeNoAnswers"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeNoAnswers-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeNoAnswers"
                                   id="gradeNoAnswers-3"
                                   name="gradeNoAnswers"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeNoAnswers-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="gradeNoAnswers"
                                   id="gradeNoAnswers-4"
                                   name="gradeNoAnswers"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="gradeNoAnswers-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
            <!--step 4 before-->
            <ng-container *ngIf="currentStep===4">
                <h3 class="feedback-dialog-by-steps__title">
                    {{ 'feedback-dialog.before_group_' + currentConcept | translate }}
                </h3>
                <!--boys-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.before_boys_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeBoys"
                                   id="beforeBoys-1"
                                   name="beforeBoys"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeBoys-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeBoys"
                                   id="beforeBoys-2"
                                   name="beforeBoys"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeBoys-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeBoys"
                                   id="beforeBoys-3"
                                   name="beforeBoys"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeBoys-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeBoys"
                                   id="beforeBoys-4"
                                   name="beforeBoys"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeBoys-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--girls-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.before_girls_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeGirls"
                                   id="beforeGirls-1"
                                   name="beforeGirls"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeGirls-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeGirls"
                                   id="beforeGirls-2"
                                   name="beforeGirls"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeGirls-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeGirls"
                                   id="beforeGirls-3"
                                   name="beforeGirls"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeGirls-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeGirls"
                                   id="beforeGirls-4"
                                   name="beforeGirls"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeGirls-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--no answers-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.before_no_answers_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeNoAnswers"
                                   id="beforeNoAnswers-1"
                                   name="beforeNoAnswers"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeNoAnswers-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeNoAnswers"
                                   id="beforeNoAnswers-2"
                                   name="beforeNoAnswers"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeNoAnswers-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeNoAnswers"
                                   id="beforeNoAnswers-3"
                                   name="beforeNoAnswers"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeNoAnswers-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="beforeNoAnswers"
                                   id="beforeNoAnswers-4"
                                   name="beforeNoAnswers"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="beforeNoAnswers-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
            <!--step 5 after-->
            <ng-container *ngIf="currentStep===5">
                <h3 class="feedback-dialog-by-steps__title">
                    {{ 'feedback-dialog.after_group_' + currentConcept | translate }}
                </h3>
                <!--boys-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.after_boys_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterBoys"
                                   id="afterBoys-1"
                                   name="afterBoys"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterBoys-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterBoys"
                                   id="afterBoys-2"
                                   name="afterBoys"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterBoys-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterBoys"
                                   id="afterBoys-3"
                                   name="afterBoys"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterBoys-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterBoys"
                                   id="afterBoys-4"
                                   name="afterBoys"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterBoys-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--girls-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.after_girls_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterGirls"
                                   id="afterGirls-1"
                                   name="afterGirls"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterGirls-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterGirls"
                                   id="afterGirls-2"
                                   name="afterGirls"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterGirls-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterGirls"
                                   id="afterGirls-3"
                                   name="afterGirls"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterGirls-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterGirls"
                                   id="afterGirls-4"
                                   name="afterGirls"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterGirls-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--no answers-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.after_no_answers_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterNoAnswers"
                                   id="afterNoAnswers-1"
                                   name="afterNoAnswers"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterNoAnswers-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterNoAnswers"
                                   id="afterNoAnswers-2"
                                   name="afterNoAnswers"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterNoAnswers-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterNoAnswers"
                                   id="afterNoAnswers-3"
                                   name="afterNoAnswers"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterNoAnswers-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="afterNoAnswers"
                                   id="afterNoAnswers-4"
                                   name="afterNoAnswers"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="afterNoAnswers-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
            <!--step 6 recommand it-->
            <ng-container *ngIf="currentStep===6">
                <h3 class="feedback-dialog-by-steps__title">
                    {{ 'feedback-dialog.recommand_group_' + currentConcept | translate }}
                </h3>
                <!--boys-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.recommendation_boys_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationBoys"
                                   id="recommendationBoys-1"
                                   name="recommendationBoys"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationBoys-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationBoys"
                                   id="recommendationBoys-2"
                                   name="recommendationBoys"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationBoys-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationBoys"
                                   id="recommendationBoys-3"
                                   name="recommendationBoys"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationBoys-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationBoys"
                                   id="recommendationBoys-4"
                                   name="recommendationBoys"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationBoys-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--girls-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.recommendation_girls_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationGirls"
                                   id="recommendationGirls-1"
                                   name="recommendationGirls"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationGirls-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationGirls"
                                   id="recommendationGirls-2"
                                   name="recommendationGirls"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationGirls-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationGirls"
                                   id="recommendationGirls-3"
                                   name="recommendationGirls"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationGirls-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationGirls"
                                   id="recommendationGirls-4"
                                   name="recommendationGirls"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationGirls-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
                <!--no answers-->
                <div class="feedback-dialog-by-steps__fieldset-wrapper">
                    <fieldset class="feedback-dialog-by-steps__fieldset">
                        <legend class="feedback-dialog-by-steps__legend">
                            {{ 'feedback-dialog.recommendation_no_answers_' + currentConcept | translate }}
                        </legend>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationNoAnswers"
                                   id="recommendationNoAnswers-1"
                                   name="recommendationNoAnswers"
                                   type="radio"
                                   value="1"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationNoAnswers-1">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-1-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationNoAnswers"
                                   id="recommendationNoAnswers-2"
                                   name="recommendationNoAnswers"
                                   type="radio"
                                   value="2"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationNoAnswers-2">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__dissatisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-2-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationNoAnswers"
                                   id="recommendationNoAnswers-3"
                                   name="recommendationNoAnswers"
                                   type="radio"
                                   value="3"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationNoAnswers-3">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-3-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                        <div class="feedback-dialog-by-steps__input-wrapper">
                            <input class="feedback-dialog-by-steps__input"
                                   formControlName="recommendationNoAnswers"
                                   id="recommendationNoAnswers-4"
                                   name="recommendationNoAnswers"
                                   type="radio"
                                   value="4"/>
                            <label class="feedback-dialog-by-steps__label"
                                   [class.feedback-dialog-by-steps__label--emoji]="useSmiley"
                                   for="recommendationNoAnswers-4">
                                <mat-icon *ngIf="useSmiley"
                                          class="feedback-dialog-by-steps__emoji-icon"
                                          svgIcon="feedback-dialog-by-steps__very-satisfied">
                                </mat-icon>
                                <span>{{ 'feedback.dialog_level-4-' + currentConcept | translate }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </ng-container>
        </ng-container>

    </form>

</mat-dialog-content>

<mat-dialog-actions class="feedback-dialog-by-steps__actions">

    <button *ngIf="(!useStep && useStep!== null) || currentStep === 6"
            class="submit-button"
            [disabled]="!answerWasMade()"
            (click)="onSubmit()">
        <span>{{ 'feedback-dialog.submit_' + currentConcept | translate }}</span>
    </button>

    <button *ngIf="useStep && currentStep < 6"
            class="submit-button"
            (click)="next()">
        <span>{{ 'feedback-dialog.next_' + currentConcept | translate }}</span>
    </button>

</mat-dialog-actions>