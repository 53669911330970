<div *ngIf="isDisplayed$() | async">
    <p *ngIf="description">{{ description | loadTemplateData$ | async }}</p>

    <button *ngIf="isButton()" (click)="triggerAction()" mat-raised-button [ngClass]="{inverted: isSecondary()}">
        <span>{{ text | loadTemplateData$ | async | translate }}</span>
        <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
    </button>

    <a *ngIf="!isButton()" (click)="triggerAction()">
        {{ text | loadTemplateData$ | async | translate }}
    </a>
</div>
