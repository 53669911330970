<div class="assignment-select-assignated">

    <div class="assignment-select-assignated__breadcrumb">

        <div *ngFor="let item of breadcrumb; let isLast = last"
              class="assignment-select-assignated__breadcrumb-item">
            <span class="assignment-select-assignated__breadcrumb-label">{{ item }}</span>
            <mat-icon *ngIf="!isLast"
                      class="assignment-select-assignated__breadcrumb-icon"
                      svgIcon="chevron-right-rounded">
            </mat-icon>
        </div>

    </div>

    <div class="assignment-select-assignated__content">

        <div class="assignment-select-assignated__primary-content">

            <h3 class="assignment-select-assignated__title">{{ 'assignment.who' | translate }}</h3>

            <div class="assignment-select-assignated__radio-container">

                <label *ngIf="assignmentByStepService.workgroups.length > 0"
                        class="assignment-select-assignated__radio-button"
                        [class.assignment-select-assignated__radio-button--selected]="selectedOption === 'workgroups'">
                    <input type="radio"
                           name="radio-group"
                           value="workgroups"
                           [(ngModel)]="selectedOption"/>
                    <span>{{ 'assignment.my_workgroups' | translate }}</span>
                </label>

                <label *ngIf="assignmentByStepService.groups.length > 0"
                        class="assignment-select-assignated__radio-button"
                        [class.assignment-select-assignated__radio-button--selected]="selectedOption === 'groups'">
                    <input type="radio"
                           name="radio-group"
                           value="groups"
                           [(ngModel)]="selectedOption"/>
                    <span>{{ 'assignment.my_groups' | translate }}</span>
                </label>

                <label *ngIf="assignmentByStepService.learners.length > 0 && !assignmentByStepService.isLaunchInClassroom"
                        class="assignment-select-assignated__radio-button"
                        [class.assignment-select-assignated__radio-button--selected]="selectedOption === 'learners'">
                    <input type="radio"
                           name="radio-group"
                           value="learners"
                           [(ngModel)]="selectedOption"/>
                    <span>{{ 'assignment.my_students' | translate }}</span>
                </label>

            </div>

        </div>

        <div class="assignment-select-assignated__secondary-content"
             [class.assignment-select-assignated__secondary-content--open]="atLeastOneItemSelected()">

            <div class="assignment-select-assignated__items-to-select-container">

                <ng-container *ngIf="selectedOption === 'workgroups'">
                    <ng-container *ngFor="let w of assignmentByStepService.workgroups">
                        <label *ngIf="w.learnersIds.length > 0"
                             class="assignment-select-assignated__item-to-select"
                             [class.assignment-select-assignated__item-to-select--selected]="w.selected">
                            <span>{{ w?.workgroupname }}</span>
                            <input type="checkbox"
                                   [(ngModel)]="w.selected"
                                   [disabled]="w.selected"
                                   [attr.aria-label]="('generic.select' | translate) + ' ' + w?.workgroupname"/>
                        </label>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="selectedOption === 'groups'">
                    <ng-container *ngFor="let g of assignmentByStepService.groups">
                        <label *ngIf="g.learnersIds.length > 0"
                             class="assignment-select-assignated__item-to-select"
                             [class.assignment-select-assignated__item-to-select--selected]="g.selected">
                            <span>{{ g?.groupname }}</span>
                            <input type="checkbox"
                                   [(ngModel)]="g.selected"
                                   [disabled]="g.selected"
                                   [attr.aria-label]="('generic.select' | translate) + ' ' + g?.groupname"/>
                        </label>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="selectedOption === 'learners'">
                    <label *ngFor="let l of assignmentByStepService.learners"
                         class="assignment-select-assignated__item-to-select"
                         [class.assignment-select-assignated__item-to-select--selected]="l.selected">
                        <span>{{ l?.username }}</span>
                        <input type="checkbox"
                               [(ngModel)]="l.selected"
                               [disabled]="l.selected"
                               [attr.aria-label]="('generic.select' | translate) + ' ' + l?.username"/>
                    </label>
                </ng-container>

            </div>

            <div *ngIf="atLeastOneItemSelected()"
                 class="assignment-select-assignated__selected-item-container">

                 <ng-container *ngFor="let w of assignmentByStepService.workgroups">
                    <label *ngIf="w.selected"
                         class="assignment-select-assignated__selected-item">
                        <span>{{ w?.workgroupname }}</span>
                        <input type="checkbox"
                               [(ngModel)]="w.selected"
                               [attr.aria-label]="('generic.unselect' | translate) + ' ' + w?.workgroupname"/>
                    </label>
                </ng-container>

                <ng-container *ngFor="let g of assignmentByStepService.groups">
                    <label *ngIf="g.selected"
                         class="assignment-select-assignated__selected-item">
                        <span>{{ g?.groupname }}</span>
                        <input type="checkbox"
                               [(ngModel)]="g.selected"
                               [attr.aria-label]="('generic.unselect' | translate) + ' ' + g?.groupname"/>
                    </label>
                </ng-container>

                <ng-container *ngFor="let l of assignmentByStepService.learners">
                    <label *ngIf="l.selected"
                         class="assignment-select-assignated__selected-item">
                        <span>{{ l?.username }}</span>
                        <input type="checkbox"
                               [(ngModel)]="l.selected"
                               [attr.aria-label]="('generic.unselect' | translate) + ' ' + l?.username"/>
                    </label>
                </ng-container>

                <label class="assignment-select-assignated__redo-checkbox">
                    <input type="checkbox"
                           [(ngModel)]="assignmentByStepService.doAgainLesson"/>
                    <span>{{ 'assignment.redo_already_done' | translate }}</span>
                </label>

            </div>

        </div>

        <input class="assignment-select-assignated__comment"
               type="text"
               [(ngModel)]="assignmentByStepService.comment"
               placeholder="{{ 'assignment.write_comment' | translate }}">

    </div>

</div>