<aside class="rewards-dialog">
    <div class="rewards-dialog__header"
         mat-dialog-title>
        <h2 class="rewards-dialog__title">
            {{ 'demo.end-activity' | translate}}
        </h2>
    </div>
    <div class="rewards-dialog__content"
         mat-dialog-content>
        <p class="rewards-dialog__message">{{'demo.win-info' | translate}}</p>
        <div class="rewards-dialog__coins">
            <div class="rewards-dialog__coin-name">{{'demo.citizen-coins' | translate}}</div>
            <div class="rewards-dialog__number-of-coins">+ 23</div>
            <mat-icon class="rewards-dialog__coin-icon"
                      svgIcon="credits">
            </mat-icon>
        </div>
    </div>
    <div class="rewards-dialog__actions"
         mat-dialog-actions>
        <button class="rewards-dialog__next-button" (click)="dialogRef.close()">
            <span>{{'demo.next' | translate}}</span>
            <mat-icon svgIcon="arrow-forward-rounded-500"></mat-icon>
        </button>
    </div>
</aside>