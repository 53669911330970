<div *ngIf="!showSpinner" fxLayout="column" class="page-layout simple fullwidth" fxFlex="fill"><!-- PAGE LAYOUT -->

    <div class="content" fxFlex fxLayout="column"><!-- CONTENT  ---------------------------------------- -->


        <!-- Is Like an other inner component -->
        <div class="page-layout simple inner-scroll" fxLayout="row" fxFlex><!-- PAGE LAYOUT -->

            <div fxFlex="none" fxLayout="column" fxLayoutAlign="center none" class="pl-16 pr-8">
                <button [style.visibility]="!(activityStepIndex > 0)? 'hidden': 'visible'"  class="preview"
                        (click)="previousPoll()" aria-label="preview">
                    <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
                </button>
            </div>

            <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center stretch" class="center content nowindow">

                <ng-container *ngFor="let form of formArray; let i = index" >
                    <form *ngIf="i === activityStepIndex"
                          [formGroup]="formArray[activityStepIndex]"
                          novalidate fxFill>

                        <!-- Activity Wording Field -->
                        <mat-card class="mat-elevation-z0 card-slide-question" fxLayout="column" fxFlex><!-- CARD LAYOUT -->
                            <mat-card-header>
                                <mat-card-title fxFlex fxLayout="row">
                                    <h3 class="titleDecoration"
                                        fxFlex>{{ 'generic.question' | translate }}&nbsp;{{questionIndex}}</h3>
                                </mat-card-title>
                            </mat-card-header>
                            <mat-card-content class="app-poll">
                                <mat-form-field>
                                    <textarea matInput name="instruction" formControlName="instruction"
                                              [placeholder]="hidePlaceholder ? '' : 'activities.form.activity_instruction' | translate"
                                              autofocus required
                                              cdkTextareaAutosize
                                              cdkAutosizeMinRows="1"
                                              cdkAutosizeMaxRows="5">
                                    </textarea>
                                </mat-form-field>
                                <div class="pollContainer">

                                    <div class="line"></div>

                                    <input type="range"
                                           name="sliderCutom"
                                           formControlName="slider"
                                           [attr.min]="minValue"
                                           [attr.max]="maxValue"
                                           (input)="sliderMove($event)"
                                           (click)="sliderClick($event)"
                                           (touchend)="sliderClick($event)">
                                </div>
                            </mat-card-content>
                        </mat-card><!-- CARD LAYOUT END -->
                    </form>
                </ng-container>
            </div>

            <div fxFlex="none" fxLayout="column" fxLayoutAlign="center none" class="pr-16 pl-8">
                <button [style.visibility]="!(activityStepIndex < formArray.length - 1) ? 'hidden': 'visible'"
                        class="next" (click)="nextPoll()" aria-label="next">
                    <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
                </button>
            </div>

        </div>


    </div><!-- CONTENT END -------------------------------------------------------------------------- -->


    <div *ngIf="!activityLocked" class="page-layout-actions pt-16 pb-16" fxLayoutAlign="center center">
        <button  class="save-button" (click)="save()" [disabled]="showSpinner">
            <span>{{ 'generic.save' | translate }}</span>
        </button>
        <button  (click)="addPoll()"
                [style.visibility]="formArray.length < limitPollNumber ? 'visible' : 'hidden'"
                [disabled]="showSpinner" class="save-button">
            <span>{{ 'activities.add_question' | translate }}</span>
        </button>
        <button  (click)="removePoll()" [disabled]="+activityStepIndex === 0 || showSpinner">
            <span>{{ 'activities.delete_question' | translate }}</span>
        </button>
    </div>

</div><!-- PAGE LAYOUT END -->

<div fxFill fxLayoutAlign="center center" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>