
<mat-card class="activities-card inner-scroll mat-elevation-z0" [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                           [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"
                           [wordingAudio]="wordingAudio"
                           [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                           (isReading)="speakStateChanged($event)"
                           questionTypeName="{{ 'activities.questionTypeName.app-fill-in-blanks' | translate }}"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [class]="btnValidateState">
        <app-consignes *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                       [instruction]="instruction"
                       [ngClass]="{'consigneImg': isInstructionImg()}"
                       [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
                       [instructionAudio]="instructionAudio"></app-consignes>
        <app-fill-in-blanks-child *ngFor="let item of items"
                                  (onOptionChange)="onOptionChange($event)"
                                  [class.hide]="displaySolution && answerFeedback"
                                  [referenceActivityGranule]="item"
                                  [childQuestion]="fillInBlanksWording | addInput: fillInBlanksWording.answer_text: fillInBlanksWording.answers"
                                  [disableOnChange]="disableOnChange"
                                  [isLatexKeyboard]="isFormula"
                                  [displayForSummary]="displayForSummary"
                                  [isSuggestedButtons]="isSuggestedButtons"
                                  (clickAnswer)="validate()"
                                  [readiness]="childReadiness"
                                  [ttsChange]="ttsChange">
        </app-fill-in-blanks-child>
        <div id="container-for-answers-available" class="available-answers" *ngIf="(isSuggestedButtons || isFormula) && autoCorrection">
            <ng-container *ngIf="isSuggestedButtons">
                <button
                    *ngFor="let option of availableAnswers"
                    class="btn-option"
                    [class]="optionState(option)"
                    (click)="validate(option)"
                    [disabled]="isDisabledOptions">
                    <span *ngFor="let answer of getParsedAnswer(option.answer)">{{answer}}</span>
                </button>
            </ng-container>
        </div>
        <div *ngIf="displaySolution && answerFeedback" [innerHTML]="answerFeedback | stringToHtml"></div>
    </mat-card-content>
    <!-- answer state start -->
    <ng-container *ngIf="displayFeedback">
        <div class="answer-state-msg"
             [class.wrongReply]="isCurrentAnswerState('wrong')"
             [class.missingReply]="isCurrentAnswerState('missing')">
            <p>{{ feedback | translate }}</p>
        </div>
    </ng-container>
    <!-- answer state end -->

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">
            {{ 'generic.score' | translate}}{{showPercentile}}
        </div>
        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button *ngIf="button.display"
                        [class]="btnValidateState"
                        [disabled]="button.disable"
                        (click)="onAction(button)">
                    <span>{{ button.title | translate }}</span>
                    <mat-icon *ngIf="button.svgIcon"
                              [svgIcon]="button.svgIcon">
                    </mat-icon>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>