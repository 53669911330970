import {AsyncPipe, NgForOf, NgIf, NgStyle} from '@angular/common';
import {Component, inject} from '@angular/core';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {MatIcon} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ActivatedRoute, RouterLink, Router} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {CompassCardComponent, CompassSearchFormComponent, KeywordsComponent} from '../../ui';
import {FuseDirectivesModule} from 'fuse-core/directives/directives';
import {CompassService} from '../../compass.service';
import {Subject, switchMap} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';

@Component({
    selector: 'app-compass-search',
    standalone: true,
    imports: [
        CompassSearchFormComponent,
        FuseDirectivesModule,
        MatIcon,
        MatProgressSpinner,
        NgForOf,
        NgIf,
        TranslateModule,
        CompassCardComponent,
        RouterLink,
        KeywordsComponent,
        NgStyle
    ],
    templateUrl: './compass-search.component.html',
    styleUrl: './compass-search.component.scss'
})
export class CompassSearchComponent {
    private activatedRoute = inject(ActivatedRoute);
    private compassService = inject(CompassService);
    private router = inject(Router);

    public query = toSignal(
        this.activatedRoute.params.pipe(map(params => params.query)),
        {initialValue: ''}
    );

    public themes = toSignal(
        toObservable(this.query).pipe(
            switchMap(query => this.compassService.searchThemes(query)),
            tap(() => this.isLoadingThemes = false)
        ),
        {initialValue: []}
    );

    public isLoadingThemes = true

    private updateSearchQuery$ = new Subject<string>();

    constructor() {
        this.updateSearchQuery$.pipe(
            tap(() => this.isLoadingThemes = true),
            debounceTime(300)
        ).subscribe(query => {
            this.router.navigate(['..', query], {relativeTo: this.activatedRoute});
        });
    }

    public updateSearchQuery($event: string): void {
        this.updateSearchQuery$.next($event);
    }

    public getQueryParams() {
        return {
            fromUrl: this.router.url,
            backLinkName: 'compass.back_search'
        }
    }
}
