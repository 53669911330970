<module-groups-listing [addEntity]="newTrainer"
                       [dataSource]="dataSource"
                       [deleteEntity]="deleteTrainer"
                       [displayedColumns]="displayedColumns"
                       [editEntity]="editTrainer"
                       [nicknameContextAllowed]=true
                       [displayedFilters]="displayedFilters"
                       fxFlex
                       fxLayout="column"
></module-groups-listing>
