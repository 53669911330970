<ng-container *ngIf="isComponentInModal">
    <div mat-dialog-title>
        <h2>{{'generic.title_modal_pdf'}}</h2>
        <button
            class="close"
            (click)="close()"
            aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div class="read-pdf__wrapper">
            <mat-icon class="read-pdf__icon"
                      svgIcon="icon_pdf">
            </mat-icon>
            <span class="read-pdf__alert">
        {{'generic.alert_open_pdf'| translate}}
    </span>
            <button class="read-pdf__button"
                    (click)="openPdf()">
                {{'generic.open_pdf'| translate}}
            </button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isComponentInModal">
    <div class="read-pdf__wrapper">
        <mat-icon class="read-pdf__icon"
                  svgIcon="icon_pdf">
        </mat-icon>
        <span class="read-pdf__alert">
        {{'generic.alert_open_pdf'| translate}}
    </span>
        <button class="read-pdf__button"
                (click)="openPdf()">
            {{'generic.open_pdf'| translate}}
        </button>
    </div>
</ng-container>
