<div class="page-layout simple fullwidth" fusePerfectScrollbar>
    <ng-container *ngIf="page$ | async as page; else loading">
        <h1>{{page.get('label')}}</h1>

        <app-tips [label]="'basic_page_cards.' + (page.get('label') | snakeCase) | translate"></app-tips>

        <div class="card-grid">
            <mat-card *ngFor="let card of page.get('pages'); let index = index;">
                <mat-card-header>
                    <mat-card-title>{{card.label}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="image-content">
                        <img mat-card-image [src]="getImageSrc(index)" [alt]="card.label">
                    </div>
                    <div class="body-wrapper">
                        <div class="body-content" [innerHTML]="card.body"></div>
                        <div class="inside-actions">
                            <a *ngIf="!!downloadDocumentLink(card)"
                               [href]="downloadDocumentLink(card)"
                               target="_blank"
                               mat-raised-button>{{'generic.download' | translate}}</a>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </ng-container>
</div>


<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>