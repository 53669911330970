<ng-container *ngIf="!item.hidden">

    <!-- normal collapse -->
    <a [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" tabindex="0" [ngClass]="item.classes"
       *ngIf="!item.url && !item.function"
       (focus)="toggleOpen($event)" (mousedown)="toggleOpen($event)" (keydown.enter)="toggleOpen($event)" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.url -->
    <a [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" [ngClass]="item.classes"
       *ngIf="item.url && !item.externalUrl && !item.function"
       (click)="toggleOpen($event)"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'mat-accent-bg']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" [ngClass]="item.classes"
       *ngIf="item.url && item.externalUrl && !item.function"
       (click)="toggleOpen($event)"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" [ngClass]="item.classes"
          *ngIf="!item.url && item.function"
          (click)="toggleOpen($event);item.function()" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" [ngClass]="item.classes"
       *ngIf="item.url && !item.externalUrl && item.function"
       (click)="toggleOpen($event);item.function()"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'mat-accent-bg']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a [attr.data-e2e]="'nav-collapse-' + item.id" class="nav-link" [ngClass]="item.classes"
       *ngIf="item.url && item.externalUrl && item.function"
       (click)="toggleOpen($event);item.function()"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon"
                  *ngIf="item.icon && !item.hasOwnProperty('displayIcon') || (item.hasOwnProperty('displayIcon') && item['displayIcon'])"
                  [svgIcon]="item.icon"></mat-icon>
        <span class="nav-link-title" [translate]="item.translate"></span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">

        </span>
        <mat-icon class="collapsable-arrow"
                  svgIcon="expand-arrow">
        </mat-icon>
    </ng-template>

    <div class="children" [@slideInOut]="isOpen">
        <ng-container *ngFor="let item of item.children">
            <ng-container *ngIf="exist">
                <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>
                <fuse-nav-vertical-collapsable *ngIf="item.type=='collapsable'"
                                               [item]="item"></fuse-nav-vertical-collapsable>
                <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
