// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `vertical-layout-2 {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}
vertical-layout-2 #main {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 1;
  min-width: 0;
}
vertical-layout-2 #main > .container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
}
vertical-layout-2 #main > .container > .container {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  width: 100%;
}
vertical-layout-2 #main > .container > .container > .container {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/vertical/layout-2/layout-2.component.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;AADJ;AAGI;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,YAAA;AADR;AAIQ;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,iCAAA;EACA,wBAAA;AAFZ;AAKY;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,WAAA;AAHhB;AAMgB;EACI,kBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,YAAA;AAJpB","sourcesContent":["@import \"@fuse/scss/fuse\";\n\nvertical-layout-2 {\n    display: flex;\n    flex: 1 1 auto;\n    width: 100%;\n    height: 100%;\n\n    #main {\n        position: relative;\n        display: flex;\n        flex: 1 1 auto;\n        flex-direction: column;\n        width: 100%;\n        height: 100%;\n        z-index: 1;\n        min-width: 0;\n\n        // Container 1 (Scrollable)\n        > .container {\n            position: relative;\n            display: flex;\n            flex: 1 1 auto;\n            flex-direction: column;\n            width: 100%;\n            overflow-x: hidden;\n            overflow-y: auto;\n            -webkit-overflow-scrolling: touch;\n            transform: translateZ(0);\n\n            // Container 2\n            > .container {\n                position: relative;\n                display: flex;\n                flex: 1 0 auto;\n                width: 100%;\n\n                // Container 3\n                > .container {\n                    position: relative;\n                    display: flex;\n                    flex: 1 1 auto;\n                    flex-direction: column;\n                    min-width: 0;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
