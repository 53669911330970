<div class="container">
    <div *ngIf="isLatexKeyboard || isSuggestedButtons" class="rb-text-wrapper renderedContent-{{uuid}}">
        <span class="rb-question-text" [innerHtml]="question | stringToHtml"></span>
    </div>
    <div *ngIf="isLatexKeyboard && !isSuggestedButtons" [ngClass]="{hide: !latexKeyboard || displayForSummary}" [id]="'latex-keyboard-'+uuid"></div>

    <div class="rb-text-wrapper">
        <ng-container *ngFor="let text of questionSplit">
            <span class="rb-question-text" *ngIf="!isLatexKeyboard && !isSuggestedButtons && text !== '#input'">{{text}}</span>
            <span class="rb-question-text" *ngIf="(displaySolution || displayForSummary) && !isLatexKeyboard && !isSuggestedButtons && text === '#input'">{{inputControl.value}}</span>
            <input class="rb-input-text"
                   [disabled]="displaySolution || displayForSummary || (isActivityEmbedded && isActivityEmbeddedDone)"
                   *ngIf="(!displaySolution && !displayForSummary) && !isLatexKeyboard && !isSuggestedButtons && text === '#input'" [formControl]="inputControl">
        </ng-container>
    </div>
</div>