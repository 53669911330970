<mat-card class="activities-card inner-scroll mat-elevation-z0 qcuContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <!--exo instruction pass in the wording field-->
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes *ngIf="(!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))" [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}" [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"  [wordingAudio]="wordingAudio"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <!--image to use for zone selection is pass by the back in instruction field
        instruction s not the real instruction here wording is use for show the instruction-->
        <div class="available-answers columns-1">
            <div [ngClass]="containerClass">
                <div *ngIf="instruction" [innerHtml]="instruction | stringToHtml"></div>
                <app-zone-generator (clickEvent)="zoneGeneratorClick($event)"
                                    [sizeOfZone]="4"
                                    [isSendClickEvenIfAnswerWasAlreadyMade]="true"
                                    [answers]="answersSelected"
                                    [activateZoneOnlyOnClick]="false"></app-zone-generator>
            </div>
            <!--audio to play if click is in allowed zone with mp3-->
            <audio
                controls
                [attr.src]="currentAudioToPlay"
                autoplay="autoplay"
                (ended)="endPlayingSound()">
            </audio>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <button *ngIf="showButton && !displayForSummary" class=" mat-button-base btn-option btn-next" (click)="nextStep()" [disabled]="!allAnswerCorrect()">
            <span>{{'generic.next' | translate}}</span>
        </button>
    </mat-card-footer>
</mat-card>

