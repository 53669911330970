<div mat-dialog-title>
    <h2>{{'tool_editor.title' | translate}}</h2>
    <button (click)="selfDialogRef.close()"
            aria-label="Close dialog"
            class="close"
            >
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form (ngSubmit)="onSubmit()" [formGroup]="form" fxFlex fxLayout="column">
        <mat-form-field>
            <mat-label>
                {{'tool_editor.tool_title' | translate}}
            </mat-label>
            <input formControlName="title" matInput type="text"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>
                {{'tool_editor.instruction' | translate}}
            </mat-label>
            <textarea formControlName="instruction" matInput rows="5"></textarea>
        </mat-form-field>
        <div class="button-tools-selector">
            <label>
                {{'tool_editor.choose_tool' | translate}}
            </label>
            <div class="button-list">
                <button (click)="select(tool.toolIdentifier)"
                        *ngFor="let tool of allowedTools"
                        [ngClass]="{'active': isActive(tool.toolIdentifier)}"

                        type="button">
                    {{tool.setting.label | translate}}
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="onSubmit()" [disabled]="form.invalid || isNoToolSelected()" class="mat-accent"
            >{{'generic.save' | translate}}</button>
</mat-dialog-actions>
