<div mat-dialog-title>
    <h2 *ngIf="modalTitle && assignOnly">
        {{ modalTitle | translate}}
    </h2>
    <h2 *ngIf="assignOnly && !modalTitle">
        {{'generic.assign_pending_account' | translate}}
    </h2>
    <h2 *ngIf="!assignOnly && !isNewUser">
        {{'generic.edit' | translate}}{{userName}}
    </h2>
    <h2 *ngIf="isNewUser">
        {{'generic.add_account' | translate}}
    </h2>
        <button (click)="close()"
            aria-label="Close dialog"
            class="close">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-card>
    <mat-card-header>
        <mat-card-title>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="edit-user-container">

            <div *ngIf="!assignOnly" class="field-container">
                <mat-label class="custom-width">{{ 'account-management.pseudoPart2' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.pseudoPart2' | translate }}</mat-label><input
                        [formControl]="controls?.name"
                        matInput
                        name="name"

                    >
                    <mat-error *ngIf="isNameInValid()">
                        {{ 'generic.firstname_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="!assignOnly" class="field-container">
                <mat-label class="custom-width">{{ 'account-management.lastName' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.lastName' | translate }}</mat-label><input
                        [formControl]="controls?.lastName"
                        matInput
                        name="name"

                    >
                    <mat-error *ngIf="isNameInValid(true)">
                        {{ 'generic.lastname_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="!assignOnly" class="field-container">
                <mat-label class="custom-width">{{ 'account-management.email' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.email' | translate }}</mat-label><input
                        [formControl]="controls?.mail"
                        matInput
                        name="mail"

                    >
                    <mat-error *ngIf="controls?.mail?.errors?.required">
                        {{ 'authentication.email.required' | translate}}
                    </mat-error>
                    <mat-error
                        *ngIf="controls?.mail?.errors?.email">
                        {{ 'authentication.email.invalid' | translate}}
                    </mat-error>
                    <mat-error
                        *ngIf="controls?.mail?.errors?.alreadyTaken">
                        {{ 'account-management.mail_taken' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div *ngIf="isNewUser" class="c field-container">
                <mat-label class="custom-width">{{ 'account-management.password' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.password' | translate }}</mat-label><input
                        [formControl]="controls?.password"
                        matInput
                        name="password"

                        type="password"
                    >
                    <mat-error *ngIf="controls?.password?.errors?.required">
                        {{ 'authentication.password.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="controls?.password?.errors?.passwordPolicyError">
                        {{ 'authentication.password.passwordPolicyError' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="field-container">
                <mat-label class="custom-width">{{ 'generic.groups' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-chip-grid #classesChipList [disabled]="!isAllowedToAssignToGroup()">
                        <mat-chip-row
                            (removed)="removeClassChip(classChip)"
                            *ngFor="let classChip  of classesChips"
                            [removable]="inputRemovable"
                            [selectable]="inputSelectable">
                            {{classChip.groupname}}
                            <mat-icon svgIcon="cross_outline" matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <mat-label>{{ 'groups-management.assign_to_class' | translate }}</mat-label><input
                            #classesChipInput
                            (matChipInputTokenEnd)="add($event, 'classes')"
                            [formControl]="controls.classes"
                            [matAutocomplete]="groups"
                            [matChipInputAddOnBlur]="inputAddOnBlur"
                            [matChipInputFor]="classesChipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            />
                    </mat-chip-grid>
                    <mat-autocomplete
                        #groups="matAutocomplete"
                        (optionSelected)="classSelected($event)">
                        <mat-option *ngFor="let classChip of classesFilteredChips | async" [value]="classChip">
                            {{ classChip.groupname }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="field-container">
                <mat-label class="custom-width">{{ 'groups-management.workgroups' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-chip-grid #workgroupsChipList [disabled]="!isAllowedToAssignToWorkGroup()">
                        <mat-chip-row
                            (removed)="removeWorkgroupChip(workgroupChip)"
                            *ngFor="let workgroupChip  of workgroupsChips"
                            [removable]="inputRemovable"
                            [selectable]="inputSelectable">
                            {{ workgroupChip.workgroupname }}
                            <mat-icon svgIcon="cross_outline" matChipRemove>cancel</mat-icon>
                        </mat-chip-row>
                        <mat-label>{{ 'groups-management.assign_to_group' | translate }}</mat-label><input
                            #workgroupsChipInput
                            (matChipInputTokenEnd)="add($event, 'workgroups')"
                            [formControl]="controls.workgroups"
                            [matAutocomplete]="workgroups"
                            [matChipInputAddOnBlur]="inputAddOnBlur"
                            [matChipInputFor]="workgroupsChipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                            />
                    </mat-chip-grid>
                    <mat-autocomplete #workgroups="matAutocomplete" (optionSelected)="workgroupSelected($event)">
                        <mat-option *ngFor="let workgroupChip of workgroupsFilteredChips | async"
                                    [value]="workgroupChip">
                            {{ workgroupChip.workgroupname }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div *ngIf="!assignOnly && canEditEducatorRights()" class="field-container">
                <mat-label class="custom-width">{{ 'generic.teacher_rights' | translate }}</mat-label>
                <div class="mat-form-field">
                    <mat-slide-toggle
                        name="teacher-rights"
                        [checked]="formControlValueByField('teacher-rights')"
                        (change)="toggle($event, 'educator')"
                    >
                    </mat-slide-toggle>
                    <mat-icon class="selected" [class.role-selected]="formControlValueByField('teacher-rights')" svgIcon="educ-role"></mat-icon>
                    <button mat-raised-button class="tooltip-info"
                            matTooltip="{{ 'generic.teacher_rights_description' | translate }}"
                            matTooltipPosition="right"
                            aria-label="Button that displays a tooltip when focused or hovered over">
                        <mat-icon class="tooltip-info" svgIcon="tooltip-info"></mat-icon>
                    </button>
                    <mat-error *ngIf="isUserRightIsInValid()">
                        {{ 'generic.no_right_in_institution_selected' | translate}}
                    </mat-error>
                </div>
            </div>

            <div *ngIf="!assignOnly && canEditInstitutionManagerRights()" class="field-container">
                <mat-label class="custom-width">{{ 'generic.admin_rights' | translate }}</mat-label>
                <div class="mat-form-field">
                    <mat-slide-toggle
                        (change)="toggle($event, 'admin')"
                        [checked]="formControlValueByField('admin-rights')"
                        name="admin-rights"
                    >
                    </mat-slide-toggle>
                    <mat-icon class="selected" [class.role-selected]="formControlValueByField('admin-rights')" svgIcon="admin-role"></mat-icon>
                    <button mat-raised-button class="tooltip-info"
                            matTooltip="{{ 'generic.admin_rights_description' | translate }}"
                            matTooltipPosition="right"
                            aria-label="Button that displays a tooltip when focused or hovered over">
                        <mat-icon class="tooltip-info" svgIcon="tooltip-info"></mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="assignOnly" class="assign-to-pending-account">
                {{ 'generic.assign_to_pending_account' | translate }}
            </div>
       </div>
    </mat-card-content>
    <mat-card-footer>
        <div mat-dialog-actions>
            <button (click)="close()" class="close-button" mat-raised-button>
                <span>{{'generic.cancel'|translate}}</span>
            </button>
            <button (click)="save()" class="save-button" [disabled]="isButtonForSaveDisabled" mat-raised-button>
                <span>{{'generic.save'|translate}}</span>
            </button>
        </div>
    </mat-card-footer>
</mat-card>

