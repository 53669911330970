<div class="custom-block">
    <h2 *ngIf="block?.title">{{ block.title }}</h2>
    <div [innerHTML]="blockHandler.clean(block.content)" (click)="blockHandler.onLinkClick($event)"></div>
    <ng-container *ngIf="block.btnLink && block.btnText">
        <a *ngIf="block.btnLink.startsWith('https');" mat-raised-button [href]="block.btnLink"
           target="_blank">{{ block.btnText }}</a>
        <a *ngIf="!block.btnLink.startsWith('https');" mat-raised-button
           [routerLink]="this.globalConfigurationService.getUseBlockPathAlone()?blockPath:blockHandler.getBlockPath(block.btnLink)"
           [queryParams]="blockHandler.getBlockQueryParams(block.btnLink)">{{ block.btnText }}</a>
    </ng-container>
</div>