<div class="nps">

    <div class="nps__header">
        <ng-container *ngIf="isUserAtLeastTrainer(); else learnerTitleTemplate">
            <h2 class="nps__title">{{ 'nps.teacher_would_you_recommend' | translate }}</h2>
        </ng-container>
        <ng-template #learnerTitleTemplate>
            <h2 class="nps__title">{{ 'nps.learner_would_you_recommend' | translate }}</h2>
        </ng-template>
    </div>

    <div class="nps__content">
        <form id="nps-form"
              class="nps__form"
              [formGroup]="npsForm">
            <mat-radio-group class="nps__radio-group"
                             formControlName="value"
                             [(ngModel)]="selectedValue">
                <!-- First subgroup of radio buttons (from 0 through 5) -->
                <div class="nps__radio-subgroup">
                    <mat-radio-button *ngFor="let number of values | slice:0:6"
                                  class="nps__radio-button"
                                  [class.nps__radio-button--checked]="selectedValue === number"
                                  [value]="number">
                        <span class="nps__radio-button-number-label">{{ number }}</span>
                        <!-- If no radio button is checked OR if first radio button (0) is checked -->
                        <ng-container *ngIf="selectedValue === undefined || selectedValue === number">
                            <span *ngIf="number === 0"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.absolutely_not" | translate }}
                            </span>
                        </ng-container>
                        <!-- If one radio button from 1 through 5 is checked -->
                        <ng-container *ngIf="selectedValue === number">
                            <span *ngIf="number >= 1 && number <= 3"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.absolutely_not" | translate }}
                            </span>
                            <span *ngIf="number >= 4 && number <= 5"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.i_dont_think_so" | translate }}
                            </span>
                        </ng-container>
                    </mat-radio-button>
                </div>
                <!-- Second subgroup of radio buttons (from 6 through 10) -->
                <div class="nps__radio-subgroup">
                    <mat-radio-button *ngFor="let number of values | slice:6:11"
                                  class="nps__radio-button"
                                  [class.nps__radio-button--checked]="selectedValue === number"
                                  [value]="number">
                        <span class="nps__radio-button-number-label">{{ number }}</span>
                        <!-- If one radio button from 6 through 9 is checked -->
                        <ng-container *ngIf="selectedValue === number">
                            <span *ngIf="number === 6"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.i_dont_think_so" | translate }}
                            </span>
                            <span *ngIf="number >= 7 && number <= 8"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.maybe" | translate }}
                            </span>
                            <span *ngIf="number === 9"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.absolutely" | translate }}
                            </span>
                        </ng-container>
                        <!-- If no radio button is checked OR if last radio button (10) is checked -->
                        <ng-container *ngIf="selectedValue === undefined || selectedValue === number">
                            <span *ngIf="number === 10"
                                  class="nps__radio-button-text-label"
                                  [@fadeInAndOut]>
                                {{ "nps.absolutely" | translate }}
                            </span>
                        </ng-container>
                    </mat-radio-button>
                </div>
            </mat-radio-group>
        </form>
    </div>

    <div class="nps__footer">
        <button class="nps__skip-button"
                (click)="notNowAction()">
            {{ "generic.not_now" | translate }}
        </button>
        <button class="nps__validate-button"
                form="nps-form"
                type="submit"
                [disabled]="!npsForm.valid"
                (click)="SaveNPS()">
            {{'generic.validate' | translate}}
        </button>
    </div>

</div>