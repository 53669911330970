<ng-template #content>
    <a class="compass__back__link"
       [routerLink]="getBackUrl()"
       [queryParams]="getBackQueryParams()"
       aria-label="précédent">
        <mat-icon svgIcon="back"></mat-icon>
        <span>{{ goBackLinkName | translate }}</span>
    </a>

    <section>
        <ng-container *ngIf="parentItems$ | async as parentItems">
            <div class="compass-resources__header"
                 [ngStyle]="{'background-image': 'url(' + (getMediaUrl(parentItems.exploreItem?.get('medias'))) + ')'}">
                <h2>{{ parentItems.theme.get('label') }}</h2>
                <div [innerHTML]="parentItems.theme.get('body') ?? parentItems.exploreItem?.get('description') ?? ''"></div>
            </div>
        </ng-container>

        <div class="grid-compass compass-ressources__content">
            <app-compass-card
                *ngFor="let resource of resources"
                class="compass-card__ressources map-ressource map-ressource-{{getResourceType(resource, true)}}">
                <div data-header class="app-compass-card__header">
                    <h3>{{ getResourceType(resource) }}</h3>
                </div>
                <div data-body class="app-compass-card__body">
                    <h4 [innerHTML]="resource.get('label')"></h4>
                    <div [innerHTML]="resource.get('body')"></div>
                </div>
                <div data-footer class="app-compass-card__footer">
                    <ng-template #link>
                        <button class="app-compass-card__footer__button" [routerLink]="['..', 'ressource', resource.id]"
                                [queryParams]="getQueryParams()" [relativeTo]="activatedRoute">
                            <mat-icon svgIcon="app-compass-card__footer__button__icon"></mat-icon>
                            <span>{{ 'compass.see_more' | translate }}</span>
                        </button>
                    </ng-template>
                    <ng-container *ngIf="isDialogResource(resource) else link">
                        <button class="app-compass-card__footer__button" (click)="onSeeResourceClick(resource)">
                            <mat-icon svgIcon="app-compass-card__footer__button__icon"></mat-icon>
                            <span>{{ 'compass.see_more' | translate }}</span>
                        </button>
                    </ng-container>
                </div>

            </app-compass-card>
        </div>
    </section>
</ng-template>

<ng-container *ngIf="displayLoader else content">
    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</ng-container>

