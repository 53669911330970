<ng-template #content>
    <h2>
        <a class="compass__back__link"
           [routerLink]="getBackUrl()"
           [queryParams]="getBackQueryParams()"
           aria-label="précédent">
            <mat-icon svgIcon="back"></mat-icon>
            <span>{{ goBackLinkName }}</span>
        </a>
    </h2>
    <section>
        <app-compass-card
            class="compass-card__ressource_infos map-ressource map-ressource-{{getResourceType(resource, true)}}">
            <div data-header class="app-compass-card__header">
                <h3>{{ getResourceType(resource) }}</h3>
            </div>
            <div data-body class="app-compass-card__body">
                <div [innerHTML]="resource.get('description')"></div>
            </div>
            <div data-footer class="app-compass-card__footer">
                <ng-container *ngFor="let media of resource.get('medias')">
                    <button *ngIf="isMediaImage(media)" (click)="onOpenImageClick(media)"
                            class="app-compass-card__footer__media">
                        <mat-icon svgIcon="image"></mat-icon>
                        <span>image</span>
                    </button>
                    <button *ngIf="isMediaVideo(media)" (click)="onOpenVideoClick(media)"
                            class="app-compass-card__footer__media">
                        <mat-icon svgIcon="video"></mat-icon>
                        <span>video</span>
                    </button>
                    <button *ngIf="isMediaPdf(media)" (click)="onOpenPdfClick(media)"
                            class="app-compass-card__footer__media">
                        <mat-icon svgIcon="pdf"></mat-icon>
                        <span>pdf</span>
                    </button>
                </ng-container>
            </div>
        </app-compass-card>
    </section>
</ng-template>

<ng-container *ngIf="displayLoader else content">
    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</ng-container>
