<div class="container">
    <div class="title"><span>  <mat-icon svgIcon="educ-role"></mat-icon>
        {{ 'account-management.join_school.title' | translate }}</span></div>
    <div class="content">{{ 'account-management.join_school.content.part1' | translate }}
        <b>{{ 'account-management.join_school.content.part2.bold' | translate }}</b></div>

    <div class="join-zone">
            <mat-form-field>
                <div class="join-institution-wrapper">
                    <mat-label>{{'account-management.join_school.code.placeholder' | translate}}</mat-label><input matInput name="code" [formControl]="codeControl" >
                    <button mat-raised-button (click)="joinSchool()" [disabled]="!codeControl?.value || codeControl?.value === '' || codeControl?.errors">
                        {{ 'account-management.join_school.join' | translate}}
                        <mat-icon svgIcon="join"></mat-icon>
                        <mat-spinner *ngIf="loading"></mat-spinner>
                    </button>
                </div>
                <mat-error *ngIf="codeControl?.errors?.invalidFormat">
                    {{ 'generic.invalid_format_code_institution' | translate }}
                </mat-error>
                <mat-error *ngIf="codeControl?.errors?.cantJoinInstitution">
                    {{ 'account-management.join-school-error' | translate }}
                </mat-error>
                <mat-error *ngIf="codeControl?.errors?.invalidCode">
                    {{ 'account-management.join-school-error-code-not-exist' | translate }}
                </mat-error>
            </mat-form-field>
    </div>
</div>
