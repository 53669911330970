<ng-container #dynamicContainer></ng-container>
<div *ngIf="showBloc()" [ngClass]="getClass()" fusePerfectScrollbar>
    <div class="p-64 content inner-scroll">
        <app-banner-info *ngIf="!isAssignationClosedActive" [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="activity"></app-banner-info>
        <app-banner-info *ngIf="isAssignationClosedActive" [rolesCanShowBanner]="rolesCanShowBannerInfoClosedAssignment" situation="closed"></app-banner-info>
        <article *ngFor="let assignation of resources; trackBy: identify" #assignationsElement class="assignation">
            <ng-container *ngIf="lessons[assignation.id] && lessons[assignation.id].lesson">
                <app-assignation-detail [assignation]="assignation" [lesson]="lessons[assignation.id]"></app-assignation-detail>
            </ng-container>
        </article>
    </div>
</div>