<div class="video-editor-import-actions" fxFill fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
    <div fxFlex fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
        <label>{{ 'corpus.import_from' | translate }}</label>
        <label [style.fontWeight]="'bold'">{{ 'corpus.title.user' | translate }}</label>
        <button (click)="chooseResource(true)" >
            <span>{{ 'corpus.import' | translate }}</span>
        </button>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
        <label>{{ 'corpus.import_from' | translate }}</label>
        <label [style.fontWeight]="'bold'">{{ 'corpus.title.generic' | translate }}</label>
        <button (click)="chooseResource()" >
            <span>{{ 'corpus.import' | translate }}</span>
        </button>
    </div>

    <div *ngIf="uploadAvailable" fxFlex fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
        <label>{{ 'corpus.upload_resource' | translate }}</label>
        <label [style.fontWeight]="'bold'">{{ 'corpus.upload' | translate }}</label>
        <button (click)="chooseResource(true, true)" >
            <span>{{ 'corpus.import' | translate }}</span>
        </button>
    </div>
</div>