<div class="container">
    <div class="header">
        <span>{{institutions.groupName}}</span>
        <span *ngIf="institutions.startDate!==''" class="date">{{ 'institution.licence.from' | translate}}
            <span>{{institutions.startDate}} </span>
            <ng-container *ngIf="institutions.endDate!==''">
                <span>{{ 'institution.licence.to' | translate}}</span>
                <span>  {{institutions.endDate}}</span>
            </ng-container>
        </span>
        <!--no licence-->
        <span *ngIf="institutions.startDate==='no-licence'"
              class="date">{{ 'institution.licence.not.active' | translate}}</span>
    </div>
    <div class="content">
        <!--shortcut-->
        <div *ngIf="hasAccessInstitutionUsersManagement" class="content-row">
            <span>
                <mat-icon svgIcon="educ-role"></mat-icon>
                {{institutions.adminCount}} {{ 'institution.administrator.count' | translate}}</span>
            <button mat-raised-button (click)="navigateToAdmins()">
                {{ 'institution.shortcut.administrator' | translate}}
                <mat-icon svgIcon="arrow_right"></mat-icon>
            </button>
        </div>

        <div class="content-row-separator"></div>

        <div *ngIf="hasAccessInstitutionUsersManagement"  class="content-row">
            <span>
                <mat-icon svgIcon="educ-role"></mat-icon>
                {{institutions.trainerCount}} {{ 'institution.trainer.count' | translate}}</span>
            <button mat-raised-button (click)="navigateToTrainers()">
                {{ 'institution.shortcut.trainer' | translate}}
                <mat-icon svgIcon="arrow_right"></mat-icon>
            </button>
        </div>

        <div class="content-row-separator"></div>

        <div class="content-row">
            <span>
                <mat-icon svgIcon="educ-role"></mat-icon>
                {{institutions.learnerCount}} {{ 'institution.learner.count' | translate}}</span>
            <button mat-raised-button (click)="navigateToLearners()">
                {{ 'institution.shortcut.learner' | translate}}
                <mat-icon svgIcon="arrow_right"></mat-icon>
            </button>
        </div>

        <div class="content-row-separator"></div>

        <div *ngIf="hasAccessInstitutionUsersManagement"  class="content-row">
            <span>
                  <mat-icon svgIcon="educ-role"></mat-icon>
                {{institutions.groupCount}} {{ 'institution.group.count' | translate}}</span>
            <button mat-raised-button (click)="navigateToGroups()" [disabled]="isAllowedToSeeAllGroups() === false">
                {{ 'institution.shortcut.group' | translate}}
                <mat-icon svgIcon="arrow_right"></mat-icon>
            </button>
        </div>

        <div class="content-row-separator"></div>

        <div class="content-row">
            <span>
                  <mat-icon svgIcon="educ-role"></mat-icon>
                {{institutions.workgroupCount}} {{ 'institution.workgroupCount.count' | translate}}</span>
            <button mat-raised-button (click)="navigateToWorkgroups()" [disabled]="isAllowedToSeeAllWorkGroups() === false">
                {{ 'institution.shortcut.workgroupCount' | translate}}
                <mat-icon svgIcon="arrow_right"></mat-icon>
            </button>
        </div>

        <div class="content-row-separator"></div>

        <!--adding teacher via schoolcode-->
        <div *ngIf="hasAccessInstitutionUsersManagement"  class="content-row">
            <div class="multiple-row">
                <span>
                    <mat-icon svgIcon="educ-role"></mat-icon>
                    {{ 'institution.school.code.adding.title' | translate}}
                </span>
                <div class="info">
                    <span>
                        {{ 'institution.school.code.adding.info.part1' | translate}}
                        <b>
                            {{ 'institution.school.code.adding.info.part2.bold' | translate}}
                        </b>
                        {{ 'institution.school.code.adding.info.part3' | translate}}
                    </span>
                </div>
                <div class="toogle-container">
                    <mat-slide-toggle
                        [disabled]="isAllowedToChangeInstitutionCode() === false"
                        [checked]="institutions.showInstCode"
                        (change)="saveHideInstitutionState($event.checked)">
                    </mat-slide-toggle>
                    <div [attr.class]="institutions.showInstCode? 'school-code' : 'school-code school-code-disabled'">
                        <span>{{ (institutions.showInstCode? 'groups-management.school_code_activated' : 'groups-management.school_code_deactivated' ) | translate}}</span>
                        <span class="code" *ngIf="institutions.showInstCode"> &nbsp;{{institutions.code}}</span>
                    </div>
                </div>
            </div>
        </div>


        <div class="content-row-separator"></div>
        <!--adding student via code-->

        <div class="content-row">
            <div class="multiple-row">
                <span>
                    <mat-icon svgIcon="educ-role"></mat-icon>
                    {{ 'institution.school.code.class.adding.title' | translate}}
                </span>
                <div class="info">
                    <span>
                        {{'institution.school.code.class.adding.info.part1' | translate}}
                        <b>{{ 'institution.school.code.class.adding.info.part2.bold' | translate}}</b>
                    </span>
                </div>
                <div class="toogle-container">
                    <mat-slide-toggle
                        [disabled]="!isAllowedToChangeGroupsCode"
                        [checked]="classCodeEnabled"
                        (change)="saveClassCodeStatus($event.checked)">
                    </mat-slide-toggle>
                    <div [attr.class]="classCodeEnabled?'school-code':'school-code school-code-class-disabled'">
                        <span>{{ (institutions.showClassCode? 'groups-management.class_code_activated' : 'groups-management.class_code_deactivated') | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

