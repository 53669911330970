<div class="page-layout simple fullwidth mat-white-bg" fusePerfectScrollbar>

<div class="pt-16 px-16">
    <mat-toolbar class="filters">
        <mat-toolbar-row>
            <form name="followedForm" fxFlex fxLayout="column">
                <div fxFlexFill fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
                    <!-- Groups Field -->
                    <mat-form-field  fxFlex="20" *ngIf="displayFilters('group')">
                        <mat-select #groupSelect placeholder="{{ 'filter.group' | translate }}" [formControl]="groupControl">
                            <mat-option *ngFor="let group of groupsFiltered" [value]="group">{{ group.groupname }}</mat-option>
                        </mat-select>
                    </mat-form-field>


                    <!-- learner Field -->
                    <mat-form-field  fxFlex="20" *ngIf="displayFilters('learner')">
                        <mat-select #learnerSelect
                                    placeholder="{{ 'assignment.select_learners' | translate }}"
                                    [formControl]="learnerControl">
                            <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                            <mat-option *ngFor="let learner of learnersFiltered" [value]="learner">{{ learner.username }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" *ngIf="displayFilters('type')">
                        <mat-select name="type" placeholder="{{ 'assignment.select_type' | translate }}" [formControl]="typeControl">
                            <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                            <mat-option *ngFor="let type of typeList" [value]="type"> {{ localizedType(type.label) | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="20" *ngIf="displayFilters('startDate')">
                        <input name="startDate" matInput [matDatepicker]="picker" [max]="maxDateLimit" placeholder="{{'generic.start_date' | translate}}" [formControl]="startDateControl">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                  <mat-form-field fxFlex="20" *ngIf="displayFilters('dueDate')">
                        <input name="dueDate" matInput [matDatepicker]="duepicker" [min]="dateLimit" placeholder="{{'generic.end_date' | translate}}" [formControl]="dueDateControl">
                        <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
                        <mat-datepicker #duepicker></mat-datepicker>
                  </mat-form-field>


                    <button mat-raised-button [disabled]="!validGraph" (click)="generate()">{{ 'generic.generate' | translate }}</button>
                    <button appGraphAssignationExport
                            *ngIf="isUserTrainer"
                            [assignments]="assignmentGroupList"
                            [learners]="learners"
                            [groups]="groups"
                            mat-raised-button>Exporter</button>
                </div>
            </form>
        </mat-toolbar-row>
    </mat-toolbar>
</div>

<div fxFlex="100" fxLayout="row" fxLayoutAlign="center center">
    <div class="pl-20"  fxFlex="85" fxLayout="row" fxLayoutAlign="space-between center">
        <h1>{{ 'graph-assignation.title' | translate }}</h1>
        <label *ngIf="showAverageLabel">{{ 'graph-assignation.no-data' | translate }}</label>
        <div fxLayout="row" class="box-average" fxLayoutGap="24px">

            <img fxFlex="70"
                 class="avatar" *ngIf="urlImgProfile" [src]="urlImgProfile" alt="">

            <div fxFlex="300px" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px" class="info-average" *ngIf="isLearnerInformationShowed">
                <label>
                    {{ (isUserTrainer ? 'graph-assignation.average' : 'graph-assignation.my_average') | translate }} : {{ averageLearner }}
                </label>
                <label>
                    {{ 'graph-assignation.others' | translate }} : {{ averageGroup }}
                </label>
            </div>
        </div>
    </div>

</div>

<div>
    <app-graph [config]="graphConfigObs"></app-graph>
</div>

</div>
