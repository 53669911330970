<div *ngIf="!!options"
     class="followed-list page-layout simple fullwidth mat-white-bg inner-scroll"
     fusePerfectScrollbar>

    <div *ngIf="displayHeader"
         class="followed-list__header header p-24"
         fxLayout="row"
         fxLayoutAlign="start center">
        <h2>{{ 'assignment.title' | translate }}</h2>
    </div>

    <div class="followed-list__content p-16 content inner-scroll"
         fxFlex
         fxLayout="column">

        <ng-container *ngIf="!isAssignationClosedActive; else assignationClosedTemplate">
            <app-banner-info class="followed-list__banner-info"
                             [rolesCanShowBanner]="rolesCanShowBannerInfo"
                             situation="followed">
            </app-banner-info>
        </ng-container>
        <ng-template #assignationClosedTemplate>
            <app-banner-info class="followed-list__banner-info"
                             [rolesCanShowBanner]="rolesCanShowBannerInfoClosedAssignment"
                             situation="closed">
            </app-banner-info>
        </ng-template>

        <button *ngIf="isAtLeastTrainer()"
                class="followed-list__filter-button" 
                (click)="displayFilters()">
            <ng-container *ngIf="!displayFilter; else hideFiltersTemplate">
                <span>{{ 'generic.show_filters' | translate }}</span>
            </ng-container>
            <ng-template #hideFiltersTemplate>
                <span>{{ 'generic.hide_filters' | translate }}</span>
            </ng-template>
            <mat-icon svgIcon="tune-rounded"></mat-icon>
        </button>

        <app-followed-filter (launchSearch)="launchSearch($event)"
                             [style.display]="displayFilter ? 'block' : 'none'"
                             *ngIf="shouldDisplayFilters()"
                             [allStates]="allStates"
                             [allTypes]="allTypes"
                             [checkDefaultFiltersInUrl]="options.checkDefaultFiltersInUrl"
                             [countEntities]="countEntities"
                             [currentSchoolYearBegin]="assignationService.currentSchoolYearBegin"
                             [displayedFilters]="displayedFilters"
                             [conditionalFilters]="conditionalFilters"
                             [educationalLevels]="educationalLevels"
                             [concepts]="concepts"
                             [chapters]="chapters"
                             [groupsList]="assignationService.groupsList"
                             [learnersList]="learnersList"
                             [schoolYearDates]="assignationService.currentSchoolYearBegin"
                             [schoolYearsList]="assignationService.schoolYearsList"
                             [workgroupsList]="assignationService.workgroupsList"
                             [origin]="origin"
        ></app-followed-filter>

        <div class="followed-list__table-wrapper content touch-scroll"
             fxFlex>
            <div *ngIf="isMultipleActionWithCheckBoxes()"
                 class="btn-multiple-action-wrapper">
                <button mat-raised-button
                        [disabled]="!isAssignmentsSelected"
                        (click)="removeMultipleAssignment()">
                    {{'generic.remove_assignment' | translate }}
                </button>
            </div>
            <table #table
                   class="followed-list__table"
                   *fuseIfOnDom
                   [@animateStagger]="{value:'50'}"
                   [dataSource]="dataSource" mat-table
                   matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox (change)="updateCheck($event)" [(ngModel)]="selectAll"></mat-checkbox>
                    </th>
                    <td *matCellDef="let entity" mat-cell>
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            [(ngModel)]="checkboxes[entity.id]">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.type' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                            <span *ngIf="row.attributes.type_term" [ngClass]="row.attributes.type_term.label"
                                  class="pastille">
                                <mat-icon svgIcon="{{row.attributes.type_term.label}}"
                                          title="{{ 'assignment.type.' + row.attributes.type_term.label | translate }}">
                                </mat-icon>
                            </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="assigned_node_title">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.title_assignment' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{getTitleAssignment(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="assignment_title">
                    <th *matHeaderCellDef
                        fxHide.xs
                        mat-header-cell
                        mat-sort-header>
                        <span>{{ 'generic.assignment_title' | translate }}</span>
                    </th>
                    <td *matCellDef="let row"
                        fxHide.xs
                        mat-cell>
                        <ng-container *ngIf="row?.get('title').length; else titleAssignment">
                            <ng-container *ngIf="this.options.isRetryEnabledOnClickInAssignmentTitle; else disabledClickTemplate">
                                <button (click)="continueAssignment(row, true)">
                                    <mat-icon svgIcon="open-in-new-rounded"></mat-icon>
                                    <span>{{ row?.get('title') }}</span>
                                </button>
                            </ng-container>
                            <ng-template #disabledClickTemplate>
                                <span>{{ row?.get('title') }}</span>
                            </ng-template>
                        </ng-container>
                        <ng-template #titleAssignment>
                            {{ getTitleAssignment(row) }}
                        </ng-template>
                    </td>
                </ng-container>

                <!-- schoolyear Column -->
                <ng-container matColumnDef="schoolyear">
                    <th *matHeaderCellDef fxHide fxShow.gt-md mat-header-cell mat-sort-header>
                        {{ 'generic.school.year' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-md mat-cell>
                        {{assignationService.schoolYears(row.attributes.created)}}

                    </td>
                </ng-container>

                <!-- Created Date Column -->
                <ng-container matColumnDef="created">
                    <th *matHeaderCellDef fxHide fxShow.gt-md mat-header-cell mat-sort-header>
                        <span *ngIf="origin === 'self'">{{ 'generic.createdDate_self_assignment' | translate }}</span>
                        <span *ngIf="origin !== 'self'">{{ 'generic.createdDate_generic_assignment' | translate }}</span>
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-md mat-cell>
                        {{ assignationService.localeDate(row.get('created')) }}
                    </td>
                </ng-container>

                <!-- Changed Date Column -->
                <ng-container matColumnDef="changed">
                    <th *matHeaderCellDef fxHide fxShow.gt-md mat-header-cell mat-sort-header>
                        <span *ngIf="origin === 'self'">{{ 'generic.changedDate_self_assignment' | translate }}</span>
                        <span *ngIf="origin !== 'self'">{{ 'generic.changedDate_generic_assignment' | translate }}</span>
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-md mat-cell>
                        {{ assignationService.localeDate(row.get('changed')) }}
                    </td>
                </ng-container>

                <!-- start_date Column -->
                <ng-container matColumnDef="start_date">
                    <th *matHeaderCellDef fxHide fxShow.gt-md mat-header-cell mat-sort-header>
                        {{ 'generic.start_date' | translate }}
                    </th>
                    <td *matCellDef="let row" [ngClass]="{'disabled' : checkState(row) === 'correct'}" fxHide
                        fxShow.gt-md
                        mat-cell>
                            <span *ngIf="row.attributes.dates">
                                <span *ngIf="row.attributes.dates.value && row.attributes.dates.value > 0">
                                    <ng-template #localeDate>
                                        <span>
                                            {{assignationService.localeDate(row.attributes.dates.value)}}
                                        </span>
                                    </ng-template>
                                    <span
                                        *ngIf="options.showHours && row.attributes.type_term && row.attributes.type_term.label === 'assessment' else localeDate">
                                        {{assignationService.localeTime(row.attributes.dates.value)}}
                                    </span>
                                </span>
                            </span>
                    </td>
                </ng-container>

                <!-- end_date Column -->
                <ng-container matColumnDef="end_date">
                    <th *matHeaderCellDef fxHide fxShow.gt-xs mat-header-cell mat-sort-header>
                        {{ 'generic.deadline' | translate }}
                    </th>
                    <td *matCellDef="let row" [ngClass]="{'disabled' : checkState(row) === 'correct'}" fxHide
                        fxShow.gt-xs
                        mat-cell>
                              <span *ngIf="row.attributes.dates">
                                  <span *ngIf="row.attributes.dates.value && row.attributes.dates.value > 0">
                                      <span *ngIf="row.attributes?.type_term?.label !== 'assessment'
                                                && row.attributes?.type_term?.label !== 'training'">
                                          {{assignationService.localeDate(row.attributes.dates.value2)}}
                                      </span>
                                      <span *ngIf="row.attributes?.type_term?.label === 'assessment'">
                                          {{assignationService.localeTime(row.attributes.dates.value2)}}
                                      </span>
                                  </span>
                              </span>
                    </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="assigned_user">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.learner' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{options?.useNicknameInsteadOfUsername ?  row.attributes.assignated_user?.nickname : row.attributes.assignated_user?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="assignatedCount">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.assignatedCount' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{ row.attributes.assignatedCount }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="educationalLevel">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.level' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{ getEducationalLevels(row.attributes.assignated_node?.educationalLevel) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="class">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.label_group' | translate }}
                    </th>
                    <!-- TODO: see if we can use assignation group because field class exist -->
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{options?.showNameClass ? row.attributes.comment : row.attributes.assignated_user?.classes}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="classOrGroups">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.groups_or_workgroups' | translate }}
                    </th>

                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        <ng-container *ngIf="!isGroupsMustBeDisplayedWithChips">
                            {{ getMultipleGroupsLabels(row) }}
                        </ng-container>
                        <div *ngIf="isGroupsMustBeDisplayedWithChips && !!getRawGroupsAndWorkgroupsInAssignment(row).length"
                             class="followed-list__group-wrapper">
                            <div class="followed-list__group-chip">
                                <div class="followed-list__group-color"
                                     [style.background-color]="getGroupColor(getRawGroupsAndWorkgroupsInAssignment(row)[0])">
                                </div>
                                <span class="followed-list__group-name">{{ getGroupLabel(getRawGroupsAndWorkgroupsInAssignment(row)[0]) === 'generic.deleted' ?
                                    ('generic.deleted' | translate) : getGroupLabel(getRawGroupsAndWorkgroupsInAssignment(row)[0]) }}</span>
                            </div>
                            <div *ngIf="getRawGroupsAndWorkgroupsInAssignment(row).length > 1"
                                 class="followed-list__group-length">
                                <span>+{{ getRawGroupsAndWorkgroupsInAssignment(row).length - 1 }}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="concept">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.concepts' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        <div>{{ getConceptLabel(row) }}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="comment">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.label_comment' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{row.attributes?.comment}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="group">
                    <th *matHeaderCellDef fxHide fxShow.gt-md mat-header-cell mat-sort-header>
                        {{ 'generic.group' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-md mat-cell>
                        {{row.attributes.assignated_user?.groups}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th *matHeaderCellDef fxHide fxShow.gt-xs mat-header-cell mat-sort-header>
                        {{ 'generic.state' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-xs mat-cell ngClass="{{checkState(row)}}">
                        {{localizedState(checkState(row)) | translate}}
                        <span *ngIf="stateWithIcon">
                                <mat-icon svgIcon="{{getIconState(row)}}"></mat-icon>
                            </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="consulted">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>
                        {{ 'generic.consulted' | translate }}
                    </th>
                    <td *matCellDef="let row" mat-cell>
                        <mat-icon [ngClass]="{'active': getConsultedAssignment(row) }" class="logo-icon-svg"
                                  svgIcon="check"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="progress">
                    <th *matHeaderCellDef fxHide fxShow.gt-xs mat-header-cell mat-sort-header>
                        {{ 'generic.progress' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide fxShow.gt-xs mat-cell>
                        {{getProgress(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="score">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.grade' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{getGrade(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="gratification">
                    <th *matHeaderCellDef class="cup" fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.gratification' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        <mat-icon (click)="openGratification($event, row)" *ngIf="row.get('progress')>0"
                                  class="cup cup-hover"
                                  svgIcon="cup"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="difficulty">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.difficulty' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{getDifficulties(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="chapters">
                    <th *matHeaderCellDef fxHide.xs mat-header-cell mat-sort-header>
                        {{ 'generic.chapters' | translate }}
                    </th>
                    <td *matCellDef="let row" fxHide.xs mat-cell>
                        {{getChapters(row)}}
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <th *matHeaderCellDef mat-header-cell><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_more_menu' | translate}}</span></th>
                    <td *matCellDef="let row" mat-cell>
                        <div class="buttons-wrapper">
                            <button *ngIf="!isUserCanOpenProgression(row) && !isProgressionEnable && isUserCanContinueHisOwnAssignment(row)"
                                    (click)="continueAssignment(row)"
                                    aria-label="continue assignment">
                                <span>{{ 'generic.continue_assignment' | translate }}</span>
                                <mat-icon svgIcon="arrow-outward-rounded"></mat-icon>
                            </button>
                            <!-- TODO button pour lancer la progression -->
                            <button *ngIf="isUserCanOpenProgression(row)"
                                    (click)="openProgression(row)"
                                    aria-label="continue assignment">
                                <span>{{ 'progression.see_the_rest' | translate }}</span>
                                <mat-icon svgIcon="arrow-outward-rounded"></mat-icon>
                            </button>
                            <button *ngIf="!isUserCanOpenProgression(row) && isUserCanSeeAssignmentRecap(row)"
                                    (click)="showRecap(row)"
                                    aria-label="continue assignment">
                                <span>{{ 'generic.see_assignment' | translate }}</span>
                                <mat-icon svgIcon="arrow-outward-rounded"></mat-icon>
                            </button>
                            <ng-container *ngIf="checkAccess(['trainer', 'manager', 'director', 'administrator'])">
                                <button [matMenuTriggerFor]="moreMenu"
                                        (click)="$event.stopPropagation();"
                                        aria-label="More">
                                    <mat-icon svgIcon="submenu"></mat-icon>
                                </button>
                                <mat-menu #moreMenu="matMenu">
                                    <button *ngIf="displayButton('startPreview')" (click)="continueAssignment(row)" aria-label="preview"
                                            mat-menu-item>
                                        {{'generic.run_preview' | translate}}
                                    </button>
                                    <div *ngIf="displayButton('delete')">
                                        <button (click)="delete(row)" aria-label="delete"
                                                mat-menu-item>
                                            <mat-icon svgIcon="unassign"></mat-icon>
                                            <span>{{ 'assignment.delete' | translate }}</span>
                                        </button>
                                    </div>
                                    <div *ngIf="displayButton('unassign')" matTooltip="{{getTooltipDeAssignLabel(row)}}">
                                        <button (click)="unAssign(row)" [disabled]="cantBeDeAssign(row)" aria-label="edit"
                                                mat-menu-item>
                                            <mat-icon svgIcon="{{!cantBeDeAssign(row) ? 'unassign' : 'lock'}}"></mat-icon>
                                            <span *ngIf="origin === 'self'">{{ 'assignment.unassign_self_assignment' | translate }}</span>
                                            <span *ngIf="origin !== 'self'">{{ 'assignment.unassign' | translate }}</span>
                                        </button>
                                    </div>
                                    <button (click)="editLessonBehindTheAssignment(row)" *ngIf="displayButton('edit')" aria-label="edit"
                                            mat-menu-item>
                                        <span>{{ 'generic.edit' | translate }}</span>
                                    </button>
                                    <button (click)="editAssignment(row)" *ngIf="displayButton('editAssign')"
                                            aria-label="edit"
                                            mat-menu-item>
                                        <span>{{ 'assignment.button.edit' | translate }}</span>
                                    </button>
                                </mat-menu>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;"
                    [@animate]="{value:'*',params:{y:'100%'}}"
                    mat-row
                    matRipple>
                </tr>
            </table>

        </div>

        <a *ngIf="isAtLeastTrainer() && options.formCreationLink?.length > 0"
           [routerLink]="options.formCreationLink[0]"><span>{{ 'assignment.link.form_creation' | translate}}</span></a>


        <mat-paginator class="followed-list__paginator"
                       (page)="onPaginateChange($event)"
                       [length]="countEntities"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="pageRangeOptions">
        </mat-paginator>


    </div>
</div>