<article class="compass"
         fusePerfectScrollbar>

    <h1 *ngIf="!isLoading()" class="cdk-visually-hidden">{{ 'generic.faq' | translate }}</h1>

    <aside *ngIf="compassHelper$ | async as compassHelper"
           class="compass__helper">
        <div class="compass__helper-content">
            <h2 [innerHTML]="compassHelper.get('title')"
                class="compass__helper-title">
            </h2>
            <div [innerHTML]="compassHelper.get('body')"
                 class="compass__helper-text">
            </div>
        </div>
        <div class="compass__helper-image-wrapper">

        </div>
    </aside>

    <section *ngIf="exploreData$ | async as exploreData"
             class="compass__explorer">
        <h2>{{ 'compass.explore' | translate }}</h2>

        @if (isSearchFormVisible) {
            <app-compass-search-form (done)="onSearchFormDone($event)"></app-compass-search-form>
        }

        <nav class="compass__explorer__nav">
            <ng-container *ngFor="let card of exploreData; let cardIndex = index;">
                <a [routerLink]="['explore', card.id ]"
                   [queryParams]="getQueryParams()"
                   class="compass__explorer__nav__button compass-theme{{cardIndex}}">
                    <mat-icon svgIcon="arrow_outward"></mat-icon>
                    <span>{{ card.get('label') }}</span>
                </a>
            </ng-container>
        </nav>
    </section>

    <ng-container *ngIf="themesPromoted$ | async as themesPromoted">
        <section *ngIf="themesPromoted.length > 0"
                 class="compass__news">
            <h2>{{ 'compass.in_the_news' | translate }}</h2>
            <div class="compass__news__content grid-compass">
                <ng-container *ngFor="let theme of themesPromoted">
                    <app-compass-card
                        [ngStyle]="{'background-image': 'url(' + (theme.get('medias') && theme.get('medias')[0] ? theme.get('medias')[0].url : '') + ')'}"
                        class="compass-card__news ">
                        <div class="app-compass-card__header" data-header>
                            <app-keywords>
                                <span *ngFor="let tag of theme.get('tags')"
                                      class="keywords"
                                      itemprop="keywords">
                                    {{ tag.name }}
                                </span>
                            </app-keywords>
                        </div>
                        <div class="app-compass-card__body"
                             data-body>
                            <h3 [innerHTML]="theme.get('label')"></h3>
                            <div [innerHTML]="theme.get('body')"></div>
                        </div>
                        <div class="app-compass-card__footer"
                             data-footer>
                            <a [routerLink]="['themes', theme.id, 'ressources']"
                               [queryParams]="getQueryParams()"
                               class="app-compass-card__footer__button">
                                <mat-icon svgIcon="app-compass-card__footer__button__icon"></mat-icon>
                                <span>{{ 'compass.see_resources' | translate }}</span>
                            </a>

                        </div>
                    </app-compass-card>
                </ng-container>
            </div>
        </section>
    </ng-container>

    <ng-container *ngIf="isLoading()">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
    </ng-container>

</article>