<div class="container" *ngIf="status === 'buying'">
  <div class="close-button-container">
    <div class="close-button" (click)="close()"><mat-icon svgIcon="cross"></mat-icon></div>
  </div>
  <p class="message">
    {{ 'gamification.buy_avatar' | translate:{ asset_cost: buyPopupInfo.badge?.attributes.price } }}
  </p>
  <div class="thumbnail-container">
    <div class="thumbnail">
      <img [src]="buyPopupInfo.badge?.attributes['image-t']" alt="">
    </div>
  </div>
  <div class="button-container">
    <div class="button" (click)="buy()">{{ 'generic.get' | translate }}</div>
  </div>
</div>
<div class="container" *ngIf="status === 'loading'">
  <div class="spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
<!-- <div class="container" *ngIf="status === 'success'">
  <p class="message">
    Élément obtenu !
  </p>
  <div class="button-container">
    <div class="button" (click)="close()">Fermer</div>
  </div>
</div> -->
<div class="container" *ngIf="status === 'error'">
  <p class="message">
    {{ 'gamification.avatar.insufficient_funds' | translate:{ asset_name : buyPopupInfo.badge?.attributes.label} }}
  </p>
  <div class="button-container">
    <div class="button" (click)="close()">{{ 'generic.ok' | translate }}</div>
  </div>
</div>