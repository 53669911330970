<div mat-dialog-title>
    <h2>
        {{'generic.remove_multiple_assignments_confirm' | translate}}
    </h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <label>{{'assignment.confirm-remove-multiple-assignments' | translate}}</label>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="dialogRef.close(true)"
            class="mr-16">{{'generic.confirm' | translate}}</button>
    <button mat-raised-button  (click)="dialogRef.close(false)">{{'generic.cancel' | translate}}</button>
</div>