<div id="register" fxLayout="column" fusePerfectScrollbar>
  <div id="register-form-wrapper" fxLayout="column" fxLayoutAlign="center center" *ngIf="!accountProvider.settings.selfSignup && !requestValidate">
    <div id="register-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
         fxLayoutGap="30px" fxLayout="column" class="w-50-p" ngClass.lt-md="w-100-p">
      <div class="logo">
        <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/brand-square.png" alt="">
        <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square" ></mat-icon>
      </div>

        <div class="title">{{ 'account-management.subscribe_request_form_title' | translate}}</div>

        <form name="requestSubscribeForm" [formGroup]="requestSubscribeForm" novalidate fxLayout="column"
              fxLayoutAlign="start stretch">

            <div  fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign="start start">

              <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                <mat-label>{{ 'account-management.you-are' | translate}}</mat-label><mat-select 
                            formControlName="you_are" required>
                  <mat-option *ngFor="let item of userInformation.you_are_field; let i = index"
                              [value]="item.value">
                    {{ item.name | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="requestSubscribeFormErrors.you_are.required">
                  {{ 'account-management.you-are.required' | translate}}
                </mat-error>
              </mat-form-field>


              <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                <mat-label>{{ 'account-management.email' | translate}}</mat-label><input matInput  formControlName="email" required>
                <mat-error *ngIf="requestSubscribeFormErrors.email.required">
                  {{ 'generic.field.error.required' | translate }}
                </mat-error>
                <mat-error *ngIf="!requestSubscribeFormErrors.email.required && requestSubscribeFormErrors.email.email">
                  {{ 'account-management.email_error_valid' | translate }}
                </mat-error>
              </mat-form-field>

            </div>

            <div  fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign="start start">
              <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                <mat-label>Votre nom</mat-label><input matInput 
                       formControlName="last_name" required>
                <mat-error *ngIf="requestSubscribeFormErrors.last_name.required">
                  {{ 'generic.field.error.required' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                <mat-label>Votre ville</mat-label><input matInput 
                       formControlName="city" required>
                <mat-error *ngIf="requestSubscribeFormErrors.city.required">
                  {{ 'generic.field.error.required' | translate }}
                </mat-error>
              </mat-form-field>

            </div>

          <div  fxLayout="row" fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign="start start">

            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
              <mat-label>Votre prénom</mat-label><input matInput  formControlName="name" required>
              <mat-error *ngIf="requestSubscribeFormErrors.name.required">
                {{ 'generic.field.error.required' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
              <mat-label>Etablissement</mat-label><input matInput  formControlName="academy" required>
              <mat-error *ngIf="requestSubscribeFormErrors.name.required">
                {{ 'generic.field.error.required' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutAlign="start start">

          </div>

          <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">

            <mat-checkbox name="terms" aria-label="I read and accept" formControlName="terms" required>
              {{ 'account-management.accept' | translate }} <a [routerLink]="'/page/conditions-generales'"  target="_blank">{{ 'account-management.cgu' |
              translate }}*</a>
            </mat-checkbox>
            <mat-error *ngIf="termsNotAccepted">
              {{ 'account-management.terms.required' | translate }}
            </mat-error>

            <!-- The value of the form control will be the verification token -->
            <ng-hcaptcha *ngIf="captcha === 'hcaptcha'" formControlName="captcha" name="captcha" required></ng-hcaptcha>
            <mat-error *ngIf="captchaPassed === false">
              {{ 'generic.captchaNotPassed' | translate }}
            </mat-error>

            <button mat-raised-button color="accent" class="submit-button"
                    (click)="newRegister()">
              {{ 'account-management.request-account' | translate}}
            </button>
          </div>

          <div class="register" fxLayout="column" fxLayoutAlign="center center">
            <span class="text">{{ 'account-management.account_already' | translate }} </span>
            <a class="link" [routerLink]="'/'">{{ 'authentication.btn-login' | translate}}</a>
          </div>
        </form>
    </div>
  </div>

  <fuse-mail-confirm *ngIf="requestValidate" [data]="formData"></fuse-mail-confirm>
</div>
