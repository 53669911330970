<div class="dialog-resume">

    <div class="dialog-resume__content">

        <div class="dialog-resume__image-wrapper">
            <img class="dialog-resume__image"
                 [src]="mainCardPicture"/>
        </div>

        <div class="dialog-resume__actions">
            <div *ngFor="let d of currentCard; let index = index"
                 class="dialog-resume__action-button-wrapper"
                 [ngClass]="{'dialog-resume__action-button-wrapper--open': menuIndex === index}">
                <button class="dialog-resume__action-button"
                        [ngClass]="{'dialog-resume__action-button--playing': index === currentIndex && isPlaying()}"
                        (click)="openMenu(index)"
                        [disabled]="!unlockDialogDone">
                    <mat-icon *ngIf="index === currentIndex && isPlaying()"
                              class="dialog-resume__action-button-icon"
                              svgIcon="sound_on">
                    </mat-icon>
                    <q class="dialog-resume__action-button-label">{{ d?.title }}</q>
                </button>
                <app-dialog-resume-action-menu *ngIf="menuIndex === index"
                                               (recordAgain)="recordAgain(index)"
                                               (playOriginalVersion)="playOriginalVersion(index)"
                                               (playUserRecord)="playUserRecord(index)"
                                               (stopPlaying)="stopPlaying(index)"
                                               [recordForSelectedElementExist]="recordForSelectedElementExist"
                                               [playing]="isPlayingUserAudio || isPlayingOriginalAudio">
                </app-dialog-resume-action-menu>
            </div>
        </div>

    </div>

    <div class="dialog-resume__footer">

        <button *ngIf="!unlockDialogDone"
                class="dialog-resume__footer-button"
                (click)="launchActivity()">
            <span class="dialog-resume__footer-button-label">{{ 'language_lab.start' | translate }}</span>
            <mat-icon class="dialog-resume__footer-button-icon"
                      svgIcon="next">
            </mat-icon>
        </button>

        <button *ngIf="unlockDialogDone"
                class="dialog-resume__footer-button"
                (click)="!isPlayingOriginalAudios ? playOriginalAudio() : stop()">
                <span class="dialog-resume__footer-button-label">{{ (!isPlayingOriginalAudios ? 'language_lab.listen_to_original_version' : 'language_lab.stop') | translate }}</span>
            <mat-icon class="dialog-resume__footer-button-icon"
                      [svgIcon]="!isPlayingOriginalAudios ? 'sound_off' : 'stop'">
            </mat-icon>
        </button>

        <button *ngIf="unlockDialogDone && almostOneUSerAudio()"
                class="dialog-resume__footer-button"
                (click)="!isPlayingUserAudios  ? playAllCurrentUserAudios() : stop()">
            <span class="dialog-resume__footer-button-label">{{ (!isPlayingUserAudios ? 'language_lab.listen_to_user_version' : 'language_lab.stop') | translate }}</span>
            <mat-icon class="dialog-resume__footer-button-icon"
                      [svgIcon]="!isPlayingUserAudios ? 'headphones' : 'stop'">
            </mat-icon>
        </button>

    </div>

</div>