<div mat-dialog-title *ngIf="!embedded">
    <h2>{{'group-management.join_class_title' | translate}}</h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content *ngIf="!embedded">
    <h3 *ngIf="!errorMessage">{{ title | translate}}</h3>

    <div
        *ngIf="!finish && !errorMessage"
        class="mb-24"
        fxLayout="row"
        fxLayoutAlign="start start">
        <mat-form-field appearance="fill" fxFlex>
            <mat-label>{{'group-management.join_class_title' | translate}}</mat-label>
            <mat-select [(value)]="selectedGroup">
                <mat-option [value]="group" *ngFor="let group of groups">{{ group.get('label') }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="errorMessage">{{ errorMessage | translate }}</div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!embedded">
    <button
        (click)="joinClass()"
        *ngIf="errorMessage === ''"
        mat-raised-button
        class="mat-accent">{{'group-management.join_class_confirm' | translate}}</button>

    <button
        (click)="reset()"
        *ngIf="errorMessage === ''"
        mat-raised-button
        class="mat-accent">{{'generic.cancel' | translate}}</button>

</mat-dialog-actions>

<ng-container *ngIf="embedded">
    <div class="embed-container">
        <mat-form-field appearance="fill" fxFlex *ngIf="!errorMessage">
            <mat-label>{{'group-management.join_class_title' | translate}}</mat-label>
            <mat-select [(value)]="selectedGroup">
                <mat-option [value]="group" *ngFor="let group of groups">{{ group.get('label') }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="errorMessage">{{ errorMessage | translate }}</div>

        <button
            (click)="joinClass()"
            *ngIf="errorMessage === ''"
            mat-raised-button
            class="mat-accent">{{'group-management.join_class_confirm' | translate}}</button>
    </div>
</ng-container>