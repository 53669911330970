<ng-container *ngIf="currentCard">
    <app-language-lab-card-player [mainCardTitle]="currentCard.title"
                                  [mainCardPicture]="currentCard?.image?.uri"
                                  [cards]="currentCard.data"
                                  (close)="close()"
                                  [showResume]="currentCard?.type==='Dialogue'">
    </app-language-lab-card-player>
</ng-container>

<mat-select *ngIf="periods.length > 0 && !currentCard"
            class="language-lab__select"
            (selectionChange)="onSelectionChange($event.value)"
            [value]="+periodSelected">
    <mat-option *ngFor="let data of periods"
                [value]="+data.id">
        {{data.label}}
    </mat-option>
</mat-select>

<ng-container *ngIf="!currentCard">
    <div class="language-lab__cards"
         infinite-scroll
         [infiniteScrollDistance]="1"
         [infiniteScrollThrottle]="200"
         [immediateCheck]="true"
         [scrollWindow]="false"
         [fromRoot]="true"
         [infiniteScrollContainer]="'app-language-lab'"
         (scrolled)="onScroll()">
        <app-language-lab-card *ngFor="let card of cards"
                               [flashCard]="card"
                               [idCardPlaying]="idCardPlaying"
                               (state)="onStateChange($event, card)"
                               (openCard)="openActivity(card)">
        </app-language-lab-card>
        <audio #stream
               controls
               [attr.id]="currentFlashCardReading?.audio?.id"
               [attr.src]="currentFlashCardReading?.audio?.uri" [attr.aria-label]="currentFlashCardReading?.audio?.alt">
            Your browser does not support the <code>audio</code> element.
        </audio>
    </div>
</ng-container>

<div *ngIf="loader"
     class="language-lab__loader"
     fxLayout="row"
     fxLayoutAlign="center center"
     fxLayoutGap="12px">
    <mat-spinner></mat-spinner>
</div>