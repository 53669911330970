<div mat-dialog-title>
    <h2>{{ 'assignment.assign_to_learners' | translate }}</h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content fusePerfectScrollbar>
    <form [formGroup]="entityForm">

            <legend
                *ngIf="('assignment.information' | translate) != 'assignment.information'">{{ 'assignment.information' | translate }}</legend>

            <mat-form-field *ngIf="displayField('group')" class="assignation__group-field">
                <mat-label>{{ 'groups-management.assign_to_class' | translate }}</mat-label>
                <mat-chip-grid #groupsChipList>
                    <mat-chip-row
                        *ngFor="let groupChip  of groupsChips"
                        [removable]="groupsRemovable"
                        (removed)="removeChip(groupChip, 'group')">
                        {{groupChip.groupname}}
                        <mat-icon matChipRemove *ngIf="groupsRemovable">cancel</mat-icon>
                    </mat-chip-row>
                    <input
                    #groupsChipInput
                    [formControl]="groupsCtrl"
                    [matAutocomplete]="groups"
                    [matChipInputFor]="groupsChipList"
                    [matChipInputSeparatorKeyCodes]="groupsSeparatorKeysCodes"
                    [matChipInputAddOnBlur]="groupsAddOnBlur"
                    (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-grid>

                <mat-autocomplete
                    #groups="matAutocomplete"
                    (optionSelected)="chipSelected($event, 'group')">
                    <mat-option *ngFor="let groupChip of groupsFilteredChips | async" [value]="groupChip">
                        {{ groupChip.groupname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field *ngIf="displayField('workgroup')" class="assignation__workgroup-field">
                <mat-label>{{ 'assignment.by_workgroup' | translate }}</mat-label>
                <mat-chip-grid #workgroupsChipList>
                    <mat-chip-row
                        *ngFor="let workgroupChip  of workgroupsChips"
                        [removable]="workgroupsRemovable"
                        (removed)="removeChip(workgroupChip, 'workgroup')">
                        {{workgroupChip.workgroupname}}
                        <mat-icon matChipRemove *ngIf="workgroupsRemovable">cancel</mat-icon>
                    </mat-chip-row>
                    <input
                        #workgroupsChipInput
                        [formControl]="workgroupsCtrl"
                        [matAutocomplete]="workgroups"
                        [matChipInputFor]="workgroupsChipList"
                        [matChipInputSeparatorKeyCodes]="workgroupsSeparatorKeysCodes"
                        [matChipInputAddOnBlur]="workgroupsAddOnBlur"
                        (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-grid>
                <mat-autocomplete #workgroups="matAutocomplete" (optionSelected)="chipSelected($event, 'workgroup')">
                    <mat-option *ngFor="let workgroupChip of workgroupsFilteredChips | async" [value]="workgroupChip">
                        {{ workgroupChip.workgroupname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>


        <ng-container *ngIf="displayField('learner')">
            <mat-form-field class="assignation__learner-field">
                <mat-label>{{ 'assignment.select_learners' | translate }}</mat-label>
                <mat-chip-grid #learnersChipList required>
                    <mat-chip-row
                        *ngFor="let learnerChip  of learnersChips"
                        [removable]="learnersRemovable"
                        (removed)="removeChip(learnerChip, 'learner')">
                        {{learnerChip.username ? assignationConfiguration?.useNicknameInsteadOfUsername() ? learnerChip.nickname : learnerChip.username : learnerChip }}
                        <mat-icon matChipRemove *ngIf="learnersRemovable">cancel</mat-icon>
                    </mat-chip-row>
                    <input
                        #learnersChipInput
                        [formControl]="learnersCtrl"
                        [matAutocomplete]="learners"
                        [matChipInputFor]="learnersChipList"
                        [matChipInputSeparatorKeyCodes]="learnersSeparatorKeysCodes"
                        [matChipInputAddOnBlur]="learnersAddOnBlur"
                        (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-grid>
                <mat-autocomplete #learners="matAutocomplete" (optionSelected)="chipSelected($event, 'learner')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let learnerChip of learnersFilteredChips | async" [value]="learnerChip">
                        {{ assignationConfiguration?.useNicknameInsteadOfUsername() ? learnerChip?.nickname : learnerChip?.username}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>

        <!-- Project Field -->
        <ng-container *ngIf="displayField('project') && hasProject">
            <mat-form-field class="assignation__project-field">
                <mat-label>{{ 'assignment.select_project' | translate }}</mat-label>
                <mat-select name="project"
                            formControlName="project" required>
                    <mat-option *ngFor="let project of projectsList" [value]="project">{{ project.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <!-- Assignment Type Field -->
        <ng-container *ngIf="displayField('assignment_type') && (hasType && !hasDefaultType)">
            <mat-form-field class="assignation__assignment-type-field">
                <mat-label>{{ 'assignment.select_type' | translate }}</mat-label>
                <mat-select [disabled]="disableTypeSelect" name="type"
                            formControlName="type" required>
                    <mat-option *ngFor="let type of typeList"
                                [value]="type.id"> {{ localizedType(type.label) | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <!-- Assignment Rating grade Field -->
        <ng-container *ngIf="displayField('assignment_grade') && hasGrade">
            <mat-form-field class="assignation__assignment-grade-field">
                <mat-label>{{ 'assignment.select_grade' | translate }}</mat-label>
                <mat-select name="rating_base"
                            formControlName="rating_base">
                    <mat-option *ngFor="let ratingBase of ratingBaseList" [value]="ratingBase">
                        {{'assignment.rating_base_on' | translate }}{{ ratingBase }}</mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>

        <!-- Start Date and Time Field -->
        <ng-container
             *ngIf="displayField('start_date') && hasCompletionDate || displayField('start_date') && hasCompletionStartDateOnly">

            <mat-form-field class="assignation__start-date-field">
                <mat-label>{{ 'generic.start_date' | translate }}</mat-label>
                <input #startDateInput name="startDate" matInput [min]="startDateMinLimit" [max]="maxDateLimit"
                       [matDatepicker]="picker"
                       formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field  *ngIf="hasCompletionTime" class="assignation__max-start-date-field">
                <input name="startTime" matInput placeholder="{{'generic.start_time' | translate}}"
                       [ngxTimepicker]="fullTime" [disableClick]="!inputStartDateValue" [min]="minStartTimeLimit"
                       [max]="maxStartTimeLimit" [format]="24" formControlName="startTime" readonly>
                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
            </mat-form-field>
        </ng-container>

        <!-- Due Date and Time Field -->
        <ng-container
             *ngIf="displayField('due_date') && hasCompletionDate">
            <mat-form-field class="assignation__due-date-field">
                <input name="dueDate" matInput [min]="dueDateMinLimit" [matDatepicker]="picker"
                       placeholder="{{'generic.end_date' | translate}}" formControlName="dueDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field  *ngIf="hasCompletionTime" class="assignation__max-due-date-field">
                <input name="dueTime" matInput placeholder="{{'generic.end_time' | translate}}"
                       [ngxTimepicker]="fullTime" [disableClick]="!maxDateLimit" [min]="minDueTimeLimit"
                       [max]="maxDueTimeLimit" [format]="24" formControlName="dueTime" readonly>
                <mat-error *ngIf="entityFormErrors.dueTime.timeError">
                    {{ 'assignment.time_error' | translate }}
                </mat-error>
                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
            </mat-form-field>
        </ng-container>

        <!--comment to send with assignation to learner-->
        <ng-container *ngIf="displayField('comment')">
            <mat-form-field class="assignation__comment-field">
                <mat-label>{{ 'assignment.comment' | translate }}</mat-label>
                <input matInput formControlName="comment"/>
                <mat-error>
                    {{ 'assignment.comment.too-long' | translate }}
                </mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="assignationObjects">
            <legend>{{ 'assignation.select-lesson' | translate }}</legend>
        </ng-container>

        <ng-container *ngIf="assignationObjects">
            <div class="assignation__select-lesson">
                <mat-button-toggle-group name="lessons" aria-label="Lessons"  formControlName="selectedLesson" required>
                    <mat-button-toggle [value]="lesson"
                                       *ngFor="let lesson of assignationObjects">{{ lesson.get('metadatas').title }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </ng-container>

        <ng-container *ngIf="assignationObjects && selectedLesson">
            <legend>{{ 'assignation.select-activities' | translate }}</legend>
        </ng-container>

        <div *ngIf="assignationObjects && selectedLesson"
             class="assignation__selected-activities">
            <mat-selection-list formControlName="selectedActivities">
                <mat-list-option checkboxPosition="before"
                                 *ngFor="let item of selectedLesson.get('reference')"
                                 [value]="item.id"
                                 [selected]="item.id">
                    {{ item.title }}
                    <span *ngIf="item.activityType"
                          class="button-like">
                        {{ item.activityType }}
                    </span>
                </mat-list-option>
            </mat-selection-list>
        </div>


    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            (click)="assignLesson()"
            class="save-button mat-accent"
            [disabled]="entityForm.invalid"
            aria-label="SAVE">
        {{ 'generic.validate' | translate }}
    </button>
</mat-dialog-actions>