<div class="confirm-dialog__header"
     mat-dialog-title>
    <h2 *ngIf="titleDialog?.length > 0">
        {{titleDialog}}
    </h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<div class="confirm-dialog__content"
     mat-dialog-content>
    <div [innerHTML]="bodyDialog"></div>
    <input matInput #inputToFocus [placeholder]="labelConfirmDialogPlaceHolder" *ngIf="labelConfirmDialog?.length > 0"
           [(ngModel)]="inputValue" type="text">
    <mat-error *ngIf="isLengthInputTooLong()">inputErrorLengthMessage</mat-error>
</div>

<div class="confirm-dialog__actions"
     mat-dialog-actions>
    <button *ngIf="labelConfirmDialog?.length > 0"
            class="mat-accent mr-16"
            mat-raised-button
            [disabled]="isLengthInputTooLong() || inputValue === originalInputValue || inputValue ==='' || inputValue === undefined"
            (click)="dialogRef.close(inputValue)">
        <span>{{labelConfirmDialog}}</span>
    </button>
    <button *ngIf="labelTrueDialog?.length > 0"
            class="mat-accent mr-16 confirm-dialog__validate-button"
            mat-raised-button
            (click)="dialogRef.close(true)">
        <span>{{labelTrueDialog}}</span>
    </button>
    <button *ngIf="labelFalseDialog?.length > 0"
            class="mr-16 confirm-dialog__cancel-button"
            [ngClass]="{'mat-accent':!(labelTrueDialog?.length > 0)}"
            mat-raised-button
            (click)="dialogRef.close(false)">
        <span>{{labelFalseDialog}}</span>
    </button>
    <button *ngIf="labelOtherDialog?.length > 0"
            class="mr-16"
            [ngClass]="{'mat-accent':!(labelOtherDialog?.length > 0)}"
            mat-raised-button
            (click)="dialogRef.close('otherButtonDialogAction')">
        <span>{{labelOtherDialog}}</span>
    </button>
    <app-error-reporting-trigger-button *ngIf="showReportbutton"
                                        [type]="'resource'"
                                        [activityId]="resourceId"
                                        [objectTitle]="titleDialog">
    </app-error-reporting-trigger-button>
</div>