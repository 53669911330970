<div class="corpus-file-list__container page-layout inner-scroll"
     fxFlex
     fxLayout="column"
     appDynamicTabIndex
     [reload]="ELEMENT_DATA">

    <app-banner-info class="corpus-file-list__banner-info"
                     [rolesCanShowBanner]="rolesCanShowBannerInfo"
                     [situation]="getCorpusSituation()">
    </app-banner-info>

    <button class="corpus-file-list__filter-button" 
            (click)="toggleFilterVisibility()">
        @if (!showFilters) {
            <span>{{ 'generic.show_filters' | translate }}</span>
        } @else {
            <span>{{ 'generic.hide_filters' | translate }}</span>
        }
        <mat-icon svgIcon="tune-rounded"></mat-icon>
    </button>

    @if (showFilters) {
        <!-- TODO : Reporter dans les autres templates ou en faire un composant -->
        <mat-toolbar class="corpus-file-list__filters filters"
                     appMoveFocusToNextAllowedElement>
            <mat-toolbar-row>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
                    <button mat-stroked-button (click)="tagsPopUp('chapters')"
                            *ngIf="displayFilters('chapters') && !tagsSelected['chapters'].length">
                        <span>{{ 'generic.chapters' | translate }}
                            (<span>{{displayedFiltersLength('chapters') | translate }}</span>)</span>
                    </button>
                    <mat-button-toggle-group *ngIf="displayFilters('chapters') && tagsSelected['chapters'].length">
                        <mat-button-toggle (click)="tagsPopUp('chapters')"
                                           class="mat-raised-button">
                            <span>{{ 'generic.chapters' | translate }}
                                (<span>{{displayedFiltersLength('chapters') | translate }}</span>)
                            </span>
                        </mat-button-toggle>
                        <mat-button-toggle (click)="resetTags('chapters')">
                            <mat-icon svgIcon="cross"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-button-toggle-group *ngIf="displayFilters('concepts') && tagsSelected['concepts'].length">
                        <mat-button-toggle (click)="tagsPopUp('concepts')"
                                           class="mat-raised-button">
                            <span>{{ 'generic.concepts' | translate }}
                                (<span>{{displayedFiltersLength('concepts') | translate }}</span>)</span>
                        </mat-button-toggle>
                        <mat-button-toggle (click)="resetTags('concepts')">
                            <mat-icon svgIcon="cross"></mat-icon>
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </mat-toolbar-row>
            <mat-toolbar-row>
                <app-search-filters [fields]="displayedFilters"
                                    [initialsValues]="urlFilters"
                                    [orderFields]="true"
                                    [customLists]="{types:this.listOfType}"
                                    [customFieldName]="customFieldName"
                                    [displayedFiltersIcons]="displayFiltersIcons"
                                    [origin]="'corpus'"
                                    (launchSearch)="launchSearchApp($event)"></app-search-filters>
                <!--help video button-->
                <button *ngIf="videoUrlToLaunch() !==''" mat-raised-button color="primary" (click)="playHelpVideo()"
                        class="btn-videoUrlToLaunch">{{ 'generic.corpus.video.help' | translate }}</button>

            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="displayFilterCheckboxOption">
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
                    <small>{{ 'generic.for_selection' | translate }}</small>
                    <button [disabled]="buttonDisabled" mat-raised-button color="primary"
                            *ngIf="mode !== 'readonly' && corpusService.userCanDelete"
                            (click)="corpusService.openDialog('multiple', checkBoxes)">{{ 'generic.delete' | translate }}</button>
                    <button *ngIf="displayAddToLesson && !inSelectionMode" [disabled]="buttonDisabled" mat-raised-button
                            color="primary"
                            (click)="launchLessonListingModal(checkboxesList)">{{ 'generic.add_to_lesson' | translate }}
                        ({{ selectedCount }})
                    </button>
                    <button *ngIf="displayAddToLesson && inSelectionMode" [disabled]="selectedCount === 0"
                            mat-raised-button
                            (click)="addToLesson()">
                        {{ 'activities.add_to_lesson' | translate}} ({{ selectedCount }})
                    </button>
                    <button *ngIf="displayAddToLesson && inSelectionMode"
                            mat-raised-button
                            (click)="corpusService.goBackToForm()">
                        {{ 'generic.cancel' | translate}}
                    </button>
                </div>
            </mat-toolbar-row>

            <mat-toolbar-row *ngIf="corpusService.dataForModalOpening?.selectionMode">
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
                    <small>{{ 'generic.for_selection' | translate }}</small>
                    <button mat-raised-button
                            (click)="addToLessonThumbnail()">
                        {{ 'activities.add_to_lesson' | translate}}
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
    }

    <div class="corpus-file-list__content content" #scrollToTopContent fusePerfectScrollbar>

        <div *ngIf="displayEmptyCorpusHelper" class="mat-body create-corpus-helper empty-corpus">
            {{'corpus.empty_corpus_helper'| translate}}
        </div>

        <div *ngIf="displayCreateCorpusHelper" class="mat-body create-corpus-helper">
            {{'corpus.creation_corpus_helper'| translate}}
        </div>

        <div *ngIf="displayCommunityCorpusHelper" class="mat-body create-corpus-helper">
            {{'corpus.community_corpus_helper'| translate}}
        </div>

        <button *ngIf="checkAccessLevel('add') && mode !== 'readonly' && showButtonOptions"
                class="add-resource-button"
                (click)="openResourceCreationModal()">
            <mat-icon svgIcon="add"></mat-icon>
            <span>{{ 'corpus.add_resource' | translate }}</span>
        </button>

        <table mat-table class="mat-table table-main" #table [dataSource]="dataSource" *fuseIfOnDom matSort
               [@animateStagger]="{value:'50'}">
            <!-- checkbox Column -->
            <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="updateCheck()" [(ngModel)]="corpusService.selectAll"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                        [(ngModel)]="checkboxesList[row.id]"
                        (ngModelChange)="onMultiSelectionChange(row)"
                        id="{{ row.id }}"
                        name="corpusCheckboxe"></mat-checkbox>
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef fxHide.xs>{{ getColumnTerms('type') | translate }}</th>
                <td mat-cell *matCellDef="let row" fxHide.xs>
                    <mat-icon *ngIf="!row.duration" svgIcon="{{ getContentTypeIcon(row)['name'] }}"
                              [ngClass]="'svg-icon-' + getContentTypeIcon(row)['name'] "
                              title="{{ getContentTypeIcon(row)['translate'] | translate }}"></mat-icon>
                    <mat-icon *ngIf="!!row.duration" svgIcon="video"></mat-icon>
                </td>
            </ng-container>

            <!-- title Column -->
            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.title' | translate }}</th>
                <td mat-cell *matCellDef="let row"> {{row.title}}</td>
            </ng-container>

            <!-- Level -->
            <ng-container matColumnDef="level">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.level' | translate }}</th>
                <td mat-cell *matCellDef="let row"> {{row.educationalLevelString}}</td>
            </ng-container>

            <!-- Difficulty -->
            <ng-container matColumnDef="difficulty">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.difficulty' | translate }}</th>
                <td mat-cell *matCellDef="let row">{{ row.difficultyString }}</td>
            </ng-container>

            <!-- Level -->
            <ng-container matColumnDef="concept">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.concepts' | translate }}</th>
                <td mat-cell *matCellDef="let row">{{ corpusService.getUseOnlyFirstConcept() ? getConcept(row.metadatasEntity) : getConceptsAsString(row.metadatasEntity) }}</td>
            </ng-container>

            <!-- Skills -->
            <ng-container matColumnDef="skills">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.skills' | translate }}</th>
                <td mat-cell *matCellDef="let row"> {{row.skillsString}}</td>
            </ng-container>

            <!-- Owner Column -->
            <ng-container matColumnDef="ownerName">
                <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md
                    mat-sort-header>{{ getColumnTerms('author') | translate }}</th>
                <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>{{row.ownerName}}</td>
            </ng-container>

            <!-- group Column -->
            <ng-container matColumnDef="group">
                <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md
                    mat-sort-header>{{ getColumnTerms('group') | translate }}</th>
                <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>{{row.groupes}}</td>
            </ng-container>

            <!-- learningTime Column -->
            <ng-container matColumnDef="learningTime">
                <th mat-header-cell *matHeaderCellDef fxHide.xs
                    mat-sort-header>{{ 'generic.learning_time' | translate }}</th>
                <td mat-cell *matCellDef="let row" fxHide.xs> {{row.duration}}</td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="changedDate">
                <th mat-header-cell *matHeaderCellDef fxHide.xs
                    mat-sort-header>{{ 'generic.changedDate' | translate }}</th>
                <td mat-cell *matCellDef="let row" fxHide.xs> {{corpusService.localeDate(row.changedDate)}}</td>
            </ng-container>

            <!-- group Column -->
            <ng-container matColumnDef="used">
                <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-md mat-sort-header>
                    <mat-icon class="type-icon">swap_horiz</mat-icon>
                </th>
                <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md></td>
            </ng-container>

            <!-- title Column -->
            <ng-container matColumnDef="favorite">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'generic.favoris' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                    <button
                        mat-icon-button
                        class="flag"
                        [ngClass]="{'active': row.bookmarks === true }"
                        (click)="flagEntity(row, 'node', 'bookmarks')"
                        [attr.aria-label]="'generic.favoris' | translate"
                        [matTooltip]="'generic.favoris' | translate">
                        <mat-icon svgIcon="heart"></mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Detail Button Column -->
            <ng-container matColumnDef="detail-button">
                <th mat-header-cell *matHeaderCellDef fxHide.gt-md><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_file' | translate}}</span></th>
                <td mat-cell *matCellDef="let row" fxHide.gt-md>
                    <button mat-icon-button class="sidenav-toggle"
                            fuseMatSidenavToggler="file-manager-right-sidenav">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttonsAction">
                <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_more_menu' | translate}}</span></th>
                <td mat-cell *matCellDef="let entity">
                    <div fxLayout="row" fxLayoutAlign="flex-end center">
                        <button *ngIf="displayVisibility()" class="button-action-visibility" mat-mini-fab (click)="corpusService.openRessource(entity)" class="mat-elevation-z1">
                            <mat-icon svgIcon="visibility"></mat-icon>
                        </button>

                        <button *ngIf="checkAtLeastOneAccessMenu(entity)" mat-icon-button class="mat-icon-button"
                                [attr.aria-label]="'generic.more' | translate"
                                [matTooltip]="'generic.more' | translate"
                                [matMenuTriggerFor]="menu">
                            <mat-icon svgIcon="submenu"></mat-icon>
                        </button>

                        <mat-menu #menu="matMenu">
                            <!-- <button *ngIf="mode !== 'readonly'" mat-menu-item (click)="openResourceEditionModal(selected)">
                               <mat-icon>edit</mat-icon> {{ 'generic.annotate' | translate }}
                           </button> -->
                            <button mat-menu-item (click)="openResourceEditionModal(selected)"
                                    *ngIf="checkAccessLevel('edit') && mode !== 'readonly'">
                                <mat-icon svgIcon="edit"></mat-icon>
                                {{ 'generic.edit' | translate }}
                            </button>
                            <!-- <button mat-menu-item (click)="duplicateResource()" *ngIf="checkAccessLevel('add') && mode !== 'readonly'">
                                <mat-icon>file_copy</mat-icon> {{ 'generic.clone' | translate }}
                            </button> -->
                            <button mat-menu-item (click)="corpusService.openDialog(selected)"
                                    *ngIf="checkAccessLevel('delete') && mode !== 'readonly' && selected?.locked === false">
                                <mat-icon svgIcon="delete"></mat-icon>
                                {{ 'generic.delete' | translate }}
                            </button>
                            <button mat-menu-item (click)="corpusService.print(selected)"
                                    *ngIf="checkAccessLevel('print') && (selected.format === 'image' || selected.format === 'document')">
                                <mat-icon svgIcon="print"></mat-icon>
                                {{ 'generic.print' | translate }}
                            </button>
                            <button
                                (click)="corpusService.downloadFile(selected)"
                                mat-menu-item
                                *ngIf="selected?.ressourceEntity && selected?.ressourceEntity.attributes.reference.filesize?.length > 0 && (checkAccessLevel('download') && mode !== 'readonly')">
                                <mat-icon svgIcon="file_download"></mat-icon>
                                {{ 'generic.download' | translate }}
                            </button>
                        </mat-menu>

                    </div>

                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td *ngIf="!this.isSearchResult" class="mat-cell" [attr.colspan]="displayedColumns.length">{{ 'corpus.empty_corpus_helper'| translate }}</td>
                <td *ngIf="this.isSearchResult" class="mat-cell" [attr.colspan]="displayedColumns.length">{{ 'corpus.empty_search_corpus_helper'| translate }}</td>
            </tr>
            <tr
                mat-row
                matRipple
                *matRowDef="let row; columns: displayedColumns;"
                tabindex="0"
                [ngClass]="{'selected': isSelected(row)}"
                [@animate]="{value:'*',params:{y:'100%'}}"
                (click)="onSelect(row)"
                (keyup.enter)="onSelect(row)"
                (keyup.space)="onSelect(row)"
                [attr.aria-label]="row.title">
            </tr>
        </table>

        <mat-paginator #paginator [length]="corpusService.paginatedCollection?.paginator?.count"
                       [pageSize]="corpusService.paginatedCollection?.paginator?.range"
                       (page)="onPaginateChange($event)">
        </mat-paginator>
        <!--erasme-->
        <div fxLayoutAlign="center center" *ngIf="inSelectionMode && !lessonEditorWithStepConfig?.mode">
            <button mat-stroked-button (click)="addToLesson()">
                {{ 'activities.add_to_lesson' | translate }} ({{ selectedCount }})
            </button>
        </div>
        <!--infohunter-->
        <div *ngIf="lessonEditorWithStepConfig?.mode" fxLayoutAlign="center center" class="my-32">
            <button mat-raised-button (click)="addToActivity()" class="save-button mat-accent">
                <span>{{ 'generic.add' | translate }}</span>
            </button>
            <button mat-raised-button (click)="goBackToActivity()">
                <span>{{ 'generic.exit' | translate }}</span>
            </button>
        </div>

        <div *ngIf="corpusService.dataForModalOpening?.selectionMode" fxLayoutAlign="center center" class="my-32">
            <button mat-raised-button
                    (click)="addToLessonThumbnail()">
                {{ 'activities.add_to_lesson' | translate}}
            </button>
        </div>


    </div>

</div>