<ng-template #loading>
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
<ng-container *ngIf="ready else loading" >
    <div mat-dialog-title>
        <h2>{{'progression.progression_title' | translate}}</h2>
        <ng-container *ngIf="!!classControl && !!classControl.value">
            <label>{{classControl.value?.groupname}}</label>
        </ng-container>
        <ng-container *ngIf="!!workgroupControl && !!workgroupControl.value">
            <label>{{workgroupControl.value?.workgroupname}}</label>
        </ng-container>
        <ng-container *ngIf="!!conceptControl">
            <label>{{conceptControl.value?.get('label')}}</label>
        </ng-container>
        <!--<ng-container *ngIf="!!classControl">
            <mat-form-field>
                <mat-select
                    name="classes"
                    [formControl]="classControl">
                    <mat-option *ngFor="let class of classes" [value]="class">
                        <span>{{class.groupname}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>-->
        <!--<ng-container *ngIf="!!conceptControl">
            <mat-form-field>
                <mat-select
                    name="concept"
                    [formControl]="conceptControl">
                    <option *ngFor="let concept of concepts" [value]="concept">
                        <span>{{concept.get('label')}}</span>
                    </option>
                </mat-select>
            </mat-form-field>
        </ng-container>-->

        <button
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>

        <ul class="breadcrumb">
            <li [class]="{'selected': isChapterSelected(chapter.id)}" *ngFor="let chapter of chaptersToDisplay">
                <span>{{chapter.label}}</span>
            </li>
        </ul>

    </div>

    <div mat-dialog-content>
            <h3 *ngIf="!!currentChapter">
                <span>{{currentChapter?.get('label')}}</span>
            </h3>

            <section class="list-with-heading">
                <h4>{{lesson?.get('metadatas').title}}</h4>
                <ul>
                    <li (click)="launchAssignment(subLesson.id)" [class]="{'done': isSubLessonDone(subLesson.id), 'next': isSubLessonAvailable(subLesson, index)}" *ngFor="let subLesson of subLessons; let index = index">
                        <span>{{subLesson.title}}</span>
                        <span class="status" *ngIf="isSubLessonDone(subLesson.id) && !isSubLessonPending(subLesson.id)">
                            <ng-container *ngIf="!!getDateCompleted(subLesson.id)">
                                {{'progression.session_completed' | translate}}&nbsp;
                                {{getDateCompleted(subLesson.id) | date: 'dd/MM/yyyy'}}
                            </ng-container>
                        </span>
                        <span class="status" *ngIf="isSubLessonAvailable(subLesson, index)">{{'progression.sub_lesson_available' | translate}}</span>
                    </li>
                </ul>
            </section>
    </div>

    <div mat-dialog-actions class="progression-action">
        <h3>{{'progression.lets_go' | translate}}</h3>
        <button [disabled]="!subLessonNotCompleted" (click)="launchAssignment()">
            <mat-icon svgIcon="progression__launch"></mat-icon>
            <span>{{'progression.launch_assignment' | translate}}</span>
        </button>
        <a (click)="openLessonPage()" (keydown)="openLessonPageOnEnter($event)" tabindex="0" role="button">{{'progression.prepare_session' | translate}}</a>
        <!--<a href="/">{{'progression.see_all_sessions_completed' | translate}}</a>
        <a href="/">{{'progression.see_the_program_for_the_year' | translate}}</a>-->
    </div>
</ng-container>

