<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">
    <div fxFill fxLayoutAlign="center center" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <table mat-table
           #table
           *ngIf="empty"
           [dataSource]="dataSource"
           fxFlex="100">

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md mat-sort-header>
                {{ 'generic.username' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
                {{row.username}}
            </td>
        </ng-container>

        <ng-container matColumnDef="groups">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
                {{ 'generic.label_group' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [fxHide]="assignationConfigurationService.activateFxHide()" fxShow.gt-md>
                {{row.groups[0]}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            matRipple>
        </tr>
    </table>
    <div *ngIf="!empty" class="emptyResourceLabel">{{ 'generic.assignment_empty_granule' | translate}}</div>
</div>
