<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>

<div class="page-layout simple fullwidth inner-scroll mat-white-bg" fusePerfectScrollbar>

    <!--<div class="header"></div>-->

    <div class="content">
        <div *ngIf="isLoadingDone else loadingBlock" class="content-editor">

            <div class="bd-header">
            </div>

            <div class="bd-content">
                <bdtool-root [comicID]="bdId" [assetsEndpoint]="assetsEndpoint"></bdtool-root>
            </div>
        </div>
    </div>
</div>
