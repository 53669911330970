<div class="timelineContainer">
    <!--tooltip current year-->
    <div *ngIf="isActiveCursorDate" class="popup" [style.left]="positionTooltip()">
        <div class="verticalLinePopUp"></div>
        {{currentValue}}</div>

    <!--couleur des zones-->
    <div class="zoneColor" *ngFor="let param of zoneColor"
         appPositionAndStyle
         [positionAndStyle]="param"></div>

    <!--separate zone-->
    <div class="splitZone" *ngFor="let position of positionSeparators"
         appPositionAndStyle
         [position]="position"></div>

    <!--regular interval split-->
    <div class="splitInterval" *ngFor="let position of positionIntervals"
         appPositionAndStyle
         [position]="position.left"></div>

    <div class="splitIntervalTimeText" *ngFor="let position of positionIntervals"
         appPositionAndStyle
         [position]="position.left">{{position.year}}
    </div>

    <!--dot history periode separator-->
    <div class="circle" *ngFor="let dot of dots"
         appPositionAndStyle
         [position]="dot"></div>

    <!--dot history midlle periode separator-->
    <div class="circle" *ngFor="let dot of dotsMiddle"
         appPositionAndStyle
         [position]="dot"></div>

    <div class="dateDebut splitIntervalTimeText">
        {{minValue}}
    </div>

    <div class="dateFin splitIntervalTimeText">
        {{maxValue}}
    </div>

    <!--slider-->
    <input type="range" name="sliderCutom"
           [attr.min]="minValue"
           [attr.max]="maxValue"
           value="0"
           [(ngModel)]="currentValue"
           (input)="sliderMove($event)"
           (click)="sliderClick($event)"
           (touchend)="sliderClick($event)">
</div>
