<div class="main-container">
    <div class="popup-container" *ngIf="showPopup">
        <div class="popup">
            <div class="close" (click)="closePopup()">
                <mat-icon svgIcon="cross"></mat-icon>
            </div>
            <div class="popup-inner">
                <div class="tabs">
                    <div class="tab universes" [ngClass]="activeTab === 'universes' ? 'active' : null"
                         (click)="switchTab('universes')">{{ 'gamification.avatar.universe' | translate }}</div>
                    <div *ngFor="let position of badgesPosition" class="tab accessories"
                         [ngClass]="activeTab === position.name ? 'active' : null"
                         (click)="switchTab(position.name)">{{ position.name | translate }}</div>

                </div>
                <div class="items-container">
                    <ng-container *ngFor="let position of badgesPosition">
                        <div class="items accessories inner-scroll" fusePerfectScrollbar
                             *ngIf="activeTab === position.name">
                            <app-accessory-thumbnail class="item"
                                                     *ngFor="let accessory of getAccessoriesPerPosition(position.id)"
                                                     [badge]="accessory.attributes"
                                                     (click)="onAccessoryClick(accessory)"></app-accessory-thumbnail>
                        </div>
                    </ng-container>
                    <div class="items universes inner-scroll" fusePerfectScrollbar *ngIf="activeTab === 'universes'">
                        <app-accessory-thumbnail class="item" *ngFor="let universe of universes"
                                                 [badge]="universe.attributes"
                                                 (click)="onUniverseClick(universe)"></app-accessory-thumbnail>
                    </div>
                </div>
                <div class="buttons">
                    <div class="button">
                        <div class="inner-button save"
                             [ngClass]="isSaveButtonActive ? 'enabled' : null"
                             [disabled]="!isSaveButtonActive"
                             (click)="save()">
                            {{ 'generic.register' | translate }}
                        </div>
                    </div>
                    <div class="button">
                        <div class="inner-button reset"
                             [ngClass]="isResetButtonActive ? 'enabled' : null"
                             [disabled]="!isResetButtonActive"
                             (click)="reset()">
                            {{ 'generic.reset' | translate }}</div>
                    </div>
                </div>
                <div class="loading" *ngIf="isLoading === true">
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </div>
    </div>


    <div class="controls customize-container" *ngIf="!showPopup" title="{{ 'gamification.avatar.edit' | translate }}">
        <div class="control customize" (click)="openPopup()">
            <mat-icon svgIcon="accessorize"></mat-icon>
        </div>
    </div>
</div>