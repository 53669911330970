<div class="dialog-content-wrapper">
    <div mat-dialog-title>
        <h2>
            {{'account-management.delete_my_account' | translate}}
        </h2>
        <button class="close"
                (click)="dialogRef.close(false)"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="p-24 m-0">
        {{'account-management.delete_my_account_confirm_message' | translate}}
    </div>

    <div mat-dialog-actions>
        <ng-template #loading>
            <button mat-button disabled>
                <mat-spinner></mat-spinner>
            </button>
        </ng-template>

        <button mat-button  class="confirm" (click)="confirm()" *ngIf="isLoading === false else loading">
            {{ 'generic.confirm' | translate }}
        </button>

        <button mat-button [disabled]="isLoading" (click)="dialogRef.close(false)">
            {{ 'generic.cancel' | translate }}
        </button>
    </div>
</div>