<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">

  <table *ngIf="!empty" mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}" fxFlex="100">

    <ng-container matColumnDef="workgroupname">
      <th mat-header-cell *matHeaderCellDef> {{'generic.my_workgroups'}} </th>
      <td mat-cell *matCellDef="let item">
        <p class="text-truncate text-bold">{{item.workgroupname}}</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="learners">
      <th mat-header-cell *matHeaderCellDef> {{ 'groups-management.number_of_student' | translate }} </th>
      <td mat-cell *matCellDef="let item">
        <p class="text-truncate font-weight-600">{{getCount(item)}}&nbsp;{{ getTerms(getCount(item)) | translate}}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
            mat-row
            *matRowDef="let row;
            columns: displayedColumns;"
            [@animate]="{value:'*',params:{y:'100%'}}"
            (click)="getLink()"></tr>
  </table>

  <div *ngIf="empty" class="emptyResourceLabel">{{ 'generic.workgroup_empty_granule' | translate}}</div>
</div>
