<ng-template #loadingBlock>
    <mat-spinner></mat-spinner>
</ng-template>
<mat-toolbar class="filters" *ngIf="isReady else loadingBlock">
    <mat-toolbar-row>
        <mat-form-field *ngIf="isDisplayed('group')">
            <mat-select [formControl]="controls.group"  placeholder="{{ 'graph.select_groups' | translate }}">
                <mat-option></mat-option>
                <mat-option *ngFor="let group of groups" [value]="group.groupname">{{ group.groupname }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('workgroup')">
            <mat-select [formControl]="controls.workgroup" placeholder="{{ 'graph.select_workgroups' | translate }}">
                <mat-option></mat-option>
                <mat-option *ngFor="let workgroup of workgroups"
                            [value]="workgroup.workgroupname">{{ workgroup.workgroupname }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('learner')">
            <label>
                <input type="text"
                       [placeholder]="'graph.learner' | translate"
                       matInput
                       [formControl]="controls.learner"
                       [matAutocomplete]="autoLearner">
            </label>
            <mat-autocomplete #autoLearner="matAutocomplete" [displayWith]="displayLearnerFn">
                <mat-option *ngFor="let learner of filteredLearners | async" [value]="learner" >
                    {{learner.nickname}}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="controls.learner.hasError('required')">{{ 'graph.progression_required_learner' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('multiLearner')">
            <mat-select [formControl]="controls.multiLearner" placeholder="{{ 'graph.select_learners' | translate }}" multiple>
                <mat-option *ngFor="let learner of filteredByGroupLearners | async" [value]="learner.id">{{learner.nickname}}</mat-option>
            </mat-select>
            <mat-error *ngIf="controls.multiLearner.hasError('required')">{{ 'graph.progression_required_multiLearner' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('startDate')">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input name="startDate"
                   [formControl]="controls.startDate"
                   matInput
                   [matDatepicker]="startPicker"
                   placeholder="{{'generic.beginning_date' | translate}}"
            />
            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
            <mat-error *ngIf="controls.startDate.hasError('required')">{{ 'graph.progression_required_startDate' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('endDate')">
            <!--suppress HtmlFormInputWithoutLabel -->
            <input name="endDate"
                   [formControl]="controls.endDate"
                   matInput
                   [matDatepicker]="endPicker"
                   placeholder="{{'generic.deadline' | translate}}"
            />
            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
            <mat-error *ngIf="controls.endDate.hasError('required')">{{ 'graph.progression_required_endDate' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('concept')">
            <mat-select [formControl]="controls.concept" placeholder="{{ 'graph.progression_concept' | translate }}">
                <mat-option *ngIf="isEmptyAllowed('concept')" [value]="">{{ 'graph.progression_none' | translate }}</mat-option>
                <mat-option *ngFor="let concept of concepts" [value]="concept.get('name')">{{ concept.get('name') }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('educationalLevel')">
            <mat-select [formControl]="controls.educationalLevel" placeholder="{{ 'graph.progression_educational_level' | translate }}">
                <mat-option *ngIf="isEmptyAllowed('educationalLevel')" [value]="">{{ 'graph.progression_none' | translate }}</mat-option>
                <mat-option *ngFor="let educationalLevel of educationalLevels" [value]="educationalLevel.get('label')">{{ educationalLevel.get('label') }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('chapter')">
            <mat-select [formControl]="controls.chapter" placeholder="{{ 'graph.progression_exercise_type' | translate }}">
                <mat-option *ngIf="isEmptyAllowed('chapter')" [value]="">{{ 'graph.progression_none' | translate }}</mat-option>
                <mat-option *ngFor="let chapter of chapters" [value]="chapter.get('name')">{{ chapter.get('name') }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('multiLesson')">
            <mat-select [formControl]="controls.multiLesson" placeholder="{{ 'graph.progression_select_lessons' | translate }}" multiple>
                <mat-option *ngFor="let lesson of lessons" [value]="lesson.id">{{lesson.get('metadatas')?.title}}</mat-option>
            </mat-select>
            <mat-error *ngIf="controls.multiLesson.hasError('required')">{{ 'graph.progression_required_lessons' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="isDisplayed('multiSubLesson')">
            <mat-select [formControl]="controls.multiSubLesson" placeholder="{{ 'graph.progression_select_sub_lessons' | translate }}" multiple>
                <mat-option *ngFor="let lesson of subLessons" [value]="lesson.id">{{lesson.get('metadatas')?.title}}</mat-option>
            </mat-select>
            <mat-error *ngIf="controls.multiSubLesson.hasError('required')">{{ 'graph.progression_required_sub_lessons' | translate }}</mat-error>
        </mat-form-field>
        <button *ngIf="couldExpend()" (click)="toggle()" class="btn-collapse">
            <span>{{ (barIsExpended ? 'filters.button.collapse' : 'filters.button.expand') | translate }}</span>
            <mat-icon class="collapsable-arrow" [svgIcon]="barIsExpended ? 'keyboard_arrow_down' : 'keyboard_arrow_up'"></mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
