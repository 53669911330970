// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  resize: none !important;
}

.idea-form-dialog .mat-dialog-title {
  margin: 0;
}
.idea-form-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
.idea-form-dialog .save-button {
  background-color: #305792;
  color: #ffffff;
}
.idea-form-dialog .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.definition-icon {
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  line-height: 36px;
  font-weight: bold;
  font-size: 14px;
  color: #ff787d;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);
}
.definition-icon:hover, .definition-icon.selected {
  background: #ff787d;
  color: white;
}

.icons-container {
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/idea-edition-modal/idea-edition-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAYE;EACE,SAAA;AATJ;AAWE;EACE,UAAA;EACA,gBAAA;AATJ;AAWE;EACE,yBAAA;EACA,cAAA;AATJ;AAYE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAVJ;;AAcA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EAEA,mBAAA;EACA,iDAAA;AAZF;AAcE;EAEE,mBAAA;EACA,YAAA;AAbJ;;AAiBA;EACE,kBAAA;EACA,WAAA;AAdF","sourcesContent":["@import \"@fuse/scss/fuse\";\n\ntextarea {\n  resize: none !important;\n}\n\n.idea-form-dialog {\n  //@include media-breakpoint('xs') {\n  //  width: 100%;\n  //}\n  //\n  //@include media-breakpoint-up('xs') {\n  //  width: 400px;\n  //}\n  .mat-dialog-title {\n    margin: 0;\n  }\n  .mat-dialog-container {\n    padding: 0;\n    overflow: hidden;\n  }\n  .save-button {\n    background-color: #305792;\n    color: #ffffff;\n  }\n\n  .dialog-content-wrapper {\n    max-height: 85vh;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.definition-icon {\n  width: 34px;\n  height: 34px;\n  margin: 8px;\n  border-radius: 50%;\n  cursor: pointer;\n  text-align: center;\n  display: inline-block;\n  line-height: 36px;\n  font-weight: bold;\n  font-size: 14px;\n  color: #ff787d;\n\n  background: #ffffff;\n  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);\n\n  &:hover,\n  &.selected {\n    background: #ff787d;\n    color: white;\n  }\n}\n\n.icons-container {\n  text-align: center;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
