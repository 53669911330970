<svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg"
     [ngStyle]="{'opacity': disabledStar?'0.6':'1'}"
     xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect width="66" height="66" [attr.fill]="'url(#'+ 'pattern' + level + ')'"/>
    <text id="idText" [attr.x]="textPositionX" [attr.y]="textPositionY" font-family="Heebo"
          font-weight="bold" fill="#DF7135">{{score!=='locked'?score:''}}
    </text>
    <defs>
        <!--we use a convention using the current level to target the good picture-->
        <pattern [attr.id]="'pattern' + level" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use [attr.xlink:href]="'#' + 'image' + level" transform="scale(0.000893655)"/>
        </pattern>
        <!-- this[level + 'png'] mean that we call method or value who had the name of the string inside in the current component-->
        <image [attr.id]="'image' + level" width="1119" height="1119" [attr.xlink:href]="this[level + 'png']"/>
    </defs>
</svg>