<mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="onSelectedIndexChange($event)">
    <mat-tab  [label]="choice.value.label" *ngFor="let choice of choicesArray">
        <ng-template  mat-tab-label>
            <mat-icon svgIcon="{{choice.value.icon}}"></mat-icon>
            <span>{{choice.value.label | translate}}</span>
        </ng-template >
<!--        <ng-template  matTabContent>-->
<!--            <ng-content select="[data-data-matTabContent]"></ng-content>-->
<!--        </ng-template>-->
    </mat-tab>
</mat-tab-group>