<mat-card class="activities-card inner-scroll mat-elevation-z0" [ngClass]="!hideElement && start? ' hidden-answers ':''">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes
                *ngIf="(!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [autoRead]="false"
                (isReading)="ttsReadingEvent($event)" [wording]="wording" [wordingAudio]="wordingAudio"
                [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmcu' | translate):''"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <!-- Answer/image wrapper -->
        <div id="container-for-answers-available"
             class="available-answers"
             [ngClass]="answersCounting">
            <div *ngFor="let option of availableAnswers; let i = index"
                 class="answer button-image button-image--inactive"
                 [ngClass]="dynamicImgContainerClass(option, i)">
                <!-- TODO => Add alt text attribute to images (has to be set in backend beforehand) -->
                <img *ngIf="showContent(option)"
                     [src]="option.image"/>
            </div>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <ng-container *ngIf="!hideElement && start">
            <div class="available-answers">
                <button *ngFor="let option of availableAnswersForButton"
                        class="btn-option btn-select-item button-audio"
                        [class]="classToApplyOnAvailableAnswer(option)"
                        [class.selected]="isSelected(option)"
                        (click)="validate(option, true, false)"
                        [disabled]="isDisabledOptions">
                    <mat-icon [svgIcon]="'editor-play'"></mat-icon>
                    <mat-icon class="equalizer" [svgIcon]="option?.icon?option?.icon:'sound_on'"></mat-icon>
                </button>
            </div>

            <ng-container *ngIf="!isActivityEmbeddedDone">
                <ng-container *ngFor="let button of buttons">
                    <!-- TODO use button.disabled -->
                    <button *ngIf="button.display && start && !displayForSummary"
                            [disabled]="isDisabledOptions"
                            [ngClass]="classToApplyOnValidateButton()"
                            (click)="onAction(button)">
                        <span>{{ button.title | translate }}</span>
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
        <audio *ngIf="currentAudio()"
               controls
               [attr.src]="currentAudio()"
               autoplay="autoplay">
        </audio>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>