<div  fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="w-100-p h-160">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" class="h-100-p">
    <div fxLayoutAlign="center center" fxLayoutGap="8px">
      <label>{{'licenses.already_exist' | translate}}</label>
      <label>{{alreadyExist}}</label>
    </div>
    <div>/</div>
    <div fxLayoutAlign="center center" fxLayoutGap="8px">
      <label>{{'licenses.success_number' | translate}}</label>
      <label>{{successNumber}}</label>
    </div>
  </div>

  <div mat-dialog-actions>

    <button mat-raised-button
            (click)="close()"
            class="mat-accent">
      {{ 'generic.close' | translate }}
    </button>

  </div>
</div>

