<div fusePerfectScrollbar id="register">
    <div
        *ngIf="accountProvider.settings.selfSignup && !requestValidate && !blockAccount"
        fxLayout="column"
        fxLayoutAlign="center center"
        id="register-form-wrapper"
        tabindex="1"
    >
        <ng-container
            *ngIf="(!loadingDatas || (!displayField('level') && !displayField('region'))) && !loading else spinner">
            <div
                *fuseIfOnDom
                [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
                fxLayout="column"
                fxLayoutGap="30px"
                id="register-form"
            >
                <div *ngIf="settingsAuth.displayLoginLogo" class="logo">
                    <img
                        *ngIf="!brandLogoSvg"
                        class="logo-icon-png"
                        src="assets/{{ brand }}/images/logos/brand-square.png"
                        alt=""
                    />
                    <mat-icon
                        *ngIf="brandLogoSvg"
                        class="logo-icon-svg"
                        svgIcon="brand_square"
                    ></mat-icon>
                </div>
                <div *ngIf="userIsAdultOrMinor">
                    <div class="title">{{ "account-management.title" | translate }}</div>
                    <div *ngIf="settings.displaySubTitle" class="sub-title">
                        {{ "account-management.you-are" | translate }}
                        <span>{{ "generic.you-are.teacher" | translate }}</span>
                    </div>
                    <form
                        [formGroup]="registerForm"
                        fxLayout="row wrap"
                        name="registerForm"
                        novalidate>
                        <mat-form-field [floatLabel]="floatLabelControl" tabOrder
                                        *ngIf="displayField('label')"
                                        class="pseudo"
                                        fxFlex="50"
                                        fxFlex.lt-md="100">
                            <mat-label>{{ 'account-management.pseudo' | translate }}</mat-label><input
                                [required]="isRequired('label')"
                                formControlName="pseudo"
                                (blur)="onBlur('pseudo')"
                                matInput

                            />
                            <mat-error *ngIf="registerFormErrors.pseudo?.required || registerFormErrors.label?.required">
                                <span>{{ "generic.field.error.required" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors.pseudo?.invalid || registerFormErrors.label?.invalid">
                                <span>{{ "authentication.email_error_wrong" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors.pseudo?.errorTypo || registerFormErrors.label?.errorTypo">
                                <span>{{ "generic.error_typo_not_allowed" | translate }}</span>
                            </mat-error>
                            <mat-error *ngFor="let error of flat(
                                           registerFormErrors.pseudo?.customErrors,
                                           registerFormErrors.label?.customErrors
                                        )">
                                <span>{{ error | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('lastName')"
                            class="pseudo"
                            fxFlex="50"
                            fxFlex.lt-md="100"
                        >
                            <mat-label>{{ 'account-management.lastName' | translate }}</mat-label><input
                                [required]="isRequired('lastName')"
                                formControlName="lastName"
                                matInput

                            />
                            <mat-error *ngIf="registerFormErrors.lastName?.required">
                                <span>{{ "generic.field.error.required" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors.lastName?.errorTypo">
                                <span>{{ "generic.error_typo_not_allowed" | translate }}</span>
                            </mat-error>
                            <mat-error
                                *ngFor="let error of registerFormErrors.lastName?.customErrors"
                            >
                                <span>{{ error | translate }}</span>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('firstName')"
                            class="pseudo"
                            fxFlex="50"
                            fxFlex.lt-md="100"
                        >
                            <mat-label>{{ 'account-management.pseudoPart2' | translate }}</mat-label><input
                                [required]="isRequired('firstName')"
                                formControlName="firstName"
                                matInput

                            />
                            <mat-error *ngIf="registerFormErrors.firstName?.required">
                                <span>{{ "generic.field.error.required" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors.firstName?.errorTypo">
                                <span>{{ "generic.error_typo_not_allowed" | translate }}</span>
                            </mat-error>
                            <mat-error *ngFor="let error of registerFormErrors.firstName?.customErrors">
                                <span>{{ error | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('contact_email')"
                            fxFlex="50"
                            fxFlex.lt-md="100"
                        >
                            <mat-label>{{
                'account-management.email_contact_sentence' | translate
              }}</mat-label><input
                                [required]="isRequired('contact_email')"
                                formControlName="contact_email"
                                matInput
                                placeholder="{{
                'account-management.email_contact_sentence' | translate
              }}"
                            />
                            <mat-error *ngIf="registerFormErrors.contact_email.required">
                                <span>{{ "authentication.email.required" | translate }}</span>
                            </mat-error>
                            <mat-error *ngIf="!registerFormErrors.contact_email.required && registerFormErrors.contact_email.email">
                                <span>{{ "authentication.email.invalid" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('email')"
                            class="field-email"
                            fxFlex="50"
                            fxFlex.lt-md="100"
                        >
                            <mat-label>{{ 'account-management.email' | translate }}</mat-label><input
                                [required]="isRequired('email')"
                                id="register-mail"
                                formControlName="email"
                                matInput

                                (keyup)="onChangeEmail($event)"
                            />
                            <mat-error *ngIf="registerFormErrors.email.required">
                                <span>{{ "authentication.email.required" | translate }}</span>
                            </mat-error>
                            <mat-error
                                *ngIf="
                !registerFormErrors.email.required &&
                registerFormErrors.email.email
              "
                            >
                                <span>{{ "authentication.email.invalid" | translate }}</span>
                            </mat-error>
                            <mat-error
                                *ngFor="let error of registerFormErrors.email?.customErrors"
                            >
                                <span>{{ error | translate }}</span>
                            </mat-error>
                            <span
                                class="info-mail mat-error"
                                *ngIf="showInfoUseAcademicEmail"
                            >{{ "info.use.email.academic" | translate }}</span
                            >
                        </mat-form-field>

                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('you_are')"
                            class="you-are"
                            fxFlex="50"
                            fxFlex.lt-md="100"
                        >
                            <mat-label>{{ 'account-management.you-are' | translate }}</mat-label><mat-select
                                [required]="isRequired('you_are')"
                                formControlName="you_are"

                            >
                                <mat-option
                                    *ngFor="let item of profileService.getYouAreValues()"
                                    [value]="item"
                                >
                                    {{ "generic.you-are." + item | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="registerFormErrors.you_are">
                                <span>{{ "account-management.you-are.required" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <ng-container *ngIf="displayField('password')">
                            <mat-form-field [floatLabel]="floatLabelControl" tabOrder fxFlex="50" class="field-password">
                                <mat-label>{{ 'account-management.password' | translate }}</mat-label><input
                                    [type]="hide ? 'password' : 'text'"
                                    [required]="isRequired('password')"
                                    formControlName="password"
                                    matInput

                                    type="password"
                                />
                                <mat-icon
                                    (click)="hide = !hide"
                                    class="visibility-icon"
                                    matSuffix
                                    svgIcon="{{ hide ? 'visibility' : 'visibility_off' }}"
                                >
                                </mat-icon>
                                <mat-error *ngIf="registerFormErrors.password.required">
                                    <span>{{ "authentication.password.required" | translate }}</span>
                                </mat-error>
                                <mat-error
                                    *ngIf="registerFormErrors?.password?.passwordPolicyError"
                                >
                                    <span>{{ "authentication.password.passwordPolicyError" | translate }}</span>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field [floatLabel]="floatLabelControl" tabOrder
                                            *ngIf="displayField('passwordConfirm')"
                                            fxFlex="50"
                                            class="field-password-confirm">
                                <mat-label>{{ 'account-management.password-confirm' | translate }}</mat-label>
                                <input [type]="hideConfirm ? 'password' : 'text'"
                                       [required]="isRequired('passwordConfirm')"
                                       formControlName="passwordConfirm"
                                       matInput
                                       type="password"/>
                                <mat-icon (click)="hideConfirm = !hideConfirm"
                                          class="visibility-icon"
                                          matSuffix
                                          svgIcon="{{ hideConfirm ? 'visibility' : 'visibility_off' }}">
                                </mat-icon>
                                <mat-error *ngIf="registerFormErrors.passwordConfirm.required">
                                    <span>{{ "account-management.password-confirm.required" | translate }}</span>
                                </mat-error>
                                <mat-error *ngIf="registerFormErrors.passwordConfirm.passwordsNotMatch">
                                    <span>{{ "account-management.password-confirm.not-identical" | translate }}</span>
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                        <mat-form-field [floatLabel]="floatLabelControl" tabOrder *ngIf="displayField('institution')">
                            <mat-label>{{ 'account-management.institution' | translate }}</mat-label><input
                                [required]="isRequired('institution')"
                                formControlName="institution"
                                matInput

                            />
                        </mat-form-field>

                        <mat-form-field [floatLabel]="floatLabelControl" tabOrder class="find-us" *ngIf="displayField('find_us')">
                            <mat-label>{{
                'account-management.find-us'
                  | translate: translateRegisterParams
              }}</mat-label><mat-select
                                [required]="isRequired('find_us')"
                                formControlName="find_us"
                                placeholder="{{
                'account-management.find-us'
                  | translate: translateRegisterParams
              }}"
                            >
                                <mat-option
                                    *ngFor="let item of profileService.getFindUsValues()"
                                    [value]="item"
                                >
                                    {{ "account-management.find-us." + item | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="registerFormErrors.find_us">
                                <span>{{ "account-management.find-us.required" | translate: translateRegisterParams }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('level') && !isEducationalLevelSelectionHiddenIfUserIsTeacher"
                            class="level"
                            fxFlex="50"
                            fxFlex.lt-md="100">

                            <mat-label>{{'account-management.level' | translate: translateRegisterParams}}</mat-label><mat-select
                                [required]="isRequired('level')"
                                formControlName="level"
                                >
                                <mat-option
                                    *ngFor="let item of userInformation.level_field; let i = index"
                                    [value]="item.id">

                                    {{ item.attributes.label | translate }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="registerFormErrors.level">
                                <span>{{ "account-management.level.required" | translate: translateRegisterParams }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field
                            tabOrder
                            *ngIf="displayField('levelInput')"
                            [ngClass]="levelInputClass()"
                            fxFlex="50"
                            fxFlex.lt-md="100">
                            <mat-label>{{ 'account-management.level-input-' + registerForm?.get('you_are')?.value  | translate }}</mat-label><input
                                type="number"
                                [required]="isRequired('levelInput')"
                                formControlName="levelInput"
                                matInput
                                />
                            <mat-error *ngIf="registerFormErrors?.levelInput">
                                <span>{{ "account-management.level-input.required" | translate: translateRegisterParams }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field [floatLabel]="floatLabelControl" tabOrder
                                        class="region"
                                        *ngIf="displayField('region')"
                                        fxFlex="50"
                                        fxFlex.lt-md="100">
                            <mat-label>{{'account-management.region' | translate: translateRegisterParams}}</mat-label><input type="text"

                                   aria-label="Number"
                                   matInput
                                   formControlName="region"
                                   [matAutocomplete]="auto"
                                   (blur)="inputControl($event)">

                            <mat-autocomplete #auto="matAutocomplete"
                                              [displayWith]="displayFn.bind(this)"
                                              (optionSelected)='isSelectedRegion($event.option.value)'>
                                <mat-option
                                    *ngFor="let item of filteredOptions | async; let i = index"
                                    [value]="item.id">
                                    {{ item.attributes.label | translate }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="registerFormErrors.region">
                                <span>{{ "account-management.region.required" | translate: translateRegisterParams }}</span>
                            </mat-error>
                        </mat-form-field>

                        <div tabOrder
                             [updateTabOrder]="updateDirective"
                             class="checkboxes"
                             fxFlex="100"
                             fxLayout="column"
                             fxLayoutAlign="start"
                        >
                            <mat-checkbox
                                *ngIf="!hideNewsletter && displayField('newsletter')"
                                aria-label="Je souhaite recevoir les actualités"
                                formControlName="newsletter"
                                name="newsletter"
                                (keydown.enter)="onCheckboxEnter('newsletter',$event)"
                            >
                                {{
                                    "account-management.newsletter"
                                        | translate: translateRegisterParams
                                }}
                            </mat-checkbox>
                            <mat-checkbox
                                aria-label="I read and accept"
                                formControlName="terms"
                                name="terms"
                                (keydown.enter)="onCheckboxEnter('terms',$event)"
                                required
                            >
                                {{ "account-management.accept" | translate }}
                                &nbsp;<a
                                [routerLink]="'/' + ('account-management.cgupath' | translate: translateRegisterParams)"
                            >{{ "account-management.cgu" | translate }}</a>
                            </mat-checkbox>
                            <mat-error *ngIf="termsAdultNotAccepted">
                                <span>{{ "account-management.terms.required" | translate }}</span>
                            </mat-error>

                            <mat-checkbox *ngIf="!hideTermsMinor && displayField('termsMinor')"
                                          [aria-label]="'account-management.accept-minor' | translate"
                                          formControlName="termsMinor"
                                          name="termsMinor"
                                          (keydown.enter)="onCheckboxEnter('termsMinor',$event)"
                                          required>
                                {{ "account-management.accept-minor" | translate }}&nbsp;*
                            </mat-checkbox>
                            <mat-error *ngIf="termsMinorNotAccepted">
                                <span>{{ "account-management.terms-minor.required" | translate }}</span>
                            </mat-error>

                            <!-- The value of the form control will be the verification token -->
                            <ng-hcaptcha
                                *ngIf="captcha === 'hcaptcha'"
                                formControlName="captcha"
                                name="captcha"
                                required
                            ></ng-hcaptcha>
                            <mat-error *ngIf="captchaOk === false">
                                <span>{{ "generic.captchaNotPassed" | translate }}</span>
                            </mat-error>

                            <button
                                (click)="newRegister()"
                                [disabled]="registering || this.registerForm.invalid || captchaOk===false"
                                class="submit-button"
                                color="accent"
                                mat-raised-button
                                appSpinner
                                [loading]="registering"
                            >
                                {{ "account-management.create" | translate }}
                            </button>
                        </div>
                        <div class="register" tabOrder fxLayout="column" fxLayoutAlign="center center">
          <span class="text"
          >{{ "account-management.account_already" | translate }}
          </span>
                            <a [routerLink]="'/login'" class="link">{{
                                    "authentication.btn-login" | translate
                                }}</a>
                        </div>
                    </form>
                </div>
                <!--this bloc of html userIsAdultOrMinor have no directive taborder for moment
                 I'don't know if it's use if needed in future taborder we will have to move it inside the
                <form></form>-->
                <div
                    *ngIf="!userIsAdultOrMinor"
                    class="bloc-minor-adult"
                    fxFlexAlign="center center"
                    fxLayout="column"
                    fxLayoutGap="30px"
                >
                    <div fxFlexAlign="center" fxLayout="column">
                        <button
                            (click)="selectMinorOrAdult('minor')"
                            class="submit-button w-100-p"
                            color="accent"
                            mat-raised-button
                        >
                            {{ "account-management.user_minor" | translate }}
                        </button>
                    </div>
                    <div fxFlexAlign="center" fxLayout="column">
                        <button
                            (click)="selectMinorOrAdult('adult')"
                            class="submit-button w-100-p"
                            color="accent"
                            mat-raised-button
                        >
                            {{ "account-management.user_adult" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div
            #widgetFC
            *ngIf="captcha === 'friendlyCaptcha'"
            class="frc-captcha"
            data-callback="myFriendlyCapthaCallback"
            [attr.data-sitekey]="friendlyCaptchaSiteKey"
            style="position: absolute !important; right: 0; bottom: 0"
        ></div>
    </div>

    <register-redirect *ngIf="!settings.selfSignup && hasRedirectUrl()"
                       [redirect]="settings.redirectSignup"></register-redirect>
    <fuse-mail-confirm *ngIf="requestValidate" [data]="formData"></fuse-mail-confirm>
    <blocked-account *ngIf="blockAccount"></blocked-account>
</div>

<ng-template #spinner>
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>