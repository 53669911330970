<mat-card class="activities-card mat-elevation-z0">
    <mat-card-content #iframeViewport fxLayout="column" fxLayoutAlign="space-around center">
        <button *ngIf="!isActivityEmbedded && displayReturnButton()" class="return-to-lessons"
                (click)="navigateToLessons()" aria-label="previous">
            <mat-icon svgIcon="exit"></mat-icon>
            <span>{{'activities.navigate_to_lessons' |translate }}</span></button>
        <!--we use a container because iframe must not exist without having the good size ref he must take-->
        <ng-container #iframeView>
            <!--style is put here because without this that can't work-->
            <iframe *ngIf="iframeUrl && sizeinitDone" (load)="onIframeLoad()" style="width:100%; height: 100%;"
                    allow="microphone; fullscreen"
                    [src]="iframeUrl" allowfullscreen="true" id="taskIframe" [title]="referenceActivityGranule.title"></iframe>
        </ng-container>


        <div *ngIf="!isActivityEmbedded && displayUserActionButton()" fxFlex="none" fxLayout="column" fxLayoutAlign="center none"
             class="pl-8 mt-24 mb-24 mr-40">
            <button  class="next" (click)="launchNextActivity()" aria-label="next">
                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            </button>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <div *ngIf="isPercentileDisplayAllowed" class="gradePercentil">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngIf="!isActivityEmbedded">
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"
                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>