<div fxflex="" fxlayout="column" id="entity" class="page-layout simple p-16">

    <!-- CONTENT -->
    <div class="content mat-white-bg" fusePerfectScrollbar>

        <table mat-table
               #table
               [dataSource]="usersInTable"
               *fuseIfOnDom matSort
               [@animateStagger]="{value:'50'}">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="checkbox">
                <th *matHeaderCellDef mat-header-cell>
                    <mat-checkbox (change)="updateCheck($event)" [(ngModel)]="selectAll"></mat-checkbox>
                </th>
                <td *matCellDef="let row" mat-cell>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="checkboxes[row.id]">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'generic.name' | translate }}
                </th>
                <td mat-cell *matCellDef="let row"
                    [class.user-with-none-assigned-class-or-group]="!filterBy('class', row).length && !filterBy('group', row).length">
                    {{row.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'generic.email' | translate }}
                </th>
                <td mat-cell *matCellDef="let row" fxHide.xs>
                    {{row.mail}}
                </td>
            </ng-container>

            <ng-container matColumnDef="classes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'generic.label_group' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngFor="let class of filterBy('class', row); index as i">{{class}}<span
                        *ngIf="i < filterBy('class', row).length - 1">,</span></ng-container>
                    <button mat-raised-button class="btn-assign-only" [disabled]="!canAssignUser(row)"
                            *ngIf="!filterBy('class', row).length && !filterBy('group', row).length"
                            (click)="edit(row, true)">
                        <span>{{'generic.assign'|translate}}</span>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="workgroups">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'generic.group' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngFor="let group of filterBy('group', row); index as i">{{group}}<span
                        *ngIf="i < filterBy('group', row).length - 1">,</span></ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="types">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'groups-management.institution_rights' | translate }}
                </th>
                <td class="icon-custom" mat-cell *matCellDef="let row">
                    <mat-icon [class.selected]="isUserHasRole(row, 'educator')" svgIcon="educ-role"></mat-icon>
                    <mat-icon [class.selected]="isUserHasRole(row, 'manager')" svgIcon="admin-role"></mat-icon>
                </td>
            </ng-container>

            <!-- Buttons Column -->
            <ng-container matColumnDef="buttons">
                <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_more_menu' | translate}}</span></th>
                <td mat-cell *matCellDef="let row">
                    <ng-container>
                        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                (click)="$event.stopPropagation();">
                            <mat-icon svgIcon="submenu"></mat-icon>
                        </button>
                        <mat-menu #moreMenu="matMenu">
                            <div *ngIf="displayButton('edit')">
                                <button mat-menu-item aria-label="edit"
                                        *ngIf="canEditUser(row)"
                                        (click)="edit(row)">
                                    <mat-icon svgIcon="edit"></mat-icon>
                                    <span>{{ 'assignment.edit' | translate }}</span>
                                </button>
                            </div>
                            <div *ngIf="displayButton('detach')">
                                <button mat-menu-item aria-label="detach"
                                        *ngIf="canEditUser(row)"
                                        (click)="detachUserFromInstitution(row)">
                                    <span>{{ 'generic.detach' | translate }}</span>
                                </button>
                            </div>
                        </mat-menu>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{'selected': false}"
                matRipple
                [@animate]="{value:'*',params:{y:'100%'}}">
            </tr>
        </table>
        <div mat-dialog-actions>
            <button mat-raised-button class="add-user add mat-button-base" (click)="createUser()">
                <span>{{'generic.add-user' | translate}}</span>
            </button>
        </div>
        <mat-paginator
            [length]="countEntities"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageRangeOptions"
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>



