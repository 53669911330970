<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a [attr.data-e2e]="'nav-item-' + item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && !item.function"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'mat-accent-bg']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [queryParams]="item.queryParams || {}"
       [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a [attr.data-e2e]="'nav-item-' + item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span [attr.data-e2e]="'nav-item-' + item.id" class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
          (click)="item.function($event, item)" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a [attr.data-e2e]="'nav-item-' + item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && !item.externalUrl && item.function"
       (click)="item.function($event, item)"
       [routerLink]="[item.url]" [routerLinkActive]="['active', 'mat-accent-bg']"
       [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
       [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a [attr.data-e2e]="'nav-item-' + item.id" class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
       (click)="item.function($event, item)"
       [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'" matRipple>
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <ng-template #itemContent>
        <mat-icon class="nav-link-icon svg-icon" *ngIf="item.icon && !item.hasOwnProperty('displayIcon') || (item.hasOwnProperty('displayIcon') && item['displayIcon'])" [svgIcon]="item.icon"></mat-icon>
        <span class="nav-link-title" [translate]="item.translate"></span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
              [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{item.badge.title}}
        </span>
    </ng-template>

</ng-container>