<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">
  <table
          mat-table
          #table
          [dataSource]="dataSource"
          [@animateStagger]="{value:'50'}"
          fxFlex="100"
          *ngIf="!empty">

    <!-- Name form models Column -->
    <ng-container matColumnDef="nameFormModels">
      <th mat-header-cell *matHeaderCellDef>{{ 'dashboard.name_form_model' | translate }}</th>
      <td mat-cell *matCellDef="let entity">
        <span class="text-truncate text-bold">{{ entity.embeddings.metadatas.attributes.title }}</span>
      </td>
    </ng-container>

    <!-- Name author form models Column -->
    <ng-container matColumnDef="author">
      <th mat-header-cell *matHeaderCellDef>{{ 'generic.author' | translate }}</th>
      <td mat-cell *matCellDef="let entity">
        <span class="text-truncate">{{ entity.get('owner-name') }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
        class="entity"
        matRipple
        [@animate]="{value:'*',params:{y:'100%'}}"
        (click)="getLinkFormModels(entity)">
    </tr>
  </table>

  <div *ngIf="empty" class="emptyResourceLabel">{{ 'dashboard.form_models_empty' | translate }}</div>
</div>
