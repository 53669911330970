<!--appMoveFocusToNextAllowedElement must be active only on citizen code because html structure is not same as other instance-->
<div class="lessons-tab"
     appDynamicTabIndex
     appMoveFocusToNextAllowedElement
     [isEnabled]="getIsRootFilter()"
     tabindex="0"
     [reload]="dataCards">
    <ng-template #addLessonCard>
        <ng-container *ngIf="!showAddLessonButton && showAddLessonButtonCard">
            <app-add-lesson-button-morphable (buttonClick)="onAddLessonButtonClick($event)"
                                             [type]="addCardButtonType"></app-add-lesson-button-morphable>
        </ng-container>
    </ng-template>

    <!-- div class="page-layout simple " fxFlex--><!-- TODO remettre ce wrapper -->
    <div *ngIf="showSpinner">
        <mat-spinner [ngClass]="isOneLessonIsAlreadyLaunch?'spinner-for-launch-lesson':'default'"></mat-spinner>
    </div>

    <app-banner-info class="lessons-tab__banner-info exclude-elem-to-order-directive"
                     [rolesCanShowBanner]="rolesCanShowBannerInfo"
                     [situation]="usageOfLessonsDisplay">
    </app-banner-info>
    <mat-toolbar appMoveFocusToNextAllowedElement
                 class="lessons-tab__toolbar filters exclude-elem-to-order-directive"
                 tabindex="0"
                 *ngIf="!showSpinner && fieldsToDisplay.length > 0 && (getIsRootFilter()===false || (getIsRootFilter() && !isAssignmentView()))">
        <mat-toolbar-row
            appMoveFocusToNextAllowedElement [previousTarget]="'root-filter-first-answer'"
            *ngIf="getIsRootFilter() === false || (getIsRootFilter() && selectedCollection === 2)"
            class="lessons-tab__toolbar-row" tabindex="1">
            <app-search-filters class="lessons-tab__search-filters"
                                [fields]="fieldsToDisplay"
                                [initialsValues]="urlFilters"
                                [customLists]="{methods:methods}"
                                [countEntities]="countEntities"
                                [customFieldName]="customFieldName"
                                [orderFields]="true"
                                [displayedFiltersIcons]="displayedFiltersIcons"
                                [origin]="'lesson'"
                                [resetList]="resetList"
                                [filtersToClearOnFormChange]="filtersToClearOnSearchFormChange"
                                (launchSearch)="launchSearch($event)">
            </app-search-filters>

            <!--<app-filter-by-steps-editor (filterToApply)="launchSearch($event)"
                                        [countEntities]="countEntities"
            ></app-filter-by-steps-editor>-->
            <!--  <button (click)="openFilter()">FILTER</button>
              <app-filters-by-steps-player
                  (filterToApply)="launchSearch($event)"
                  [countEntities]="countEntities"
                  [steps]="[{field: 'concepts', endpoint: 'concepts', setFilter: null, includeFilter: null, nameIncludeFilter: null, filter: 'concepts', title: 'quel période?'},
                          {
                              field: 'grade',
                              endpoint: 'educational_level',
                              setFilter: null,
                              includeFilter: 'concepts',
                              nameIncludeFilter: 'levels',
                              filter: 'level',
                              title: 'quel niveau?'
                          },
                          {
                              field: 'chapters',
                              endpoint: 'chapters',
                              setFilter: ['grade', 'concepts'],
                              includeFilter: null,
                              nameIncludeFilter: null,
                              filter: 'chapters',
                              title: 'quel matiere?'
                          },
                          {
                              field: 'sequences', endpoint: 'lesson_granule_search',
                              setFilter: ['concepts', 'chapters', 'format:lesson'],
                              includeFilter: null,
                              nameIncludeFilter: null,
                              filter: 'format',
                              title: 'quel matiere?'
                          }]"
              ></app-filters-by-steps-player>-->
        </mat-toolbar-row>
    </mat-toolbar>

    <div *ngIf="isLearner && (getIsRootFilter())"
         class="lessons-tab__header">
        <h1 class="lessons-tab__header-title">{{ ('lesson.model_list_title_' + selectedOption + currentChapters()) | translate }}</h1>
        <p class="lessons-tab__header-description">{{ ('lesson.model_list_description_' + selectedOption + currentChapters()) | translate }}</p>
    </div>

    <div *ngIf="displayCreateLessonHelper && showAddLessonButton" class="mat-body create-lesson-helper">
        {{ 'lessons.create_lesson_helper'|translate }}
    </div>

    <div *ngIf="displayCommunityLessonHelper" class="mat-body create-lesson-helper">
        {{ 'lessons.community_lesson_helper'|translate }}
    </div>

    <div *ngIf="showLoader">
        <mat-spinner></mat-spinner>
    </div>

    <div class="lessons-tab__cards cards content touch-scroll"
         [ngClass]="{
            'lessons-tab__cards--learner': isLearner,
            'lessons-tab__cards--junior': this.selectedCollection === 0,
            'lessons-tab__cards--college': this.selectedCollection === 1,
            'lessons-tab__cards--python': this.selectedCollection === 2,
         }"
         [ngStyle]="{'visibility':showLoader?'hidden':'visible'}"
         #scrollContainer>

        <ng-container *ngIf="addCardButtonPosition === 'first'">
            <ng-container *ngTemplateOutlet="addLessonCard"></ng-container>
        </ng-container>

        <ng-container *ngFor="let dataCard of dataCards">
            <app-card class="lessons-tab__card"
                      [ngClass]="getCardLayout()"
                      [dataCard]="dataCard">
            </app-card>
        </ng-container>

        <ng-container *ngIf="addCardButtonPosition === 'last'">
            <ng-container *ngTemplateOutlet="addLessonCard"></ng-container>
        </ng-container>


    </div>

    <mat-paginator [length]="countEntities" [pageIndex]="pageIndex" [pageSizeOptions]="pageRangeOptions"
                   (page)="onPaginateChange($event, type)">
    </mat-paginator>


    <div *ngIf="showAddLessonButton && !showAddLessonButtonCard" class="addBtnBottomRight" fx-layout="row"
         fx-flex fx-layout-align="end">
        <button [title]="'generic.create_lesson_title' | translate"
                (click)="lessonsService.launchEditor('', 'new', 'lesson')" class="add-file-button">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </div>
</div>