<ng-container *ngIf="configuration.getToolbarUseCtzVersion()===false">
    <mat-toolbar class="mat-elevation-z1">

        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

                <button
                    *ngIf="!hiddenNavbar && !rightNavbar"
                    fxHide.gt-md
                    mat-icon-button
                    class="navbar-toggle-button"
                    data-e2e="nav-menu"
                    (click)="toggleSidebarOpen('navbar')"
                    [attr.aria-label]="'generic.menu' | translate"
                    [matTooltip]="'generic.menu' | translate">
                    <mat-icon
                        class="secondary-text"
                        svgIcon="menu">
                    </mat-icon>
                </button>

                <app-button-help *ngIf="showHelp"></app-button-help>

                <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

                <div *ngIf="hiddenNavbar && horizontalNavbar"
                     class="toolbar__home-pseudo-button"
                     role="button"
                     tabindex="0"
                     (click)="navigateToDefaultRoute()"
                     (keyup.enter)="navigateToDefaultRoute()"
                     (keyup.space)="navigateToDefaultRoute()">
                    <ng-container *ngIf="brandLogoSvg; else homeIconPngTemplate">
                        <mat-icon class="toolbar__home-icon-svg"
                                  svgIcon="brand_square">
                        </mat-icon>
                    </ng-container>
                    <ng-template #homeIconPngTemplate>
                        <img class="toolbar__home-icon-png"
                             src="assets/{{ brand }}/images/logos/brand-square.png"
                             aria-hidden="true">
                    </ng-template>
                    <mat-icon class="toolbar__home-chevron"
                              svgIcon="chevron-left-rounded-extra-light">
                    </mat-icon>
                    <div class="toolbar__home-wording">{{ 'navigation.home' | translate }}</div>
                </div>

            </div>

            <div class="toolbarMessage" *ngIf="showTechnicalProblemMessage && !authenticationService.isGAR()">
                {{ 'generic.technical_problem' | translate }}
            </div>

            <div class="toolbarMessage" *ngIf="showTechnicalProblemMessageGar && authenticationService.isGAR()">
                {{ 'generic.technical_problem_gar' | translate }}
            </div>

            <div class="toolbarMessage"
                 *ngIf="teacherWithAlmostTwoAssignment() && !showTechnicalProblemMessage && !showTechnicalProblemMessageGar">
                {{ 'generic.header_redirect_msg' | translate }}
                <a target="_blank"
                   [href]="'generic.header_redirect_url' | translate">{{ 'generic.header_redirect_url_msg' | translate }}</a>
            </div>

            <div class="toolbarMessage" *ngIf="showAsks()">
                <span>{{ 'generic.header_redirect_msg' | translate }}</span>
                <a target="_blank"
                   [href]="'generic.header_redirect_url' | translate">{{ 'generic.header_redirect_url_msg' | translate }}</a>
                <span>{{ 'generic.header_redirect_msg_part2' | translate }}</span>
            </div>

            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

                <button
                    *ngIf="settings.showWebAppBtn && authenticationService.isAuthenticated === true && !authenticationService.isGAR()"
                    (click)="goToSite('webapp.url')" mat-raised-button role="button" class="btn-webapp">
                    <img class="logo-icon-png" src="assets/{{ brand }}/images/webapp.png" alt="">
                    <span>{{ 'navigation.webapp.label' | translate }}</span>
                </button>


                <button *ngIf="shouldDisplayGoToLoginPage()" mat-button (click)="goToLoginPage()"
                        class="btn__goToLoginPage">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <span>{{ 'authentication.btn-login' | translate }}</span>
                    </div>
                </button>

                <ng-container *ngIf="activeLanguage$ | async as activeLanguage">
                    <button *ngIf="showTranslateMenu && !this.hideLangueMenu" mat-button fxHide fxShow.gt-xs
                            class="language-button"
                            [matMenuTriggerFor]="languageMenu">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <mat-icon class="mr-8" *ngIf="!showFlagImg" svgIcon="earth"></mat-icon>
                            <img class="flag mr-8" *ngIf="showFlagImg && activeLanguage?.flag"
                                 src="assets/{{ brand }}/images/flags/{{ activeLanguage.flag }}.png" alt="">
                            <span *ngIf="activeLanguage?.id" class="iso text-uppercase">{{ activeLanguage.id }}</span>
                        </div>
                    </button>
                </ng-container>

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item *ngFor="let lang of languages" (click)="onLanguageClick(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" *ngIf="showFlagImg"
                             src="assets/{{ brand }}/images/flags/{{lang.flag}}.png" alt="">
                        <span class="iso">{{ lang.title }}</span>
                    </span>
                    </button>

                </mat-menu>

                <div class="toolbar-separator"></div>
                <button
                    *ngIf="authenticationService.isAuthenticated"
                    mat-button
                    class="user-button"
                    [matMenuTriggerFor]="userMenu"
                    [attr.aria-label]="'generic.user_menu' | translate"
                    [matTooltip]="'generic.user_menu' | translate">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div class="account-info">
                            <img class="avatar" *ngIf="userInformation && userInformation.get('picture')"
                                 [src]="userInformation?.get('picture')" alt="">
                            <div class="account-labels mr-12">
                            <span _ngcontent-c3="" class="username" fxhide="" fxshow.gt-sm="" style="display: block;">
                                {{ username }}</span>
                                <span class="role"
                                      *ngIf="isRoleDisplayable()">{{ 'toolbar.role_' + getRole() | translate }}</span>
                            </div>
                        </div>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item [routerLink]="'/profile/show-profile'">
                        <mat-icon svgIcon="user"></mat-icon>
                        <span>{{ 'account-management.profile.title' | translate }}</span>
                    </button>

                    <button *ngIf="showContact()" mat-menu-item (click)="navigateToContact()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showFeedBack()" mat-menu-item (click)="navigateToFeedback()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showFeedBackGar()" mat-menu-item (click)="navigateToFeedbackGar()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showLicensing" mat-menu-item [routerLink]="'/profile/licensing'">
                        <mat-icon svgIcon="bookmark"></mat-icon>
                        <span>{{ 'account-management.profile.licensing.title' | translate }}</span>
                    </button>

                    <button mat-menu-item id="logout" class="" (click)="logOut()"
                            *ngIf="!authenticationService.isGAR()">
                        <mat-icon svgIcon="log_out"></mat-icon>
                        <span>{{ 'account-management.logout' | translate }}</span>
                    </button>

                </mat-menu>

                <div *ngIf="authenticationService.isAuthenticated" class="toolbar-separator" fxHide fxShow.gt-xs></div>

                <button *ngIf="authenticationService.isAuthenticated && showNotification" mat-icon-button
                        class="quick-panel-toggle-button"
                        (click)="toggleSidebarOpen('quickPanel')"
                        aria-label="Toggle quick panel">
                    <mat-icon class="icon">notifications</mat-icon>
                    <span class="spacer"></span>
                    <span class="badge" *ngIf="notificationsCount > 0">{{ notificationsCount }}</span>
                </button>

                <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

                <button mat-icon-button class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

                <div class="toolbar-separator" *ngIf="settingsTralereToolbar.displayed"></div>
                <!-- TRALALERE BAR -->
                <app-tralalere-bar *ngIf="settingsTralereToolbar.displayed"></app-tralalere-bar>
                <!-- / TRALALERE BAR -->

            </div>

        </div>

    </mat-toolbar>

</ng-container>

<!--code from citizen code extract after fusion-->
<ng-container *ngIf="configuration.getToolbarUseCtzVersion()">
    <mat-toolbar class="mat-elevation-z1 toolbar">

        <div class="toolbar__container"
             fxFlex
             fxFill
             fxLayout="row"
             fxLayoutAlign="start center">

            <div class="toolbar__left-content"
                 fxFlex="1 0 auto"
                 fxLayout="row"
                 fxLayoutAlign="start center">

                <button *ngIf="!hiddenNavbar && !rightNavbar"
                        class="navbar-toggle-button toolbar__menu-button"
                        mat-icon-button
                        fxHide.gt-md
                        data-e2e="nav-menu"
                        (click)="toggleSidebarOpen('navbar')"
                        [attr.aria-label]="'generic.menu' | translate"
                        [matTooltip]="'generic.menu' | translate">
                    <mat-icon class="secondary-text"
                              svgIcon="menu">
                    </mat-icon>
                </button>

                <div *ngIf="!hiddenNavbar && !rightNavbar"
                     class="toolbar-separator toolbar__separator"
                     fxHide.gt-md>
                </div>

                <div *ngIf="hiddenNavbar && horizontalNavbar"
                     class="toolbar__home-pseudo-button"
                     role="button"
                     tabindex="0"
                     (click)="navigateToDefaultRoute()"
                     (keyup.enter)="navigateToDefaultRoute()"
                     (keyup.space)="navigateToDefaultRoute()">
                    <ng-container *ngIf="brandLogoSvg; else homeIconPngTemplate">
                        <mat-icon class="toolbar__home-icon-svg"
                                  svgIcon="brand_square">
                        </mat-icon>
                    </ng-container>
                    <ng-template #homeIconPngTemplate>
                        <img class="toolbar__home-icon-png"
                             src="assets/{{ brand }}/images/logos/brand-square.png"
                             aria-hidden="true">
                    </ng-template>
                    <mat-icon class="toolbar__home-chevron"
                              svgIcon="chevron-left-rounded-extra-light">
                    </mat-icon>
                    <div class="toolbar__home-wording">{{ 'navigation.home' | translate }}</div>
                </div>

            </div>

            <div class="toolbarMessage" *ngIf="showTechnicalProblemMessage">
                {{ 'generic.technical_problem' | translate }}
            </div>

            <div class="toolbarMessage" *ngIf="teacherWithAlmostTwoAssignment()">
                {{ 'generic.header_redirect_msg' | translate }}
                <a target="_blank"
                   [href]="'generic.header_redirect_url' | translate">{{ 'generic.header_redirect_url_msg' | translate }}</a>
            </div>

            <div class="toolbarMessage" *ngIf="showAsks()">
                <span>{{ 'generic.header_redirect_msg' | translate }}</span>
                <a target="_blank"
                   [href]="'generic.header_redirect_url' | translate">{{ 'generic.header_redirect_url_msg' | translate }}</a>
                <span>{{ 'generic.header_redirect_msg_part2' | translate }}</span>
            </div>

            <div class="toolbar__right-content"
                 fxFlex="0 1 auto"
                 fxLayout="row"
                 fxLayoutAlign="start center">

                <app-button-help *ngIf="showHelp"
                                 class="toolbar__help-button">
                </app-button-help>

                <button
                    *ngIf="settings.showWebAppBtn && authenticationService.isAuthenticated === true && !authenticationService.isGAR()"
                    (click)="goToSite('webapp.url')" mat-raised-button role="button" class="btn-webapp">
                    <img class="logo-icon-png" src="assets/{{ brand }}/images/webapp.png" alt="">
                    <span>{{ 'navigation.webapp.label' | translate }}</span>
                </button>


                <button *ngIf="shouldDisplayGoToLoginPage()"
                        class="btn__goToLoginPage toolbar__login-button"
                        mat-button
                        (click)="goToLoginPage()">
                    <div fxLayout="row"
                         fxLayoutAlign="center center">
                        <span>{{ 'authentication.btn-login' | translate }}</span>
                    </div>
                </button>

                <button *ngIf="showTranslateMenu && !this.hideLangueMenu"
                        class="language-button toolbar__language-button"
                        aria-label="langue"
                        mat-button
                        fxHide
                        fxShow.gt-xs
                        [matMenuTriggerFor]="languageMenu">
                    <div fxLayout="row"
                         aria-label="langue"
                         fxLayoutAlign="center center">
                        <mat-icon *ngIf="!showFlagImg"
                                  class="mr-8"
                                  svgIcon="earth">
                        </mat-icon>
                        <img *ngIf="showFlagImg && selectedLanguage?.flag"
                             class="flag mr-8"
                             src="assets/{{ brand }}/images/flags/{{ selectedLanguage.flag }}.png"
                             alt="">
                        <span *ngIf="selectedLanguage?.id"
                              class="iso text-uppercase">
                        {{ selectedLanguage.id }}
                    </span>
                    </div>
                </button>

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" *ngIf="showFlagImg"
                             src="assets/{{ brand }}/images/flags/{{lang.flag}}.png" alt="">
                        <span class="iso">{{ lang.title }}</span>
                    </span>
                    </button>

                </mat-menu>

                <div class="toolbar-separator toolbar__separator"></div>

                <button *ngIf="authenticationService.isAuthenticated"
                        class="user-button toolbar__user-button"
                        mat-button
                        [matMenuTriggerFor]="userMenu"
                        [attr.aria-label]="'generic.user_menu' | translate"
                        [matTooltip]="'generic.user_menu' | translate">
                    <div class="toolbar__user-button-container"
                         fxLayout="row"
                         fxLayoutAlign="center center">
                        <div class="account-info toolbar__user-data">
                            <img *ngIf="userInformation && userInformation.get('picture')"
                                 class="avatar toolbar__user-avatar"
                                 [src]="userInformation?.get('picture')"
                                 alt="">
                            <div class="account-labels mr-12 toolbar__user-labels">
                            <span class="username toolbar__user-name"
                                  fxhide=""
                                  fxshow.gt-sm=""
                                  style="display: block;"
                                  _ngcontent-c3="">
                                {{ username }}
                            </span>
                                <span *ngIf="isRoleDisplayable()"
                                      class="role toolbar__user-role">
                                {{ 'toolbar.role_' + getRole() | translate }}
                            </span>
                            </div>
                        </div>
                        <mat-icon class="s-16 toolbar__user-button-icon"
                                  fxHide.xs
                                  svgIcon="user-menu">
                        </mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item [routerLink]="'/profile/show-profile'">
                        <mat-icon svgIcon="user"></mat-icon>
                        <span>{{ 'account-management.profile.title' | translate }}</span>
                    </button>

                    <button *ngIf="showContact()" mat-menu-item (click)="navigateToContact()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showFeedBack()" mat-menu-item (click)="navigateToFeedback()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showFeedBackGar()" mat-menu-item (click)="navigateToFeedbackGar()">
                        <mat-icon svgIcon="help"></mat-icon>
                        <span>{{ 'navigation.contact' | translate }}</span>
                    </button>

                    <button *ngIf="showLicensing" mat-menu-item [routerLink]="'/profile/licensing'">
                        <mat-icon svgIcon="bookmark"></mat-icon>
                        <span>{{ 'account-management.profile.licensing.title' | translate }}</span>
                    </button>

                    <button mat-menu-item id="logout" class="" (click)="logOut()"
                            *ngIf="!authenticationService.isGAR()">
                        <mat-icon svgIcon="log_out"></mat-icon>
                        <span>{{ 'account-management.logout' | translate }}</span>
                    </button>

                </mat-menu>

                <div *ngIf="authenticationService.isAuthenticated"
                     class="toolbar-separator toolbar__separator"
                     fxHide
                     fxShow.gt-xs>
                </div>

                <button *ngIf="authenticationService.isAuthenticated && showNotification"
                        class="toolbar__notification-button"
                        [class.toolbar__notification-button--unread]="hasNotifications"
                        (click)="openNotifications()"
                        [attr.aria-label]="'notification.title' | translate"
                        [matTooltip]="'notification.title' | translate">
                    <ng-container *ngIf="hasNotifications; else noNotificationTemplate">
                        <mat-icon svgIcon="notifications-unread"
                                  class="toolbar__notification-icon"></mat-icon>
                    </ng-container>
                    <ng-template #noNotificationTemplate>
                        <mat-icon svgIcon="notifications"
                                  class="toolbar__notification-icon"></mat-icon>
                    </ng-template>
                </button>

                <div *ngIf="!hiddenNavbar && rightNavbar"
                     class="toolbar-separator toolbar__separator"
                     fxHide
                     fxShow.gt-xs>
                </div>

                <button mat-icon-button class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                    <mat-icon class="secondary-text">menu</mat-icon>
                </button>

                <div *ngIf="settingsTralereToolbar.displayed"
                     class="toolbar-separator toolbar__separator">
                </div>

                <!-- TRALALERE BAR -->
                <app-tralalere-bar *ngIf="settingsTralereToolbar.displayed"></app-tralalere-bar>
                <!-- / TRALALERE BAR -->

            </div>

        </div>

    </mat-toolbar>
</ng-container>