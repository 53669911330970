<ng-container *ngIf="answersState; else placeholderTemplate">

    <ng-container *ngFor="let answer of answersState.answers.answers; let index = index">
        <button class="activity-edition-preview-sort-mat__answer-button"
                [class.activity-edition-preview-sort-mat__image-button]="answersState.answers.type === 'image'">
            <ng-container *ngIf="answersState.answers.type === 'text'">
                <span>{{ answer }}</span>
            </ng-container>
            <ng-container *ngIf="answersState.answers.type === 'image'">
                <img [src]="answer.image?.uri">
            </ng-container>
            <ng-container *ngIf="answersState.answers.type === 'audio'">
<!--                <audio [src]="answer?.uri" controls></audio> hide player to be replace by icon -->
                <mat-icon svgIcon="edition-preview__audio"></mat-icon>
            </ng-container>
        </button>
        <button class="activity-edition-preview-sort-mat__number-button">
            <span>{{ index + 1 }}</span>
        </button>
    </ng-container>

</ng-container>

<ng-template #placeholderTemplate>

    <ng-container *ngFor="let item of [].constructor(4); let index = index">
        <button class="activity-edition-preview-sort-mat__answer-button-placeholder">
            <span>{{ 'activity_edition.text_answer_placeholder_' + index | translate }}</span>
        </button>
        <button class="activity-edition-preview-sort-mat__number-button-placeholder">
            <span>{{ index + 1 }}</span>
        </button>
    </ng-container>

</ng-template>