<section class="feedback">

    <div class="feedback__header"
         mat-dialog-title>
        <h1 *ngIf="title"
            class="feedback__title">
            {{ title | translate }}
        </h1>
        <button class="feedback__close-button close"
                (click)="close()"
                [matTooltip]="'generic.close' | translate">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div class="feedback__content"
         mat-dialog-content>
        <div *ngIf="innerHtmlContent"
             [innerHTML]="innerHtmlContent">
        </div>
        <div *ngIf="content">{{ content }}</div>
    </div>

    <div class="feedback__actions"
         mat-dialog-actions>
        <button class="feedback__next-button"
                (click)="close()">
            <span>{{ (btnTitle ? btnTitle : 'feedback.got_it') | translate }}</span>
            <mat-icon svgIcon="next"></mat-icon>
        </button>
    </div>

</section>