<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">

  <table *ngIf="!empty" mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}" fxFlex="100">

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef> {{ 'groups-management.color' | translate }} </th>
      <td mat-cell *matCellDef="let item">
        <div class="pastille" [style.background-color]="item.color"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="groupname">
      <th mat-header-cell *matHeaderCellDef> Classe </th>
      <td mat-cell *matCellDef="let item">
        <p class="text-truncate font-weight-600 text-bold">{{item.groupname}}</p>
      </td>
    </ng-container>


    <ng-container matColumnDef="learners">
      <th mat-header-cell *matHeaderCellDef> {{ 'groups-management.number_of_student' | translate }} </th>
      <td mat-cell *matCellDef="let item">
        <p class="text-truncate font-weight-600">{{item.learners}}&nbsp;{{ getTerms(item.learners) | translate}}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row;
        columns: displayedColumns;"
        [@animate]="{value:'*',params:{y:'100%'}}"
        (click)="getLink()"></tr>
  </table>

  <div *ngIf="empty" class="emptyResourceLabel">{{ getTermsByRole | translate }}</div>
</div>
