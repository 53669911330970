<form fxFlex fxLayout="column" name="searchForm">
    <div fxFlex="1 1 100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
        <ng-container *ngFor="let control of controls">
            <mat-form-field [ngClass]="'filters-field filters-field-' + control.field.id"
                            [style.visibility]="control.field.hidden?'hidden':'visible'">
                <mat-label>{{control.field.label | translate}}</mat-label>

                <mat-select *ngIf="isFieldListOption(control.field)"
                            [attr.id]="control.field.id"
                            [attr.name]="control.field.id"
                            [formControl]="control.formControl"
                            [placeholder]="control.field.placeholder | translate">
                    <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                    <mat-option *ngFor="let item of control.field.list" [value]="item.id">
                        <span>{{ item.label | translate }}</span>
                    </mat-option>
                </mat-select>

                <input *ngIf="isFieldTextOption(control.field)"
                       [formControl]="control.formControl"
                       [placeholder]="control.field.placeholder"
                       matInput>

            </mat-form-field>
            <mat-icon svgIcon="info" *ngIf="control.field.showInfoIcon"
                      (click)="infoClick(control.field.id)"></mat-icon>
        </ng-container>

        <button (click)="onSearchButtonClick()" class="filters-field-launchSearch" color="primary"
                mat-raised-button>{{ 'generic.search' | translate }}</button>
    </div>
</form>
