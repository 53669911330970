<ng-template #wordingOrInstructionMedia>
    <ng-container *ngIf="guessWordingOrInstructionMedia$ | async as media ">
        <div class="activity-sample__window-infos multi-media wordingOrInstructionImage">
            <div class="activity-sample__preview">
                <div>
                    <img *ngIf="media.type === 'image'"
                         [src]="media.uri"
                         [alt]="media.alt">
                    <img *ngIf="media.type === 'flashcard'"
                         [src]="media.uri"
                         [alt]="media.alt">
                    <video *ngIf="media.type === 'video'"
                           [src]="media.uri"></video>
                </div>
            </div>
            <mat-icon [svgIcon]="'activity-sample__' + media.type" ></mat-icon>
            <span *ngIf="media.count > 1">{{ media.count }} {{ 'activity_sample.media_flashcards' | translate }}</span>
        </div>
    </ng-container>
</ng-template>


<ng-template #genericMultipleChoice let-typologyLabel>
    <ng-container *ngTemplateOutlet="wordingOrInstructionMedia"></ng-container>
    <div [ngClass]="{' multi-media': isWordingExist()}" class="activity-sample__overlay-infos bb genericMultipleChoice">
        <ng-container *ngIf="areAnswersAreImage(getAnswers())">
            <mat-icon svgIcon='activity-sample__image'></mat-icon>
            <span>{{ getAnswers().length }} {{ 'activity_sample.' + typologyLabel + '_images' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="areAnswersAreText(getAnswers())">
            <mat-icon [svgIcon]="getTypology() === TypologyLabel.shortAnswer ? 'activity-sample__keyboard' : 'activity-sample__text'"></mat-icon>
            <span>{{ getAnswers().length }} {{ 'activity_sample.' + typologyLabel + '_texts' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="areAnswersAreAudio(getAnswers())">
            <mat-icon svgIcon='activity-sample__audio'></mat-icon>
            <span>{{ getAnswers().length }} {{ 'activity_sample.' + typologyLabel + '_audios' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="areAnswserAreFlashcards(getAnswers())">
            <mat-icon svgIcon='activity-sample__flashcard'></mat-icon>
            <span *ngIf="getAnswers().length > 1">{{ getAnswers().length }} {{ 'activity_sample.' + typologyLabel + '_flashcards' | translate }}</span>
        </ng-container>
        <ng-container *ngIf="undefinedAnswersType(getAnswers())">
            <mat-icon svgIcon='activity-sample__undefined'></mat-icon>
            <span>{{ getAnswers().length }} {{ 'activity_sample.' + typologyLabel + '_undefined_type' | translate }}</span>
        </ng-container>
    </div>
</ng-template>

<!--multipleChoiceUniqueAnswer-->
<ng-container *ngIf="getTypology() === TypologyLabel.multipleChoiceUniqueAnswer">
    <ng-container *ngTemplateOutlet="genericMultipleChoice; context: {$implicit: TypologyLabel.multipleChoiceUniqueAnswer}">
    </ng-container>
</ng-container>
<!--trueFalse-->
<ng-container *ngIf="getTypology() === TypologyLabel.trueFalse">
    <ng-container *ngTemplateOutlet="wordingOrInstructionMedia"></ng-container>
    <div class="activity-sample__overlay-infos activity-sample__typology-trueFalse cc trueFalse">
        <mat-icon svgIcon="thumb_up"></mat-icon>
        /
        <mat-icon svgIcon="thumb_down"></mat-icon>
    </div>
</ng-container>
<!--textMatching-->
<ng-container *ngIf="getTypology() === TypologyLabel.textMatching">
    <div class="activity-sample__preview activity-sample__text-matching">
        <div *ngIf="getSourceTypeOfTextMatching() === 'audio'"
             class="activity-sample__text-matching-audio">
            <mat-icon svgIcon='activity-sample__audio'></mat-icon>
            <span>{{ getSourcesOfTextMatching().length }} {{ 'activity_sample.audios' | translate }}</span>
        </div>
        <div *ngIf="getSourceTypeOfTextMatching() === 'img'"
             class="activity-sample__text-matching-image">
            <div [innerHtml]="getAnswers()[0].source"></div>
            <mat-icon svgIcon='activity-sample__image'></mat-icon>
            <span>{{ getSourcesOfTextMatching().length }} {{ 'activity_sample.images' | translate }}</span>
        </div>
        <div *ngIf="getSourceTypeOfTextMatching() === 'text'"
             class="activity-sample__text-matching-text">
            <mat-icon svgIcon='activity-sample__text'></mat-icon>
            <span>{{ getSourcesOfTextMatching().length }} {{ 'activity_sample.texts' | translate }}</span>
        </div>
        <div *ngIf="getTargetTypeOfTextMatching() === 'audio'"
             class="activity-sample__text-matching-audio">
            <mat-icon svgIcon='activity-sample__audio'></mat-icon>
            <span>{{ getTargetsOfTextMatching().length }} {{ 'activity_sample.audios' | translate }}</span>
        </div>
        <div *ngIf="getTargetTypeOfTextMatching() === 'img'"
             class="activity-sample__text-matching-image">
            <div [innerHtml]="getAnswers()[0].target"></div>
            <mat-icon svgIcon='activity-sample__image'></mat-icon>
            <span>{{ getTargetsOfTextMatching().length }} {{ 'activity_sample.images' | translate }}</span>
        </div>
        <div *ngIf="getTargetTypeOfTextMatching() === 'text'"
             class="activity-sample__text-matching-text">
            <mat-icon svgIcon='activity-sample__text'></mat-icon>
            <span>{{ getTargetsOfTextMatching().length }} {{ 'activity_sample.texts' | translate }}</span>
        </div>
        <mat-icon class="activity-sample__text-matching-icon"
                  svgIcon='activity-sample__matching'>
        </mat-icon>
    </div>
</ng-container>
<!--multipleChoice-->
<ng-container *ngIf="getTypology() === TypologyLabel.multipleChoice">
    <ng-container
        *ngTemplateOutlet="genericMultipleChoice; context: {$implicit: TypologyLabel.multipleChoice}">
    </ng-container>
</ng-container>
<!--multimedia-->
<ng-container *ngIf="getTypology() === TypologyLabel.multimedia">
    <ng-container *ngFor="let granule of getMultimediaActivityMedias()">

        <ng-container *ngIf="granule.file?.type === 'image'">
            <div [ngClass]="{' multi-media': isWordingExist() || getMultimediaActivityMedias().length > 1}" class="activity-sample__window-infos activity-sample__multimedia-image">
                <div class="activity-sample__preview"><img [alt]="granule.file?.alt" [src]="granule.file?.uri"></div>
                <mat-icon svgIcon='activity-sample__image'></mat-icon>
            </div>
        </ng-container>
        <ng-container *ngIf="granule.file?.type === 'audio'">
            <div [ngClass]="{' multi-media': isWordingExist() || getMultimediaActivityMedias().length > 1}" class="activity-sample__overlay-infos activity-sample__multimedia-audio">
                <mat-icon svgIcon='activity-sample__audio'></mat-icon>
                <app-audio-time [src]="granule.file?.uri"></app-audio-time>
            </div>
        </ng-container>
        <ng-container *ngIf="granule.file?.type === 'video'">
            <div [ngClass]="{' multi-media': isWordingExist()}" class="activity-sample__window-infos activity-sample__multimedia-video">
                <div class="activity-sample__preview">
                    <app-shared-video
                        [video]="{uri: granule.file?.uri, filemime: granule.file?.filemime, subtitles: {}}"
                        controls>
                    </app-shared-video>
                </div>
                <mat-icon svgIcon='activity-sample__video'></mat-icon>
                <app-video-time [src]="granule.file?.uri"></app-video-time>

            </div>
        </ng-container>
        <ng-container *ngIf="granule.file?.type === 'document'">
            <div [ngClass]="{' multi-media': isWordingExist() || getMultimediaActivityMedias().length > 1}" class="activity-sample__overlay-infos activity-sample__multimedia-document">
                <mat-icon svgIcon='activity-sample__document'></mat-icon>
                <span>{{ granule.file.filename }}</span>
            </div>
        </ng-container>

    </ng-container>
    <ng-container *ngIf="isWordingExist()">
        <div [ngClass]="{' multi-media': getMultimediaActivityMedias().length > 0 }" class="activity-sample__window-infos activity-sample__multimedia-text">
            <div class="activity-sample__preview">{{ getWording() }}</div>
            <mat-icon svgIcon='activity-sample__text'></mat-icon>
        </div>
    </ng-container>
</ng-container>

<!--sortMat areAnswersAreImage-->
<ng-container *ngIf="getTypology() === TypologyLabel.sortMat && areAnswersAreImage(getAnswers())">
    <div class="activity-sample__preview activity-sample__image-ordering"
         [class]="'imgCounter-' + getAnswers().length">
        <ng-container *ngFor="let answer of getAnswers()">
            <img [src]="answer.image"
                 alt="{{ 'activities.alt_img_sort_mat' | translate }}">
        </ng-container>
    </div>
</ng-container>
<!--sortMat areAnswersAreText-->
<ng-container *ngIf="getTypology() === TypologyLabel.sortMat && areAnswersAreText(getAnswers())">
    <ng-container *ngIf="getTypeOfSortMatActivity() === 'orderSentence'; else longTextOrderingTemplate">
        <div class="activity-sample__preview activity-sample__short-text-ordering">
            <div>
                <span>"</span>
                <ng-container *ngFor="let answer of getAnswers(); last as isLast">
                    <span>{{ answer.answer }}</span>
                    <span *ngIf="!isLast">&nbsp;</span>
                </ng-container>
                <span>"</span>
            </div>
        </div>
    </ng-container>
    <ng-template #longTextOrderingTemplate>
        <div class="activity-sample__preview activity-sample__long-text-ordering">
            <div *ngFor="let answer of getAnswers(); last as isLast">
                <span>{{ answer.answer }}</span>
                <span *ngIf="!isLast">,</span>
                <span *ngIf="isLast">.</span>
            </div>
        </div>
    </ng-template>
</ng-container>
<!--sortMat areAnswersAreAudio-->
<ng-container *ngIf="getTypology() === TypologyLabel.sortMat && areAnswersAreAudio(getAnswers())">
    <div class="activity-sample__preview activity-sample__audio-ordering">
        <ng-container *ngFor="let answer of getAnswers(); let i = index">
            <mat-icon svgIcon='activity-sample__audio' ></mat-icon>
        </ng-container>
    </div>
</ng-container>

<!--shortAnswer-->
<ng-container *ngIf="getTypology() === TypologyLabel.shortAnswer">

        <ng-container *ngIf="isLongAnswerCRT()">
            <mat-icon svgIcon='activity-sample__keyboard' class="full-icon"></mat-icon>
        </ng-container>
        <ng-container *ngIf="!isLongAnswerCRT()">

            <ng-container *ngIf="guessWordingOrInstructionMedia$ | async as image; else multiShortAnswersTemplate">
                <ng-container *ngTemplateOutlet="genericMultipleChoice; context: {$implicit: TypologyLabel.shortAnswer}"></ng-container>
            </ng-container>

            <ng-template #multiShortAnswersTemplate>
                <div class="activity-sample__overlay-infos activity-sample__multiShortAnswersTemplate">
                    <mat-icon svgIcon='activity-sample__keyboard'></mat-icon>
                    <ng-container *ngIf="getAnswers().length > 1">
                        <div class="grid-multi-answers">
                            <ng-container *ngFor="let answer of getAnswers(); let i = index">
                                <div class="activity-sample__short-answer_multi">
                                    <span>{{ answer.answer }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="getAnswers().length == 1">
                        <span>{{ getAnswers()[0].answer }}</span>
                    </ng-container>
                </div>
            </ng-template>



        </ng-container>
</ng-container>
<!--fillBlanks-->
<ng-container *ngIf="getTypology() === TypologyLabel.fillBlanks">
    <div class="activity-sample__preview activity-sample__fill-blanks">
        <mat-icon svgIcon='activity-sample__keyboard'></mat-icon>
        <span>
        "
        <ng-container *ngFor="let textPart of getFillBlanksTextParts()">
            <strong *ngIf="textPart.isBlank">{{ textPart.text }}</strong>
            <ng-container *ngIf="!textPart.isBlank">{{ textPart.text }}</ng-container>
        </ng-container>
        "
            </span>
    </div>
</ng-container>

<!--awareness-->
<ng-container *ngIf="getTypology() === TypologyLabel.awareness">
    <div class="activity-sample__preview activity-sample__awareness">
        <ng-container *ngFor="let flashcard of getFlashcardActivity(), let imgIndex = index; ">
                <img [alt]="flashcard.image?.alt" src="{{flashcard.image?.uri}}">
        </ng-container>
    </div>
</ng-container>

<!--memory-->
<ng-container *ngIf="getTypology() === TypologyLabel.memory">
    <div class="activity-sample__preview activity-sample__memory">
        <ng-container *ngFor="let flashcard of getFlashcardActivity(), let imgIndex = index;">
            <div class="activity-sample__memory-image-wrapper">
                    <img class="activity-sample__memory-image"
                         src="{{flashcard.image?.uri}}"
                         [alt]="flashcard.image?.alt">
                    <div class="activity-sample__memory-placeholder"
                         aria-hidden="true">
                    </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<!--missing-->
<ng-container *ngIf="getTypology() === TypologyLabel.missing || getTypology() === TypologyLabel.missingAudio">
    <div class="activity-sample__preview activity-sample__missing-item">
        <div>
            <div *ngFor="let answer of getAnswers()">
                <img [src]="answer.image"
                     alt="{{'activities.alt_img_missing_audio' | translate }}">
            </div>
        </div>
        <ng-container *ngIf="getTypology() === TypologyLabel.missing; else missingAudioTemplate">
                <mat-icon svgIcon='activity-sample__text'></mat-icon>
                <span>{{ getAnswers().length }} {{ 'activity_sample.text_answers' | translate }}</span>
        </ng-container>
        <ng-template #missingAudioTemplate>
            <div>
                <mat-icon svgIcon='activity-sample__audio'></mat-icon>
                <span>{{ getAnswers().length }} {{ 'activity_sample.audio_answers' | translate }}</span>
            </div>
        </ng-template>
    </div>
</ng-container>
<!--recording-->
<ng-container *ngIf="getTypology() === TypologyLabel.recording">
    <div class="activity-sample__preview activity-sample__recording"
         [class.activity-sample__recording--missing-image]="!guessWordingOrInstructionMedia$">
        <img *ngIf="guessWordingOrInstructionMedia$ | async as image"
             [src]="image.uri"
             [alt]="image.alt">
        <mat-icon svgIcon='activity-sample__microphone'></mat-icon>
    </div>
</ng-container>
<!--pointImage-->
<ng-container *ngIf="getTypology() === TypologyLabel.pointImage">
    <div class="activity-sample__preview activity-sample__point-image"
         [class.activity-sample__point-image--missing-image]="!guessWordingOrInstructionMedia$">
        <img *ngIf="guessWordingOrInstructionMedia$ | async as image"
             [src]="image.uri"
             [alt]="image.alt">
        <mat-icon svgIcon='activity-sample__image-search'></mat-icon>
    </div>
</ng-container>
<!-- external-->
<ng-container *ngIf="getTypology() === TypologyLabel.external">
    <div class="activity-sample__preview activity-sample__external">
        <mat-icon svgIcon="activity-sample__portal"></mat-icon>
    </div>
</ng-container>
<!--tool-->
<ng-container *ngIf="getTypology() === TypologyLabel.tool">
    <div class="activity-sample__preview activity-sample__tool">
        <mat-icon svgIcon="activity-sample__tools"></mat-icon>
        <span *ngIf="getTypeOfToolActivity() === 'bd'">
            {{ 'activity_sample.bd' | translate }}
        </span>
        <span *ngIf="getTypeOfToolActivity() === 'mindmap'">
            {{ 'activity_sample.mindmap' | translate }}
        </span>
        <span *ngIf="getTypeOfToolActivity() === 'notepad'">
            {{ 'activity_sample.notepad' | translate }}
        </span>
    </div>
</ng-container>