<div class="groups-listing page-layout simple inner-scroll p-16"
     id="entity"
     fxFlex
     fxLayout="column">

    <!-- CENTER -->
    <div class="groups-listing__content-wrapper center" fxFlex fxLayout="column">

        <!-- CONTENT -->
        <content class="groups-listing__content inner-scroll mat-white-bg" fusePerfectScrollbar fxFlex fxLayout="column">

            <fuse-groups-list [activateMetacognitionEntitySettings]="activateMetacognitionEntity"
                              [addEntity]="addEntity"
                              [archiveEntitySettings]="archiveEntity"
                              [customActions]="customActions"
                              [dataSource]="dataSource"
                              [deactivateMetacognitionEntitySettings]="deactivateMetacognitionEntity"
                              [dearchiveEntitySettings]="deArchiveEntity"
                              [deleteEntitySettings]="deleteEntity"
                              [displayedColumns]="displayedColumns"
                              [editEntitySettings]="editEntity"
                              [groupNameClassroomSelected]="groupNameClassroomSelected"
                              [groupNameSelected]="groupNameSelected"
                              [isAddingInlineLearnerRowAddButton]="isAddingInlineLearnerRowAddButton"
                              [isAddingInlineLearner]="isAddingInlineLearner"
                              [isAddingLearnerFromGroupOrWorkgroup]="isAddingLearnerFromGroupOrWorkgroup"
                              [joinLearner]="joinLearner"
                              [nicknameContextAllowed]="nicknameContextAllowed"
                              [options]="options"
                              [showGlobalAddButton]="settings.showGlobalAddButton"
                              [displayedFilters]="displayedFilters"
                              [context]="context"
                              fxFlex
                              fxLayout="column"
            ></fuse-groups-list>

        </content>
        <!-- / CONTENT -->
        <!-- / CENTER -->

    </div>

    <!-- ADD CONTACT BUTTON -->
    <!--TODO(val) delete add-entity-button-->
    <button (click)="newEntity()"
            *ngIf="!isStudent && settings.showGlobalAddButton"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
            aria-label="add entity"
            class="mat-fab-bottom-right add-entity-button"
            mat-fab>
        <mat-icon [ngClass]="'svg-icon-' + selectedIcon" [svgIcon]="selectedIcon"></mat-icon>
    </button>
    <!-- / ADD CONTACT BUTTON -->
</div>