<div fxLayout="column" class="page-layout simple fullwidth " [ngClass]="{'play-mode':isSummaryInPlayer}" fxFlex>

    <div *ngIf="warningsAllowed && isSummaryInPlayer && mode === 'marker' && warningControl" class="warning-infos"
         [innerHTML]="warningControl?.value?.get('description')">
    </div>

    <div *ngIf="warningsAllowed && warningControl && !isSummaryInPlayer && mode === 'marker'" class="warning-infos-field">
        <mat-form-field>
            <mat-select #warningSelect
                        [formControl]="warningControl" panelClass="warning-infos-panel">
                <mat-select-trigger>
                    <span [innerHTML]="warningControl?.value?.get('description')"></span>
                </mat-select-trigger>
                <mat-option [value]="option" *ngFor="let option of warnings">
                    <span [innerHTML]="option?.get('description')"></span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button  class="save-button" (click)="saveWarnings()" [disabled]="showSpinner">
            <span>{{ 'generic.save' | translate }}</span>
        </button>
    </div>


    <div *ngIf="mode === 'marker'" class="content marker-card-layout" fxFlex fxLayoutAlign="center center"
        >

        <div *ngFor="let marker of markers" (click)="showMarkersDetail(marker)"
             class="markerCard p-12" fxLayout="column" fxLayoutAlign="center center"
        ><!-- TODO .markerCard > view css guideline nomenclature -->

            <button >
                <mat-icon [svgIcon]="getIcon(marker)"></mat-icon>
            </button>
            <label>{{getTitleMarkerType(marker) | translate}}</label>

        </div>

    </div>

    <div *ngIf="mode === 'poll'" class="header global-poll-legend" fxLayout="column" fxFlex="1 1">
        <div>
            <span [ngStyle]="{'background-color': outsidePollColor}" class="legend-outside-color"></span>
            <span class="legend-outside-label">{{'poll.legend.outside_poll_color' | translate}}</span>
        </div>
        <div>
            <span [ngStyle]="{'background-color': insidePollColor}" class="legend-inside-color"></span>
            <span class="legend-inside-label">{{'poll.legend.inside_poll_color' | translate}}</span>
        </div>
    </div>


    <div *ngIf="mode === 'poll'" class="content inner-content poll-review-container" fxLayout="row" fxFill>

        <div *ngFor="let data of polls" class="app-poll-review">
            <app-poll-review
                    [poll1]="data.poll1"
                    [poll2]="data.poll2"
                    [outsidePollColor]="outsidePollColor"
                    [insidePollColor]="insidePollColor"
                    [title]="data.legend">
            </app-poll-review>

            <div class="pollLegend">
                <h5>{{data.legend}}</h5>
            </div>
        </div>

    </div>
    <div *ngIf="mode === 'qcmu'" class="content qcmu" fxFlex fxLayoutAlign="center center"
        >
        <div fxFlex fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="24px">
            <h2>{{'activities.qcu_recap_answer' | translate}}</h2>
            <div fxFlex fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="40px">
                <div *ngFor="let state of answers; let i = index"
                     [ngClass]="state"
                     class="pastille"
                     fxFlex
                     fxLayout="colunm"
                     fxLayoutAlign="center center">
                    <span>{{i + 1}}</span>
                    <mat-icon [svgIcon]="state"></mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div fxFill fxLayoutAlign="center center" class="mat-spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
