<mat-spinner *ngIf="showSpinner"></mat-spinner>
<mat-expansion-panel [expanded]="true" *ngIf="conceptAssignments && conceptAssignments.length !== 0">
    <mat-expansion-panel-header appAddClassInRegardOfTextHeight>
        <mat-panel-title>
            <span>{{ 'assignment.title' | translate }}</span>
        </mat-panel-title>
        <mat-icon svgIcon="chevron"></mat-icon>
    </mat-expansion-panel-header>
    <ul>
        <li>
            <mat-card class="lesson-card" *ngFor="let assignment of conceptAssignments">
                <mat-card-header>
                    <mat-card-title class="assignment">{{assignment.get('assignated_node')?.title}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-progress-bar mode="determinate" [value]="pourcent(assignment)"></mat-progress-bar>
                    <div *ngIf="assignment.get('assignator') && assignment.get('assignator').name" class="assign_comment">
                        <b>{{assignment.get('assignator').name}} <span *ngIf="notAutoAssignation(assignment)">{{ 'assign.lesson_to_you'| translate}}</span> </b>
                        <span *ngIf="assignment.get('comment') && assignment.get('comment').length>0">{{assignment.get("comment")}}</span>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-button (click)="launchModal(assignment)">
                        <mat-icon aria-hidden="true" svgIcon="assignmentsCardIcon"></mat-icon>
                        <span>{{ 'activities.explore' | translate}}</span>
                    </button>
                </mat-card-actions>
            </mat-card>
        </li>
    </ul>
</mat-expansion-panel>
