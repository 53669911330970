<div class="page-layout simple inner-scroll" fusePerfectScrollbar>
    <div *ngIf="showLoader" class="content p-16" fusePerfectScrollbar fxFlex fxLayout="column">
        <div class="spinner-wrapper">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div *ngIf="!showLoader" class="content p-16" fusePerfectScrollbar fxFlex fxLayout="column">

        <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="faq"></app-banner-info>

        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <app-search-filters [fields]="fieldsToDisplay" [countEntities]="countEntities"
                                    [customFieldName]="customFieldName"
                                    [orderFields]="true"
                                    [displayedFiltersIcons]="displayedFiltersIcons"
                                    [origin]="'faq'"
                                    (launchSearch)="launchSearch($event)"></app-search-filters>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="faq-banner">
            <span>{{ 'generic.question' | translate }}</span>
        </div>
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel *ngFor="let question of questions">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon svgIcon="chevron"></mat-icon>
                        {{question.get('title')}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <p *ngIf="question.get('answer')" [innerHTML]="question.get('answer')" appLinkHandler></p>

                <ng-container *ngIf="question.get('questions')">
                    <mat-expansion-panel *ngFor="let subquestion of question.get('questions')">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon svgIcon="chevron"></mat-icon>
                                {{subquestion.title}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <p *ngIf="subquestion['answer']" [innerHTML]="subquestion['answer']" appLinkHandler></p>

                    </mat-expansion-panel>
                </ng-container>

            </mat-expansion-panel>
        </mat-accordion>
        <mat-paginator [length]="countEntities" [pageIndex]="pageIndex" [pageSizeOptions]="pageRangeOptions"
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>
