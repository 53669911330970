<div id="mail-confirm" fxLayout="column" fusePerfectScrollbar>

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="mail-confirm-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <mat-icon class="s-96">email</mat-icon>
            </div>

            <div class="title">{{ 'account-management.user_registration_blocked_title' | translate}}</div>

            <div class="subtitle">
              <p>
                  {{ 'account-management.user_registration_blocked_body' | translate}}
              </p>
            </div>

        </div>

    </div>

</div>
