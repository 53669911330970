<div class="sidenav-content">

    <div class="card mat-white-bg">
        <!-- SIDENAV HEADER -->
        <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">

            <!-- USER -->
            <img [src]="user.avatar" class="avatar mr-16" alt=""/>
            <span class="h5">{{user.name}}</span>
            <!-- / USER -->

        </div>
        <!-- / SIDENAV HEADER -->

        <!-- SIDENAV CONTENT -->
        <div class="content py-16" fusePerfectScrollbar>

            <div class="nav">

                <div class="nav-item" aria-label="inbox">
                    <a class="nav-link" matRipple (click)="changeFilter('all')" [ngClass]="{'active':filterBy ==='all'}">
                        <span class="title">{{ 'groups-management.nav_all_student' | translate }}Tous les élèves</span>
                    </a>
                </div>

                <div class="nav-item" aria-label="frequently eleveed" (click)="changeFilter('frequent')">
                    <a class="nav-link" matRipple [ngClass]="{'active':filterBy ==='frequent'}">
                        <div class="title">{{ 'groups-management.nav_frequently_students' | translate }}</div>
                    </a>
                </div>

                <div class="nav-item" aria-label="starred" (click)="changeFilter('starred')">
                    <a class="nav-link" matRipple [ngClass]="{'active':filterBy ==='starred'}">
                        <div class="title">{{ 'groups-management.nav_favorites_students' | translate }}</div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- / SIDENAV CONTENT -->
