<div class="assignment-select-seances">

    <div class="assignment-select-seances__breadcrumb">

        <div *ngFor="let item of breadcrumb; let isLast = last"
              class="assignment-select-seances__breadcrumb-item">
            <span class="assignment-select-seances__breadcrumb-label">{{ item }}</span>
            <mat-icon *ngIf="!isLast"
                      class="assignment-select-seances__breadcrumb-icon"
                      svgIcon="chevron-right-rounded">
            </mat-icon>
        </div>

    </div>

    <div class="assignment-select-seances__content">

        <h3 class="assignment-select-seances__title">{{ 'assignment.what' | translate }} <cite>{{ title }}</cite> ?</h3>

        <div *ngIf="seances.length > 0"
             class="assignment-select-seances__session-container">
            <label *ngFor="let s of seances"
                    class="assignment-select-seances__session-checkbox">
                <input type="checkbox"
                       [(ngModel)]="s.selected"
                       (change)="checkboxEvent($event)">
                <span class="assignment-select-seances__session-label">{{ s.label }}</span>
                <div class="assignment-select-seances__session-assignment">
                    <span class="assignment-select-seances__assignment-label">{{ 'assignment.assign' | translate }}</span>
                    <div class="assignment-select-seances__assignment-icon"
                         [class.assignment-select-seances__assignment-icon--checked]="s.selected"
                         aria-hidden="true">
                    </div>
                </div>
            </label>
        </div>

    </div>

</div>