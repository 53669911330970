<form
    id="newsletter-form"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
>
    <mat-form-field>
        <mat-label>{{ 'account-management.newsletter_email' | translate }}</mat-label><input 
            matInput 
            
            formControlName="email"
        />
    </mat-form-field>
    <mat-checkbox
        [aria-label]="'account-management.newsletter' | translate"
        formControlName="newsletter"
        (keydown.enter)="suscribeToNewsLetter($event)"
    >
        {{ "account-management.newsletter" | translate }}
    </mat-checkbox>

    <mat-error *ngIf="displayGenericError">{{'generic.form_error' | translate}}</mat-error>
</form>

<div class="actions">
    <button
        mat-raised-button
        (click)="close()"
    >
        {{ "generic.close" | translate }}
    </button>
    <button
        mat-raised-button
        color="accent"
        type="submit"
        form="newsletter-form"
        [disabled]="!formGroup.valid || isSubmitting"
    >
        {{ "account-management.newsletter_signup" | translate }}
        <ng-container *ngIf="isSubmitting">
            <mat-spinner diameter="24"></mat-spinner>
        </ng-container>
    </button>
</div>
