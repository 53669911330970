<form>
    <div class="mb-24 fontBold">{{ 'generic.select_'+ type +'_help' | translate}}</div>
    <div  class="mb-24"
          fxLayoutAlign="start start">
        <mat-form-field fxFlex="100">
            <mat-label>{{ 'generic.'+ type | translate }}</mat-label><input class="w-100-p" [formControl]="titleFilter" matInput >
        </mat-form-field>
    </div>

    <div class="mb-24 fontBold">{{tagsSelected.length}}
        <span *ngIf="tagsSelected.length < 2">{{ 'generic.selected_'+ type | translate}}</span>
        <span *ngIf="tagsSelected.length > 1">{{ 'generic.selected_'+ type +'_plural' | translate}}</span>
        <button  mat-icon-button (click)="resetTags()" *ngIf="tagsSelected.length > 0">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div class="mb-24 h-400" fusePerfectScrollbar>

        <table mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef><span class="th__span--hidden-accessibility">{{'accessibility.th_hidden_name' | translate}}</span></th>
                <td mat-cell *matCellDef="let entity">
                    <p class="text-truncate">{{entity.name}}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
                class="entity"
                (click)="selectedChapter(entity)"
                matRipple
                [ngClass]="{'selected': checkSelected(entity) > -1}">
            </tr>
        </table>
    </div>

</form>
