<div class="assignation-groups-list page-layout simple fullwidth  inner-scroll" fusePerfectScrollbar>

    <div class="assignation-groups-list__container p-16 content inner-scroll" fxLayout="column" fxFlex>

        <app-followed-filter *ngIf="shouldDisplayFilters()"
                             class="assignation-groups-list__filter"
                             [displayedFilters]="displayedFilters"
                             [allTypes]="allTypes"
                             [groupsList]="assignationService.groupsList"
                             [workgroupsList]="assignationService.workgroupsList"
                             [learnersList]="assignationService.learnersList"
                             [countEntities]="countEntities"
                             (launchSearch)="launchSearch($event)">
        </app-followed-filter>

        <div *ngIf="!isLoading"
             class="assignation-groups-list__content content touch-scroll">

            <div *ngIf="!dataSource.data.length"
                 class="assignation-groups-list__help-message emptyResourceLabel">
                {{ 'generic.assignment_group_empty_granule' | translate }}
            </div>

            <table class="assignation-groups-list__table"
                   mat-table
                   #table
                   [dataSource]="dataSource"
                   *fuseIfOnDom matSort
                   [@detailExpand]="{value:'50'}" multiTemplateDataRows>

                <!--name of exercise-->
                <ng-container matColumnDef="lesson">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.lesson_title' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{ row?.lesson }}
                    </td>
                </ng-container>


                <!--name of exercise-->
                <ng-container matColumnDef="learnersCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.learnersCount' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{ row?.learners.length }}
                    </td>
                </ng-container>


                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'generic.start_date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        {{ assignationService.localeDate(row.startDate) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="questionSet">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'assignation.subLesson' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        <span [class]="'button blue'">{{ row?.questionSet | translate }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'generic.state' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        <span
                            [class]="'button ' + row?.status.replaceAll('.', '-')">{{ row?.status | translate }}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button aria-label="expand row"
                                (click)="(expandedElement = expandedElement === row ? null : row); handleRowClick(row,expandedElement === row); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== row">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === row">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell
                        *matCellDef="let row; let i= index"
                        [attr.colspan]="columnsToDisplayWithExpand.length">
                        <div class="example-element-detail"
                             [@detailExpand]="row === expandedElement ? 'expanded' : 'collapsed'">
                            <mat-list role="list">
                                <mat-list-item *ngFor="let learner of row.learners"
                                               role="listitem">
                                    <div matListItemTitle>{{ row.assignations[learner].username }}</div>
                                    <div matListItemLine>
                                        <div [class]="getAssignationStatus(row.assignations[learner]).replaceAll('.', '-')">
                                            {{ getAssignationStatus(row.assignations[learner]) | translate }}
                                        </div>
                                        <button (click)="unAssign(row.assignations[learner],row.assignations[learner]?.id,learner)"
                                                [disabled]="+row.assignations[learner]?.progress > 0">
                                            <span>{{ 'assignment.unassign' | translate }}</span>
                                        </button>
                                    </div>
                                </mat-list-item>
                            </mat-list>

                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="unassign">
                    <th mat-header-cell
                        *matHeaderCellDef
                        aria-label="row actions">{{ 'assignment.unassign_all' | translate }}
                    </th>
                    <td mat-cell
                        *matCellDef="let row;">
                        <button (click)="unAssignAll(row)"
                                [disabled]="cantBeDeAssign(row)">
                            <span>{{ 'assignment.unassign_all' | translate }}</span>
                        </button>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: columnsToDisplayWithExpand;"
                    matRipple
                    [@detailExpand]="{value:'*',params:{y:'100%'}}"
                    [class.example-expanded-row]="expandedElement === row"
                    (click)="expandedElement = expandedElement === row ? null : row; handleRowClick(row,expandedElement === row)">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
            </table>
        </div>

        <mat-spinner *ngIf="isLoading"
                     class="assignation-groups-list__spinner">
        </mat-spinner>

        <mat-paginator class="assignation-groups-list__pagination"
                       [length]="countEntities"
                       [pageIndex]="pageIndex"
                       [pageSize]="pageRange"
                       [pageSizeOptions]="pageRangeOptions"
                       (page)="onPaginateChange($event)">
        </mat-paginator>

    </div>

</div>