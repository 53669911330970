<form [formGroup]="form" (ngSubmit)="submit()">
    <mat-form-field class="compass-search-form">
<!--        <mat-label>{{'compass.search_label' | translate}}</mat-label>-->
        <input matInput formControlName="search" placeholder="{{'compass.search_placeholder' | translate}}">
        <button matSuffix class="compass-search-form__button"
                aria-label="search"
                type="submit"
        >
            <mat-icon svgIcon="magnify"></mat-icon>
        </button>
    </mat-form-field>

</form>