<div class="page-layout simple fullwidth" fxFlex fxLayout="column">
    <div class="content" fxFlex fxLayout="column">
        <app-graph-details-modal *ngIf="displayTooltipIndex !== null" [infoSettings]="graphDetailsModal"></app-graph-details-modal>
        <canvas #chart
                [type]="barChartType"
                [datasets]="barChartData"
                [legend]="barChartLegend"
                [options]="barChartOptions"
                [labels]="barChartLabels"
                baseChart>
        </canvas>
    </div>
</div>
