<section class="legal-notice">

    <h1 class="legal-notice__title">{{ 'generic.legal_notice' | translate }}</h1>

    <div *ngIf="showLoader"
         class="legal-notice__spinner-wrapper spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>

    <mat-accordion *ngIf="!showLoader"
                   class="legal-notice__accordion">
        <mat-expansion-panel *ngFor="let page of pagesReady;let index=index"
                             [attr.id]="'focus_'+index"
                             (afterExpand)="focus(index)"
                             class="legal-notice__panel"
                             hideToggle>
            <mat-expansion-panel-header class="legal-notice__panel-header">
                <mat-icon class="legal-notice__panel-icon"
                          [svgIcon]="page.icon">
                </mat-icon>
                <h2 class="legal-notice__panel-title"
                    [innerHTML]="page.title">
                </h2>
                <mat-icon class="legal-notice__arrow-icon"
                          svgIcon="arrow-forward-ios-rounded">
                </mat-icon>
            </mat-expansion-panel-header>
            <div class="legal-notice__panel-content"
                 [innerHTML]="page.body">
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</section>