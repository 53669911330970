<div class="usersaves-list page-layout simple fullwidth inner-scroll"
     fusePerfectScrollbar>

    <div class="usersaves-list__container p-16 content inner-scroll"
         fxFlex
         fxLayout="column">

        <app-banner-info *ngIf="!isAssignationClosedActive"
                         class="usersaves-list__banner-info"
                         [rolesCanShowBanner]="rolesCanShowBannerInfo"
                         situation="activity">
        </app-banner-info>

        <app-banner-info *ngIf="isAssignationClosedActive"
                         class="usersaves-list__banner-info"
                         [rolesCanShowBanner]="rolesCanShowBannerInfoClosedAssignment"
                         situation="closed">
        </app-banner-info>

        <app-followed-filter *ngIf="shouldDisplayFilters()"
                             class="usersaves-list__filter"
                             [displayedFilters]="displayedFilters"
                             [groupsList]="assignationService.groupsList"
                             [workgroupsList]="assignationService.workgroupsList"
                             [learnersList]="assignationService.learnersList"
                             [countEntities]="countEntities"
                             (launchSearch)="launchSearch($event)">
        </app-followed-filter>

        <div *ngIf="!isLoading"
             class="usersaves-list__content content touch-scroll">

            <table class="usersaves-list__table"
                   mat-table
                   #table
                   [dataSource]="dataSource"
                   *fuseIfOnDom
                   matSort
                   multiTemplateDataRows>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'account-management.pseudo' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.id}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="learner">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'account-management.pseudo' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.uid}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lesson">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'assignation.lesson' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.lesson}}
                    </td>

                </ng-container>
                <ng-container matColumnDef="questionSet">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'assignation.subLesson' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        <span *ngIf="isCellContentMustBeDisplayed(row)" >{{row?.questionSet}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="activity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'assignation.activityName' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.activity}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="errors">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'assignation.errorsCount' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.errorsCount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="context">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'assignation.context' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        <span class="tag" [ngClass]="row?.context">{{ 'assignment.' + row?.context | translate}}</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    matRipple>
                </tr>

            </table>

        </div>

        <mat-spinner *ngIf="isLoading"
                     class="usersaves-list__spinner">
        </mat-spinner>

        <mat-paginator class="usersaves-list__pagination"
                       (page)="onPaginateChange($event)"
                       [length]="countEntities"
                       [pageIndex]="pageIndex"
                       [pageSizeOptions]="pageRangeOptions">
        </mat-paginator>

    </div>

</div>