<div id="header-upload-exercice">
    <h3>{{ 'upload.exo.title'|translate}}</h3>
    <button class="mat-accent"
            (click)="download()" type="button" mat-raised-button>
        <span>{{'download.model'| translate}}</span>
    </button>
</div>
<div class="content">
    <!--upload local zip-->
    <div class="center text-center">
        <span>{{ 'download.import.url.informations' | translate }}</span><br>
        <em><small>{{ 'download.import.url.details.rules' | translate }}</small></em>
        <ngx-file-drop [accept]="config.formatAllowed" (onFileDrop)="dropped($event)">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <button mat-raised-button class="add-file-button mat-accent" (click)="openFileSelector()"
                        aria-label="Add file">
                    <span>{{ 'generic.browse.upload' | translate }}</span>
                </button>
            </ng-template>
        </ngx-file-drop>
    </div>

    <span>{{fileSelected}}</span>

    <!--success zone-->
    <div *ngIf="fileUploaded">
        <span class="mat-success">{{'upload.done' | translate}}</span>
    </div>


    <!--alert zone-->
    <div *ngIf="error.tooManyFiles">
        <span class="mat-error">{{'upload.too.many.files' | translate}}</span>
    </div>

    <div *ngIf="error.isNotFormatAllowed">
        <span class="mat-error">{{'upload.bad.format' | translate}}</span>
    </div>

    <div *ngIf="error.sizeTooBig">
        <span class="mat-error">{{'upload.size.limit' | translate}}</span>
    </div>

    <div *ngIf="uploadError">
        <span class="mat-error">{{'upload.generic.upload.error' | translate}}</span>
        <span class="mat-error" [innerHTML]="uploadError"></span>
    </div>


    <mat-progress-bar *ngIf="progress!==0"
                      [ngClass]="uploadError?'error-upload':'progress-upload'"
                      [value]="progress"></mat-progress-bar>

</div>

