// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
  resize: none !important;
}

.idea-form-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
.idea-form-dialog .mat-dialog-container .mat-toolbar {
  min-height: initial;
}
.idea-form-dialog .mat-dialog-container .toolbar-bottom {
  height: auto;
}
.idea-form-dialog .dialog-content-wrapper {
  max-height: 85vh;
  display: flex;
  flex-direction: column;
}

.definition-icon {
  width: 34px;
  height: 34px;
  margin: 8px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  line-height: 36px;
  font-weight: bold;
  font-size: 14px;
  color: #FF787D;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);
}
.definition-icon:hover, .definition-icon.selected {
  background: #FF787D;
  color: white;
}

.icons-container {
  text-align: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/ideas-wall/core/ideas-wall-edition-modal/ideas-wall-edition-modal.component.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAcE;EACE,UAAA;EACA,gBAAA;AAXJ;AAaI;EACE,mBAAA;AAXN;AAcI;EACE,YAAA;AAZN;AAgBE;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;AAdJ;;AAkBA;EACE,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EAEA,mBAAA;EACA,iDAAA;AAhBF;AAkBE;EACE,mBAAA;EACA,YAAA;AAhBJ;;AAwBA;EACE,kBAAA;EACA,WAAA;AArBF","sourcesContent":["@import \"@fuse/scss/fuse\";\n\ntextarea {\n  resize: none !important;\n}\n\n.idea-form-dialog {\n\n  //@include media-breakpoint('xs') {\n  //  width: 100%;\n  //}\n  //\n  //@include media-breakpoint-up('xs') {\n  //  width: 400px;\n  //}\n\n  .mat-dialog-container {\n    padding: 0;\n    overflow: hidden;\n\n    .mat-toolbar {\n      min-height: initial;\n    }\n\n    .toolbar-bottom {\n      height: auto;\n    }\n  }\n\n  .dialog-content-wrapper {\n    max-height: 85vh;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.definition-icon {\n  width: 34px;\n  height: 34px;\n  margin: 8px;\n  border-radius: 50%;\n  cursor: pointer;\n  text-align: center;\n  display: inline-block;\n  line-height: 36px;\n  font-weight: bold;\n  font-size: 14px;\n  color: #FF787D;\n\n  background: #FFFFFF;\n  box-shadow: 0px 0px 10px rgba(255, 120, 125, 0.3);\n\n  &:hover, &.selected {\n    background: #FF787D;\n    color: white;\n  }\n}\n\nmat-form-field, mat-select, mat-form-field {\n  //width: 100%;\n}\n\n.icons-container {\n  text-align: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
