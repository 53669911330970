<mat-card class="activities-card inner-scroll mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [wording]="wording"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                questionTypeName="{{ 'activities.questionTypeName.app-draw-line' | translate }}">
            </app-consignes>
            <app-consignes
                *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                [ngClass]="{'consigneImg': isInstructionImg()}"
                [instruction]="instruction"
                [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
                questionTypeName="{{ 'activities.questionTypeName.app-draw-line' | translate }}">
            </app-consignes>
            <!-- to set false qcmOption and qcuOptions and send answerStatus -->
            <app-options [activityId]="activityId" [answerStatus]="answerStatus" [contextId]="assignmentId"
                         [mode]="'drawLine'">
            </app-options>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <div class="canvas-wrapper">
            <canvas #canvasElement [height]="canvasHeight" [width]="canvasWidth"></canvas>
        </div>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div *ngIf="isPercentileDisplayAllowed"
             class="gradePercentil">{{ 'generic.score' | translate}}{{showPercentile}}</div>
    </mat-card-footer>

</mat-card>
