<div mat-dialog-title>
    <button (click)="selfDialogRef.close(false)" aria-label="Close dialog" class="close">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-dialog-content fusePerfectScrollbar>

    {{modalData.contentKey | translate}}

</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="selfDialogRef.close(true)" class="button mat-button" mat-button
            mat-raised-button>{{'generic.yes' | translate}}</button>
    <button (click)="selfDialogRef.close(false)" class="button mat-button" mat-button
            mat-raised-button>{{'generic.no' | translate}}</button>
</mat-dialog-actions>