<div mat-dialog-title>
  <h2>{{ 'corpus.add_resource' | translate }}</h2>

  <button
          class="close"
          (click)="dialogRef.close()"
          aria-label="Close dialog">
    <mat-icon svgIcon="cross"></mat-icon>
  </button>
</div>

<form [formGroup]="form">
  <div mat-dialog-content>
    <div class="content p-24" fxLayout="column" fxLayoutAlign="strech" fxFlex="1 0 auto">
    <mat-form-field>
        <mat-label>{{ 'activities.url' | translate }}</mat-label><input matInput formControlName="url" />
        <mat-error *ngIf="form?.get('url')?.errors?.urlInvalid">
            {{ 'activities.url_invalid' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'generic.title' | translate }}</mat-label><input matInput formControlName="title" />
    </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'generic.date' | translate }}</mat-label><input matInput
               formControlName="date"
               
               type="text">
      </mat-form-field>

      <mat-form-field fxFlex="100">
        <mat-label>{{ 'generic.source_author' | translate }}</mat-label><input matInput
               formControlName="author"
               
               type="text">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'generic.source' | translate }}</mat-label><input matInput
               formControlName="source"
               
               type="text">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'generic.description' | translate }}</mat-label><textarea matInput
                  rows="7"
                  formControlName="description"
                  
                  type="text">
        </textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="pt-24">
    <button [disabled]="form.invalid" type="submit"  color="accent" class="submit-button"
            value="{{ 'generic.validate' | translate }}"
            (click)="validate()">{{ 'generic.validate' | translate }}</button>
  </div>
</form>

