<div class="assignment-manage-dates">

    <div class="assignment-manage-dates__breadcrumb">

        <div *ngFor="let item of breadcrumb; let isLast = last"
              class="assignment-manage-dates__breadcrumb-item">
            <span class="assignment-manage-dates__breadcrumb-label">{{ item }}</span>
            <mat-icon *ngIf="!isLast"
                      class="assignment-manage-dates__breadcrumb-icon"
                      svgIcon="chevron-right-rounded">
            </mat-icon>
        </div>

    </div>

    <div class="assignment-manage-dates__content">

        <h3 class="assignment-manage-dates__title">{{ 'assignment.when' | translate }}</h3>

        <div class="assignment-manage-dates__fieldset-container">

            <!-- Wrapper needed to properly style the fieldset element -->
            <div class="assignment-manage-dates__fieldset-wrapper">
                <fieldset class="assignment-manage-dates__fieldset">
                    <legend class="assignment-manage-dates__fieldset-legend">
                        <mat-icon class="assignment-manage-dates__legend-icon"
                                  svgIcon="event-upcoming-rounded-fill">
                        </mat-icon>
                        <span class="assignment-manage-dates__legend-label">{{ 'assignment.starts' | translate }}</span>
                    </legend>
                    <div class="assignment-manage-dates__input-wrapper">
                        <input matInput
                               [attr.aria-label]="'generic.start_date' | translate"
                               [value]="selectedStartDate"
                               [matDatepicker]="startDatePicker"
                               (dateChange)="startDateSelected($event)">
                        <mat-datepicker-toggle matSuffix
                                               [for]="startDatePicker">
                            <mat-icon matDatepickerToggleIcon
                                      svgIcon="arrow-drop-down-rounded-700">
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #startDatePicker></mat-datepicker>
                    </div>
                    <div class="assignment-manage-dates__select-wrapper">
                        <mat-select [attr.aria-label]="'generic.start_time' | translate"
                                    [(ngModel)]="selectedStartHour"
                                    (selectionChange)="onStartHourSelectionChange($event)">
                            <mat-option *ngFor="let hour of hours"
                                        [value]="hour">
                                <span>{{ hour }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </fieldset>
            </div>

            <!-- Wrapper needed to properly style the fieldset element -->
            <div class="assignment-manage-dates__fieldset-wrapper">
                <fieldset class="assignment-manage-dates__fieldset">
                    <legend class="assignment-manage-dates__fieldset-legend">
                        <mat-icon class="assignment-manage-dates__legend-icon"
                                  svgIcon="event-rounded-fill">
                        </mat-icon>
                        <span class="assignment-manage-dates__legend-label">{{ 'assignment.ends' | translate }}</span>
                    </legend>
                    <div class="assignment-manage-dates__input-wrapper">
                        <input matInput
                               [attr.aria-label]="'generic.deadline' | translate"
                               [value]="selectedEndDate"
                               [matDatepicker]="endDatePicker"
                               (dateChange)="endDateSelected($event)">
                        <mat-datepicker-toggle matSuffix
                                               [for]="endDatePicker">
                            <mat-icon matDatepickerToggleIcon
                                      svgIcon="arrow-drop-down-rounded-700">
                            </mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #endDatePicker></mat-datepicker>
                    </div>
                    <div class="assignment-manage-dates__select-wrapper">
                        <mat-select [attr.aria-label]="'generic.end_time' | translate"
                                    [(ngModel)]="selectedEndHour"
                                    (selectionChange)="onEndHourSelectionChange($event)">
                            <mat-option *ngFor="let hour of hours"
                                        [value]="hour">
                                <span>{{ hour }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                </fieldset>
            </div>

        </div>

    </div>

</div>