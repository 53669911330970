<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [ngClass]="{'consigneImg': isWordingImg()}"
                [wording]="wording"
                [wordingAudio]="wordingAudio"
                (isReading)="speakStateChanged($event)"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
                questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
            <app-consignes
                *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                [ngClass]="{'consigneImg': isInstructionImg()}"
                [instruction]="instruction"
                [instructionAudio]="instructionAudio"
                (isReading)="speakStateChanged($event)"
                [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
                questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="app-flashcard-wrapper"
             [ngClass]="'answers-counting-' + numberOfFlashCards">
            <app-flashcard *ngFor="let flashcard of availableFlashCards"
                           [ngClass]="flashCardClass(flashcard)"
                           [flashCardStopPlayingOrderSubject]="flashCardStopPlayingOrderSubject"
                           [showTitleOnlyIfNoAudio]="true"
                           [flashCard]="flashcard"
                           [resetFlashCardSubject]="resetFlashCardSubject"
                           (state)="onStateChange($event, flashcard)"
                           [readBlocked]="isAwarenessWithoutAudioMedia()">
            </app-flashcard>
        </div>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button
                    [ngClass]="button.classCss"
                    *ngIf="button.display"
                    [disabled]="isDone() === false || button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>