<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpened" (opened)="setStatePanel(true)" hideToggle>

        <mat-expansion-panel-header>
            <div fxLayoutAlign="center center" fxFlex>
                <mat-icon svgIcon="add"></mat-icon>
                <span>&nbsp;{{'licensing.method.add' | translate}}</span>
            </div>
        </mat-expansion-panel-header>

        <form [formGroup]="form">

            <ng-container *ngIf="!addMethodSuccess && !addMethodError">
                    <p class="lead text-info">
                        {{'licensing.method.instructions.verif' | translate}}<br/>
                        {{'licensing.method.instructions.verif2' | translate}}
                    </p>
                    <mat-form-field>
                        <input matInput
                            placeholder="{{ 'licensing.method.add.code' | translate }}"
                            formControlName="methodCode" required>
                        <mat-error *ngIf="formErrors.methodCode.required">
                            {{ 'generic.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <button mat-raised-button (click)="checkAndUnlock()"
                        [disabled]="codeInputCheck()">
                        <span>{{ 'licensing.method.check_unlock' | translate }}</span>
                    </button>
                    <button mat-raised-button (click)="setStatePanel(false)">
                        <span>{{ 'generic.cancel' | translate }}</span>
                    </button>
            </ng-container>

        <ng-container *ngIf="addMethodSuccess && !addMethodError">
            <div class="lead text-success">
                <p>
                    {{'licensing.method.add.success' | translate}}<br/>
                    <span *ngIf="lastMethodNameAdded">{{lastMethodNameAdded}}</span>
                    {{'licensing.method.add.success2' | translate}}
                </p>
                <div class="pastille pastille-icon">
                    <mat-icon svgIcon="check"></mat-icon>
                </div>
            </div>
            <button mat-raised-button (click)="setStatePanel(false)">
                {{ 'generic.ok' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="!addMethodSuccess && addMethodError">
                <div class="lead text-danger">
                    <p>
                        {{ errorKeyMessages.main | translate}}<br/>
                        {{ errorKeyMessages.details | translate}}<br/>
                        {{form.controls['methodCode']?.value}}
                    </p>
                </div>
                <button mat-raised-button (click)="setStatePanel(false)">
                    {{ 'generic.close' | translate }}
                </button>
        </ng-container>

        </form>
    </mat-expansion-panel>
</mat-accordion>
