<div fxLayout="column" class="page-layout simple fullwidth inner-scroll" fxFlex>
    <!-- HEADER -->
    <div class="header" fxLayout="row" fxLayoutAlign="start center">
        <h1>{{'generic.lesson' | translate}}:&nbsp;{{title}}</h1>
    </div>
    <div class="content">
        <!-- Is Like an other inner component -->
        <div fxLayout="column" class="page-layout simple fullwidth" fxFlex>

            <!-- HEADER -->
            <div class="header" fxLayout="row" fxLayoutAlign="start center">
                <h2 fxLayout="row" fxLayoutAlign="start center" fxFlex="fill">
                    <mat-icon [svgIcon]="stepIcon(stepIndex)" class="mr-16"></mat-icon>
                    <span fxLayout="row" fxLayoutAlign="start center">
                        {{+stepIndex + 1}}&nbsp;-&nbsp;{{stepTitle | translate}}
                    </span>
                    <span class="ml-auto"><span>{{+stepIndex + 1}}</span>/5</span>
                </h2>

            </div>

            <div class="content inner-scroll pt-24 pb-24 pl-40 pr-40" fxFlex fxLayout="row">
                <div class="page-layout simple fullwidth inner-scroll" fxLayout="column" fxFlex>

                    <div class="header" fxLayout="row" fxLayoutAlign="start stretch">
                        <mat-icon svgIcon="message_circle"  class="mr-16" ></mat-icon>
                        <p fxFlex="95">{{('edit_lesson.instruction_step' + stepIndex) | translate}}</p>
                    </div>

                    <div fxLayout="row" fxFlex>
                        <div fxFlex="none" fxLayout="column" fxLayoutAlign="center none" class="pr-8">
                            <button [style.visibility]="!(stepIndex > 0)? 'hidden': 'visible'" mat-fab class="preview" (click)="previousActivity()" aria-label="preview">
                                <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
                            </button>
                        </div>

                        <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center stretch"
                             class="center content nowindow">
                            <content>
                                <router-outlet></router-outlet>
                            </content>
                        </div>

                        <div fxFlex="none" fxLayout="column" fxLayoutAlign="center none" class="pl-8">
                            <button [style.visibility]="!(selectedLessonContent.length > 0 && +stepIndex < selectedLessonContent.length - 1) ? 'hidden': 'visible'" mat-fab class="next" (click)="nextActivity()" aria-label="next">
                                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- -------------------------------- -->
    </div>
    <div class="edit-lesson-actions" fxLayoutAlign="center center">
        <button mat-raised-button (click)="exit()">
            <span>{{ 'generic.exit' | translate }}</span>
        </button>
    </div>
</div>
