<div class="assignment-select-one-seance">

    <div class="assignment-select-one-seance__breadcrumb">

        <div *ngFor="let item of breadcrumb; let isLast = last"
              class="assignment-select-one-seance__breadcrumb-item">
            <span class="assignment-select-one-seance__breadcrumb-label">{{ item }}</span>
            <mat-icon *ngIf="!isLast"
                      class="assignment-select-one-seance__breadcrumb-icon"
                      svgIcon="chevron-right-rounded">
            </mat-icon>
        </div>

    </div>

    <div class="assignment-select-one-seance__content">

        <h3 class="assignment-select-one-seance__title">{{ 'assignment.what' | translate }} <cite>{{ title }}</cite> ?</h3>

        <div *ngIf="seances.length > 0"
             class="assignment-select-one-seance__session-wrapper">
            <button *ngFor="let s of seances"
                    class="assignment-select-one-seance__session-button"
                    (click)="launch(s)">
                <span class="assignment-select-one-seance__session-label">{{ s.label }}</span>
                <mat-icon svgIcon="next"></mat-icon>
            </button>
        </div>

    </div>

</div>