import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/services/lessons.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {
    ActivityGranule,
    ActivityGranuleAttributes
} from '@modules/activities/core/models/activities/activity-granule.attributes';
import {DrawLineService} from '@modules/activities/core/services/draw-line.service';
import {AnswerInterface} from '@modules/activities/core/models/answer.interface';
import {environment} from 'environments/environment';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ActivatedRoute} from '@angular/router';
import {AnswerResultInterface} from '@modules/activities/core/models';
import {AuthenticationService} from '@modules/authentication';
import {ContextualService} from '@modules/activities/core/services/contextual.service';
import {takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from 'shared/models';
import {
    DrawLineActivityGranule
} from '@modules/activities/core/models/activities/typologies/draw-line-activity.granule';
import {LessonsConfigurationService} from '@modules/activities/core/lessons/services/lessons-configuration.service';
import {userReview} from '../../../../../settings';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Platform} from '@angular/cdk/platform';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {UserReviewComponent} from 'fuse-core/components/user-review/user-review.component';
import {UserReviewService} from 'fuse-core/components/user-review/user-review.service';

const ACTIVITIES_WITHOUT_CORRECTION: TypologyLabel[] = [
    TypologyLabel.divider,
    TypologyLabel.audio,
    TypologyLabel.external,
    TypologyLabel.image,
    TypologyLabel.interactiveImage,
    TypologyLabel.tool,
    TypologyLabel.summary,
    TypologyLabel.video,
    TypologyLabel.recording,
    TypologyLabel.multimedia,
    TypologyLabel.awareness,
    TypologyLabel.memory,
    TypologyLabel.imageSoundZone,
];

const GOOD_FEEDBACK_THRESHOLD = 6;

@Component({
    selector: 'app-summary-sub-lesson',
    templateUrl: './summary-sub-lesson.component.html',
    styleUrls: ['./summary-sub-lesson.component.scss'],
})
export class SummarySubLessonComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnDestroy {

    protected readonly TypologyLabel = TypologyLabel;

    private activitiesService = inject(ActivitiesService);
    private activatedRoute = inject(ActivatedRoute);
    private lessonsService = inject(LessonsService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private contextualService = inject(ContextualService);
    private communicationCenter = inject(CommunicationCenterService);
    private drawLineService = inject(DrawLineService);
    private authenticationService = inject(AuthenticationService);
    private userReviewService = inject(UserReviewService);

    public panelOpenState = false;
    public shouldDisplayGradeBlock = this.lessonsConfigurationService.displayGradeBlockInSummarySubLesson();
    public shouldDisplayDetailsBlock = this.lessonsConfigurationService.displayDetailsBlockInSummarySubLesson();
    public showBasePlatformPathButton = this.lessonsConfigurationService.showBasePlatformPathButton();

    public activities: ActivityGranule[] = [];
    public basePlatformPath: string = environment.basePlatformPath;
    private isMobile: boolean;
    private _bottomSheet = inject(MatBottomSheet);
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    public dialogNpsRef: MatDialogRef<UserReviewComponent>;
    public _bottomSheetRef: MatBottomSheetRef<UserReviewComponent>;
    private userReviewSetting: string[] = userReview;

    constructor(
        private _platform: Platform,
        private dialog: MatDialog,
    ) {
        super();
        this.isMobile = false;
        this.panelOpenState = this.authenticationService.isLearner();
        this.communicationCenter
            .getRoom('stepper')
            .getSubject('display')
            .next(false);
    }

    public get assignmentId(): string {
        return this.lessonsService.currentAssignment?.id.toString();
    }

    public get isMyAssignment(): boolean {
        return !this.lessonsService.currentAssignment
            || (!!this.lessonsService.currentAssignment.get('assignated_user')?.id
                && this.authenticationService.isMe(this.lessonsService.currentAssignment.get('assignated_user').id));
    }

    /**
     * savoir si on a une activité suivante pour afficher le bouton "next"
     */
    public get isNextActivity(): boolean {
        return !!this.lessonsService.currentLesson?.get('reference')[this.activitiesService.presentArrayElementIndex + 1];
    }

    private get nextActivityName(): string {
        return this.lessonsService.currentLesson?.get('reference')[this.activitiesService.presentArrayElementIndex + 1]?.title;
    }

    public get displayRetryBtn(): boolean {
        return this.lessonsConfigurationService.displayBtnRetryInSummarySubLesson && !this.activatedRoute.snapshot?.queryParams?.navigateDirectlyToSummary;
    }

    ngOnInit(): void {
        if ( this._platform.ANDROID || this._platform.IOS ) {
            this.isMobile = true;
        }
        const now = new Date();
        const localStorageDate = localStorage.getItem('user_review_ask');
        if (
            (!localStorageDate || localStorageDate !== now.toDateString()) // localStorage ok
            && this.userReviewSetting.length > 0 // NPS available for some roles
            && this.lessonsService.currentLesson.get('allow_user_review') === true // granule ok
        ){
            this.communicationCenter.getRoom('authentication').getSubject('userData').subscribe(
                (userData) => {
                    const rolesArray = userData.get('role');
                    let userRole = '';
                    if (rolesArray.includes(3)) {
                        userRole = 'administrator';
                    }
                    if (rolesArray.includes(4)) {
                        userRole = 'manager';
                    }
                    if (rolesArray.includes(5)) {
                        userRole = 'trainer';
                    }
                    if (rolesArray.includes(6)) {
                        userRole = 'learner';
                    }
                    // display content score modal

                    // Check if user already answered the question
                    this.userReviewService.getUserReview(userData.id, +this.lessonsService.currentLesson.id).subscribe(
                        (result) => {
                            if (
                                userRole
                                && result.entities.length === 0 // user never answered for this lesson
                                && this.userReviewSetting.includes(userRole) // setting ok
                                && this.lessonsService.currentLesson.get('allow_user_review') === true // granule ok
                                && userData.get('displayUserReview') // back ok
                            ) {
                                if (this.isMobile) { // if mobile, display in bottom sheet
                                    this._bottomSheetRef = this._bottomSheet.open(UserReviewComponent, {
                                        data: {
                                            itemToReview: this.lessonsService.currentLesson,
                                            uid: userData.id,
                                            type: 'bottomSheet'
                                        },
                                        panelClass: 'user-review-bottom-sheet-wrapper',
                                    });
                                    this._bottomSheetRef.afterDismissed().subscribe(result => {
                                        localStorage.setItem('user_review_ask', now.toDateString());
                                    });
                                } else { // else display in dialog
                                    this.dialogNpsRef = this.dialog.open(UserReviewComponent, {
                                        data: {
                                            itemToReview: this.lessonsService.currentLesson,
                                            uid: userData.id,
                                            type: 'dialog'
                                        },
                                        panelClass: 'user-review-dialog-wrapper',
                                    });
                                    this.dialogNpsRef.afterClosed().subscribe(result => {
                                        localStorage.setItem('user_review_ask', now.toDateString());
                                    });
                                }
                            }
                        }
                    );
                }
            );
        }
        
        this.communicationCenter.getRoom('header-exo').next('show', false);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', true);
        this.communicationCenter.getRoom('footer').next('visibility', false);
        this.initialize();
        this.setupContextual();
        // scroll on top to ever had summary title on top
        document.getElementsByTagName('app-summary-sub-lesson')[0].scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.communicationCenter.getRoom('progress-bar-exo').next('reset', false);
        this.communicationCenter
            .getRoom('activities')
            .next('retryCurrentActivity', null);
        this.communicationCenter.getRoom('activities').next('nextActivity', null);
        this.communicationCenter.getRoom('activities').next('backToLesson', false);
        this.communicationCenter.getRoom('header-exo').next('show', true);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', false);
        this.communicationCenter.getRoom('footer').next('visibility', true);
        this.communicationCenter.getRoom('stepper').next('display', true);
        document.getElementsByTagName('body')[0].classList.remove('summary');
    }

    initialize(): void {
        this.addSummaryClassToBody();
        this.activities = this.getActivities();

        // hotfix, should be fix in higher level
        this.activities = this.removeDuplicates(this.activities);
        const params = this.activatedRoute.pathFromRoot.reduce((params, route) => ({...params, ...route.snapshot.params}), {});
        const lessonId = params['lessonId'];

        const queryParams = this.activatedRoute.pathFromRoot.reduce((params, route) => ({...params, ...route.snapshot.queryParams}), {});
        const subLessonId = queryParams['subLessonId'];

        const target = subLessonId ?? lessonId;
        if (queryParams['navigateDirectlyToSummary']) {
            const config = this.lessonsService.currentAssignment.get('config');
            if (config) {
                const data = JSON.parse(config);
                if (data[target]) {
                    this.activities.forEach((activity) => {
                        if (data[target].scoreByActivities[activity.id]) {
                            this.activitiesService.answersProgressBarMultiZone.push(
                                {
                                    id: +activity.id,
                                    state: +data[target].scoreByActivities[activity.id] === 100 ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.currentlyCorrect,
                                    isLast: true
                                }
                            );
                        }
                    })
                }
            }
        }
        if (this.lessonsService.currentAssignment && +this.lessonsService.currentAssignment.get('assignated_user').uid === +this.lessonsService.currentUser.id) {
            this.lessonsService.updateCurrentLessonState();
            if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
                const score = this.lessonsService.currentAssignment ? this.lessonsService.calculateAndSaveQuestionSetProgress(target) : 0;
                const statementData = {
                    id: `questionSet/${target}`,
                    result: {
                        score: {
                            scaled: score / 100,
                        },
                        completion: true
                    }
                };

                this.communicationCenter.getRoom('lrs').getSubject('activity_complete').next(statementData);
            }
        }
    }

    private setupContextual(): void {
        this.contextualService.actionLessonPlay$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.nextActivity());
        this.contextualService.actionLessonRestart$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.retry());
        this.contextualService.actionLessonSummary$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(() => this.panelOpenState = true);
        this.contextualService.conditionLessonAvailable$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.isNextActivity && this.isMyAssignment));
        this.contextualService.conditionLessonCanRestart$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.displayRetryBtn));
        this.contextualService.dataLessonNextLessonName$
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((callback) => callback(this.nextActivityName));
    }

    addSummaryClassToBody(): void {
        document.getElementsByTagName('body')[0].classList.add('summary');
    }

    getActivities(): ActivityGranule[] {
        if (this.lessonsService.subLessonContentEdited.length) {
            return <ActivityGranule[]>this.lessonsService.subLessonContentEdited;
        }
        return <ActivityGranule[]>this.lessonsService.activities.filter(
            (activity) => activity?.get('metadatas')?.typology?.label !== 'EXT'
        );
    }

    removeDuplicates(activities: ActivityGranule[]): ActivityGranule[] {
        const uniqueActivities: ActivityGranule[] = [];
        const map = new Map();
        for (const activity of activities) {
            if (!map.has(activity.id)) {
                map.set(activity.id, activity);
                uniqueActivities.push(activity);
            }
        }
        return uniqueActivities;
    }

    public answers(activity: DataEntity): AnswerInterface[] {
        return (
            activity.get('reference').activity_content.answers ||
            activity.get('reference').activity_content.answers_app
        );
    }

    public getOnlyCorrectAnswer(
        itemAnswerInterfaces: AnswerInterface[]
    ): AnswerInterface[] {
        return itemAnswerInterfaces.filter((answer) => !!+answer.correct_answer);
    }

    public getOnlyCorrectFirstAnswers() {
        return this.activities.filter((activity) => this.isSuccessOnFirstAttempt(activity));
    }

    public getAnswersGradeFeedback(): string {
        const correctAnswers = this.getOnlyCorrectFirstAnswers();
        if (correctAnswers.length < GOOD_FEEDBACK_THRESHOLD) {
            return 'activities.summary_not_so_good_feedback';
        }
        return 'activities.summary_good_feedback';
    }

    public getParsedAnswer(answer: string): string[] {
        return answer && JSON.parse(answer);
    }

    public replaceFieldToFind(activity: DataEntity): string {
        return activity
            .get('reference')
            .activity_content.answer_text.replace(/\[]/g, '_ _ _ _');
    }

    public answerState(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): string {
        if (this.noCorrectionRequired(activity)) {
            return ItemAnswerStateEnum.wasCorrect;
        }

        if (!this.isSuccessOnFirstAttempt(activity)) {
            return ItemAnswerStateEnum.incorrect;
        }

        return ItemAnswerStateEnum.wasCorrect;
    }

    retry(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter
            .getRoom('activities')
            .next('retryCurrentActivity', this.activatedRoute.snapshot?.queryParams);
    }

    nextActivity(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter.getRoom('activities').next('nextActivity', this.activatedRoute?.snapshot?.queryParams);
    }

    back(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter
            .getRoom('activities')
            .next('backToLesson', {forceUrl: [], initialiseSubject: true});
    }

    public getItemAnswerInterfaceAsALine(
        onlyCorrectAnswer: AnswerInterface[]
    ): string {
        return onlyCorrectAnswer.map((a) => a.answer).join(', ');
    }

    public getDrawLineActivityImagePaths(activity: DrawLineActivityGranule): string[] {
        const imageIdentifier = activity.get('reference').config.charId;

        const dirPath = this.drawLineService.getRootImageDirectoryPath();
        return this.drawLineService
            .getTemplate(imageIdentifier)
            .steps.map((s) => s.imagePath)
            .map((i) => dirPath + i);
    }

    public isSuccessOnFirstAttempt(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        const answerResults = this.activitiesService.answersProgressBarMultiZone.filter((answerResult: AnswerResultInterface) => +answerResult.id === +activity.id);
        const incorrectAnswers = answerResults.filter((answerResult: AnswerResultInterface) => {
            return answerResult.state !== ItemAnswerStateEnum.currentlyCorrect && answerResult.state !== ItemAnswerStateEnum.wasCorrect && !answerResult.forceStateToCurrentlyCorrect;
        });

        return answerResults.length > 0 && incorrectAnswers.length === 0;
    }

    public noCorrectionRequired(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        return activity.get('metadatas')?.typology?.label && ACTIVITIES_WITHOUT_CORRECTION.includes(activity.get('metadatas')?.typology?.label);
    }

    public isAnswerMissing(activity: TypedDataEntityInterface<ActivityGranuleAttributes>): boolean {
        const answers = this.activitiesService.answersProgressBarMultiZone.filter((act) => +act.id === +activity.id);
        const userAnswersAttemptNotMissing =
            !!answers.length && answers.some((act: AnswerResultInterface) =>
                act.state !== ItemAnswerStateEnum.missing || act.forceStateToCurrentlyCorrect === true
            );
        // il peut y avoir plusieurs essais selon l'activité et on veut savoir s'il a passé l'activité sans donner de réponse
        return (!answers.length || !userAnswersAttemptNotMissing);
    }

}
