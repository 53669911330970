<div class="page-layout simple fullwidth" fusePerfectScrollbar>

  <!-- HEADER -->
  <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">
    <h2>{{ 'notification.title' | translate }}</h2>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24 mat-white-bg">
      <app-notif-list></app-notif-list>
  </div>
  <!-- / CONTENT -->

</div>

