<ng-container *ngIf="exploreEntity$ | async as exploreEntity">
    <a [queryParams]="getBackQueryParams()" [routerLink]="getBackUrl()" aria-label="précédent"
       class="compass__back__link">
        <mat-icon svgIcon="back"></mat-icon>
        <span>{{ 'compass.home' | translate }}</span>
    </a>

    <section>
        <div [ngClass]="'map-theme-' + exploreEntity.get('key')" class="compass-themes__header map-theme">
            <h2>{{ exploreEntity.get('label') }}</h2>
            <p>{{ exploreEntity.get('description') }}</p>
        </div>
    </section>
    <section>
        <div class="compass-themes__content">
            <div *ngIf="isFiltersActive" class="compass-themes__filter">
                <mat-form-field appearance="fill" class="chip-list compass-themes__filter__list">
                    <mat-chip-grid #chipList aria-label="selected chip list">
                        <mat-chip-row
                            (removed)="onThemeFilterRemoveClick(item)"
                            *ngFor="let item of entitiesForFilter">
                            {{ item.get('label') }}
                            <button [attr.aria-label]="'remove ' + item.get('label')" matChipRemove>
                                <mat-icon matChipRemove svgIcon="cross"></mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input
                        #filterInput
                        (matChipInputTokenEnd)="onThemeFilterInputSubmit($event)"
                        [formControl]="filterCtrl"
                        [matAutocomplete]="auto"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        placeholder="Tous les types...">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onThemeFilterSelected($event)">
                        <mat-option *ngFor="let item of entitiesFiltered | async" [value]="item">
                            {{ item.get('label') }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <!------------------------------------- LEVELS ------------------------------------->

                <mat-form-field appearance="fill" class="levels-chip-list compass-themes__filter__list__levels">
                    <mat-chip-grid #levelsList aria-label="Level selection">
                        <mat-chip-row
                            (removed)="onLevelRemoveClick(level)"
                            *ngFor="let level of levels">
                            {{ level.get('label') }}
                            <button matChipRemove>
                                <mat-icon matChipRemove svgIcon="cross"></mat-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>

                    <input
                        #levelInput
                        (matChipInputTokenEnd)="onLevelInputSubmit($event)"
                        [formControl]="levelCtrl"
                        [matAutocomplete]="autoLevel"
                        [matChipInputFor]="levelsList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        placeholder="Tous les niveaux..."
                    >
                    <mat-autocomplete #autoLevel="matAutocomplete" (optionSelected)="onLevelSelected($event)">
                        <mat-option *ngFor="let level of levelsFiltered | async" [value]="level">
                            {{ level.get('label') }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>


                <button (click)="onResetFiltersClick()" *ngIf="levels?.length || entitiesForFilter?.length">
                    <mat-icon svgIcon="trash"></mat-icon>
                    <span>{{ 'generic.delete' | translate }}</span>
                </button>
            </div>
            <div *ngIf="!displayLoader" class="compass-themes-filter-diagnostic">
                <div class="grid-compass">
                    <ng-container *ngFor="let theme of themes">
                        <app-compass-card
                            [ngStyle]="{'background-image': 'url(' + (theme.get('medias') && theme.get('medias')[0] ? theme.get('medias')[0].url : '') + ')'}"
                            class="compass-card__ressources map-theme map-theme-{{filterType}}">
                            <div class="app-compass-card__header" data-header>
                                <app-keywords>
                                <span *ngFor="let tag of (theme.get('tags') || [])" class="keywords"
                                      itemprop="keywords">
                                    {{ tag.name }}
                                </span>
                                </app-keywords>
                            </div>
                            <div class="app-compass-card__body" data-body>
                                <h3 [innerHTML]="theme.get('label')"></h3>
                                <div [innerHTML]="theme.get('body')"></div>
                            </div>
                            <div class="app-compass-card__footer" data-footer>
                                <a
                                    [queryParams]="getThemeQueryParams(exploreEntity, theme)"
                                    [routerLink]="['themes', theme.id, 'ressources']"
                                    class="app-compass-card__footer__button">
                                    <mat-icon svgIcon="app-compass-card__footer__button__icon"></mat-icon>
                                    <span>{{ 'compass.see_resources' | translate }}</span>
                                </a>
                            </div>

                        </app-compass-card>
                    </ng-container>
                </div>
            </div>
        </div>

    </section>
</ng-container>

<ng-container #loader *ngIf="displayLoader">
    <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
</ng-container>
