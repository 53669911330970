<div #divToScrollMultiImage class="parent"
     [ngStyle]="commonStyles">

    <div class="cadreImage" [ngStyle]="cadreImageStyles">
        <div class="beginTimeline"
             [ngStyle]="beginImageStyles"></div>

        <div class="imageContainer" *ngFor="let image of images; let i = index"
             [ngStyle]="commonStyles">
            <mat-icon svgIcon="search" class="loupe"
                      [attr.id]="'loupe' + i"
                      (click)="openDocumentEmitter(image)"></mat-icon>

            <!-- TODO add alt text to the image, set in the back -->
            <img [src]="image.uri"
                 [attr.id]="'img' + i"
                 [style.width]="image.width * ratio + 'px'"
                 [style.height]="commonStyles.height * ratio"
                 (swipeleft)="onSwipeLeft($event)"
                 (swiperight)="onSwipeRight($event)">
        </div>
        <div class="endTimeline"
             [ngStyle]="endImageStyles"></div>
    </div>
</div>
