<div id="about" class="p-24">

    <div class="about-content" fxLayout="column" fxFlex="100" fxFlex.gt-sm="50" fxFlex.gt-md="65">

        <div class="profile-box info-box general" fxLayout="column">

            <header class="mat-accent-bg">

                <div class="title">{{ 'account-management.profile.title' | translate }}</div>
                <div *ngIf="profileProvider.settings.showJoinSchool && userIsAllowed && schoolName !==''"
                     class="attached-info">{{'account-management.join_school.attachedTo'|translate}}
                    <b>{{schoolName}}</b></div>

            </header>

            <div class="content">

                <div fxFlex fxLayout="column" fxLayoutAlign="start start">

                    <div class="info-line" *ngIf="displayField('id') && userInformation?.id">
                        <div class="title">{{ 'account-management.id' | translate }}</div>
                        <div class="info">{{userInformation.id}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('ido') && userInformation?.get('sso') && userIDO">
                        <div class="title">{{ 'account-management.ido' | translate }}</div>
                        <div class="info">{{ userIDO }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('UAI') && userInformation?.get('sso') && userUAI">
                        <div class="title">{{ 'account-management.uai' | translate }}</div>
                        <div class="info">{{ userUAI }}</div>
                    </div>

                        <div class="info-line" *ngIf="displayField('label') || displayLabelFieldInProfile">
                        <div class="title">{{ 'account-management.username' | translate }}</div>
                        <div class="info">{{ifEmptyValue('label') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('lastName')">
                        <div class="title">{{ 'account-management.lastName' | translate }}</div>
                        <div class="info">{{ifEmptyValue('lastName') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('firstName')">
                        <div class="title">{{ 'account-management.firstName' | translate }}</div>
                        <div class="info">{{ifEmptyValue('firstName') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('email') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.my_email' | translate }}</div>
                        <div class="info">{{ifEmptyValue('email') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('password') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.password' | translate }}</div>
                        <div class="info">*********</div>
                    </div>

                    <div fxFlex="100px" class="info-line" *ngIf="displayField('licence')">
                        <div class="title">{{ 'account-management.end_licence_date' | translate }}</div>
                        <div class="info">{{userLicences}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('checkNotifMail')">
                        <input type="checkbox" [(ngModel)]="checkBoxNewsletter" (change)="onCheckNotifMail($event)" name="notif">
                        <label class="title"> {{'account-management.check_box_mail' | translate}}</label>
                    </div>

                    <div class="info-line" *ngIf="displayField('institution')">
                        <div class="title">{{ 'account-management.institution' | translate }}</div>
                        <div class="info">{{ifEmptyValue('institution') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('you_are') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.you-are' | translate }}</div>
                        <div class="info">{{ 'generic.you-are.' + youAreValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('find_us') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.find-us' | translate }}</div>
                        <div class="info">{{ 'account-management.find-us.' + findUsValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('level') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.level' | translate }}</div>
                        <div class="info">{{ levelValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('region') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.region' | translate }}</div>
                        <div class="info">{{ regionValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('newsletter') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.newsletter' | translate }}</div>
                        <div class="info">{{ getTranslate() | translate}}</div>
                    </div>
                </div>


                <div fxFlex="100px" class="avatar-container" fxLayoutAlign="end start" *ngIf="displayField('picture')">
                    <img *ngIf="userInformation?.get('picture')" class="avatar" [src]="userInformation?.get('picture')" alt="">
                    <div *ngIf="!userInformation?.get('picture')"
                         class="info">{{ 'generic.to-complete' | translate}}</div>
                </div>

            </div>

            <mat-card-actions fxLayoutAlign="center center" *ngIf="!hideEdition()">

                <button (click)="setMode(true)" mat-raised-button>
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span>{{ 'account-management.edit_my_profile' | translate }}</span>
                </button>

                <button class="delete" *ngIf="canDeleteCurrentAccount()" (click)="deleteCurrentAccount()" mat-raised-button>
                    <mat-icon svgIcon="delete"></mat-icon>
                    <span>{{ 'account-management.delete_my_account' | translate }}</span>
                </button>

            </mat-card-actions>

        </div>

        <div class="content-row-separator"></div>

        <app-join-school *ngIf="profileProvider.settings.showJoinSchool && userIsAllowed" (schoolJoin)="setCurrentSchoolJoined($event)"></app-join-school>

        <app-join-group *ngIf="profileProvider.settings.showJoinGroup && canJoinGroup"></app-join-group>

    </div>
</div>