<mat-card class="activities-card inner-scroll mat-elevation-z0 qcmContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns}"
          [attr.id]="type === 'isWordSelecting' || type === 'isLetterSelecting'?'type-is-word-or-lettersSelecting':''">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                (isReading)="ttsReadingEvent($event)"
                [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"
                [wordingAudio]="wordingAudio"
                [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmc' | translate):''"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
            ></app-consignes>
            <h2 [ngClass]="isTTSReading?.value ? 'read-in-progress' : 'read-not-in-progress'"
                *ngIf="showSubInstruction">{{ 'activities.sub_instruction_qmc' | translate }}</h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes
            *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
            [instruction]="instruction" [ngClass]="{'consigneImg': isInstructionImg()}"
            [instructionAudio]="instructionAudio"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            questionTypeName="{{ 'activities.questionTypeName.app-qcm' | translate }}">
        </app-consignes>

        <ng-container *ngFor="let f of referenceActivityGranule.flashcards">
            <app-flashcard [class.button-image]="f.image"
                           [class.is-audio]="f.audio?.uri"
                           [flashCard]="f">
            </app-flashcard>
        </ng-container>

        <div class="checkbox-group"
             [ngClass]="parentClass()">
            <div *ngIf="isWrapperForCheckbox && !answerContainImg && !areAnswersContainAudio()">
                <div
                    *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' let index = index;"
                    class="checkbox-wrapper" (click)="toggleCheck($event, option)" [ngClass]="optionState(option)">
                    <mat-checkbox
                        [ngModel]="isAnswerSelected(option)"
                        [disabled]="isDisabledOptions()"
                        [ngClass]="optionState(option)"
                        [disableRipple]="true" [value]="option.answer">
                        <span [innerHTML]="option.answer | stringToHtml"></span>
                    </mat-checkbox>
                </div>
            </div>
            <ng-container *ngIf="!isWrapperForCheckbox && !answerContainImg && !areAnswersContainFlashcard() && !areAnswersContainAudio()">
                <div
                    *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' let index = index;">
                    <mat-checkbox
                        *ngIf="!option.image || option.image === ''"
                        [ngModel]="isAnswerSelected(option)"
                        (change)="toggleCheck($event, option)"
                        [disabled]="isDisabledOptions()"
                        [ngClass]="optionState(option)"
                        [disableRipple]="true" [value]="option.answer">
                        <span [innerHTML]="option.answer | stringToHtml"></span>
                    </mat-checkbox>
                </div>
            </ng-container>

            <ng-container *ngIf="answerContainImg">
                <div
                    *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' let index = index;"
                    (click)="toggleCheck($event, option)"
                    [ngClass]="optionState(option)" class="button-image">
                    <div *ngIf="option.image && option.image !== ''">
                        <!-- TODO add alt text to the image, set in the back -->
                        <img [attr.id]="'imgToZoom' + uuid + index.toString()" [src]="option.image"/>
                        <div class="action-buttons" *ngIf="isImageFullscreenButtonEnabled()">
                            <app-zoomable [uuid]="uuid" [index]="index"></app-zoomable>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="areAnswersContainFlashcard() && !answerContainImg">
                <ng-container *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' let index = index;">
                    <ng-container *ngIf="option.flashcards">
                        <app-flashcard *ngFor="let flashcard of option.flashcards"
                                       [class.button-image]="flashcard.image"
                                       [class.is-audio]="flashcard.audio?.uri"
                                       [ngClass]="optionState(option)"
                                       [flashCard]="flashcard"
                                       (click)="toggleCheck($event, option)">
                        </app-flashcard>
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="areAnswersContainAudio()">
                <button *ngFor="let option of availableAnswers | intToBoolean: 'correct_answer':'select' let index = index;"
                     class="qcm__audio-button"
                     [ngClass]="optionState(option)"
                     (click)="playAudioAndToggleCheck($event, option.audio.id, option)">
                    <mat-icon [svgIcon]="'qcm-qcu__audio-' + (index + 1)"></mat-icon>
                    <audio *ngIf="option.audio"
                           [src]="option.audio.uri"
                           [id]="option.audio.id">
                    </audio>
                </button>
            </ng-container>

        </div>

    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ feedback | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate }}{{ showPercentile }}
        </div>
        <ng-container *ngIf="!isActivityEmbeddedDone">
            <button
                class="btn-option"
                *ngIf="displaySaveBtn"
                [class]="buttonState"
                [disabled]="isDisabledOptions(false)"
                (click)="validate()">
                <span>{{ 'generic.register_' + buttonState | translate }}</span>
                <mat-icon svgIcon="{{'qcm__register__' + buttonState}}"></mat-icon>
            </button>
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"
                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <span>{{ button.title | translate }}</span>
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>