<div class="progress">
    <div *ngIf="username"
         class="progress__username">{{ username }}</div>
    <div *ngIf="level && coins"
         class="progress__data">
        <div class="progress__level">
            <span>{{ 'activities.progress_level' | translate }}&nbsp;{{ level }}</span>
        </div>
        <div class="progress__coins">
            <span>{{ coins }}</span>
            <mat-icon [svgIcon]="img"></mat-icon>
        </div>
    </div>
</div>