<button class="activity-edition-preview-true-false__true-button"
        [class.correct]="trueFalseAnswer === true">
    <mat-icon class="activity-edition-preview-true-false__true-icon"
              svgIcon="activity-edition-preview__true">
    </mat-icon>
    <span>{{ 'activity_edition.true' | translate }}</span>
</button>
<button class="activity-edition-preview-true-false__false-button"
        [class.correct]="trueFalseAnswer === false">
    <mat-icon class="activity-edition-preview-true-false__false-icon"
              svgIcon="activity-edition-preview__false">
    </mat-icon>
    <span>{{ 'activity_edition.false' | translate }}</span>
</button>