<div id="forgot-password" fxLayout="column" fusePerfectScrollbar>

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center" *fuseIfOnDom
         [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

        <div id="forgot-password-form">

            <div class="logo" *ngIf="settingsAuth.displayLoginLogo">
                <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/brand-square.png"
                     alt="">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>

            <div class="title">{{ 'forgot-password.title' | translate }}</div>
            <span *ngIf="showInfoTokenNotValid">{{'generic.token_not_valid_resend_email' | translate}}</span>
            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field [floatLabel]="floatLabelControl">
                    <mat-label>{{ 'forgot-password.email' | translate }}</mat-label><input matInput  formControlName="email">
                    <mat-error *ngIf="forgotPasswordFormErrors.email.required">
                        <span>{{ 'authentication.email.required' | translate }}</span>
                    </mat-error>
                    <mat-error *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
                        <span>{{ 'authentication.email.invalid' | translate }}</span>
                    </mat-error>
                </mat-form-field>

                <mat-error *ngIf="emailNotValidated">
                    <span>{{ 'generic.email.validation.invalid' | translate }}</span>
                </mat-error>

                <mat-error *ngIf="expiredLicence">
                    <span>{{ 'generic.expiredlicence' | translate }}</span>
                </mat-error>

                <mat-error *ngIf="noLicence">
                    <span>{{ 'generic.nolicence' | translate }}</span>
                </mat-error>

                <mat-error *ngIf="emailNotFound">
                    <span>{{ 'account-management.mail-not-found' | translate }}</span>
                </mat-error>

                <button (click)="sendResetLink()" mat-raised-button class="submit-button" color="accent"
                        aria-label="Recevoir le lien de réinitialisation">
                    {{ 'forgot-password.link.reset' | translate }}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">{{ 'authentication.btn-login' | translate }}</a>
            </div>

        </div>

    </div>

</div>