<div class="tools page-layout simple fullwidth" fxFlex fxLayout="column">
    <div class="header" fxlayout="row" fxlayoutalign="start center">
        <h2>{{ 'generic.multimedia_title' | translate }}</h2>
    </div>

    <div class="content">
        <form (ngSubmit)="activityFormOnSubmit()" *ngIf="renderIsReady else elseBlock" [formGroup]="activityForm">
            <div>
                <mat-form-field>
                    <mat-label>{{ 'multimedia_editor.title' | translate }}</mat-label>
                    <input [placeholder]="'multimedia_editor.title' | translate" formControlName="title" id="title" matInput
                           type="text">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-label>{{ 'multimedia_editor.instruction' | translate }}</mat-label>
                    <input [placeholder]="'multimedia_editor.instruction' | translate" formControlName="instruction" id="instruction" matInput
                           type="text">
                </mat-form-field>
            </div>

            <div [(dragulaModel)]="draggableMedias"
                 [dragula]="formIsSaving ? null : 'draggableMedias'"
                 class="container list-item">
                <div *ngFor="let resource of draggableMedias" class="itemDragula activity-item">
                    <div class="handle" fxFlex="0 0 40px" fxLayoutAlign="center center">
                        <button type="button">
                            <mat-icon svgIcon="drag"></mat-icon>
                        </button>
                    </div>
                    <div [ngSwitch]="resource.format.label" class="activity-main-item" fxFlex
                         fxLayoutAlign="left center">
                        <img *ngSwitchCase="'image'" alt="{{resource.metadatas.title}}"
                             src="{{resource.reference.uri}}">

                        <app-shared-video *ngSwitchCase="'video'" [controls]="true"
                                          [video]="resource?.reference"></app-shared-video>

                        <app-video-with-markers *ngSwitchCase="'videoUrl'"
                                                [videoConfig]="{config: {src : resource.reference.url}}"></app-video-with-markers>

                        <audio *ngSwitchCase="'audio'" controls>
                            <source [src]="resource.reference.uri" [type]="resource.reference.filemime">
                            Your browser does not support the audio tag.
                        </audio>

                        <div *ngSwitchCase="'text'">
                            <div [innerHTML]="resource.reference.text" class="ck-editor-output"></div>
                        </div>

                        <div *ngSwitchDefault>
                            <div>{{'multimedia_editor.format_not_implemented' | translate}} {{resource.format.label}}</div>

                        </div>
                    </div>

                    <div class="actionBtns" fxFlex="0 0 150px" fxLayoutAlign="center center">
                        <button (click)="editMedia(resource)" *ngIf="canEdit(resource)" aria-label="edit"
                                matTooltip="{{ 'generic.edit' | translate }}"
                                type="button">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button (click)="deleteMedia(resource)" aria-label="delete" matTooltip="{{ 'generic.delete' | translate }}"
                                type="button">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>


            <div class="footer h-64" fxLayout="row" fxLayoutAlign="center center">
                <button [disabled]="formIsSaving || isUserShouldSave() === false" class="save-button mat-accent"
                        type="submit">
                    <span>{{ 'generic.save' | translate }}</span>
                    <mat-icon svgIcon="save"></mat-icon>
                </button>
                <button (click)="goBack()"  type="button">
                    <span>{{ 'generic.exit' | translate }}</span>
                    <mat-icon svgIcon="exit"></mat-icon>
                </button>

                <div class="addBtnBottomRight">
                    <button [matMenuTriggerFor]="menu" aria-label="More" class="add-file-button"  matTooltip="More"
                            type="button">
                        <mat-icon svgIcon="add"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" backdropClass="horizontalAddSubMenu" class="form-activities-menu">
                        <div mat-menu-item>
                            <button (click)="createGenericMedia()" [disabled]="canAddGenericMedia() === false"
                                    type="button">
                                <mat-icon svgIcon="eye"></mat-icon>
                            </button>
                            <span
                                (click)="canAddGenericMedia() && createGenericMedia()">{{ 'multimedia_activity.generic_media' | translate }}</span>
                        </div>
                        <div mat-menu-item>
                            <button (click)="createTextMedia()" [disabled]="canAddTextMedia() === false"
                                    type="button">
                                <mat-icon svgIcon="sheet"></mat-icon>
                            </button>
                            <span
                                (click)="canAddTextMedia() && createTextMedia()">{{ 'multimedia_activity.generic_text' | translate }}</span>
                        </div>
                    </mat-menu>
                </div>
            </div>
        </form>

        <ng-template #elseBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>
</div>