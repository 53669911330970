<ng-container *ngIf="service.state$ | async as state">
    <div class="container-voice-recorder">
        <div class="pseudo-consignes" [ngClass]="{ 'consigneImg': state?.image }" *ngIf="state?.image?.uri?.length > 1">
            <div class="container">
                <div class="is-picture">
                    <img  [src]="state?.image?.uri" [alt]="state?.image?.title"/>
                </div>
                <div *ngIf="showTitle"
                     class="voice-recorder__caption"
                     [innerHTML]="flashcard.title">
                </div>
            </div>
        </div>

        <div class="available-answers">
            <button (click)="incomingAudio?.paused === false? pauseIncomingAudio(): playIncomingAudio()"
                [disabled]="state.listenIncomingAudioButtonDisabled"
                class="bem-voice-recorder__button">
                {{ 'activities.listen_incoming_audio' | translate }}
                <mat-icon svgIcon="voice-recorder__listen"></mat-icon>
            </button>
            <!-- ↓ Legacy HTML element (plateforme) ↓ -->
            <button *ngIf="!displayForSummary"
                    (click)="startRecording()"
                    [disabled]="state.isRecording || state.recordingButtonDisabled">
                {{ 'activities.record_audio' | translate }}
            </button>
            <!-- ↑ Legacy HTML element (plateforme) ↑ -->
            <button *ngIf="!displayForSummary"
                (click)="state.isRecording ? stopRecording() : startRecording()"
                [disabled]="state.recordingButtonDisabled"
                class="bem-voice-recorder__button bem-voice-recorder__record-button">
                <div *ngIf="!state.isRecording">
                    {{ 'activities.record_audio' | translate }}
                </div>
                <div *ngIf="state.isRecording"
                    class="bem-voice-recorder__progress-wrapper">
                    <div class="bem-voice-recorder__timer">{{ recordTimer }}</div>
                    <mat-icon svgIcon="voice-recorder__stop-recording"></mat-icon>
                    <progress class="bem-voice-recorder__progress-bar"
                        [value]="recordProgress"
                        max="100"
                        role="progressbar">
                    </progress>
                </div>
                <mat-icon svgIcon="voice-recorder__record"></mat-icon>
            </button>
            <button *ngIf="!displayForSummary"
                (click)="compareAudio()"
                [disabled]="state.listenUserRecordingDisabled"
                class="bem-voice-recorder__button">
                {{ 'activities.listen_and_compare' | translate }}
                <mat-icon svgIcon="voice-recorder__listen-and-compare"></mat-icon>
            </button>
        </div>
    </div>
</ng-container>