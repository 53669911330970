<div class="content steps"
     *ngIf="displayStepper"
     [ngClass]="'layout-align-'+layoutAlign+ ' layout-'+layout"
     fxFlex
     [fxLayout]="layout"
     [fxLayoutAlign]="layoutAlign">

    <!-- new stepper list with a list of step and two arrow to go back and forward -->
    <ng-container *ngIf="options.isListFormatStepper">
        <div class="generic-stepper__left-toolbar">
            <ng-content select="[toolbar-before-stepper]"></ng-content>
        </div>
        <button
            class="previous"
            [ngStyle]="{'visibility' : isPreviousVisible()?'visible':'hidden'}"
            [disabled]="isLocked"
            (click)="previous()"
            [attr.aria-label]="'generic.previous' | translate"
            [matTooltip]="'generic.previous' | translate">
            <mat-icon svgIcon="arrow_left"></mat-icon>
        </button>
        <mat-form-field appearance="fill" *ngIf="options.steps.length > 1">
            <mat-select panelClass="generic-stepper__select-panel"
                        [(value)]="selectedStep"
                        (selectionChange)="clickOnElem($event.value)"
                        [disabled]="isLocked">
                <mat-option *ngFor="let step of options.steps" [value]="step">{{getLabelToDisplay(step)}}
                    / {{options.steps.length}}
                    - {{stepTitle(step)}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button
            class="next"
            [ngStyle]="{'visibility' : isNextVisible()?'visible':'hidden'}"
            [disabled]="isLocked"
            (click)="next()"
            [attr.aria-label]="'generic.next' | translate"
            [matTooltip]="'generic.next' | translate">
            <mat-icon svgIcon="arrow_right"></mat-icon>
        </button>
        <div class="generic-stepper__right-toolbar">
            <ng-content select="[toolbar-after-stepper]"></ng-content>
        </div>
    </ng-container>

    <div *ngIf="options.recap.isAllowed"
         [ngClass]="{'active':options.recap.isActive }"
         (click)="clickOnElem(null)"
         fxLayoutAlign="space-evenly center"
         class="step">
        <mat-icon svgIcon="flag" class="number flag"></mat-icon>
        <mat-icon svgIcon="arrow_right" class="arrow" [ngClass]="arrowPositionClass"
                  *ngIf="options.recap.isArrowActive"></mat-icon>
    </div>

</div>