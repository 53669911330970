<div mat-dialog-title>
    <h2>{{data.title | translate}}</h2>
    <button class="close"
            (click)="close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-dialog-content fusePerfectScrollbar>
    <app-dynamic widgetCard room="lessons" subject="buttonListComponent" [params]="data"></app-dynamic>
</mat-dialog-content>