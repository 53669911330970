<mat-toolbar matDialogTitle class="m-0">
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">{{ 'browser-test.screen-size-error.title' | translate }}</span>
    <button color="white"
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div mat-dialog-content >
  <div>{{ 'browser-test.screen-size-error.message' | translate:param }}</div>
</div>
<div mat-dialog-actions class="pt-24 val">
  <button (click)="dialogRef.close(true)" mat-raised-button class="mat-accent mr-16" >{{ 'generic.ok' | translate }}</button>
</div>