// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `module, .add-entity-button {
  position: absolute;
  bottom: 20px;
  right: 12px;
  padding: 0;
  z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/groups-management/core/groups-listing/groups-list/groups-list.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,WAAA;AADJ","sourcesContent":["@import \"@fuse/scss/fuse\";\n\nmodule, .add-entity-button { // TODO(val) delete\n    position: absolute;\n    bottom: 20px;\n    right: 12px;\n    padding: 0;\n    z-index: 99;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
