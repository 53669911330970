<div class="compass" fusePerfectScrollbar>

    <a routerLink="./../.." aria-label="précédent"
       class="compass__back__link">
        <mat-icon svgIcon="back"></mat-icon>
        <span>{{ 'compass.home' | translate }}</span>
    </a>

    <app-compass-search-form [searchValue]="query()" (done)="updateSearchQuery($event)"></app-compass-search-form>

    <ng-container *ngIf="themes() as themes">
        <section *ngIf="themes.length > 0"
                 class="compass__news">
            <div class="compass__news__content grid-compass">
                <ng-container *ngFor="let theme of themes">
                    <app-compass-card
                        [ngStyle]="{'background-image': 'url(' + (theme.get('medias') && theme.get('medias')[0] ? theme.get('medias')[0].url : '') + ')'}"
                        class="compass-card__news ">
                        <div class="app-compass-card__header" data-header>
                            <app-keywords>
                                <span *ngFor="let tag of theme.get('tags')"
                                      class="keywords"
                                      itemprop="keywords">
                                    {{ tag.name }}
                                </span>
                            </app-keywords>
                        </div>
                        <div class="app-compass-card__body"
                             data-body>
                            <h3 [innerHTML]="theme.get('label')"></h3>
                            <div [innerHTML]="theme.get('body')"></div>
                        </div>
                        <div class="app-compass-card__footer"
                             data-footer>
                            <a [routerLink]="['themes', theme.id, 'ressources']"
                               [queryParams]="getQueryParams()"
                               class="app-compass-card__footer__button">
                                <mat-icon svgIcon="app-compass-card__footer__button__icon"></mat-icon>
                                <span>{{ 'compass.see_resources' | translate }}</span>
                            </a>

                        </div>
                    </app-compass-card>
                </ng-container>
            </div>
        </section>
        <section *ngIf="themes.length === 0">
            {{'compass.no_results' | translate}}
        </section>
    </ng-container>

    <ng-container *ngIf="isLoadingThemes">
        <mat-spinner [diameter]="60" [strokeWidth]="5"></mat-spinner>
    </ng-container>

</div>