<module-groups-listing [addEntity]="newLearner"
                       [customActions]="customActions"
                       [dataSource]="dataSource"
                       [deleteEntity]="deleteLearner"
                       [displayedColumns]="isInLineAddingLearner()?displayedColumnsInlineAdding: displayedColumns"
                       [editEntity]="editLearner"
                       [groupNameClassroomSelected]="groupNameClassroomSelected"
                       [groupNameSelected]="groupNameSelected"
                       [isAddingInlineLearnerRowAddButton]="isAddingInlineLearnerRowAddButton"
                       [isAddingInlineLearner]="isAddingInlineLearnerRow"
                       [isAddingLearnerFromGroupOrWorkgroup]="isAddingLearnerFromGroupOrWorkgroup"
                       [joinLearner]="joinLearnerToGroupsOrWorkgroups"
                       [nicknameContextAllowed]=true
                       [displayedFilters]="displayedFilters"
                       fxFlex
                       fxLayout="column"
></module-groups-listing>
