<!-- SIDENAV HEADER -->
<div class="header p-24" fxLayout="column" fxLayoutAlign="space-between">

    <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16">folder</mat-icon>
        <span class="logo-text h1">Corpus</span>
    </div>

</div>
<!-- / SIDENAV HEADER -->

<!-- SIDENAV CONTENT -->
<div class="content py-16" fusePerfectScrollbar>

    <div class="nav">

        <div class="nav-item" aria-label="inbox">
            <a class="nav-link" matRipple>
                <mat-icon class="nav-link-icon">folder</mat-icon>
                <span class="title">My Files</span>
            </a>
        </div>

        <div class="nav-item" aria-label="starred">
            <a class="nav-link" matRipple>
                <mat-icon class="nav-link-icon">star</mat-icon>
                <div class="title">Starred</div>
            </a>
        </div>

        <div class="nav-item" aria-label="starred">
            <a class="nav-link" matRipple>
                <mat-icon class="nav-link-icon">folder_shared</mat-icon>
                <div class="title">Sharred with me</div>
            </a>
        </div>

        <div class="nav-item" aria-label="starred">
            <a class="nav-link" matRipple>
                <mat-icon class="nav-link-icon">access_time</mat-icon>
                <div class="title">Recent</div>
            </a>
        </div>

        <div class="nav-item" aria-label="starred">
            <a class="nav-link" matRipple>
                <mat-icon class="nav-link-icon">not_interested</mat-icon>
                <div class="title">Offline</div>
            </a>
        </div>
    </div>

</div>
<!-- / SIDENAV CONTENT -->
