<div class="animal-container" #compositionContainer *ngIf="isReady === true">
  <app-animal-canvas #canvas [creature]="currentData" *ngIf="currentData"></app-animal-canvas>
  <app-animal-controls (next)="onNext()" (previous)="onPrevious()"
    (saveUserImage)="onSaveUserImage($event)" (update)="redraw($event)" [creature]="currentData" [width]="controlsWidth"
    [navigationAllowed]="availableData.length > 1" [controlsAllowed]="displayControls" *ngIf="currentData">
  </app-animal-controls>
  <div class="message" *ngIf="!currentData">{{'gamification.avatar.unavailable' | translate}}</div>
</div>

<div class="spinner" *ngIf="isReady === false">
  <mat-spinner></mat-spinner>
</div>