<module-groups-listing [addEntity]="newWorkgroup"
                       [archiveEntity]="archiveWorkgroup"
                       [dataSource]="dataSource"
                       [deArchiveEntity]="deArchiveWorkgroup"
                       [deleteEntity]="deleteWorkgroup"
                       [displayedColumns]="displayedColumns"
                       [editEntity]="editWorkgroup"
                       [displayedFilters]="displayedFilters"
                       [options]="options"
                       fxFlex
                       fxLayout="column"
></module-groups-listing>
