import {Routes} from '@angular/router';
import {CompassThemesComponent, CompassComponent, CompassSearchComponent} from './pages';
import {CompassResourceComponent, CompassResourcesComponent} from './pages/resources';
import {IsUserLogged} from '../../app/guards';

const resourcesRoutes: Routes = [
    {
        path: 'themes/:themeId/ressources',
        component: CompassResourcesComponent,
    },
    {
        path: 'themes/:themeId/ressource/:id',
        component: CompassResourceComponent,
    }
];

export const routes: Routes = [
    {
        path: 'boussole',
        canActivate: [IsUserLogged],
        children: [
            {
                path: '',
                component: CompassComponent,
            },
            {
                path: 'search/:query',
                children: [
                    {
                        path: '',
                        component: CompassSearchComponent
                    },
                    ...resourcesRoutes
                ]
            },
            {
                path: 'explore/:exploreId',
                children: [
                    {
                        path: '',
                        component: CompassThemesComponent
                    },
                    ...resourcesRoutes
                ]
            },
            ...resourcesRoutes
        ]
    }
];