<mat-card *ngIf="!isInitializing" class="activities-card inner-scroll mat-elevation-z0">
    <mat-card-header *ngIf="instruction && instruction !==''">
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
                [autoRead]="isAutoReadActive('instruction')"
                [readable]="config?.isTtsEnabled && !isConsigneContainMedia(instruction)"
                [instructionAudio]="instructionAudio"
                [instruction]="instruction" [ngClass]="{'consigneImg': isInstructionImg()}"
            ></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-consignes
            *ngIf="(isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))) && !typeOfContent"
            [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)"
            [ngClass]="{'consigneImg': isWordingImg()}"
            [wordingAudio]="wordingAudio"
            [wording]="wording"></app-consignes>

        <!--middle center-->
        <div [class]="typeOfContent" class="medias bem-multimedia__media">

            <ng-container *ngFor="let resource of resources">

                <!--------------------------------      PDF     --------------------------------------->

                <div *ngIf="resource.get('format').label === 'document'
                && resource?.attributes?.reference?.uri" class="media-wrapper pdf-download">
                    <app-read-pdf [url]="resource?.attributes?.reference?.uri"></app-read-pdf>
                </div>

                <!--------------------------------      IMAGE     --------------------------------------->

                <div *ngIf="resource.get('format').label === 'image'"
                     class="media-wrapper image">
                    <div [attr.id]="'imgToZoom' + uuid">
                        <img oncontextmenu="return false"
                             alt="{{ resource.get('reference')?.alt || resource.get('metadatas').title }}"
                             src="{{resource.get('reference')?.uri}}">
                    </div>
                    <div *ngIf="isImageFullScreenButton()" class="action-buttons">
                        <app-zoomable [uuid]="uuid"></app-zoomable>
                    </div>
                </div>

                <!--------------------------------      VIDEO     --------------------------------------->

                <div *ngIf="resource.get('format').label === 'video' && resource.get('reference')?.uri"
                     class="media-wrapper video">
                    <app-shared-video
                        [controls]="true"
                        controlsList="nodownload"
                        oncontextmenu="return false"
                        [video]="resource.get('reference')" [muted]="isMuted() ? 'muted' : null"></app-shared-video>
                </div>

                <!--------------------------------      VIDEO URL     ----------------------------------->

                <div *ngIf="resource.get('format').label === 'videoUrl' && !!videoEmbedUrl(resource)"
                     class="media-wrapper video-with-markers">
                    <app-video-with-markers
                        [videoConfig]="videoEmbedUrl(resource)"></app-video-with-markers>
                </div>

                <!--------------------------------      AUDIO     --------------------------------------->

                <div *ngIf="resource.get('format').label === 'audio'"
                     class="media-wrapper audio">
                    <mat-icon svgIcon="speaker-equalizer"></mat-icon>
                    <audio *ngIf="resource.get('format').label === 'audio'"
                           oncontextmenu="return false"
                           controls
                           controlsList="nodownload">
                        <source [src]="resource.get('reference')?.uri"
                                [type]="resource.get('reference')?.filemime">
                        Your browser does not support the audio tag.
                    </audio>
                </div>

                <!--------------------------------      TEXT     --------------------------------------->

                <div *ngIf="resource.get('format').label === 'text'" class="media-wrapper text AA">
                    <div [innerHTML]="resource.get('reference').text"></div>
                </div>

            </ng-container>

            <ng-container *ngIf="typeOfContent === 'read' && wording && wording !== ''">
                <div class="media-wrapper text read BB">
                    <div [innerHTML]="wording" [appDayRelativeNameToString]="{language_key: language}"
                         [appTimeSlotToString]="{language_key: language}"></div>
                </div>
            </ng-container>

            <ng-container *ngIf="typeOfContent === 'listen' && wording && wording !== ''">
                <div class="btn-tts-wrapper">
                    <div #readMe
                         (speaking)="speakStateChanged($event)"
                         [forceText]="wording" [ngClass]="{'tts-reading': isTTSReadingText}"
                         [uuid]="uuid"
                         appReadable
                         class="tts btn-tts">
                        <mat-icon (click)="clickToTts()" [class.read]="isTTSReadingText"
                                  [svgIcon]="'sound_off'"></mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <ng-container *ngIf="!isActivityEmbedded">
            <ng-container *ngFor="let button of buttons">
                <app-button-element
                    (clickButton)="onAction($event)"
                    [button]="button"
                    *ngIf="button.display">
                </app-button-element>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>

<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>

<div *ngIf="isInitializing" fxFill fxLayoutAlign="center center">
    <mat-spinner></mat-spinner>
</div>