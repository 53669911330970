<fuse-groups-form-dialog
    *ngIf="(showInlineModal || addEntity.data.action === 'edit') && addEntity && addEntity.data"
    [closeEmitter]="saveEmitter"
    [dataByInput]="isJoiningExistingLearner ? joinLearner : addEntity"
    [groupNameClassroomSelected]="groupNameClassroomSelected"
    [groupNameSelected]="groupNameSelected"
    [isJoiningExistingLearner]="isJoiningExistingLearner"
    [learnersList]="learnersList"
    (isFormValid)="isFormValid($event)"
    (submitInside)="submitInside($event)"
    (saveInProgress)="saveInProgressEmit()"
    class="id-groups-form-dialog"
    fusePerfectScrollbar>
</fuse-groups-form-dialog>

<div *ngIf="addEntity.data.action !== 'edit'"
     class="groups-inline-modal__actions"
     mat-dialog-actions>

    <!--    A un moment donné ici on créé des classes-->
    <button *ngIf="!isgroupOrClassroomFilterActivate() && isCreateAuthorized()"
            class="groups-inline-modal__add-button add-group-button add"
            [disabled]="(!groupFormIsValid && showInlineModal) || isAddLearnerQuotaDoneAndNotGar"
            (click)="openModalFormInline()">
        <mat-icon svgIcon="add"></mat-icon>
        <span>&nbsp;{{'groups-management.' + addEntity.data.title | translate}} {{addLearnerButtonInfo}}</span>
    </button>

    <ng-container *ngIf="canAddNewLearner() else cannotAddNewLeaner">
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openModalFormInline()"
                    [ngClass]="isAddLearnerQuotaDoneAndNotGar?'menu-button-add-learner-disabled':''"
                    [disabled]="isAddLearnerQuotaDoneAndNotGar">
                <span>{{'groups-management.add_new_learner' | translate}} </span> <span
                *ngIf="isAddLearnerButtonInfoAllowed"> &nbsp; {{addLearnerButtonInfo}} {{'groups-management.add_new_learner_info' | translate}}</span>
            </button>
            <button mat-menu-item (click)="openModalFormInline(true)">
                {{'groups-management.add_existing_learner' | translate}}
            </button>
        </mat-menu>
        <button *ngIf="isgroupOrClassroomFilterActivate() && isCreateAuthorized() && isJoinAuthorized()"
                [disabled]="!groupFormIsValid && showInlineModal"
                [matMenuTriggerFor]="menu"
                aria-label="add entity"
                class="groups-inline-modal__add-learner-button add-learner-button add">
            <mat-icon svgIcon="add"></mat-icon>
            <span>&nbsp;{{'groups-management.' + joinLearner.data.title | translate}} </span>
        </button>
    </ng-container>
    <ng-template #cannotAddNewLeaner>
        <button *ngIf="isgroupOrClassroomFilterActivate() && isJoinAuthorized()"
                [disabled]="!groupFormIsValid && showInlineModal"
                (click)="openModalFormInline(true)"
                aria-label="add entity"
                class="groups-inline-modal__add-learner-button add-learner-button add">
            <mat-icon svgIcon="add"></mat-icon>
            <span>&nbsp;{{'groups-management.' + joinLearner.data.title | translate}}</span>
        </button>
    </ng-template>
</div>