<div id="login" fxLayout="column" fusePerfectScrollbar class="user-blocked">
    <div
        id="login-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div
            id="login-form"
            *fuseIfOnDom
            [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
        >
            <div class="logo">
                <img
                    *ngIf="!brandLogoSvg"
                    src="assets/{{ brand }}/images/logos/brand-square.png"
                    alt=""
                />
                <mat-icon
                    *ngIf="brandLogoSvg"
                    class="logo-icon-svg"
                    svgIcon="brand_square"
                ></mat-icon>
            </div>

                <h1>{{ 'authentication.unauthorized-title' | translate}}</h1>
                <p>{{ 'authentication.unauthorized-body' | translate}}</p>
        </div>

    </div>
</div>