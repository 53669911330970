<app-image-upload
    [image]="flashcardData?.image"
    (imageChange)="onImageChange($event)"
    (imageSoftDelete)="onMediaSoftDelete($event)"
></app-image-upload>
<app-audio-upload
    [audio]="flashcardData?.audio"
    (audioChange)="onAudioChange($event)"
    (audioSoftDelete)="onMediaSoftDelete($event)"
></app-audio-upload>
<!--<app-activity-editor-text-input-->
<!--    [control]="flashcardData?.text?.control"-->
<!--    [characterLimit]="flashcardData?.text?.characterLimit"-->
<!--    [icon]="flashcardData?.text?.icon"-->
<!--    [placeholder]="flashcardData?.text?.placeholder"-->
<!--    [isAnAbsoluteLimit]="flashcardData?.text?.isAnAbsoluteLimit"-->
<!--&gt;</app-activity-editor-text-input>-->
