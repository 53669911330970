<div mat-dialog-title>
    <h2>{{header | translate}}</h2>
    <button class="close"
            (click)="cancel()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<form novalidate (ngSubmit)="validate()">
    <div mat-dialog-content>
        <div *ngIf="body">{{body}}</div>
        <mat-form-field *ngIf="askTitle">
            <mat-label>{{'cards.assignment_modal_ask_title' | translate}}</mat-label>
            <input matInput name="title" [(ngModel)]="title" type="text">
        </mat-form-field>

        <mat-chip-set>
            <mat-chip
                [disabled]="isUserEditAssignment"
                *ngFor="let group of selectedGroupList$ | async"
                (removed)="removeGroup(group)"
            >
                {{ group.label }}
                <mat-icon matChipRemove svgIcon="cross"></mat-icon>
            </mat-chip>
        </mat-chip-set>

        <mat-form-field>
            <input
                [disabled]="isUserEditAssignment"
                type="text"
                [placeholder]="'corpus.share_to_groups_input_placeholder' | translate"
                matInput
                [matAutocomplete]="auto"
            />
            <mat-icon matSuffix>search</mat-icon>

            <mat-autocomplete
                [displayWith]="displayFn"
                (optionSelected)="selectGroup($event)"
                autoActiveFirstOption
                #auto="matAutocomplete"
            >
                <mat-option *ngFor="let group of groupList$ | async" [value]="group">
                    {{ group.label }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>

    <div mat-dialog-actions>
        <button [disabled]="disableValidateBtn()"
                (click)="validate()" mat-raised-button
                class="mat-accent mr-16">{{validLabel | translate}}</button>
    </div>
</form>