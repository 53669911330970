<section class="assignment-by-steps">

    <div class="assignment-by-steps__header">

        <h2 class="assignment-by-steps__title">{{ 'assignment.assign_to_students' | translate }}</h2>
        <span *ngIf="assignmentByStepService.assignatedTo!==''">{{assignmentByStepService.assignatedTo}}</span>
        <div *ngIf="steps.length>1" class="assignment-by-steps__step-container">
            <div *ngFor="let s of steps; let i = index;"
                 class="assignment-by-steps__step"
                 [class.assignment-by-steps__step--active]="i === currentStep">
                {{ 'assignment.step_' + (i + 1) | translate }}
            </div>
        </div>

        <button class="assignment-by-steps__close-button"
                (click)="dialogRef.close()"
                [attr.aria-label]="'generic.close' | translate">
            <mat-icon svgIcon="close-rounded"></mat-icon>
        </button>

    </div>

    <ng-container #stepContainer></ng-container>

    <div class="assignment-by-steps__actions">

        <button *ngIf="!assignmentByStepService.isLaunchInFollowedHelp && currentStep !== 0"
                class="assignment-by-steps__previous-button"
                (click)="previous()">
            <mat-icon svgIcon="previous"></mat-icon>
            <span>{{ 'generic.previous' | translate }}</span>
        </button>

        <button *ngIf="currentStep < steps.length - 1 && nextButtonAllowed()"
                class="assignment-by-steps__next-button"
                (click)="next()"
                [disabled]="assignmentByStepService.disabledNextButton">
            <span>{{ 'generic.next' | translate }}</span>
            <mat-icon svgIcon="next"></mat-icon>
        </button>

        <button *ngIf="currentStep === steps.length - 1 && !assignmentByStepService.isLaunchInFollowedHelp"
                class="assignment-by-steps__validate-button"
                (click)="validate()"
                [disabled]="assignmentByStepService.disabledValidateButton">
            <span>{{ 'assignment.validate' | translate }}</span>
            <mat-icon svgIcon="next"></mat-icon>
        </button>

    </div>

</section>