<div id="lesson-page" fxLayout="column" class="page-layout" fxFlex>

    <!-- / HEADER -->
    <div class="page-layout simple right-sidebar inner-scroll">
        <!-- CENTER -->
        <div class="center" fxFlex>
            <!-- CONTENT -->
            <div class="content p-16" fusePerfectScrollbar>
                <div class="header p-16" fxLayout="row">
<!--                Il y a une volonté de cacher le bouton sans perdre l'intelligence jusqu'à ce qu'on repense la feature -->
<!--                    <button-->
<!--                        *ngIf="hasPreviousPage()"-->
<!--                        mat-raised-button-->
<!--                        (click)="goBackToPrevPage()"-->
<!--                        class="inverted"-->
<!--                    >-->
<!--                        <mat-icon svgIcon="icon-arrow-back" ></mat-icon>-->
<!--                        <span>{{ 'generic.back' | translate }}</span>-->
<!--                    </button>-->

                    <div class="breadcrumb">
                        <ul>
                            <ng-container *ngIf="resource?.get('metadatas')?.concepts.length > 0">
                                <li class="concept">
                                    <a *ngFor="let concept of resource?.get('metadatas')?.concepts; let index = index;"
                                       [routerLink]="['/lessons/list/search']"
                                       [queryParams]="{usage: cardsService.usages, concepts: concept.id}">
                                        {{concept.label}}
                                        <span *ngIf="index !== resource?.get('metadatas')?.concepts.length - 1"
                                              class="lesson-card-breadcrump__nav__word__separator"
                                              aria-hidden="true">,</span>
                                    </a>
                                </li>
                            </ng-container>
                            <ng-container *ngIf="resource?.get('metadatas')?.chapters.length > 0">

                                <li class="chapter" *ngFor="let chapter of resource?.get('metadatas')?.chapters">
                                    <span *ngIf="resource?.get('metadatas')?.concepts.length > 0"
                                          class="lesson-card-breadcrump__nav__item__separator"
                                          aria-hidden="true">/</span>

                                    <a [routerLink]="['/lessons/list/search']"
                                       [queryParams]="{usage: cardsService.usages, chapters: chapter.id}">{{chapter.label}}</a>

                                </li>

                            </ng-container>
                            <li class="title" *ngIf="resource?.get('metadatas')?.title">
                                <span
                                    *ngIf="resource?.get('metadatas')?.concepts.length > 0 || resource?.get('metadatas')?.chapters.length > 0"
                                    class="lesson-card-breadcrump__nav__item__separator"
                                    aria-hidden="true">/</span>
                                <span>{{resource?.get('metadatas')?.title}}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <section class="lesson-section">
                    <div class="lesson-wrapper">
                        <div class="lesson-thumbnail" *ngIf="resource?.get('metadatas')?.thumbnail?.uri">
                            <img src="{{ resource?.get('metadatas')?.thumbnail?.uri  }}" alt="">
                        </div>
                        <div class="lesson-content">
                            <div class="lesson-title" *ngIf="resource?.get('metadatas')?.title">
                                <h1>{{ resource?.get('metadatas')?.title }}</h1>
                            </div>
                            <div class="lesson-author"
                                 *ngIf=" resource?.get('owner-role')?.includes('formateur') && resource?.get('owner-name')">
                                <span>{{ 'activities.lesson.created_by' | translate }}</span>
                                <span>&nbsp;</span>
                                <span>{{ resource?.get('owner-name') }}</span>
                            </div>
                            <div class="lesson-description" *ngIf="resource?.get('metadatas')?.description">
                                <p>{{ resource?.get('metadatas')?.description }}</p>
                            </div>
                            <div class="lesson-tags">
                                <mat-chip-set class="metadatas" tabIndex="-1">
                                    <ng-container *ngIf="resource?.get('metadatas')?.indexation.length > 0">
                                        <mat-chip class="meta-info indexation"
                                                  *ngFor="let indexation of resource?.get('metadatas')?.indexation">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: cardsService.usages, title: indexation.label }">{{indexation.label}}</a>
                                        </mat-chip>
                                    </ng-container>
                                    <ng-container *ngIf="resource?.get('metadatas')?.educationalLevel.length > 0">
                                        <mat-chip class="meta-info educationalLevel"
                                                  *ngFor="let educationalLevel of resource?.get('metadatas')?.educationalLevel">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: cardsService.usages, educationalLevel: educationalLevel.id }">{{educationalLevel.label}}</a>
                                        </mat-chip>
                                    </ng-container>
                                    <ng-container *ngIf="resource?.get('metadatas')?.difficulty.length > 0">
                                        <mat-chip class="meta-info difficulty"
                                                  *ngFor="let difficulty of resource?.get('metadatas')?.difficulty">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: cardsService.usages, title: difficulty.label }">{{difficulty.label}}</a>
                                        </mat-chip>
                                    </ng-container>
                                    <ng-container *ngIf="resource?.get('metadatas')?.concepts.length > 0">
                                        <mat-chip class="meta-info concepts"
                                                  *ngFor="let concept of resource?.get('metadatas')?.concepts">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: cardsService.usages, concepts: concept.id }">{{concept.label | translate}}</a>
                                        </mat-chip>
                                    </ng-container>
                                    <ng-container *ngIf="resource?.get('metadatas')?.chapters.length > 0">
                                        <mat-chip class="meta-info chapters"
                                                  *ngFor="let chapter of resource?.get('metadatas')?.chapters">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: cardsService.usages, chapters: chapter.id }">{{chapter.label | translate}}</a>
                                        </mat-chip>
                                    </ng-container>
                                    <ng-container class="meta-info" *ngIf="resource?.get('usage').length > 0">
                                        <mat-chip class="meta-info usage" *ngFor="let usage of resource?.get('usage')">
                                            <a [routerLink]="['/lessons/list/search']"
                                               [queryParams]="{usage: usage.id }">{{ usage.label | translate }}</a>
                                        </mat-chip>
                                    </ng-container>
                                </mat-chip-set>
                            </div>
                        </div>
                        <div class="lesson-actions">
                            <div>
                                <button *ngIf="isAssignable$() | async"
                                        (click)="assignToLearners()"
                                        [attr.aria-label]="'lesson.assign' | translate">
                                    <span>{{ 'lesson.assign' | translate }}</span>
                                    <mat-icon svgIcon="assign"></mat-icon>
                                </button>
                                <button *ngIf="getIsRootFilter()?cardsService?.isLaunchLessonAskModalActive() && selectedItem !==2: true"
                                        (click)="playSession()"
                                        [attr.aria-label]="'lesson.play_' + currentUserRole + '_label' | translate">
                                    <span>{{ 'lesson.play_' + currentUserRole + '_label' | translate }}</span>
                                    <mat-icon svgIcon="play-in-class"></mat-icon>
                                </button>
                            </div>
                            <div>
                                <button *ngIf="getIsRootFilter()?datacard.isPreviewAccessible:isPreviewAccessible() | async"
                                        (click)="playPreview()"
                                        [attr.aria-label]=" 'generic.run_preview' | translate">
                                    <span>{{ 'generic.run_preview' | translate }}</span>
                                    <mat-icon svgIcon="visibility"></mat-icon>
                                </button>
                                <button *ngIf="cardsService.showFavoriteButton()"
                                        [class]="[isFavorite() ? 'active' : '', 'button-favorite', 'flag'].join(' ')"
                                        (click)="bookmark()"
                                        [attr.aria-label]=" 'generic.favoris' | translate">
                                    <span>{{ 'generic.favoris' | translate }}</span>
                                    <mat-icon svgIcon="heart"></mat-icon>
                                </button>
                                <button *ngIf="showDuplicateAndEditButton() | async"
                                        (click)="datacard.duplicateAndEdit(resource.id)"
                                        [attr.aria-label]=" 'generic.duplicate_and_edit' | translate">
                                    <span>{{ 'generic.duplicate_and_edit' | translate }}</span>
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button *ngIf="showEditButton() | async"
                                        (click)="datacard.openEditor(resource.id)"
                                        [attr.aria-label]=" 'lesson_page.edit_own_or_disabled' | translate"
                                        [disabled]="(isEditionAllowedForThisLesson$ | async) === false">
                                    <span>{{ 'lesson_page.edit_own_or_disabled' | translate }}</span>
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button *ngIf="showDuplicateIfNeededAndEditButton() | async"
                                        data-e2e="duplicate-if-needed-and-edit"
                                        (click)="datacard.duplicateIfNeededAndEdit(resource.id)"
                                        [attr.aria-label]=" 'generic.duplicate_if_needed_and_edit' | translate">
                                    <span>{{ 'generic.duplicate_if_needed_and_edit' | translate }}</span>
                                    <mat-icon svgIcon="edit"></mat-icon>
                                </button>
                                <button *ngIf="(isEditionActiveForThisLesson() | async)"
                                        (click)="onDeleteButtonClick($event)"
                                        [attr.aria-label]=" 'generic.delete' | translate"
                                        [disabled]="(isEditionAllowedForThisLesson$ | async) === false">
                                    <span>{{ 'generic.delete' | translate }}</span>
                                    <mat-icon svgIcon="delete"></mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="lesson-files" *ngIf="filesCount > 0" anchor="documents">
                        <div class="lesson-files-title">
                            <mat-icon svgIcon="file_download"></mat-icon>
                            <h2>{{ 'generic.fiches_accompagnement' | translate }}</h2>
                        </div>
                        <div class="file-cards-wrapper">
                            <div class="custom-nav prev_next" *ngIf="filesCount > 1">
                                <button
                                    [attr.aria-label]=" 'generic.previous' | translate"

                                    id="prev"
                                    (click)="owlFiles.prev()"
                                >
                                    <mat-icon svgIcon="play"></mat-icon>
                                </button>
                                <button
                                    [attr.aria-label]=" 'generic.next' | translate"
                                     id="next"
                                    (click)="owlFiles.next()"
                                >
                                    <mat-icon svgIcon="play"></mat-icon>
                                </button>
                            </div>
                            <owl-carousel-o [options]="customOptions" #owlFiles class="owlFiles">
                                <ng-container *ngFor="let file of resource?.get('metadatas')?.files;let index = index">
                                    <ng-template carouselSlide [width]="340">
                                        <mat-card tabindex="0"
                                                  role="button"
                                                  [attr.aria-label]="('generic.download' | translate) + ' ' + file.filename"
                                                  (click)="downloadDoc(file.uri)"
                                                  (keyup.enter)="downloadDoc(file.uri)"
                                                  (keyup.space)="downloadDoc(file.uri)">
                                            <mat-icon svgIcon="document"></mat-icon>
                                            <mat-card-content>
                                                {{file.filename}}
                                            </mat-card-content>
                                        </mat-card>
                                    </ng-template>
                                </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </section>

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->
    </div>

</div>