<div id="forms" class="page-layout simple fullwidth" fxLayout="column" fusePerfectScrollbar>

    <!-- CONTENT -->
    <div class="content p-24">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayout.gt-md="row">
            <form class="mat-elevation-z4 p-24 mr-24 mb-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" *ngIf="formIsReady">
                <div class="h2 mb-24">{{ 'account-management.edit_profile' | translate }}</div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('label') || displayLabelFieldInProfile">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.username' | translate }}</mat-label><input matInput
                               formControlName="label">
                        <mat-error *ngIf="formErrors.label.required">
                            {{ 'account-management.username.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('lastName')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.lastName' | translate }}</mat-label><input matInput
                               formControlName="lastName">
                        <mat-error *ngIf="formErrors.lastName.required">
                            {{ 'account-management.lastName.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('firstName')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.firstName' | translate }}</mat-label><input matInput
                               formControlName="firstName">
                        <mat-error *ngIf="formErrors.firstName.required">
                            {{ 'account-management.firstName.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="displayField('email') && !userInformation?.get('sso')" fxLayout="row"
                     fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.email' | translate }}</mat-label><input matInput
                               formControlName="email">
                        <mat-error *ngIf="formErrors.email.required || (form.get('email').hasError('required') && form.get('newsletter').value)">
                            {{ 'authentication.email.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field [floatLabel]="floatLabelControl" *ngIf="displayField('password') && !userInformation?.get('sso')">
                    <mat-label>{{ 'account-management.edit-password' | translate }}</mat-label><input matInput type="password"
                           formControlName="password" autocomplete="off">
                    <mat-error *ngIf="formErrors.password.required">
                        {{ 'authentication.password.required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field [floatLabel]="floatLabelControl" *ngIf="displayField('password') && !userInformation?.get('sso')">
                    <mat-label>{{ 'account-management.password-confirm' | translate }}</mat-label><input matInput type="password"

                           formControlName="passwordConfirm" autocomplete="off">
                    <mat-error *ngIf="formErrors.passwordConfirm.required">
                        {{ 'account-management.password-confirm.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="formErrors.passwordConfirm.passwordsNotMatch">
                        {{ 'account-management.password-confirm.not-identical' | translate }}
                    </mat-error>
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('institution')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.institution' | translate }}</mat-label><input matInput
                               formControlName="institution">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('you_are') && !userInformation?.get('sso')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.you-are' | translate }}</mat-label><mat-select
                                    formControlName="you_are">
                            <mat-option *ngFor="let item of settings?.youAreValues" [value]="item">
                                {{ 'generic.you-are.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.you_are.required">
                            Le champ {{ 'account-management.you-are.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('find_us') && !userInformation?.get('sso')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.find-us' | translate }}</mat-label><mat-select

                            formControlName="find_us">
                            <mat-option *ngFor="let item of settings?.findUsValues" [value]="item">
                                {{ 'account-management.find-us.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.find_us.required">
                            {{ 'account-management.find-us.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('region') && !userInformation?.get('sso')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.region' | translate }}</mat-label><mat-select
                             formControlName="region"
                            >
                            <mat-option *ngFor="let item of defaultUserInformation?.region_field" [value]="+item.id">
                                {{item.attributes.label | translate}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.region.required">
                            Le champ {{ 'account-management.region' | translate }} est requis.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('level') && !userInformation?.get('sso')">
                    <mat-form-field [floatLabel]="floatLabelControl" fxFlex="100">
                        <mat-label>{{ 'account-management.level' | translate }}</mat-label><mat-select  formControlName="level"
                                    >
                            <mat-option *ngFor="let item of defaultUserInformation.level_field" [value]="+item.id">
                                {{ item.attributes.label | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.level.required">
                            Le champ {{ 'account-management.level' | translate }} est requis.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="terms" fxLayout="row" *ngIf="displayField('newsletter') && !userInformation?.get('sso')">
                    <mat-checkbox name="newsletter"
                                  aria-label="{{ 'account-management.newsletter' | translate }}"
                                  formControlName="newsletter">
                        <span>{{ 'account-management.newsletter' | translate }}</span>
                    </mat-checkbox>
                </div>

                <mat-error *ngIf="displayGenericError">{{'generic.form_error' | translate}}</mat-error>
                <mat-error *ngIf="displayEndpointError">{{ displayEndpointError | translate}}</mat-error>


                <div class="upload-file-container" *ngIf="canEditAvatar">
                    <app-upload-file [maxSizeFile]="allowedMaxSize"
                                     (alertSizeFileTooBig)="changeColorIfTooBig($event)"></app-upload-file>
                    <em fxLayout="row"><small>{{ 'corpus.import_url.details' | translate }} {{ allowedExtensions }} </small></em>
                    <em><small
                        [ngClass]="{'mat-error': isPngTooBig}">{{ 'generic.size.max' | translate: {size: allowedMaxSize} }} </small></em>
                </div>

                <div fxLayoutAlign="center center" class="mt-24">
                    <button mat-raised-button class="submit-button save-button"
                            (click)="editProfile()"
                            [disabled]="isPngTooBig || this.form.invalid">
                        {{ "generic.save" | translate }}
                    </button>
                </div>

                <div fxLayoutAlign="center center" class="mt-24">
                    <button mat-raised-button class="submit-button cancel-button"
                            (click)="setMode(false)">
                        {{ "generic.cancel" | translate }}
                    </button>
                </div>
            </form>

        </div>

    </div>
    <!-- / CONTENT -->

</div>