import { Component } from '@angular/core';

@Component({
  selector: 'app-compass-card',
  standalone: true,
  templateUrl: './compass-card.component.html'
})
export class CompassCardComponent {

}
