<ng-template #graphsAreNotAvailable>
    <p class="graphs-are-not-available">{{'graph_inclusive.graphs_are_not_available' | translate}}</p>
</ng-template>

<div fxLayout="column" class="page-layout simple fullwidth" fxFlex>
    <div class="content inner-scroll" fusePerfectScrollbar>
        <ng-container *ngIf="graphsAreAvailable() else graphsAreNotAvailable">
            <app-banner-info [rolesCanShowBanner]="rolesCanShowBannerInfo" situation="progression"></app-banner-info>
            <app-graph-selector></app-graph-selector>
            <app-shared-filters></app-shared-filters>
            <router-outlet></router-outlet>
        </ng-container>
    </div>
</div>