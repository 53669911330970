<a *ngIf="lesson && lesson['lesson']" [ngClass]="{ 'disabled': owlActivities?.navData?.prev?.disabled }"
   class="btn btn-success navBtn backBtn" (click)="owlActivities.prev()">
    <mat-icon svgIcon="keyboard_arrow_up"></mat-icon>
</a>
<header>
    <div>{{ assignation.get('assignated_node').title }}
        <span *ngIf="lesson && lesson['lesson']" class="button white">
            {{lesson['lesson'].get('metadatas').title }}
        </span>
    </div>
    <span *ngIf="lesson" class="button white">
        {{ getAssignedActivitiesMessage(assignation, lesson) }}
    </span>
</header>
<div *ngIf="lesson && lesson['lesson']" class="activities">
    <owl-carousel-o [options]="customOptions" #owlActivities
                    [ngClass]="{'allAssignated' : isAllAssignated(assignation, lesson)}">
        <ng-container *ngFor="let activity of lesson['activities']; let index = index;">
            <ng-template carouselSlide [width]="325">
                <div class="item"
                     [ngClass]="{'assignated': (!isAllAssignated(assignation, lesson) && isActivityAssignated(assignation, activity))}">
                    <app-activty-card [title]="activity.get('metadatas').title"
                                      [image]="getActivityTypeImage(activity)"
                                      [isActivitiesIsDone]="isActivitiesIsDone(activity)"
                                      [subLesson]="lesson['lesson']" [i]="index" (playEvent)="playEvent($event)">
                    </app-activty-card>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<a *ngIf="lesson && lesson['lesson']" [ngClass]="{ 'disabled': owlActivities?.navData?.next?.disabled }"
   class="btn btn-success navBtn nextBtn" (click)="owlActivities.next()">
    <mat-icon svgIcon="keyboard_arrow_up"></mat-icon>
</a>
