<section class="page-layout simple inner-scroll summary"
         [class.summary--grade-only]="shouldDisplayGradeBlock && !shouldDisplayDetailsBlock"
         fusePerfectScrollbar
         fxflex
         fxlayout="column">
    <!-- TODO add class css for scroll and features -->

    <div *ngIf="shouldDisplayGradeBlock"
         class="summary__grade-container">
        <div class="summary__grade">{{getOnlyCorrectFirstAnswers().length}}/{{activities.length}}</div>
        <p class="summary__grade-feedback">{{getAnswersGradeFeedback() | translate}}</p>
    </div>

    <mat-accordion *ngIf="shouldDisplayDetailsBlock">

        <mat-expansion-panel (closed)="panelOpenState = false" (opened)="panelOpenState = true"
                             [expanded]="panelOpenState" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>

                    <h1 class="recap-header">{{ (panelOpenState ? 'activities.summary_header' : 'activities.summary_header_panel_close') | translate }}
                        <div class="mat-select-arrow-wrapper">
                            <div class="mat-select-arrow"></div>
                        </div>
                    </h1>
                </mat-panel-title>
                <mat-panel-description>
                    {{ "activities.summary_header_description" | translate }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div *ngFor="let activity of activities; let i = index"
                 class="activities-card-solution bem-summary-sub-lesson__activity"
                 [ngClass]="noCorrectionRequired(activity) ? 'discovered' : answerState(activity)">
                <div class="activity-counter activities-card-solution__header bem-summary-sub-lesson__activity-header">
                    <mat-icon class="bem-summary-sub-lesson__activity-icon"
                              [svgIcon]="noCorrectionRequired(activity) ? 'bookmark-added' : 'check'">
                    </mat-icon>
                    <span class="activity-counter-text bem-summary-sub-lesson__activity-counter">
                        {{ "activities.summary_activity_counter" | translate }} {{ i + 1 }} / {{ activities.length }}
                    </span>
                    <h2 *ngIf="noCorrectionRequired(activity)"
                        class="bem-summary-sub-lesson__activity-title">
                        {{'generic.what_you_discovered' | translate}}
                    </h2>
                    <h2 *ngIf="!noCorrectionRequired(activity) && isSuccessOnFirstAttempt(activity)"
                        class="bem-summary-sub-lesson__activity-title">
                        {{'generic.success_first_attempt' | translate}}
                    </h2>
                    <h2 *ngIf="!isAnswerMissing(activity) && !noCorrectionRequired(activity) && !isSuccessOnFirstAttempt(activity)"
                        class="bem-summary-sub-lesson__activity-title">
                        {{'generic.error_first_attempt' | translate}}
                    </h2>
                    <h2 *ngIf="!noCorrectionRequired(activity) && isAnswerMissing(activity)"
                        class="bem-summary-sub-lesson__activity-title">
                        {{'generic.step_missing' | translate}}
                    </h2>
                </div>
                <mat-card [ngClass]="activity.attributes.metadatas?.typology.label | lowercase"
                          [ngClass]="answerState(activity)"
                          class="mat-elevation-z0 bem-summary-sub-lesson__activity-card">
                    <mat-card-content>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'MULTI'">
                            <app-multimedia #activeComponent [activity]='activity' [contextId]="assignmentId"
                                            [displayForSummary]="true"></app-multimedia>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === TypologyLabel.awareness">
                            <app-awareness #activeComponent [activity]='activity' [contextId]="assignmentId"
                                           [displayForSummary]="true"></app-awareness>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'QCM'">
                            <app-qcm #activeComponent [activity]='activity' [contextId]="assignmentId"
                                     [displayForSummary]="true"></app-qcm>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'QCMU'">
                            <app-qcu #activeComponent [activity]='activity' [contextId]="assignmentId"
                                     [displayForSummary]="true"></app-qcu>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'VF'">
                            <app-true-false #activeComponent [activity]='activity' [contextId]="assignmentId"
                                            [displayForSummary]="true"></app-true-false>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'missingaudio'">
                            <app-what-is-missing-audio #activeComponent [activity]='activity' [contextId]="assignmentId"
                                                       [displayForSummary]="true"></app-what-is-missing-audio>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'missing'">
                            <app-what-is-missing #activeComponent [activity]='activity' [contextId]="assignmentId"
                                                 [displayForSummary]="true"></app-what-is-missing>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'TXTMAT'">
                            <app-text-matching #activeComponent [activity]='activity' [contextId]="assignmentId"
                                               [displayForSummary]="true"></app-text-matching>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'ORDMAT'">
                            <app-order-matching #activeComponent [activity]='activity' [contextId]="assignmentId"
                                                [displayForSummary]="true"></app-order-matching>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'CRT'">
                            <app-short-answer #activeComponent [activity]='activity' [contextId]="assignmentId"
                                              [displayForSummary]="true"></app-short-answer>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'RB'">
                            <app-fill-in-blanks #activeComponent [activity]='activity' [contextId]="assignmentId"
                                                [displayForSummary]="true"></app-fill-in-blanks>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'MEM'">
                            <app-memory #activeComponent [activity]='activity' [contextId]="assignmentId"
                                        [displayForSummary]="true"></app-memory>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'recording'">
                            <app-voice-recorder-activity #activeComponent [activity]='activity'
                                                         [contextId]="assignmentId"
                                                         [displayForSummary]="true"></app-voice-recorder-activity>
                        </div>
                        <div *ngIf="activity.get('metadatas')?.typology.label === 'Tool'">
                            <app-tool #activeComponent [activity]='activity' [contextId]="assignmentId"
                                      [displayForSummary]="true"></app-tool>
                        </div>

                        <div *ngIf="activity.get('metadatas')?.typology.label === 'imgsoundzone'">
                            <app-image-zoning-play-sound #activeComponent [activity]='activity'
                                                         [contextId]="assignmentId"
                                                         [displayForSummary]="true"></app-image-zoning-play-sound>
                        </div>

                        <div *ngIf="activity.get('metadatas')?.typology.label==='POINTIMG'">
                            <app-image-zoning #activeComponent [activity]='activity'
                                              [contextId]="assignmentId"
                                              [displayForSummary]="true"></app-image-zoning>
                        </div>

                    </mat-card-content>
                    <mat-card-footer>
                        <div class="answer-state">
                            <div
                                *ngIf="activity.get('metadatas')?.typology.label.startsWith('POINTWORD')"
                            >
                                {{
                                getItemAnswerInterfaceAsALine(
                                    getOnlyCorrectAnswer(answers(activity))
                                )
                                }}
                            </div>
                            <div
                                *ngIf="
                  activity
                    .get('metadatas')
                    ?.typology.label.startsWith('POINTMULTIWORD')
                "
                            >
                                {{
                                getItemAnswerInterfaceAsALine(
                                    getOnlyCorrectAnswer(answers(activity))
                                )
                                }}
                            </div>
                            <!-- ce bloc ne sert surement à rien mais dans l'incertitude d'un exo qui matcherais avec lui je le laisse-->
                            <div
                                *ngIf="activity.get('metadatas')?.typology.label.startsWith('POINTIMG') && activity.get('metadatas')?.typology.label !== TypologyLabel.pointImage">
                                <div class="answer-wrapper">
                                    <div [innerHtml]="activity.get('reference')?.instruction | stringToHtml"></div>
                                    <app-zone-generator
                                        [answers]="answers(activity)"
                                        [showAnswers]="true"
                                        [sizeOfZone]="4"
                                    >
                                    </app-zone-generator>
                                </div>
                            </div>

                            <ng-container *ngIf="activity.get('metadatas')?.typology.label === TypologyLabel.drawLine">
                                <div class="image-wrapper-drl" style="position: relative">
                                    <img
                                        *ngFor=" let imagePath of getDrawLineActivityImagePaths(activity)"
                                        [src]="imagePath"
                                        alt=""
                                        style="position: absolute"
                                    />
                                </div>
                            </ng-container>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>


        </mat-expansion-panel>

    </mat-accordion>

    <div class="summary__footer">

            <button *ngIf="isNextActivity && isMyAssignment"
                    class="summary__next-button"
                    (click)="nextActivity()">
                <span>{{ "generic.next" | translate }}</span>
                <mat-icon svgIcon="next"></mat-icon>
            </button>

            <button *ngIf="displayRetryBtn"
                    class="summary__footer__retry-button"
                    (click)="retry()">
                <span>{{ "generic.retry" | translate }}</span>
                <mat-icon svgIcon="retry"></mat-icon>
            </button>

            <button class="summary__back-button"
                    (click)="back()">
                <ng-container *ngIf="shouldDisplayGradeBlock && !shouldDisplayDetailsBlock; else backTemplate">
                    <span>{{ "generic.finish" | translate }}</span>
                </ng-container>
                <ng-template #backTemplate>
                    <span>{{ "generic.back" | translate }}</span>
                </ng-template>
                <mat-icon svgIcon="quit"></mat-icon>
            </button>

            <a *ngIf="showBasePlatformPathButton"
               [href]="basePlatformPath"
               target="_blank">
                <span>{{ "navigation.external-target-link-menu" | translate }}</span>
                <mat-icon svgIcon="log_out"></mat-icon>
            </a>

        </div>
</section>