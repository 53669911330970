import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChip, MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {RandomClassDirective} from 'fuse-core/directives/random-class/random-class.directive';
import {CompassService} from 'fuse-core/compass/compass.service';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedMaterialModule} from 'shared/shared-material.module';
import {CompassComponent, CompassSearchComponent, CompassThemesComponent} from './pages';
import {CompassResourceComponent, CompassResourcesComponent, ModalWrapperMediaComponent} from './pages/resources';
import {routes} from './routes';
import {CompassCardComponent, CompassSearchFormComponent, KeywordsComponent} from './ui';

@NgModule({
    declarations: [
        CompassComponent,
        CompassResourcesComponent,
        CompassThemesComponent,
        CompassResourceComponent,
        CompassResourceComponent,
        ModalWrapperMediaComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatIconModule,
        TranslateModule,
        RandomClassDirective,
        FuseSharedModule,
        CompassSearchComponent,
        CompassSearchFormComponent,
        CompassCardComponent,
        KeywordsComponent,
    ],
    providers: [MatChip]
})
export class CompassModule {
    static forRoot(): ModuleWithProviders<CompassModule> {
        return {
            ngModule: CompassModule,
            providers: [
                CompassService,
            ]
        };
    }
}