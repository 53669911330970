import {Component} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {
    MatDialogActions,
    MatDialogContent,
    MatDialogRef, MatDialogTitle
} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-warning',
    standalone: true,
    templateUrl: './warning.component.html',
    imports: [
        MatDialogTitle,
        TranslateModule,
        MatDialogContent,
        MatDialogActions,
        MatButton
    ],
    styleUrls: ['./warning.component.scss']
})
export class WarningComponent {


    constructor(public dialogRef: MatDialogRef<WarningComponent>) { }

    close(value?: boolean): void {
        this.dialogRef.close(value);
    }
}