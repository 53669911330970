<div fxLayout="column" fxLayoutAlign="space-evenly stretch"  >
    <mat-form-field fxFlex="100" *ngIf="displayField('chapters') && disabled === false; else loading">
        <mat-select [required]="displayRequiredField('chapters')"
                    (selectionChange)="onMethodSelected($event)"
                    [formControl]="select"
                    placeholder="{{ 'generic.method' | translate }}">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let method of availableMethods" [value]="method">
                {{ method.attributes.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-template #loading>
        <mat-spinner fxFlexAlign="center"></mat-spinner>
    </ng-template>

    <div class="table-container" fusePerfectScrollbar>
        <table mat-table [dataSource]="availableChapters" *ngIf="availableChapters && availableChapters.length > 0" >
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="isAllSelected()"
                                  [indeterminate]="selectedChapters.length > 0 && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let chapter">
                    <mat-checkbox *ngIf="isChildrenChapter(chapter)"
                                  (click)="$event.stopPropagation()"
                                  (change)="$event ? toggleSelect(chapter) : null"
                                  [checked]="isSelected(chapter)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>{{'generic.chapters' | translate }}</th>
                <td mat-cell *matCellDef="let chapter" [ngClass]="{'tabulation': isChildrenChapter(chapter)}"> {{ chapter.attributes.label }} </td>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let chapter; columns: displayedColumns;" (click)="$event && isChildrenChapter(chapter) ? toggleSelect(chapter) : null"></tr>
        </table>
    </div>

    <button mat-raised-button color="accent" class="submit-button" (click)="validateSelectedChapters()"
            *ngIf="availableChapters && availableChapters.length > 0"
            [disabled]="selectedChapters.length === 0">{{ 'generic.add' | translate }}</button>
</div>
