<h2 mat-dialog-title>{{'update_mail_dialog.title' | translate}}</h2>
<mat-dialog-content class="flex-it">
    <div *ngIf="isLoading" class="loading-wrapper">
        <mat-spinner></mat-spinner>
    </div>
    <div [innerHTML]="'update_mail_dialog.body' | translate"></div>
    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
        <mat-label>{{ 'update_mail_dialog.mail' | translate }}</mat-label>
        <input [formControl]="mailFormControl" [placeholder]="'update_mail_dialog.mail' | translate" matInput
               type="text" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
        <mat-error *ngIf="!!error">{{error | translate}}</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="onSaveClick($event)" [disabled]="canSave() === false"
            mat-raised-button class="mat-accent">{{'update_mail_dialog.save' | translate}}</button>
    <button (click)="onCancelClick($event)" [disabled]="isLoading"
            mat-button>{{'update_mail_dialog.cancel' | translate}}</button>
</mat-dialog-actions>
