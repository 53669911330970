<div mat-dialog-title>
    <h2 *ngIf="mode ==='creation'">{{ 'corpus.add_resource' | translate }}</h2>
    <h2 *ngIf="mode ==='edition'">{{ 'generic.edit' | translate }}</h2>

    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="content p-24" fxLayout="column" fxLayoutAlign="strech" fxFlex="1 q0 auto">

        <app-chapter-recap [selections]="selections"
                           (onRemove)="onRemoveSelected($event)"></app-chapter-recap>

        <app-chapter-selector [availableMethods]="availableMethods"
                              (onValidation)="onChaptersSelected($event)"
                                [disabled]="disableSelector" ></app-chapter-selector>

    </div>
</div>

<div mat-dialog-actions class="pt-24">
    <button type="submit" mat-raised-button color="accent" class="submit-button"
            (click)="validateStep()" [disabled]="isfrozen">{{ 'generic.validate' | translate }}</button>
</div>