<mat-spinner *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="!isLoading">
    <ng-template #register>
        <div *ngIf="signup || selfSignup" class="register">
            <span class="text">{{ "account-management.no-account" | translate }}</span>

            <ng-container *ngIf="!hasRedirectUrl() else redirectLink">
                <a
                    [routerLink]="'/register'"
                    ariaLabelTranslate="{{ 'account-management.register' | translate }}"
                    class="link"
                >
                    {{ "account-management.register" | translate }}</a
                >
            </ng-container>

            <ng-template #redirectLink>
                <a
                    [href]="redirectSignup.url"
                    ariaLabelTranslate="{{ 'account-management.register' | translate }}"
                    class="link"
                    [target]="redirectSignup.target"
                >
                    {{ "account-management.register" | translate }}</a
                >
            </ng-template>
        </div>
    </ng-template>

    <div fusePerfectScrollbar fxLayout="column" id="login">
        <div
            *ngIf="!this.modeSSO"
            fxLayout="column"
            fxLayoutAlign="center center"
            id="login-form-wrapper"
        >
            <div
                *fuseIfOnDom
                [@animate]="{ value: '*', params: { duration: '300ms', y: '100px' } }"
                id="login-form"
            >
                <div *ngIf="settingsAuth.displayLoginLogo" class="logo">
                    <img
                        *ngIf="!brandLogoSvg"
                        src="assets/{{ brand }}/images/logos/brand-square.png"
                        alt=""
                    />
                    <mat-icon
                        *ngIf="brandLogoSvg"
                        class="logo-icon-svg"
                        svgIcon="brand_square"
                    ></mat-icon>
                </div>

                <ng-container *ngIf="!displaySessions">
                    <button
                        (click)="displayENTHelp()"
                        *ngIf="settingsAuth.enableGAR && displayGARHelper && entButtonAllowed"
                        class="submit-button ent-help"
                        color="accent"
                        mat-raised-button
                    >
                        {{ "authentication.btn-login-gar" | translate }}
                    </button>

                    <div *ngIf="settingsAuth.enableGAR && displayGARHelper && settingsAuth.enableFormLogin && entButtonAllowed"
                         class="divider">
                <span>
                    {{ "generic.or" | translate }}
                </span>
                    </div>

                    <h2 *ngIf="!settingsAuth.enableGAR || !displayGARHelper" class="title mat-headline-6">
                        {{ "authentication.title" | translate }}
                    </h2>

                    <h3 *ngIf="settingsAuth.enableSSO" class="sso-description">
                        {{ "authentication.sso.learner" | translate }}
                    </h3>
                    <form *ngIf="settingsAuth.enableFormLogin" [formGroup]="loginForm" name="loginForm" novalidate>
                        <mat-form-field [floatLabel]="floatLabelControl">
                            <mat-label>{{ 'authentication.login' | translate }}</mat-label><input
                                [attr.disabled]="
              renewPassword && settingsAuth.activeChangePasswordStrategy
            "
                                data-e2e="input-login"
                                formControlName="login"
                                matInput

                                required
                            />

                            <mat-error *ngIf="loginFormErrors.login.required">
                                <span>{{ "authentication.login.required" | translate }}</span>
                            </mat-error>

                            <mat-error *ngIf="loginFormErrors.login.invalid">
                                <span>{{ "authentication.login.invalid" | translate }}</span>
                            </mat-error>

                            <mat-error *ngIf="loginFormErrors.login.email">
                                <span>{{ "authentication.login_email" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field [floatLabel]="floatLabelControl"
                                        *ngIf="!renewPassword">
                            <mat-label>{{ 'authentication.password' | translate }}</mat-label><input
                                [attr.disabled]="
              (renewPassword && settingsAuth.activeChangePasswordStrategy) ||
              loginFormErrors.emailValidation.invalid
            "
                                [type]="hide ? 'password' : 'text'"
                                data-e2e="input-password"
                                formControlName="password"
                                matInput

                                required
                            />
                            <mat-icon
                                (click)="hide = !hide"
                                class="visibility-icon"
                                matSuffix
                                svgIcon="{{ hide ? 'visibility' : 'visibility_off' }}"
                            >
                            </mat-icon>

                            <mat-error *ngIf="loginFormErrors.password.required">
                                <span>{{ "authentication.password.required" | translate }}</span>
                            </mat-error>

                            <mat-error *ngIf="loginFormErrors.password.invalid">
                                <span>{{ "authentication.password.invalid" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="renewPassword && settingsAuth.activeChangePasswordStrategy"
                                        [floatLabel]="floatLabelControl"
                        >
                            <mat-label>{{ 'authentication.new.password' | translate }}</mat-label><input
                                [type]="hideNewPassword ? 'password' : 'text'"
                                autocomplete="off"
                                formControlName="newPassword"
                                matInput
                                name="changePassword"

                                required
                            />
                            <mat-icon
                                (click)="hideNewPassword = !hideNewPassword"
                                matSuffix
                                svgIcon="{{ hideNewPassword ? 'visibility' : 'visibility_off' }}"
                            >
                            </mat-icon>

                            <mat-error *ngIf="loginFormErrors.newPassword.required">
                                <span>{{ "authentication.password.required" | translate }}</span>
                            </mat-error>

                            <mat-error *ngIf="loginFormErrors.newPassword.samePasswordAsOrigine">
                                <span>{{ "authentication.password.must.be.change" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="renewPassword && settingsAuth.activeChangePasswordStrategy"
                                        [floatLabel]="floatLabelControl"
                        >
                            <mat-label>{{ 'authentication.new.password' | translate }}</mat-label><input
                                [type]="hideConfirmPassword ? 'password' : 'text'"
                                autocomplete="off"
                                formControlName="confirmPassword"
                                matInput
                                name="confirmPassword"

                                required
                            />
                            <mat-icon
                                (click)="hideConfirmPassword = !hideConfirmPassword"
                                matSuffix
                                svgIcon="{{
              hideConfirmPassword ? 'visibility' : 'visibility_off'
            }}"
                            >
                            </mat-icon>

                            <mat-error *ngIf="loginFormErrors.confirmPassword.notMatch">
                                <span>{{ "account-management.password-confirm.not-identical" | translate }}</span>
                            </mat-error>
                        </mat-form-field>

                        <div
                            *ngIf="!settingsAuth.enableSSO && !renewPassword"
                            class="remember-forgot-password"
                            fxLayout="row"
                            fxLayout.xs="column"
                            fxLayoutAlign="space-between center"
                        >
                            <a [routerLink]="'/forgot-password'" class="forgot-password">
                                {{ "authentication.forgotten-password" | translate }}
                            </a>
                        </div>

                        <ng-template [ngTemplateOutlet]="register"></ng-template>

                        <button
                            (click)="login()"
                            *ngIf="!loginFormErrors.emailValidation.invalid"
                            [disabled]="loginForm.invalid"
                            class="submit-button"
                            color="accent"
                            data-e2e="button-submit"
                            mat-raised-button
                        >
                            {{ "authentication.btn-login" | translate }}
                        </button>
                        <!-- if auto redirection doesnt work -->
                        <mat-error *ngIf="showMessageForReloadPage">
                            <span class="show-message-reload-page">
                                {{ "generic.message_reload_page" | translate }}</span>
                        </mat-error>
                        <!--if mail was not validated error msg and button to send again email link -->
                        <mat-error *ngIf="loginFormErrors.emailValidation.invalid">
                            <span>{{ "generic.email.validation.invalid" | translate }}</span>
                        </mat-error>
                        <!--no licence-->
                        <mat-error *ngIf="loginFormErrors.licenceValidation.invalid">
                            <span>{{ "generic.validation.licence.invalid" | translate }}</span>
                        </mat-error>
                        <mat-error *ngIf="newLinkSendError">
                            <span>{{ "generic.email.not.send" | translate }}</span>
                        </mat-error>

                        <p *ngIf="newLinkSendSuccess">
                            {{ "generic.email.send" | translate }}
                        </p>

                        <p *ngIf="!!licenceLink">{{ 'account-management.ask_licence_part1' | translate }}
                            <a
                                [href]="licenceLink"
                                ariaLabelTranslate="{{ 'account-management.ask_licence_part2' | translate }}"
                                class="link"
                                target="_blank"
                            >
                                {{ "account-management.ask_licence_part2" | translate }}</a>
                        </p>

                        <button
                            (click)="sendLinkToValidateEmail()"
                            *ngIf="loginFormErrors.emailValidation.invalid"
                            color="accent"
                            mat-raised-button
                        >
                            {{ "generic.email.validation.invalid.send.new.link" | translate }}
                        </button>
                    </form>

                    <div class="social-connect">
                        <h3
                            *ngIf="settingsAuth.enableSSO"
                            class="sso-description sso-description-trainer"
                        >
                            {{ "authentication.sso.trainer" | translate }}
                        </h3>

                        <button
                            (click)="loginSSO()"
                            *ngIf="settingsAuth.enableSSO"
                            class="submit-button"
                            color="accent"
                            mat-raised-button
                        >
                            {{ "authentication.btn-login-sso" | translate }}
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="displaySessions">
                    <app-sessions-list (loginSession)="loginSession($event)"></app-sessions-list>
                    <ng-template [ngTemplateOutlet]="register"></ng-template>
                </ng-container>

            </div>
        </div>

        <div
            *ngIf="this.modeSSO"
            fxLayout="column"
            fxLayoutAlign="center center"
            id="login-sso-wrapper"
        >
            <div id="login-sso">
                <div *ngIf="settingsAuth.displayLoginLogo" class="logo">
                    <img
                        *ngIf="!brandLogoSvg"
                        src="assets/{{ brand }}/images/logos/brand-square.png"
                        alt=""
                    />
                    <mat-icon
                        *ngIf="brandLogoSvg"
                        class="logo-icon-svg"
                        svgIcon="brand_square"
                    ></mat-icon>
                </div>
                <h2>{{ "authentication.sso.in-progress" | translate }}</h2>
            </div>
        </div>
    </div>
</ng-container>