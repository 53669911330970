<button *ngIf="flashCard"
        class="language-lab-card"
        [ngClass]="{'language-lab-card--playing' : isPlaying()}"
        (click)="cardClick()">

    <ng-container *ngIf="isPlaying()">
        <div class="language-lab-card__overlay"></div>
        <mat-icon *ngIf="flashCard?.audio?.uri"
                  class="language-lab-card__play-icon"
                  svgIcon="sound_on">
        </mat-icon>
    </ng-container>

    <img *ngIf="flashCard?.image?.uri"
         class="language-lab-card__image"
         [src]="flashCard?.image?.uri"
         [alt]="flashCard?.image?.alt">

    <div class="language-lab-card__content">
        <span class="language-lab-card__title">{{flashCard?.title}}</span>
        <div class="language-lab-card__data-wrapper">
            <div class="language-lab-card__type">
                <mat-icon [svgIcon]="flashCard?.type ? flashCard?.type.toLowerCase():'Dialogue'"></mat-icon>
                <span>{{flashCard?.type}}</span>
            </div>
            <div class="language-lab-card__count">
                <span>{{flashCard?.count}}</span>
                <mat-icon svgIcon="equalizer"></mat-icon>
            </div>
        </div>
    </div>
    
</button>