<mat-card class="language-lab-card-player activities-card">

    <div class="language-lab-card-player__header">
        <button class="language-lab-card-player__close-button"
                (click)="closeActivity()">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <h1 class="language-lab-card-player__title">{{mainCardTitle}}</h1>
        <div class="language-lab-card-player__progress-bar"
             [ngClass]="{'language-lab-card-player__progress-bar--completed' : progress === 100}"
             role="progressbar"
             [attr.aria-valuenow]="progress"
             [attr.aria-label]="'generic.my_progress' | translate">
            <div [ngStyle]="{'width':progress + '%'}"></div>
        </div>
        <app-consignes class="language-lab-card-player__instructions"
                       *ngIf="instructionAudio && !showResume"
                       [instruction]="instruction"
                       [instructionAudio]="instructionAudio">
        </app-consignes>
        <app-consignes class="language-lab-card-player__instructions"
                       *ngIf="!instructionAudio && !showResume"
                       [instruction]="instruction">
        </app-consignes>
    </div>

    <mat-card-content class="language-lab-card-player__content">
        <app-voice-recorder *ngIf="!showResume"
                            [flashcard]="card"
                            [showTitle]="true"
                            (activityDone)="activityDone()"
                            (userAudioBlobEvent)="userAudio($event)"
        ></app-voice-recorder>

        <ng-container *ngIf="showResume">
            <app-dialog-resume [currentCard]="cards"
                               [allUserAudio]="allUserAudio"
                               [mainCardPicture]="mainCardPicture"
                               [unlockDialogDone]="unlockDialogDone"
                               (launch)="launch()"
                               (close)="closeActivity()"
                               (record)="recordAgain($event)"
            ></app-dialog-resume>
        </ng-container>
    </mat-card-content>

    <mat-card-footer *ngIf="!showResume"
                     class="language-lab-card-player__footer">
        <button class="language-lab-card-player__next-button"
                [disabled]="!enableNextButton"
                (click)="next()">
            <span class="language-lab-card-player__next-button-label">{{ (recordAnotherTime ? 'language_lab.skip' : 'generic.next') | translate }}</span>
            <mat-icon class="language-lab-card-player__next-button-icon"
                      [svgIcon]="recordAnotherTime ? 'skip' : 'next'">
            </mat-icon>
        </button>
    </mat-card-footer>

</mat-card>