<mat-card class="short-answer activities-card mat-elevation-z0" [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                class="centeredContent"
                [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"
                [wordingAudio]="wordingAudio"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)" >
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content  [class.displayForSummary]="displayForSummary" [class.instruction-is-picture]="instruction?.includes('<img')" [ngClass]="reply?.state">
        <app-consignes
            *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
            [read]="readSecondConsigne"
            class="centeredContent"
            [class.isWordingImage]="isWordingImage"
            [instruction]="instruction" [ngClass]="{'consigneImg': isInstructionImg()}"
            [instructionAudio]="instructionAudio"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            questionTypeName="{{ 'activities.questionTypeName.app-short-answer' | translate }}">
        </app-consignes>

        <ng-container *ngFor="let f of referenceActivityGranule.flashcards">
            <app-flashcard [class.button-image]="f.image"
                           [class.is-audio]="f.audio?.uri"
                           [flashCard]="f">
            </app-flashcard>
        </ng-container>

        <div class="textarea-wrapper" *ngIf="!isFormula">
            <textarea
                [disabled]="disable || isSaving || displaySolution || displayForSummary"
                class="responseTextarea"
                type="text"
                placeholder="{{'activities.placeholder_reply_here' | translate}}"
                [(ngModel)]="reply.answer"
                (ngModelChange)="onNgModelChange($event)">
            </textarea>
        </div>

        <app-poll *ngIf="isPoll" [value]="reply.answer" [assignatedCount]="assignatedCount"
                  (moveSlider)="onMoveSlider($event)"
                  (clickSlider)="onClickSlider($event)"></app-poll>

        <div class="latex-wrapper longAnswer" *ngIf="isFormula">
            <span class="correction" [ngClass]="answerState()"></span>
            <app-keyboard-latex-field
                *ngFor="let reference of generateArrayToIterateAndDisplayKeyboard"
                [symbols]="symbols"
                [optionsFromSettings]="optionsFromSettings"
                [blacklistTabs]="blacklistTabs"
                [disable]="disable || displaySolution || displayForSummary"
                [displayForSummary]="displayForSummary"
                [displaySolution]="displaySolution"
                [answerCorrected]="answerCorrected"
                (clickAnswer)="validate()"
                (doesUserResponded)="doesUserResponded($event)"
                (onOptionChange)="onOptionChange($event)"
                (onKeyboardReady)="onKeyboardReady($event)"
            ></app-keyboard-latex-field>
        </div>
    </mat-card-content>

    <div *ngIf="!isPoll && displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button
                    [class]="getStateAnswer(button.type)"
                    *ngIf="button.display"

                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>
<div fxFill fxLayoutAlign="center center" *ngIf="isLessonWithStep && isSaving">
    <mat-spinner></mat-spinner>
</div>