<aside class="rewards-dialog">

    <div class="rewards-dialog__header"
         mat-dialog-title>

        <h2 *ngIf="titleDialog?.length > 0"
            class="rewards-dialog__title">
            {{titleDialog}}
        </h2>

    </div>

    <div class="rewards-dialog__content"
         mat-dialog-content>

        <ng-container *ngIf="anyRewards; else noRewardTemplate">
            <p class="rewards-dialog__message">{{ bodyDialog }}</p>
        </ng-container>

        <ng-template #noRewardTemplate>
            <p class="rewards-dialog__message">{{ 'gamification.reward.no-rewards' | translate }}</p>
        </ng-template>

        <div *ngIf="coins > 0"
             class="rewards-dialog__coins">
            <div class="rewards-dialog__coin-name">{{ coinsName }}</div>
            <div class="rewards-dialog__number-of-coins">+ {{ coins }}</div>
            <mat-icon class="rewards-dialog__coin-icon"
                      svgIcon="credits">
            </mat-icon>
        </div>

        <ul *ngIf="!!achievements.length"
            class="rewards-dialog__achievement-list">
            <li *ngFor="let achievement of achievements"
                 class="rewards-dialog__achievement-item">
                <img class="rewards-dialog__achievement-image"
                     [src]="achievement.image"
                     [attr.alt]="achievement.name"
                     [matTooltip]="achievement.name"/>
                <div class="rewards-dialog__achievement-name">{{ 'gamification.new_' + achievement.type | translate }}</div>
            </li>
        </ul>

    </div>
    
    <div class="rewards-dialog__actions"
         mat-dialog-actions>

        <button class="rewards-dialog__exit-button"
                (click)="dialogRef.close('exit')">
            <span>{{ labelExit }}</span>
        </button>

        <button *ngIf="authorizeExit"
                class="rewards-dialog__next-button"
                (click)="dialogRef.close('next')">
            <span>{{ labelNext }}</span>
            <mat-icon svgIcon="arrow-forward-rounded-500"></mat-icon>
        </button>

    </div>

</aside>