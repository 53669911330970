<div>
  <div>Ecran temporaire de gestion des corpus</div>
  <input type="button" value="Add corpus" (click)="addCorpus()">
  <input type="button" value="Add test set" (click)="addSet()">

  <div *ngFor="let corpus of corpusList"><span [routerLink]="['..', corpus.id]">Corpus {{ corpus.id }}</span> <span (click)="corpus.remove()">X</span></div>

  <br>
  <br>
  <div *ngFor="let corpusSet of setLists">Set {{ corpusSet.id }} <span (click)="corpusSet.activateMenu()">Set menu</span> <span (click)="corpusSet.remove()">X</span></div>
</div>