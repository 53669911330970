<div mat-dialog-title>
    <h2>{{'group-management.import_learners_list' | translate}}</h2>
    <button class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div *ngIf="!done">
        <div [innerHTML]="page?.get('body')"></div>
        <div>
        <mat-form-field appearance="fill" *ngIf="importInClasses">
            <mat-label>{{ 'groups-management.assign_to_class' | translate }}</mat-label>
            <mat-select [(value)]="selectedGroup">
                <mat-option *ngFor="let group of groups" [value]="group">
                    {{ group['groupname'] }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        </div>

        <button mat-raised-button color="primary" (click)="fileInput.click()">
            <span>{{ 'corpus.import_file' | translate }}</span>
            <input hidden type="file" #fileInput (change)="onFileChanged($event)" id="fileToUpload" />
        </button>
        <span *ngIf="fileName && !done && !fileError">{{ fileName | translate }}</span>
        <mat-error *ngIf="fileError">{{ fileError | translate }}</mat-error>
        <span *ngIf="fileName && done">{{ fileName }}</span>
    </div>

    <div *ngIf="done">
           <div *ngFor="let field of objectKeys(result['attributes'])">
               <span *ngIf="result['attributes'][field]">
                   <span [innerHTML]="field | translate"></span> : {{ result['attributes'][field] }}
               </span>
               <p *ngIf="field === 'groups-management.username_taken' && result['attributes'][field]" [innerHTML]="'groups-management.username_taken_description' | translate"></p>
           </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button  *ngIf="!done"
            (click)="importLearners()"
            [disabled]="!uploaded"
            mat-raised-button
            class="mat-accent">{{'group-management.import' | translate}}</button>


    <button  *ngIf="!done"
             (click)="dialogRef.close()"
             mat-raised-button
             class="mat-accent">{{'generic.cancel' | translate}}</button>

    <button  *ngIf="done"
             (click)="dialogRef.close()"
             mat-raised-button
             class="mat-accent">{{'generic.ok' | translate}}</button>
</mat-dialog-actions>