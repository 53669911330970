<mat-card class="activities-card inner-scroll mat-elevation-z0"
          [ngClass]="{'two-columns-layout': isTwoColumns,
                      'order-sentence':isOrderSentence}">
    <mat-card-header>
        <mat-card-title>
            <app-consignes
                *ngIf="isConsigneContainMedia(wording) || (!isDataEmpty(wording) && !isDataEmpty(wordingAudio)) || (!isDataEmpty(wording) && isDataEmpty(wordingAudio))"
                [wording]="wording" [ngClass]="{'consigneImg': isWordingImg()}"
                [wordingAudio]="wordingAudio"
                questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}"
                [autoRead]="isAutoReadActive('wording')" [readable]="!isConsigneContainMedia(wording)">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [class.displayForSummary]="displayForSummary">

        <app-consignes
            *ngIf="isConsigneContainMedia(instruction) || (!isDataEmpty(instruction) && !isDataEmpty(instructionAudio)) || (!isDataEmpty(instruction) && isDataEmpty(instructionAudio))"
            [instruction]="instruction" [ngClass]="{'consigneImg': isInstructionImg()}"
            [instructionAudio]="instructionAudio"
            [autoRead]="isAutoReadActive('instruction')" [readable]="!isConsigneContainMedia(instruction)"
            questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}">
        </app-consignes>

        <div id="container-for-answers-selected">
            <ng-container *ngFor="let answerSelected of answersOrderToGuess; let i = index">
                <!-- Answer not selected -->
                <div *ngIf="!answersSelected[i]?.answer && !answersSelected[i]?.image && !answersSelected[i]?.audio && !answersSelected[i]?.flashcards"
                     class="button-order"
                     [ngClass]="returnClassAnswerState(answerSelected, i)">
                    <span class="answer-position"
                          [class.current]="currentAnswerToSelect === i">
                        {{ i + 1 }}
                    </span>
                </div>
                <!-- Text answer selected -->
                <div *ngIf="isTwoColumns && !!answersSelected[i]?.answer"
                     class="button-order"
                     [ngClass]="returnClassAnswerState(answerSelected, i)">
                    <span>{{ answersSelected[i]?.answer }}</span>
                </div>
                <!-- Image answer selected -->
                <div *ngIf="!isTwoColumns && !!answersSelected[i]?.image"
                     class="button-image"
                     [ngClass]="returnClassAnswerState(answerSelected, i)">
                    <img [src]="answersSelected[i]?.image">
                </div>
                <!-- Audio answer selected -->
                <div *ngIf="!isTwoColumns && !!answersSelected[i]?.audio"
                        class="order-matching__audio-button"
                        [ngClass]="returnClassAnswerState(answerSelected, i)">
                    <mat-icon [svgIcon]="'order-matching__audio-' + (i + 1)"></mat-icon>
                </div>
                <!-- Flashcard answer selected -->
                <app-flashcard *ngIf="!isTwoColumns && !!answersSelected[i]?.flashcards && answersSelected[i].flashcards[0]"
                               [class.button-image]="answersSelected[i]?.flashcards[0].image"
                               [ngClass]="returnClassAnswerState(answerSelected, i)"
                               [flashCard]="answersSelected[i]?.flashcards[0]">
                </app-flashcard>
            </ng-container>
        </div>

        <div *ngIf="!displayForSummary"
             id="container-for-answers-available"
             class="available-answers">
            <ng-container *ngIf="!isTwoColumns">
                <ng-container *ngFor="let item of availableAnswers let index = index;">
                    <ng-container *ngIf="item.image">
                        <div class="button-image"
                             [ngClass]="item.state"
                             (click)="validate(item)">
                            <img [src]="item.image"
                                 [attr.id]="'imgToZoom' + uuid + index.toString()"/>
                            <div *ngIf="isImageFullscreenButtonEnabled()"
                                 class="action-buttons">
                                <app-zoomable [uuid]="uuid"
                                              [index]="index">
                                </app-zoomable>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.audio">
                        <button class="order-matching__audio-button"
                                [ngClass]="item.state"
                                (click)="playAudioAndValidate(item)">
                            <mat-icon [svgIcon]="'order-matching__audio-' + (index + 1)"></mat-icon>
                            <audio [src]="item.audio?.uri" [id]="item.id"></audio>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="item.flashcards">
                        <app-flashcard *ngFor="let flashcard of item.flashcards"
                                       [class.button-image]="flashcard.image"
                                       [class.is-audio]="flashcard.audio?.uri"
                                       [ngClass]="item.state"
                                       [flashCard]="flashcard"
                                       role="button"
                                       (click)="validate(item)">
                        </app-flashcard>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isTwoColumns">
                <button *ngFor="let item of availableAnswers"
                        class="button-text"
                        [ngClass]="item.state"
                        (click)="validate(item)"
                        [disabled]="item.state === 'was-correct' || disableAllAnswers">
                    <span>{{ item.answer }}</span>
                </button>
            </ng-container>
        </div>

    </mat-card-content>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate }}{{ showPercentile }}
        </div>

        <ng-container *ngIf="!isActivityEmbeddedDone">
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"
                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>
        </ng-container>
    </mat-card-footer>
</mat-card>
<app-activities-feedback *ngIf="!!feedbackFromApi && isActivityEmbeddedDone"
                         (action)="actionFromFeedback($event)"
                         [activityType]="activityType"
                         [feedback]="feedbackFromApi"
                         [buttons]="feedbackButtons"
                         [answerStatus]="answerStatus">
</app-activities-feedback>