<div fxFlex="100" fxLayout="column" fxLayoutAlign="{{align}}">

  <table *ngIf="!empty" mat-table #table [dataSource]="dataSource" [@animateStagger]="{value:'50'}" fxFlex="100">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Projet </th>
      <td mat-cell *matCellDef="let item">
        <p class="font-weight-600 text-bold">{{item.name}}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="groups">
      <th mat-header-cell *matHeaderCellDef> Classe </th>
      <td mat-cell *matCellDef="let item">
        <p class="text-truncate font-weight-600">{{item.groups.join(', ')}}</p>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToProject(row)"></tr>
  </table>

  <div *ngIf="empty" class="emptyResourceLabel">{{ getTermsByRole | translate }}</div>
</div>