<ng-container *ngIf="type === 'old-school'">
    <app-old-school (buttonClick)="onButtonOfAnytypeClick($event)"></app-old-school>
</ng-container>

<ng-container *ngIf="type === 'neo-first-time'">
    <app-neo>
        <mat-icon data-icon svgIcon="neo__icon-first-time"></mat-icon>
        <h3 data-header>
            <span>{{ 'add_lesson_button.first_time_create_lesson_title_part_1' | translate }}</span>
            {{ 'add_lesson_button.first_time_create_lesson_title_part_2' | translate }}
            {{ 'add_lesson_button.first_time_create_lesson_title_part_3' | translate }}
        </h3>
        <span data-subtitle>
            {{ 'add_lesson_button.first_time_create_lesson_subtitle' | translate }}
        </span>
        <button data-e2e="create-lesson" (click)="onButtonOfAnytypeClick($event)" class="add-file-button add-file-button--cardLayout"
                data-call-to-action>
            <mat-icon svgIcon="icon-lesson-add"></mat-icon>
            <span>{{ 'add_lesson_button.first_time_create_lesson_action' | translate }}
            </span>
        </button>
    </app-neo>
</ng-container>

<ng-container *ngIf="type === 'neo-already-practiced'">
    <app-neo>
        <mat-icon data-icon svgIcon="neo__icon-already-practiced"></mat-icon>
        <h3 data-header>
            {{ 'add_lesson_button.already_practiced_create_lesson_title' | translate }}
        </h3>
        <span data-subtitle>
            {{ 'add_lesson_button.already_practiced_create_lesson_subtitle' | translate }}
        </span>
        <button data-e2e="create-lesson" (click)="onButtonOfAnytypeClick($event)" class="add-file-button add-file-button--cardLayout"
                data-call-to-action>
            <mat-icon svgIcon="icon-lesson-add"></mat-icon>
            <span>{{ 'add_lesson_button.already_practiced_create_lesson_action' | translate }}</span>
        </button>
    </app-neo>
</ng-container>

<ng-container *ngIf="type === 'neo-searching'">
    <app-neo>
        <mat-icon data-icon svgIcon="neo__icon-searching"></mat-icon>
        <h3 data-header>{{ 'add_lesson_button.searching_create_lesson_title' | translate }}</h3>
        <span data-subtitle>{{ 'add_lesson_button.searching_create_lesson_subtitle' | translate }}</span>
        <button data-e2e="create-lesson" (click)="onButtonOfAnytypeClick($event)" class="add-file-button add-file-button--cardLayout"
                data-call-to-action>
            <mat-icon svgIcon="icon-lesson-add"></mat-icon>
            <span>{{ 'add_lesson_button.searching_create_lesson_action' | translate }}</span>
        </button>
    </app-neo>
</ng-container>
