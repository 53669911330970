<div *ngIf="!showSpinner" fxLayout="column" class="page-layout simple fullwidth" fxFlex>
    <div class="content" [ngClass]="{'show-markers-list':showMarkers && videoUrl && !settings?.markerPersonalizeOnly}" fxFlex fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        <app-video-with-markers *ngIf="videoReady" [videoConfig]="videoConfig" ></app-video-with-markers>

        <div *ngIf="!videoUrl && !showMarkers" class="video-editor-import-actions" fxFill fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
            <app-select-corpus-upload id="app-select-corpus-upload" [uploadAvailable]="true" (corpusSelect)="chooseResource($event)"></app-select-corpus-upload>
        </div>
    </div>
    <mat-list fxFlex="none" *ngIf="showMarkers && videoUrl && !settings?.markerPersonalizeOnly" class="content video-editor-actions list-thumb video-editor-marker-content containerMarkers"
         fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-list-item *ngFor="let type of markerTypes">
            <button  (click)="addMarker(type)" class="video-editor-btn-marker" role="button" title="{{type.get('label')}}" >
                <mat-icon svgIcon="add"></mat-icon>
            </button>
            <p matListItemLine>
            {{type.get('label')}}
            </p>
        </mat-list-item>
        <mat-list-item>
            <button  (click)="addMarker()"  class="video-editor-btn-marker"  role="button" title="{{'activities.personalize' | translate}}">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
            <p matListItemLine>
            {{'activities.personalize' | translate}}
            </p>
        </mat-list-item>
    </mat-list>

    <button (click)="addMarker()" *ngIf="settings?.markerPersonalizeOnly"  role="button" title="{{'activities.personalize' | translate}}" >
        <span>{{'activities.personalize' | translate}}</span>
    </button>
</div>

<div fxFill fxLayoutAlign="center center" flex="1
" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>