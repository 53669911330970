<div fxFill fxLayoutAlign="center center" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<mat-card class="lesson-card" *ngFor="let resource of resources">
    <mat-card-header>
        <mat-card-title
            [ngClass]="{
                'assignment' : assignmentsListId.includes(resource.id)
            }">{{resource.attributes.metadatas.title}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-progress-bar mode="determinate" [value]="pourcent(resource.id)"></mat-progress-bar>
        <div *ngIf="getAssignmentAssignatorName(resource.id)!==''" class="assign_comment">
            <b>{{getAssignmentAssignatorName(resource.id)}} <span *ngIf="notAutoAssignation(resource.id)">{{ 'assign.lesson_to_you'| translate}}</span> </b>
            <span *ngIf="getAssignmentComment(resource.id)!==''">{{getAssignmentComment(resource.id)}}</span>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <button role="button" [attr.data-e2e]="'btn_launch_modal' + resource.id"
                (click)="
                    launchModal(
                        resource,
                        assignmentsListId.includes(resource.id),
                        autoAssignmentsListId.includes(resource.id),
                        closedAssignmentsListId.includes(resource.id))
                ">
            <mat-icon aria-hidden="true" svgIcon="lessonCardIcon"></mat-icon>
            <span>{{ 'activities.explore' | translate}}</span>
        </button>
    </mat-card-actions>
</mat-card>