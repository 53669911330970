<div class="page-layout simple inner-scroll" fxFlex>
    <mat-toolbar class="filters">
        <mat-toolbar-row>
            <app-search-filters [fields]="fieldsToDisplay" [countEntities]="countEntities"
                                [orderFields]="settings.filterThemeUsePosition"
                                [overrideEndpointFilterName]="[{originalFilterName: 'bookmarks', targetFilterName: 'bookmarks_theme'}]"
                                (launchSearch)="loadData($event)"></app-search-filters>
        </mat-toolbar-row>
    </mat-toolbar>

    <ng-template #loading>
        <div mat-dialog-content>
            <div class="spinner-wrapper">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="isLoading === false else loading">
        <div class="cards content" fxLayout="row" fxLayoutAlign="start start">
            <div *ngFor="let dataCard of datas" class="p-16" fxFlex="auto" fxFlex.gt-xs="50" fxFlex.gt-lg="33">
                <app-card [dataCard]="dataCard" [ngClass]="'card-split-media-info'"
                ></app-card>
            </div>
        </div>

        <mat-paginator
            [length]="countEntities"
            [pageIndex]="themesService.pageIndex"
            [pageSizeOptions]="themesService.pageRangeOptions"
            (page)="themesService.onPaginateChange($event)">
        </mat-paginator>

    </ng-container>
</div>
