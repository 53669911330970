<div mat-dialog-title>
    <h2>{{title}}</h2>
    <button
        class="close"
        (click)="close()"
        aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <app-shared-video
        *ngIf="video"
        [controls]="controls"
        controlsList="controlsList"
        oncontextmenu="return false"
        [video]="video" [muted]="muted ? 'muted' : null"></app-shared-video>

    <img
        *ngIf="image"
        oncontextmenu="return false"
        alt="{{ image.title }}"
        src="{{ image.uri }}">
</div>

