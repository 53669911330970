<div id="file-manager" fxLayout="column" class="page-layout" fxFlex>

    <!-- HEADER -->
    <div class="header p-16" *ngIf="displayHeader">
        <h2 *ngIf="displayHeaderTitle">{{ titleCorpus() | translate }}</h2>
        <app-getting-started [alias]="corpusService.gettingStarted"></app-getting-started>
    </div>
    <!-- / HEADER -->
    <div class="page-layout simple right-sidebar inner-scroll">
        <!-- CENTER -->
        <div class="center" fxFlex>

            <!-- CONTENT -->
            <div class="content mat-white-bg" fusePerfectScrollbar>
                <fuse-corpus-file-list [itemId]="itemId" [urlFilters]="filters"></fuse-corpus-file-list>
            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CENTER -->

        <!-- SIDENAV -->
        <fuse-sidebar fxFlex class="sidebar details-sidebar pb-60" position="right" lockedOpen="gt-xs">


            <div fxFlex="100">
                <fuse-corpus-details-sidenav [mode]="mode"></fuse-corpus-details-sidenav>
            </div>

        </fuse-sidebar>
        <!-- / SIDENAV -->
    </div>

</div>