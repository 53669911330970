<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h3>{{ title }}</h3>
        </mat-card-title>

        <button [matMenuTriggerFor]="menu"
                ariaLabelTranslate="{{'generic.menu' | translate}}"
                class="mat-icon-button"
                mat-icon-button matTooltip="{{'generic.menu' | translate}}">
            <mat-icon svgIcon="submenu"></mat-icon>
            <mat-menu #menu="matMenu">
                <button (click)="edit($event)" mat-menu-item>
                    <mat-icon svgIcon="edit"></mat-icon>
                    {{ 'generic.edit' | translate }}
                </button>
                <button (click)="delete($event)" mat-menu-item>
                    <mat-icon svgIcon="delete"></mat-icon>
                    {{ 'generic.delete' | translate }}
                </button>
            </mat-menu>
        </button>
    </mat-card-header>

    <img alt="" mat-card-image src="{{ imageUri }}">

    <mat-card-content>
        <dl class="dl-listitem-inline" role="list">
            <dt>{{'generic.createdDate' | translate}}</dt>
            <dd>{{creationDate}}</dd>
            <dt>{{'generic.changedDate' | translate}}</dt>
            <dd>{{lastUpdate}}</dd>
            <ng-container *ngIf="associatedLessonIsLoading">
                <dt>{{'bd.linked_lesson' | translate}}</dt>
                <dd>
                    <mat-spinner diameter="20"></mat-spinner>
                </dd>
            </ng-container>

            <ng-container *ngIf="!associatedLessonIsLoading && !!associatedLessonTitle">
                <dt>{{'bd.linked_lesson' | translate}}</dt>
                <dd>{{associatedLessonTitle}}</dd>
            </ng-container>
        </dl>
    </mat-card-content>

    <!--     <mat-card-actions>
            <button mat-mini-fab *ngIf="!associatedLessonIsLoading && !!associatedLessonId" (click)="goToLesson()">
                <mat-icon aria-label="transition" svgIcon="transition"></mat-icon>
            </button>
           This action is commented because everything is ready to open an empty popup about the bd
                    but we don't know what we have to show -->
<!--        <button (click)="show($event)" mat-raised-button role="button">-->
<!--            {{'generic.see' | translate}}-->
<!--        </button>
    </mat-card-actions>-->
</mat-card>


