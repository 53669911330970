<div class="page-layout simple fullwidth" fusePerfectScrollbar>
    <div *ngIf="configurationService.settings.showOtherSync" class="header p-24" fxLayout="row"
         fxLayoutAlign="start center">
        <h2>{{ 'navigation.data' | translate }}</h2>
    </div>
    <div class="content p-16 mat-white-bg">
        <div fxFlex fxLayout="column">
            <ng-container *ngIf="configurationService.settings.showUploadExercice">
                <app-upload-exercice [config]="configurationService.settings.uploadExoConfig"
                                     (fileToUpload)="uploadFile($event, ['zip', 'csv'], 'api/ubolino-upload')"
                                     [fileUploaded]="fileUploaded"
                                     [uploadError]="error"></app-upload-exercice>
            </ng-container>
            <ng-container *ngIf="configurationService.settings.showOtherSync">
                <h2>{{'configuration.data.synchro.editic' | translate}}</h2>
                <div fxFlex class="m-24">
                    <p>{{'configuration.data.synchro.editic.info' | translate}}</p>
                    <p>{{'configuration.data.synchro.editic.ids.help' | translate}}</p>
                    <mat-form-field>
                        <input matInput
                               placeholder="{{ 'configuration.data.synchro.editic.ids' | translate }}"
                               [formControl]="idsList">
                    </mat-form-field>
                    <div class="mb-12">
                        <button mat-raised-button class="mat-primary mr-12" (click)="synchronize()"
                                [disabled]="!canMigrate">
                            <span>{{'configuration.data.synchro.editic.button' | translate}}</span>
                        </button>
                        <span>{{synchroLabel}}</span>
                        <p>{{'configuration.data.synchro.editic.info_maj' | translate}}</p>
                        <ul>
                            <li>{{'generic.exercices' | translate}}</li>
                            <li>{{'generic.domains_concepts_skills' | translate}}</li>
                            <li>{{'generic.struct_licensing' | translate}}</li>
                        </ul>
                    </div>
                </div>
                <h2>{{'licensing.method.import' | translate}}</h2>
                <div fxFlex class="m-24">
                    <mat-form-field required>
                        <mat-select placeholder="{{'licensing.method.choose' | translate}}"
                                    [formControl]="methodControl"
                                    required>
                            <mat-option *ngFor="let method of licensingMethods" [value]="method">
                                {{method.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="mb-12">
                        <button mat-raised-button class="mat-primary mr-12" (click)="fileInput.click()"
                                [disabled]="methodControl.errors?.required">
                            <span>{{'generic.choose_file' | translate}}</span>
                        </button>
                        <input #fileInput type="file" (change)="onFileInput($event)" id="browseButton"
                               style="display:none;"/>
                        <span>{{'configuration.data.info' | translate}}</span>
                    </div>
                    <button mat-raised-button class="mat-primary" [disabled]="!uploaded" (click)="importLicense()">
                        <span>{{'generic.import' | translate}}</span>
                    </button>
                </div>
            </ng-container>
        </div>


    </div>

</div>
