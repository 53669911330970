<div class="page-layout simple fullwidth" fxFlex fxLayout="column">
    <div class="content" fxFlex fxLayout="column" fusePerfectScrollbar>
        <table *ngIf="!!data" mat-table [dataSource]="data.row">
            <!-- Position Column -->
            <ng-container *ngFor="let header of data.headers" [cdkColumnDef]="header.id">
                <th mat-header-cell *cdkHeaderCellDef [ngStyle]="{backgroundColor: data.headerColor}"> {{header.label | translate}} </th>
                <td mat-cell *cdkCellDef="let row"> {{row[header.id]}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>
