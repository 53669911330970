<div class="navbar-top mat-indigo-700-bg">

    <div class="logo">
        <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/brand.png" alt="">
        <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand" ></mat-icon>
        <span class="logo-text secondary-text">{{ brand }}</span>
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user mat-indigo-700-bg" fxLayout="column">

        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie&#64;mail.com</div>
        <div class="avatar-container" [ngClass]="layoutConfig.layout.navbar.background">
            <img class="avatar" src="assets/{{ brand }}/images/avatars/Velazquez.jpg" alt="">
        </div>

    </div>

    <div class="navbar-content">
        <fuse-navigation layout="vertical" role="navigation"></fuse-navigation>
    </div>

</div>
