<!--accessibility-->
<div id="skiptocontent" [tabIndex]="globalConfigurationService.getAddIndexToSkiptocontent()?null:1" [ngStyle]="{visibility:isAllowedSkipNavigation?'':'hidden'}"><a href="#maincontent" tabindex="1" (click)="navigateToAnchor($event)">{{'generic.accessibility_goto_main' | translate}}</a></div>
<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="layoutConfig.layout.style === 'vertical-layout-1'">
    <vertical-layout-1 ngClass="{{ brand }}" class="{{ themeColor }}"></vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="layoutConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2 ngClass="{{ brand }}" class="{{ themeColor }}"></vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="layoutConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3 ngClass="{{ brand }}" class="{{ themeColor }}"></vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="layoutConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1 ngClass="{{ brand }}" class="{{ themeColor }}"></horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<app-snackbar-recall-button *ngIf="isSnackbarRecallButtonConfigActive"></app-snackbar-recall-button>