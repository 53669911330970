<div mat-dialog-title>
    <h2 *ngIf="!!data.user">
        {{'generic.edit' | translate}}{{userName}}
    </h2>
    <h2 *ngIf="!data.user">
        {{'generic.add_account' | translate}}
    </h2>

    <button (click)="close()"
            aria-label="Close dialog"
            class="close">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-card>
    <mat-card-header>
        <mat-card-title>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="edit-user-container">
            <div class="field-container" *ngIf="!data?.user">
                <mat-label class="custom-width">{{ 'account-management.pseudoPart2' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.pseudoPart2' | translate }}</mat-label><input
                        [formControl]="controls.firstName"
                        matInput
                        name="firstName"
                        
                    >
                    <mat-error *ngIf="isNameInvalid()">
                        {{ 'generic.firstname_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field-container">
                <mat-label class="custom-width">{{ 'account-management.lastName' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.lastName' | translate }}</mat-label><input
                        [formControl]="controls.lastName"
                        matInput
                        name="lastName"
                        
                    >
                    <mat-error *ngIf="isNameInvalid(true)">
                        {{ 'generic.lastname_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="field-container">
                <mat-label class="custom-width">{{ 'account-management.email' | translate }}</mat-label>
                <mat-form-field appearance="outline" class="field-container-with-error">
                    <mat-label>{{ 'account-management.email' | translate }}</mat-label><input
                        [formControl]="controls.mail"
                        matInput
                        name="mail"
                        
                    >
                    <mat-error *ngIf="controls?.mail?.errors?.required">
                        {{ 'authentication.email.required' | translate}}
                    </mat-error>
                    <mat-error
                        *ngIf="controls?.mail?.errors?.email">
                        {{ 'authentication.email.invalid' | translate}}
                    </mat-error>
                    <mat-error
                        *ngIf="controls?.mail?.errors?.alreadyTaken">
                        {{ 'account-management.mail_taken' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field>
                <mat-label>{{'generic.license_type' | translate }}</mat-label><mat-select name="license" 
                            [formControl]="controls.license">
                    <mat-option [disabled]="disableOption(license)" *ngFor="let license of licenseTypes" [value]="license">
                        {{getTranslation(license) | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error class="licenseError" *ngIf="isLicenseError()">
                {{ 'generic.license_required' | translate }}
            </mat-error>


            <mat-form-field>
                <mat-label>{{'generic.deadline' | translate}}</mat-label><input name="endDate"
                       [formControl]="controls.endDate"
                       matInput
                       [matDatepicker]="endPicker"
                       
                />
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'generic.institution' | translate }}</mat-label><input
                    type="text"
                    name="institution"
                    
                    matInput
                    [matAutocomplete]="autoInstitution"
                    [formControl]="filterControl"

                >
                <mat-autocomplete #autoInstitution="matAutocomplete">
                    <mat-option *ngFor="let institution of filteredInstitutions | async" [value]="institution.attributes.label" (onSelectionChange)="onInstitutionChange(institution)">
                        {{showLabelInstitutionWithUai(institution)}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-expansion-panel #panel [disabled]="disableExpansionPanel" (opened)="enableNewInstitution()"
                                 (closed)="disableNewInstitution()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'groups-management.create_new_institution' | translate}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="institutionFormGroup" name="institutionForm">
                    <div class="creation-institution-form">
                        <!-- label institution Field -->
                        <mat-form-field>
                            <mat-label>{{ 'groups-management.choose_label_institution' | translate }}</mat-label><input
                                name="label"
                                formControlName="label"
                                
                                matInput
                            >
                            <mat-error *ngIf="institutionFormErrors?.label?.required">
                                {{ 'groups-management.label_institution_required' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <!-- uai (identifiant unique pour institution) institution Field -->
                        <mat-form-field>
                            <mat-label>{{ 'groups-management.choose_uai_institution' | translate }}</mat-label><input
                                name="uai"
                                formControlName="uai"
                                
                                matInput
                            >
                            <mat-error *ngIf="institutionFormErrors?.uai?.required">
                                {{ 'generic.uai_institution_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error class="errorInstitutionAlreadyExist" *ngIf="institutionFormErrors?.label?.exist && institutionFormErrors?.uai?.exist">
                        {{ 'groups-management.institution_already_exist' | translate }}
                    </mat-error>
                </form>
            </mat-expansion-panel>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <div mat-dialog-actions>
            <button (click)="close()" class="close-button" mat-raised-button>
                <span>{{'generic.cancel'|translate}}</span>
            </button>
            <button [disabled]="isFormInvalid" (click)="save()" class="save-button" mat-raised-button>
                <span>{{'generic.save'|translate}}</span>
            </button>
        </div>
    </mat-card-footer>
</mat-card>

