<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>
<form (ngSubmit)="onSubmit(noteForm.value)" [formGroup]="noteForm" fusePerfectScrollbar>
    <div mat-dialog-title>
        <h2>{{ "bd.create_bd_modal_title" | translate }}</h2>
        <button type="button" (click)="selfDialogRef.close()" aria-label="Close dialog" class="close">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container *ngIf="isInitializing === false else loading">

            <div class="form-content">
                <div class="title-field">
                    <mat-form-field class="w-100-p">
                        <mat-label>{{ 'bd.create_bd_title_label' | translate }}</mat-label>
                        <input formControlName="title" matInput
                               placeholder="{{ 'bd.create_bd_title_placeholder' | translate }}"/>
                        <mat-error *ngIf="noteForm.get('title').errors?.required">
                            {{ 'generic.field.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!-- <div class="lesson-field" fusePerfectScrollbar>
                    <mat-label>{{ 'bd.create_bd_lesson_label' | translate }}</mat-label>
                    <mat-list [class.disabled]="lessonIsDisabled" fusePerfectScrollbar>
                        <mat-list-item *ngIf="availableLessonsAreLoading">
                            <mat-spinner diameter="20"></mat-spinner>
                        </mat-list-item>

                        <mat-list-item (click)="(lessonIsDisabled === false) && toggleLesson(lesson)"
                                       *ngFor="let lesson of lessons"
                                       [ngClass]="{active : selectedLesson === lesson, selectable: true}">
                            {{lesson.attributes.metadatas.title}}
                        </mat-list-item>
                    </mat-list>
                </div> -->
            </div>

        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button [disabled]="isSaving || isValid === false" class="button mat-button" mat-button mat-raised-button type="submit">
            <mat-spinner *ngIf="isSaving"></mat-spinner>
            {{'generic.create_go_on' | translate}}
        </button>
    </mat-dialog-actions>
</form>